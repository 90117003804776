<!--  -->
<template>
  <div class='com-container com-container1'>
    <div class="com-title">
      <div class="com-title-left" style="color:#fff;">血压总览</div>
    </div>
    <div class="com-chart com-chart1" ref="ref_rank"></div>
  </div>
</template>
<script>
export default {
  data () {
    return { }
  },
  methods: {
    getresize () {
      setTimeout(() => {
        this.chartInstance.resize()
      }, 100)
    },
    async initChart () {
      this.chartInstance = this.$echarts.init(this.$refs.ref_rank, 'chalk')
      var colorList = ['#73DDFF', '#73ACFF', '#FDD56A', '#FDB36A', '#FD866A', '#9E87FF', '#58D5FF']
      var arr = [
        { value: 123, name: '高血压' },
        { value: 102, name: '正常范围' },
        { value: 122, name: '低血压' }
      ]
      const initOption = {
        color: colorList,
        title: {
        // text: 'PM2.5含量',
        // subtext:'50%',
          x: 'center',
          y: 'center',
          textStyle: {
            color: '#fff',
            fontSize: 15
          }
        },
        tooltip: {
          trigger: 'item'
        },
        series: [{
          type: 'pie',
          center: ['50%', '50%'],
          radius: ['24%', '35%'],
          clockwise: true,
          avoidLabelOverlap: true,
          hoverOffset: 15,
          itemStyle: {
            color: function (params) {
              return colorList[params.dataIndex]
            }
          },
          label: {
            show: true,
            position: 'outside',
            formatter: '{a|{b}：{d}%}\n{hr|}',
            rich: {
              hr: {
                backgroundColor: 't',
                borderRadius: 3,
                width: 3,
                height: 3,
                padding: [3, 3, 0, -12]
              },
              a: {
                padding: [-30, 15, -20, 15]
              }
            }
          },
          labelLine: {
            length: 20,
            length2: 30,
            lineStyle: {
              width: 1
            }
          },
          data: arr
        }]
      }
      this.chartInstance.setOption(initOption)
    }
  },
  mounted () {
    this.initChart()
    window.addEventListener('resize', this.getresize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.getresize)
    if (this.chartInstance) {
      this.chartInstance.dispose()
    }
  }
}
</script>
<style lang='less' scoped>
.com-chart1 {
  height: 100% !important;
}
</style>
