/*
 * @Author: 小宝 zksukuo@yunkangda.com
 * @Date: 2023-01-16 17:19:36
 * @LastEditors: 小宝 zksukuo@yunkangda.com
 * @LastEditTime: 2023-12-21 16:58:02
 * @FilePath: \visions\src\store\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import Vuex from 'vuex'
// 引入插件
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    theme: 'chalk',
    loginStatus: true,
    loginInfo: {},
    loginType: 0,
    SmartLargeScreenIndex: 0,
    SocketInfo: null,
    SocketData: [],
    SocketCode: '',
    AppMessageInfo: {
      showMessage: false,
      messageInfo: ''
    },
    ShowHeadTab: []
  },
  mutations: {
    changeTheme (state) {
      if (state.theme === 'chalk') {
        state.theme = 'vintage'
      } else {
        state.theme = 'chalk'
      }
    },
    changeLoginInfo (state, loginInfo) {
      state.loginInfo = loginInfo
    },
    changeLoginStatus (state, loginStatus) {
      state.loginStatus = loginStatus
    },
    ChageLoginType (state, index) {
      state.loginType = index
    },
    changeSmartLargeScreenIndex (state, index) {
      // console.log('智慧大屏索引', index)
      state.SmartLargeScreenIndex = index
      window.sessionStorage.setItem('zhdpIndex', index)
    },
    ChangeSocketInfo (state, obj) {
      // console.log(obj)
      state.SocketInfo = obj
    },
    ChangeSocketData (state, obj) {
      // console.log('修改监听数据：', obj)
      state.SocketData = obj
    },
    ChangeSocketCode (state, val) {
      // console.log('修改监听数据：', val)
      state.SocketCode = val
    },
    ChangeAppMessageInfo (state, obj) {
      state.AppMessageInfo.showMessage = obj.showMessage
      state.AppMessageInfo.messageInfo = obj.messageInfo
    },
    ChangeShowHeadTab (state, val) {
      // console.log('首页顶部导航：', val)
      state.ShowHeadTab = val
    }
  },
  actions: {
    changeLogin (context, loginStatus) {
      context.commit('changeLoginStatus', loginStatus)
    },
    changeLogins (context, loginInfo) {
      context.commit('changeLoginInfo', loginInfo)
    }
  },
  getters: {
    getLoginStatus (state) {
      return state.loginStatus
    },
    getLoginInfo (state) {
      return state.loginInfo
    }
  },
  modules: {
  },
  plugins: [
    createPersistedState({
      // 存储方式：localStorage、sessionStorage、cookies
      storage: window.sessionStorage,
      // 存储的 key 的key值
      key: 'store',
      render (state) {
        // 要存储的数据：本项目采用es6扩展运算符的方式存储了state中所有的数据
        return { ...state }
      }
    })
  ]
})
