<!--  -->
<template>
  <div class='com-container'>
    <van-tabs v-model="cxtype" @click="onTabClick" @disabled="disableClick">
      <van-tab title="体检进度" name="tjjd"></van-tab>
      <van-tab title="上传统计" name="sstj"></van-tab>
      <van-tab title="异常数据" name="ycsj"></van-tab>
    </van-tabs>
    <template v-if="showPage">
      <van-pull-refresh v-model="isLoading" success-text="刷新成功" @refresh="onRefresh">
        <van-row style="background:#ffffff" v-if="cxtype=='ycsj'">
          <van-col :span="24">
            <van-form @submit="onSubmit">
              <van-field
                v-model="org"
                name="org"
                label="机构"
                readonly
                placeholder="选择机构"
                @click="orgShow = !orgShow"
                :rules="[{ required: true, message: '请选择机构' }]"
              />
              <van-field
                v-model="startTime"
                name="startTime"
                label="开始时间"
                readonly
                placeholder="选择开始时间"
                @click="startTimeShow = !startTimeShow"
                :rules="[{ required: true, message: '选择开始时间' }]"
              />
              <van-field
                v-model="endTime"
                name="endTime"
                label="结束时间"
                readonly
                placeholder="选择结束时间"
                @click="endTimeShow = !endTimeShow"
                :rules="[{ required: true, message: '选择结束时间' }]"
              />
              <div style="margin: 16px;display:flex;justify-content: space-around;">
                <van-button type="info" style="width:40%;" block size="small" native-type="submit">查询</van-button>
                <van-button type="primary" style="width:40%;" block size="small" @click="excel()" native-type="button">导出</van-button>
              </div>
            </van-form>
          </van-col>
        </van-row>
        <van-row style="background:#ffffff" v-if="cxtype=='sstj'">
          <van-col :span="24">
            <van-form @submit="onSubmit">
              <van-field
                v-model="org"
                name="org"
                label="机构"
                readonly
                placeholder="选择机构"
                @click="orgShow = !orgShow"
                :rules="[{ required: true, message: '请选择机构' }]"
              />
              <van-field
                v-model="yearTime"
                name="yearTime"
                label="年份"
                readonly
                placeholder="选择年份"
                @click="yearShow = !yearShow"
                :rules="[{ required: true, message: '选择年份' }]"
              />
              <div style="margin: 16px;display:flex;justify-content: space-around;">
                <van-button type="info" style="width:40%;" block size="small" native-type="submit">查询</van-button>
                <van-button type="primary" style="width:40%;" block size="small" @click="excel()" native-type="button">导出</van-button>
              </div>
            </van-form>
          </van-col>
        </van-row>
        <div v-if="cxtype=='ycsj'">
          <ycsj :ycsjData="dataList"></ycsj>
          <van-row>
            <van-pagination
              v-model="start"
              :total-items="currentTotal"
              :show-page-size="length"
              force-ellipses
              @change="changePage"
            />
          </van-row>
        </div>
        <div v-else-if="cxtype=='tjjd'">
          <tjjd :tjjdData="tjjdData"></tjjd>
        </div>
        <div v-else-if="cxtype=='sstj'">
          <van-row>
            <div class="typeCard">
              <div class="typeCard-item item1" style="width:30%">
                <div>{{sumtjrs}}</div>
                <div>总体检数</div>
              </div>
              <div class="typeCard-item item2" style="width:30%">
                <div>{{sumscrs}}</div>
                <div>总上传</div>
              </div>
              <div class="typeCard-item item3" style="width:30%" @click="excel('all')">
                <div>{{sumwscrs}}</div>
                <div>总未传</div>
              </div>
            </div>
          </van-row>
          <van-row>
            <van-col :span="24">
              <table style="width: 100%">
                <thead style="background:#F1F2F7;font-size: 13px;">
                  <td>序号</td>
                  <td>机构</td>
                  <td>总体检人数</td>
                  <td>已上传人数</td>
                  <td>未上传人数</td>
                </thead>
                <tbody style="font-size: 13px;" v-if="dataList.length > 0">
                  <tr v-for="(item,index) in dataList" :key="index" @click="excel(item)">
                    <td>{{index+1}}</td>
                    <td>{{item.tjjgcmhmc}}</td>
                    <td>{{item.tjrs}}</td>
                    <td>{{item.scrs}}</td>
                    <td v-if="item.tjrs==item.scrs">0</td>
                    <td v-else>{{item.tjrs - item.scrs > 0 ? (item.tjrs - item.scrs) : ''}}</td>
                  </tr>
                </tbody>
                <tbody style="font-size: 13px;" v-else>
                  <tr style="text-align:center">
                    <td colspan="10">暂无数据</td>
                  </tr>
                </tbody>
              </table>
            </van-col>
          </van-row>
          <!-- <van-row>
            <van-pagination
              v-model="start"
              :total-items="currentTotal"
              :show-page-size="length"
              force-ellipses
              @change="changePage"
            />
          </van-row> -->
        </div>
      </van-pull-refresh>
    </template>
    <template v-else>
      <van-tag type="warning">暂无权限</van-tag>
    </template>
    <van-popup round position="bottom" v-model="orgShow">
      <div class="content">
        <van-picker show-toolbar title="选择机构" value-key="label"  :columns="orgColumns" @cancel="orgShow = !orgShow" @change="orgChange" @confirm="orgConfirm" :default-index="defaultOrg" />
      </div>
    </van-popup>
    <van-popup round position="bottom" v-model="startTimeShow">
      <div class="content">
        <van-datetime-picker v-model="currentDate" type="date" title="选择开始时间"
         :min-date="minDate" :max-date="maxDate" @cancel="startTimeShow = !startTimeShow" @change="startTimeChange" @confirm="startTimeConfirm"/>
      </div>
    </van-popup>
    <van-popup round position="bottom" v-model="endTimeShow">
      <div class="content">
        <van-datetime-picker v-model="currentDate" type="date" title="选择结束时间"
         :min-date="minDate" :max-date="maxDate" @cancel="endTimeTimeShow = !endTimeTimeShow" @change="endTimeChange" @confirm="endTimeConfirm"/>
      </div>
    </van-popup>
    <van-popup round position="bottom" v-model="yearShow">
      <div class="content">
        <van-picker show-toolbar :columns="yearColumns" title="选择年份" :default-index="11" @cancel="yearShow = !yearShow" @change="yearChange" @confirm="yearConfirm"/>
      </div>
    </van-popup>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import tjjd from './tjjd.vue'
import ycsj from './ycsj.vue'
export default {
// import引入的组件需要注入到对象中才能使用
  components: { ycsj, tjjd },
  data () {
  // 这里存放数据
    return {
      isLoading: false,
      yearColumns: ['2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021', '2022', '2023'],
      yearShow: false,
      yearTime: '2021',
      showPage: true,
      currentPage: 1,
      dataList: '',
      org: '',
      orgValue: '',
      startTime: '',
      endTime: '',
      minDate: new Date(1994, 0, 1),
      maxDate: new Date(2025, 10, 1),
      currentDate: new Date(),
      orgShow: false,
      startTimeShow: false,
      endTimeShow: false,
      orgColumns: [],
      username: '',
      openId: '',
      jgbh: '',
      unionid: '',
      tmcode: '',
      start: 0,
      length: 20,
      currentTotal: 0,
      ssjgbh: '',
      defaultOrg: 0,
      lytype: '',
      tjrqy: '',
      cxtype: 'ycsj',
      tjjdData: null,
      tjdnData: [],
      sumscrs: '',
      sumtjrs: '',
      sumwscrs: '',
      jgjb: 4
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    // 下拉刷新
    onRefresh () {
      this.onSubmit()
    },
    async excel (val) {
      this.$toast.loading({
        duration: 0,
        message: '加载中...',
        forbidClick: true
      })
      let type = ''
      if (this.cxtype === 'ycsj') {
        type = 'tjsjycjg'
      } else if (this.cxtype === 'sstj') {
        type = 'wscrs'
      }
      let params = {}

      if (val) {
        if (val === 'all') {
          params = {
            username: this.username, // 类型：String  必有字段  备注：用户名
            openId: this.openId, // 类型：String  必有字段  备注：微信openid
            ssjgbh: this.orgValue, // 类型：String  必有字段  备注：所属机构
            jgbh: this.jgbh, // 类型：String  必有字段  备注：机构编号
            unionid: this.unionid, // 类型：String  必有字段  备注：微信unionid
            beginsj: this.startTime, // 类型：String  必有字段  备注：开始时间
            endsj: this.endTime, // 类型：String  必有字段  备注：结束时间
            offset: this.start, // 类型：Number  必有字段  备注：分页参数
            limit: this.length, // 类型：Number  必有字段  备注：分页长度
            yztype: type
          }
        } else {
          params = {
            username: this.username, // 类型：String  必有字段  备注：用户名
            openId: this.openId, // 类型：String  必有字段  备注：微信openid
            ssjgbh: this.orgValue, // 类型：String  必有字段  备注：所属机构
            jgbh: val.tjjgcmhid, // 类型：String  必有字段  备注：机构编号
            unionid: this.unionid, // 类型：String  必有字段  备注：微信unionid
            beginsj: this.startTime, // 类型：String  必有字段  备注：开始时间
            endsj: this.endTime, // 类型：String  必有字段  备注：结束时间
            offset: this.start, // 类型：Number  必有字段  备注：分页参数
            limit: this.length, // 类型：Number  必有字段  备注：分页长度
            yztype: type
          }
        }
      } else {
        params = {
          username: this.username, // 类型：String  必有字段  备注：用户名
          openId: this.openId, // 类型：String  必有字段  备注：微信openid
          ssjgbh: this.orgValue, // 类型：String  必有字段  备注：所属机构
          jgbh: this.orgValue, // 类型：String  必有字段  备注：机构编号
          unionid: this.unionid, // 类型：String  必有字段  备注：微信unionid
          beginsj: this.startTime, // 类型：String  必有字段  备注：开始时间
          endsj: this.endTime, // 类型：String  必有字段  备注：结束时间
          offset: this.start, // 类型：Number  必有字段  备注：分页参数
          limit: this.length, // 类型：Number  必有字段  备注：分页长度
          yztype: type
        }
      }
      const { data: res } = await this.$http.post('/excels/findtjexcel', params)
      this.$toast.clear()
      if (res.code === 0) {
        if (res.data) {
          window.location.href = res.data
        } else {
          this.$toast('没有返回正常的url')
        }
      } else {
        this.$toast('导出失败')
      }
    },
    async binduserfind () {
      this.$toast.loading({
        duration: 0,
        message: '加载中...',
        forbidClick: true
      })
      this.username = this.getQueryString('username')
      this.openId = this.getQueryString('openId')
      this.jgbh = this.getQueryString('jgbh')
      this.unionid = this.getQueryString('unionid')
      this.endTime = this.getQueryString('endsj')
      this.startTime = this.getQueryString('beginsj')
      this.ssjgbh = this.getQueryString('ssjgbh')
      this.lytype = this.getQueryString('lytype')
      this.tmcode = this.getQueryString('tmcode')
      this.cxtype = this.getQueryString('cxtype')
      this.jgjb = this.getQueryString('jgjb')
      const params = { username: this.username, openId: this.openId, jgbh: this.jgbh, unionid: this.unionid, ssjgbh: this.ssjgbh }
      const { data: res } = await this.$http.post('/api/wx/binduserfind', params)
      this.$toast.clear()
      if (res.code === 0) {
        // if (res.data.children && res.data.children.length > 0) {
        //   this.orgColumns = res.data.children
        // }

        // if (this.jgbh === this.ssjgbh) {
        //   this.orgColumns.unshift({
        //     label: res.data.label,
        //     value: res.data.value || this.ssjgbh
        //   })
        // }
        this.orgColumns = res.data.children
        this.orgColumns.unshift({
          label: res.data.label,
          value: res.data.value || this.ssjgbh
        })
        this.getDefaultOrg(this.ssjgbh, this.orgColumns)
      } else {
        this.$toast(res.msg)
      }
    },
    getDefaultOrg (val, data) {
      data.forEach((ele, index) => {
        if (ele.value === val) {
          this.defaultOrg = index
          this.org = ele.label
          this.orgValue = ele.value
          this.onSubmit()
        }
      })
    },
    orgConfirm (value, index) {
      this.org = value.label
      this.orgValue = value.value
      this.orgShow = false
    },
    orgChange (picker, value, index) {
      // this.$toast(`当前值：${value}, 当前索引：${index}`)
    },
    startTimeConfirm (value) {
      this.startTime = this.formatDateTime(value)
      this.startTimeShow = false
    },
    startTimeChange (picker) {
      // this.$toast(value)
      this.startTime = picker.getValues()[0] + '-' + picker.getValues()[1] + '-' + picker.getValues()[2]
    },
    endTimeConfirm (value) {
      this.endTime = this.formatDateTime(value)
      this.endTimeShow = false
    },
    endTimeChange (picker) {
      // this.$toast(value)
      this.endTime = picker.getValues()[0] + '-' + picker.getValues()[1] + '-' + picker.getValues()[2]
    },
    yearConfirm (value) {
      // // console.log(value)
      // this.yearTime = this.formatDateTime(value)
      this.yearShow = false
    },
    yearChange (picker) {
      // this.$toast(value)
      this.yearTime = picker.getValues()[0]
    },
    // 格式化时间
    formatDateTime (date) {
      var dates = new Date(date)
      var y = dates.getFullYear()
      var m = dates.getMonth() + 1
      m = m < 10 ? ('0' + m) : m
      var d = dates.getDate()
      d = d < 10 ? ('0' + d) : d
      // var h = date.getHours()
      return y + '-' + m + '-' + d
    },
    changePage (val) {
      this.start = val - 1
      this.onSubmit()
    },
    async onSubmit (valuess) {
      this.$toast.loading({
        duration: 0,
        message: '加载中...',
        forbidClick: true
      })
      const params = {
        cxtype: this.cxtype,
        lytype: this.lytype,
        username: this.username, // 类型：String  必有字段  备注：用户名
        openId: this.openId, // 类型：String  必有字段  备注：微信openid
        ssjgbh: this.orgValue, // 类型：String  必有字段  备注：所属机构
        jgbh: this.jgbh, // 类型：String  必有字段  备注：机构编号
        unionid: this.unionid, // 类型：String  必有字段  备注：微信unionid
        beginsj: this.startTime, // 类型：String  必有字段  备注：开始时间
        endsj: this.endTime, // 类型：String  必有字段  备注：结束时间
        offset: this.start, // 类型：Number  必有字段  备注：分页参数
        limit: this.length, // 类型：Number  必有字段  备注：分页长度
        tmcode: this.tmcode,
        tjrqy: this.yearTime,
        jgjb: this.jgjb
      }
      let url = ''
      let data = null
      if (this.cxtype === 'ycsj') {
        url = '/api/wx/app/tjfindinfo'
        data = await this.$http.get(url, { params: params })
      } else {
        url = '/api/wx/tjfindinfo'
        data = await this.$http.post(url, params)
      }
      // // console.log(data.data)
      const res = data.data
      this.isLoading = false
      this.$toast.clear()
      if (this.cxtype === 'tjjd') {
        // // console.log(res.data)
        this.tjjdData = res.data
      } else if (this.cxtype === 'ycsj') {
        if (res.data.length > 0) {
          this.currentTotal = res.currentTotal
          this.dataList = res.data
        } else if (res.code === -100) {
          this.dataList = []
          this.$toast('暂无权限！')
        } else if (res.data.length === 0) {
          this.dataList = []
          this.$toast('查询数据为空!')
        } else {
          this.dataList = []
          this.$toast(res.msg)
        }
      } else if (this.cxtype === 'sstj') {
        // this.currentTotal = res.currentTotal
        this.dataList = res.data.sstjlist
        this.sumscrs = res.data.sumscrs
        this.sumtjrs = res.data.sumtjrs
        this.sumwscrs = res.data.sumwscrs
      }
    },
    onTabClick () {
      this.onSubmit()
    },
    disableClick () {
      this.$message.warning('该功能暂未开放！')
    },
    getQueryString (name) {
      var search = window.location.search.substring(1)
      if (!search) {
        search = window.location.hash.split('?')[1]
      }
      if (search) {
        var obj = JSON.parse('{"' + decodeURIComponent(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
        return name ? obj[name] : obj
      }
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  async mounted () {
    await this.binduserfind()
  },
  beforeCreate () {}, // 生命周期 - 创建之前
  beforeMount () {}, // 生命周期 - 挂载之前
  beforeUpdate () {}, // 生命周期 - 更新之前
  updated () {}, // 生命周期 - 更新之后
  beforeDestroy () {}, // 生命周期 - 销毁之前
  destroyed () {}, // 生命周期 - 销毁完成
  activated () {} // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.com-container {
  // background-color: #f2f2f2;
  background-color: #fff;
  overflow: auto;
}
td {
  padding: 10px 0;
  text-align: center;
}
tr {
  border-top: 1px solid #eee;
}
table
  {
    border-collapse:collapse;
  }
  .dui {
    color: green;
  }
  .cuo {
    color: red;
  }
  .typeCard {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    &-item {
      width: 21%;
      height: 60px;
      font-size: 12px;
      padding-top: 15px;
      box-sizing: border-box;
      margin: 5px 0;
      border-radius: 6px;
      color: #fff;
      text-align: center;
    }
    .item1 {
      background-image: linear-gradient(135deg, rgba(250,164,102,1), rgba(250,164,102,0.5));
    }
    .item2 {
      background-image: linear-gradient(135deg, rgb(20, 233, 116), rgba(20, 233, 116,0.5));
    }
    .item3 {
      background-image: linear-gradient(135deg, rgb(185, 80, 245), rgba(185, 80, 245,0.5));
    }
  }
</style>
