<!-- 年度分析 -->
<template>
  <div class='com-container com-container1'>
    <div class="com-chart com-chart1" ref="ref_rank"></div>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';

export default {
// import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
  // 这里存放数据
    return {
      chartInstance: null, // echarts实例
      chartIndex: 0,
      allData: [], // 图表数据
      allData2: null,
      timerId: null,
      timerId1: null
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    async initChart () {
      this.chartInstance = this.$echarts.init(this.$refs.ref_rank, 'chalk')
      const initOption = {
        grid: {
          containLabel: true,
          left: '2%',
          top: '15%',
          right: '2%',
          bottom: '2%'
        },
        legend: {
          right: '14%',
          top: '0%',
          itemStyle: {
            color: {
              name: '慢性病',
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [{
                offset: 0, color: '#00F8A6' // 0% 处的颜色
              }, {
                offset: 1, color: '#0062C3' // 100% 处的颜色
              }],
              global: false // 缺省为 false
            }
          }
        },
        tooltip: {
          show: true
        },
        xAxis: {
          type: 'category'
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '慢性病',
            type: 'bar',
            label: {
              normal: {
                show: true,
                position: 'top',
                color: 'rgba(0,240,166,1)'
              }
            }
          }
        ]
      }
      this.chartInstance.setOption(initOption)
      // 鼠标移入移出操作
      this.chartInstance.on('mouseover', () => {
        clearInterval(this.timerId)
      })
      this.chartInstance.on('mouseout', () => {
        this.startInterval()
      })
    },
    async getData () {
      const params = {
        jgbh: this.$store.state.loginInfo.jgbh,
        jbmc: this.$store.state.loginInfo.jgmc,
        ssjgbhmc: this.$store.state.loginInfo.label,
        usercode: this.$store.state.loginInfo.usercode,
        username: this.$store.state.loginInfo.username,
        jgjb: this.$store.state.loginInfo.jgjb,
        ssjgbh: this.$store.state.loginInfo.value,
        sjjgbh: this.$store.state.loginInfo.sjjgbh
      }
      const { data: res } = await this.$http.post('api/jkct/app/zhdpgwfwsxzztgy', params)
      if (res.code !== 0) this.$message.error('res.msg')
      this.allData = res.data
      this.updataChart()
    },
    updataChart () {
      // 颜色渐变数组
      const colorArr = [
        ['rgba(0,243,166,1)', 'rgba(0,101,194,1)']
      ]
      const nameArr = this.allData.map(item => {
        return item.name
      })
      const valueArr = this.allData.map(item => {
        return item.value
      })
      // valueArr = valueArr.sort((a, b) => {
      //   return b - a
      // })
      const dataOption = {
        xAxis: {
          data: nameArr
        },
        series: [
          {
            name: '慢性病',
            data: valueArr,
            itemStyle: {
              color: arg => {
                const targetColor = colorArr[0]
                return new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: targetColor[0]
                  },
                  {
                    offset: 1,
                    color: targetColor[1]
                  }
                ])
              }
            }
          }
        ]
      }
      this.chartInstance.setOption(dataOption)
    },
    screenAdapter () {
      const titleFontSize = this.$refs.ref_rank.offsetWidth / 100 * 3.6
      const adapterOption = {
        legend: {
          itemWidth: titleFontSize * 0.8,
          itemHeight: titleFontSize * 1
        },
        series: [
          {
            type: 'bar',
            barWidth: titleFontSize * 1,
            itemStyle: {
              barBorderRadius: [titleFontSize * 0.3, titleFontSize * 0.3, titleFontSize * 0.3, titleFontSize * 0.3]
            }
          }
        ]
      }
      this.chartInstance.setOption(adapterOption)
      this.chartInstance.resize()
    },
    startInterval () {
      if (this.timerId) {
        clearInterval(this.timerId)
      }
      this.timerId = setInterval(() => {
        this.getData()
        // this.screenAdapter()
      }, 1000 * 60 * 5)

      if (this.timerId1) {
        clearInterval(this.timerId1)
      }
      this.timerId1 = setInterval(() => {
        const obj = this.allData[0]
        this.allData.splice(0, 1)
        this.allData.push(obj)
        this.updataChart()
      }, 3000)
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.initChart()
    this.getData()
    window.addEventListener('resize', this.screenAdapter)
    this.screenAdapter()
    this.startInterval()
  },
  beforeCreate () {}, // 生命周期 - 创建之前
  beforeMount () {}, // 生命周期 - 挂载之前
  beforeUpdate () {}, // 生命周期 - 更新之前
  updated () {}, // 生命周期 - 更新之后
  beforeDestroy () {}, // 生命周期 - 销毁之前
  destroyed () {
    window.removeEventListener('resize', this.screenAdapter)
    clearInterval(this.timerId)
  }, // 生命周期 - 销毁完成
  activated () {} // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>

<style scoped lang="less">
/* // @import url(); 引入公共css类 */
.com-container {
  background: none;
  // .com-chart {
  //   height: 100%;
  // }
}
</style>
