<template>
  <div class="warn">
    <div class="warn-box">
      <div class="warn-head">
        <div class="title">
          <span>报警信息列表</span>
        </div>
        <div class="close" @click="close">
          <span class="el-icon-close"></span>
        </div>
      </div>

      <div class="warn-scroll">
        <!-- 序号 id  预警来源  lytype  预警分类 msgtype 预警模块  mkmc   姓名 name  身份证号 sfzh  服务内容 fwnr   预警信息 msg  床位信息 lcfjcw  机构名称  tjjgmc 上报人  usercode   上边时间 fwsj  处置人 czr   处置意见  czryj -->
        <el-table :data="tableData" height="100%" style="width: 100%">
          <el-table-column prop="id" label="序号">
          </el-table-column>
          <el-table-column prop="lytype" label="预警来源">
          </el-table-column>
          <el-table-column prop="name" label="姓名">
          </el-table-column>
          <el-table-column prop="sfzh" label="身份证号">
          </el-table-column>
          <el-table-column prop="fwnr" label="服务内容">
          </el-table-column>
          <el-table-column prop="msg" label="预警信息">
          </el-table-column>
          <el-table-column prop="tjjgmc" label="机构名称">
          </el-table-column>
          <el-table-column prop="usercode" label="上报人">
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button size="mini" @click="openInfo(scope.row)">详情</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="warn-info" v-if="showInfo">
      <div class="warn-info-box">
        <div class="warn-info-head">
          <div class="title">
            <span>详情</span>
          </div>
          <div class="close" @click="closeInfo">
            <span class="el-icon-close"></span>
          </div>
        </div>
        <div class="warn-info-scroll">
          <div class="item">
            <div class="key">
              <span>序号</span>
            </div>
            <div class="value">
              <span>{{ info.id }}</span>
            </div>
          </div>
          <div class="item">
            <div class="key">
              <span>姓名</span>
            </div>
            <div class="value">
              <span>{{ info.name }}</span>
            </div>
          </div>
          <div class="item">
            <div class="key">
              <span>身份证号</span>
            </div>
            <div class="value">
              <span>{{ info.sfzh }}</span>
            </div>
          </div>
          <div class="item">
            <div class="key">
              <span>预警来源</span>
            </div>
            <div class="value">
              <span>{{ info.lytype }}</span>
            </div>
          </div>
          <div class="item">
            <div class="key">
              <span>预警分类</span>
            </div>
            <div class="value">
              <span>{{ info.msgtype }}</span>
            </div>
          </div>
          <div class="item">
            <div class="key">
              <span>预警模块</span>
            </div>
            <div class="value">
              <span>{{ info.mkmc }}</span>
            </div>
          </div>
          <div class="item">
            <div class="key">
              <span>服务内容</span>
            </div>
            <div class="value">
              <span>{{ info.fwnr }}</span>
            </div>
          </div>
          <div class="item">
            <div class="key">
              <span>预警信息</span>
            </div>
            <div class="value">
              <span>{{ info.msg }}</span>
            </div>
          </div>
          <div class="item">
            <div class="key">
              <span>机构名称</span>
            </div>
            <div class="value">
              <span>{{ info.tjjgmc }}</span>
            </div>
          </div>
          <div class="item">
            <div class="key">
              <span>上报人</span>
            </div>
            <div class="value">
              <span>{{ info.usercode }}</span>
            </div>
          </div>
          <div class="item">
            <div class="key">
              <span>上报时间</span>
            </div>
            <div class="value">
              <span>{{ info.fwsj }}</span>
            </div>
          </div>
          <div class="item">
            <div class="key">
              <span>处理人</span>
            </div>
            <div class="value">
              <span>{{ info.czr }}</span>
            </div>
          </div>
          <div class="item">
            <div class="key">
              <span>处置意见</span>
            </div>
            <div class="value">
              <span>{{ info.czryj }}</span>
            </div>
          </div>
          <div class="item">
            <div class="key">
              <span>预警信息</span>
            </div>
            <div class="value">
              <span></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data () {
    return {
      tableData: [],
      params: {
        jgbh: '',
        msgtype: 'zhyysyts'
      },
      showInfo: false,
      info: {
        id: '',
        name: '',
        sfzh: '',
        lytype: '',
        msgtype: '',
        mkmc: '',
        fwnr: '',
        msg: '',
        tjjgmc: '',
        usercode: '',
        fwsj: '',
        czr: '',
        czryj: ''
      }
    }
  },
  computed: {
    ...mapState(['loginInfo'])
  },
  created () {
    this.getList()
  },
  methods: {
    async getList () {
      // https://doc.weixin.qq.com/doc/w3_AAMAewYpAFE8HoMulH1T7eXOuyYQP?scode=ACwAmwdjAAY7a0Y55NAAMAewYpAFE
      try {
        this.params.jgbh = this.loginInfo.jgbh
        const { data: res } = await this.$http.post('/api/jkct/app/zhgwsyyj', this.params)
        console.log('报警信息列表', res)
        this.tableData = res.data
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
      } catch (error) {
        console.log('报警信息列表请求失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    openInfo (info) {
      this.showInfo = true
      this.info = info
    },
    closeInfo () {
      this.showInfo = false
      for (const key in this.info) {
        this.info[key] = ''
      }
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.warn {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .7);

  .warn-box {
    width: 70%;
    height: 80%;
    padding: 0 30px;
    background-image: url('../../assets/img/spms/bg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    border: 1px solid #44D7B6;
    box-sizing: border-box;

    .warn-head {
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 80px;

      .title {
        height: 80px;
        line-height: 80px;
        color: #44D7B6;
        font-size: 18px;
        font-weight: 600;
      }

      .close {
        height: 80px;
        line-height: 80px;
        color: #44D7B6;
        font-size: 20px;
        cursor: pointer;
      }
    }

    .warn-scroll {
      width: 100%;
      height: calc(100% - 105px);

      // 表格样式修改
      .el-table {
        background-color: #081C38;
        border: 4px solid #073F73;
        box-sizing: border-box;

        &::before {
          background-color: transparent;
        }

        // 表头首行
        /deep/th.el-table__cell.is-leaf {
          border-bottom: none;
        }

        /deep/td.el-table__cell {
          border-bottom: 1px solid #01EEFD;
        }

        // 表头背景颜色
        /deep/th.el-table__cell {
          background-color: #024276;
        }

        /deep/.el-table__cell {
          text-align: center;
          color: #fff;
        }

        /deep/tr {
          background-color: #081C38;
        }

        /deep/.el-table__body tr:hover>td.el-table__cell {
          background-color: transparent !important;
        }

        /deep/.el-checkbox__inner {
          background-color: transparent;
        }

        /deep/.is-indeterminate .el-checkbox__inner,
        /deep/.is-checked .el-checkbox__inner {
          background-color: #04B4D0;
        }

        /deep/.el-table__body-wrapper::-webkit-scrollbar {
          width: 0;
        }

        /deep/.el-table__empty-text {
          color: #fff;
        }

        /deep/ th.gutter {
          display: none;
          width: 0px !important;
        }

        /deep/ colgroup col[name='gutter'] {
          display: none;
          width: 0;
        }

        /deep/ .el-table__body {
          width: 100% !important;
        }

        .el-button {
          color: #4d76ff;
        }
      }
    }
  }

  .warn-info {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99999;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .4);

    .warn-info-box {
      width: 50%;
      height: 80%;
      padding: 0 30px;
      background-image: url('../../assets/img/spms/bg.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      border: 1px solid #44D7B6;
      box-sizing: border-box;

      .warn-info-head {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 80px;

        .title {
          height: 80px;
          line-height: 80px;
          color: #01EEFD;
          font-size: 18px;
          font-weight: 600;
        }

        .close {
          height: 80px;
          line-height: 80px;
          color: #01EEFD;
          font-size: 20px;
          cursor: pointer;
        }
      }

      .warn-info-scroll {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        height: calc(100% - 105px);
        padding: 0 20px;
        box-sizing: border-box;

        .item {
          display: flex;
          align-items: center;
          width: 50%;

          .key {
            width: 100px;
            text-align: right;
            color: #01EEFD;
            font-size: 16px;
          }

          .value {
            width: calc(100% - 110px);
            height: 50px;
            line-height: 50px;
            text-align: center;
            margin-left: 10px;
            border-bottom: 1px solid #01EEFD;
            box-sizing: border-box;
            color: #01EEFD;
            font-size: 16px;
          }
        }
      }
    }
  }
}
</style>
