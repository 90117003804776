/* eslint-disable vue/valid-v-bind */
<!-- 体检详情 -->
<template>
  <div class='hcontainer'>
    <div class="detail-top">
      <div class="detail-left">
        <div class="header"><img :src="ybqk.txurl" alt=""></div>
        <div class="name">{{ybqk.name}}</div>
        <div class="dept" style="padding: 10px 0">{{ybqk.tjjgmc}}</div>
        <div class="time">{{ybqk.tjrq}}</div>
      </div>
      <div class="detail-right">
        <div class="title">体检结论</div>
        <div class="info" @click="tipDetail(ybqk.tjycjg)">体检异常结果：{{ybqk.tjycjg}}</div>
        <div class="info" @click="tipDetail(ybqk.tjjkzd)">健康指导：{{ybqk.tjjkzd}}</div>
        <div class="info" @click="tipDetail(ybqk.tjwxyskz)">危险控制因素：{{ybqk.tjwxyskz}}</div>
        <div class="info" @click="tipDetail(tzjg)">体质结果：{{tzjg}}</div>
      </div>
    </div>
    <div style="height:15px;background-color:#F1F2F1"></div>
    <div class="detail-bot" v-if="activeIndex == 1">
      <van-cell title="体检明细" />
      <van-cell icon="location-o" title="一般项目" is-link @click="showDetail(2)" />
      <van-cell icon="location-o" title="血常规" :value="xcgValue" is-link @click="activeIndex = 3" />
      <van-cell icon="location-o" title="生化检查" :value="shValue" is-link @click="activeIndex = 4" />
      <van-cell icon="location-o" title="尿常规" :value="ncgValue" is-link @click="activeIndex = 5" />
      <van-cell icon="location-o" title="心电图" :value="xdt.jktjts" is-link @click="activeIndex = 6" />
      <van-cell icon="location-o" title="超声检查" is-link @click="activeIndex = 7" />
      <van-cell icon="location-o" title="老年人中医药健康管理服务详情" is-link @click="goLnr" />
    </div>
    <div class="detail-bot" v-if="activeIndex == 2">
      <div class="detail-close"><van-icon name="cross" @click="activeIndex = 1" /></div>
      <van-cell title="一般项目" />
      <van-cell icon="location-o" title="身高"  :value="ybqk.ybzksg+' cm'" />
      <van-cell icon="location-o" title="体重" :value="ybqk.ybzktz+' kg'" />
      <van-cell icon="location-o" title="体温" :value="ybqk.ybzktw+' ℃'"  />
      <van-cell icon="location-o" title="脉率" :value="ybqk.ybzkbml+' 次/分钟'"  />
      <van-cell icon="location-o" title="血压" :value="ybqk.ybzkxyg+'/'+ybqk.ybzkxyd+' mmhg'"  />
      <van-cell icon="location-o" title="腰围" :value="ybqk.ybzkyw+' cm'"  />
      <van-cell icon="location-o" title="体质指数(BMI)" :value="ybqk.ybzkbmi+' kg/㎡'" />
    </div>
    <div class="detail-bot" v-if="activeIndex == 3">
      <div class="detail-close"><van-icon name="cross" @click="activeIndex = 1" /></div>
      <van-cell title="血常规" />
      <van-row>
        <van-col span="8">项目名称</van-col>
        <van-col span="2">结果</van-col>
        <van-col span="3">提示</van-col>
        <van-col span="3">单位</van-col>
        <van-col span="8">参考范围</van-col>
      </van-row>
      <van-row v-for="(i,index) in xcgList" :key="index" >
        <van-col span="8">{{i.jktjxmmc}}</van-col>
        <van-col span="2">{{i.jktjjgsz}}</van-col>
        <van-col span="3">{{i.jktjts}}</van-col>
        <van-col span="3">{{i.jktjdw}}</van-col>
        <van-col span="8">{{i.jktjckfw}}</van-col>
      </van-row>
    </div>
    <div class="detail-bot" v-if="activeIndex == 4">
      <div class="detail-close"><van-icon name="cross" @click="activeIndex = 1" /></div>
      <van-cell title="生化检查" />
      <van-row>
        <van-col span="8">项目名称</van-col>
        <van-col span="2">结果</van-col>
        <van-col span="3">提示</van-col>
        <van-col span="3">单位</van-col>
        <van-col span="8">参考范围</van-col>
      </van-row>
      <van-row v-for="(i,index) in shjcList" :key="index" >
        <van-col span="8">{{i.jktjxmmc}}</van-col>
        <van-col span="2">{{i.jktjjgsz}}</van-col>
        <van-col span="3">{{i.jktjts}}</van-col>
        <van-col span="3">{{i.jktjdw}}</van-col>
        <van-col span="8">{{i.jktjckfw}}</van-col>
      </van-row>
    </div>
    <div class="detail-bot" v-if="activeIndex == 5">
      <div class="detail-close"><van-icon name="cross" @click="activeIndex = 1" /></div>
      <van-cell title="尿常规" />
      <van-row>
        <van-col span="8">项目名称</van-col>
        <van-col span="2">结果</van-col>
        <van-col span="3">提示</van-col>
        <van-col span="3">单位</van-col>
        <van-col span="8">参考范围</van-col>
      </van-row>
      <van-row v-for="(i,index) in ncgList" :key="index" >
        <van-col span="8">{{i.jktjxmmc}}</van-col>
        <van-col span="2">{{i.jktjjgsz}}</van-col>
        <van-col span="3">{{i.jktjts}}</van-col>
        <van-col span="3">{{i.jktjdw}}</van-col>
        <van-col span="8">{{i.jktjckfw}}</van-col>
      </van-row>
    </div>
    <div class="detail-bot" v-if="activeIndex == 6">
      <div class="detail-close"><van-icon name="cross" @click="activeIndex = 1" /></div>
      <van-cell title="心电图" />
      <van-image width="100%" :src="xdt.rpturl" />
    </div>
    <div class="detail-bot" v-if="activeIndex == 7">
      <div class="detail-close"><van-icon name="cross" @click="activeIndex = 1" /></div>
      <van-cell title="超声检查" />
      <div style="display:flex">
        <van-image width="100%" :src="bc.tp1url" />
        <van-image width="100%" :src="bc.tp2url" />
      </div>
      <van-collapse v-model="activeNames">
        <van-collapse-item title="超声所见:" name="1">
          {{bc.jktjcsms}}
        </van-collapse-item>
        <van-collapse-item title="诊断意见:" name="2">
          {{bc.jktjcsts}}
        </van-collapse-item>
      </van-collapse>
    </div>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';

export default {
// import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
  // 这里存放数据
    return {
      activeNames: ['1', '2'],
      activeIndex: 1,
      userInfo: {},
      ybqk: {},
      tzjg: '',
      xcgList: [],
      shjcList: [],
      ncgList: [],
      xdt: {},
      bc: {},
      xcgZc: 0,
      xcgYc: 0,
      xcgValue: '',
      shZc: 0,
      shYc: 0,
      shValue: '',
      ncgZc: 0,
      ncgYc: 0,
      ncgValue: ''
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    tipDetail (info) {
      // // console.log(info.split('|'))
      let str = ''
      info.split('|').forEach(item => {
        str = str + item + '\n'
      })
      this.$toast(str)
    },
    goLnr () {
      this.$router.push({
        path: '/hlnr',
        query: {
          jktjid: this.$route.query.jktjid,
          grjbxxid: this.$route.query.grjbxxid
        }
      })
    },
    // 体质结果
    async getTzjgData () {
      const url = '/ykdlnrzyyjkfwjls/finds'
      const params = {
        jktjid: this.$route.query.jktjid,
        grjbxxid: this.$route.query.grjbxxid
      }
      const { data: res } = await this.$http.post(url, params)
      if (res.code === 0) {
        const tzData = res.data[0]
        if (tzData.tzlxqxzbs !== '3') {
          this.tzjg += '气虚质\n'
        }
        if (tzData.tzlxyxzbs !== '3') {
          this.tzjg += '阳虚质\n'
        }
        if (tzData.tzlxyinxzbs !== '3') {
          this.tzjg += '阴虚质\n'
        }
        if (tzData.tzlxtszbs !== '3') {
          this.tzjg += '痰湿质\n'
        }
        if (tzData.tzlxsrzbs !== '3') {
          this.tzjg += '湿热质\n'
        }
        if (tzData.tzlxxyzbs !== '3') {
          this.tzjg += '血瘀质\n'
        }
        if (tzData.tzlxtbzbs !== '3') {
          this.tzjg += '气郁质\n'
        }
        if (tzData.tzlxtbzbs !== '3') {
          this.tzjg += '特禀质\n'
        }
        if (tzData.tzlxphzbs !== '3') {
          this.tzjg += '平和质'
        }
        // // console.log('----------', res)
      } else {
        this.$message.error(res.msg)
        this.tzjg = '无'
      }
    },
    // 一般情况
    async getYbqkData () {
      const url = '/api/wx/findWxtjbg'
      const params = {
        sfzh: this.userInfo.sfzh,
        tmcode: this.userInfo.tmcode,
        username: this.$route.query.username,
        openId: this.$route.query.openId
      }
      const { data: res } = await this.$http.post(url, params)
      if (res.code === 0) {
        this.ybqk = res.data
      } else {
        this.$message.error(res.msg)
      }
      // // console.log(res)
    },
    // 血常规
    async getXcgData () {
      const url = '/ykdxcgjcbgjgs/findxcg'
      const params = {
        jktjid: this.userInfo.tmcode
      }
      const { data: res } = await this.$http.post(url, params)
      this.xcgList = res.data
      res.data.forEach(ele => {
        if (!ele.jktjts) {
          this.xcgZc++
        } else {
          this.xcgYc++
        }
      })
      if (this.xcgYc === 0) {
        this.xcgValue = `共${res.data.length}项数据，均正常`
      } else {
        this.xcgValue = `共${res.data.length}项数据，${this.xcgYc}项异常`
      }
      // // console.log(res)
    },
    // 生化检查
    async getShjcData () {
      const url = '/ykdshjctjbbs/finds'
      const params = {
        jktjid: this.userInfo.tmcode
      }
      const { data: res } = await this.$http.post(url, params)
      this.shjcList = res.data
      res.data.forEach(ele => {
        if (!ele.jktjts) {
          this.shZc++
        } else {
          this.shYc++
        }
      })
      if (this.shYc === 0) {
        this.shValue = `共${res.data.length}项数据，均正常`
      } else {
        this.shValue = `共${res.data.length}项数据，${this.shYc}项异常`
      }
      // // console.log(res)
    },
    // 尿常规检查
    async getNcgData () {
      const url = '/ykdncgjcbgjgs/findncg'
      const params = {
        jktjid: this.userInfo.tmcode
      }
      const { data: res } = await this.$http.post(url, params)
      this.ncgList = res.data
      res.data.forEach(ele => {
        if (!ele.jktjts) {
          this.ncgZc++
        } else {
          this.ncgYc++
        }
      })
      if (this.ncgYc === 0) {
        this.ncgValue = `共${res.data.length}项数据，均正常`
      } else {
        this.ncgValue = `共${res.data.length}项数据，${this.ncgYc}项异常`
      }
      // // console.log(res)
    },
    // 心电图
    async getXdtData () {
      const url = '/ykdxdts/finds'
      const params = {
        jktjid: this.userInfo.tmcode
      }
      const { data: res } = await this.$http.post(url, params)
      this.xdt = res.data
      // // console.log(res)
    },
    // B超
    async getBcData () {
      const url = '/ykdcscsjcbabs/findjg'
      const params = {
        tmcode: this.userInfo.tmcode
      }
      const { data: res } = await this.$http.post(url, params)
      this.bc = res.data
      // // console.log(res)
    },
    showDetail (val) {
      this.activeIndex = val
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.userInfo = JSON.parse(this.$route.query.userInfo)
    this.getYbqkData()
    this.getTzjgData()
    this.getXcgData()
    this.getShjcData()
    this.getNcgData()
    this.getXdtData()
    this.getBcData()
  }
}
</script>
<style lang="less">
.van-row {
  padding: 8px 0;
  border-bottom: 1px solid #ccc;
  .van-col {
    text-align: center;
  }
}
</style>
<style lang='less' scoped>
//@import url(); 引入公共css类
.hcontainer {
  padding: 0;
  background-color: #fff;
}
.detail-top {
  display: flex;
  background-color: #fff;
  padding: 10px;
  box-sizing: border-box;
  .detail-left {
    width: 40%;
    text-align: center;
    .header {
      width: 100%;
      img {
        width: 50%;
        border-radius: 50%;
      }
    }
    .name {
      font-size: 15px;
    }
    .dept {
      font-size: 13px;
      color: #A1A1A1;
    }
    .time {
      font-size: 13px;
      color: #A1A1A1;
    }
  }
  .detail-right {
    width: 60%;
    .title {
      padding: 8px 0;
      font-size: 16px;
      font-weight: 600;
    }
    .info {
      padding: 8px 0;
      overflow:hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
.detail-bot {
  margin-top: 15px;
  width: 100%;
  position: relative;
}
.detail-close {
  position: absolute;
  font-size: 16px;
  top: 10px;
  right: 10px;
  z-index: 9;
}
.grayRow {
  background: #ccc;
}
</style>
