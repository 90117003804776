<template>
  <div class="popup">
    <div class="popup-content">
      <div class="popup-title">
        <span>监测明细</span>
      </div>
      <div class="popup-box">
        <div class="content">
          <div class="close" @click="close">
            <i class="el-icon-close"></i>
          </div>
          <div class="scroll">
            <div class="tip">
              <div class="line">
                <span class="span">序号：</span>
                <span>{{ info.id || '-' }}</span>
              </div>
              <div class="line">
                <span class="span">姓名：</span>
                <span>{{ info.name || '-' }}</span>
              </div>
              <div class="line">
                <span class="span">性别：</span>
                <span>{{ info.xb === '1' ? '男' : '女' }}</span>
              </div>
              <div class="line">
                <span class="span">年龄：</span>
                <span>{{ info.age || '-' }}</span>
              </div>
              <div class="line">
                <span class="span">身份证：</span>
                <span>{{ info.sfzh || '-' }}</span>
              </div>
              <div class="line">
                <span class="span">监测类型：</span>
                <span>{{ info.fwlxmc || '-' }}</span>
              </div>
              <div class="line">
                <span class="span">监测项目：</span>
                <span>{{ info.jcxm || '-' }}</span>
              </div>
              <div class="line">
                <span class="span">监测值：</span>
                <span>{{ info.jcsz || '-' }}</span>
              </div>
              <div class="line">
                <span class="span">参考值：</span>
                <span>{{ info.jcckz || '-' }}</span>
              </div>
              <div class="line">
                <span class="span">风险等级：</span>
                <span>{{ info.fxdj || '-' }}</span>
              </div>
              <div class="line">
                <span class="span">来源：</span>
                <span>{{ info.lytype || '-' }}</span>
              </div>
              <div class="line">
                <span class="span">设备类型：</span>
                <span>{{ info.sblx || '-' }}</span>
              </div>
              <div class="line">
                <span class="span">设备型号：</span>
                <span>{{ info.sbbh || '-' }}</span>
              </div>
              <div class="line">
                <span class="span">所在位置：</span>
                <span>{{ info.jtwzxx || '-' }}</span>
              </div>
              <div class="line">
                <span class="span">所属机构：</span>
                <span>{{ info.ssjgmc || '-' }}</span>
              </div>
            </div>
          </div>

          <!-- <div class="btn">
            <el-button size="small" @click="close">关闭</el-button>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      info: {
        id: '',
        name: '',
        xb: '',
        age: '',
        sfzh: '',
        fwlxmc: '',
        jcxm: '',
        jcsz: '',
        jcckz: '',
        fxdj: '',
        lytype: '',
        sblx: '',
        sbbh: '',
        jtwzxx: '',
        ssjgmc: ''
      }
    }
  },
  created () {
    this.getInfo()
  },
  methods: {
    async getInfo () {
      // https://app.apifox.com/project/2726972/apis/api-119834526
      try {
        const { data: res } = await this.$http.post('/zhyyapi/zhyyyjscsm/' + this.id)
        console.log('监测消息列表-根据id获取', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.info = res.data
      } catch (error) {
        console.log('监测消息列表-根据id获取请求失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="less">
.popup {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 88;
  background-color: rgba(0, 0, 0, .74);

  .popup-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    width: 859px;

    .popup-title {
      width: 147px;
      height: 50px;
      line-height: 60px;
      background-image: url('./img/dialogTitleBg.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      text-align: center;
      color: #fff;
      font-size: 20px;
    }

    .popup-box {
      width: 1000px;
      height: 560px;
      padding: 10px;
      background-color: rgba(3, 27, 44, .8);
      border-radius: 10px;
      border: 4px solid rgba(1, 49, 88, .5);
      box-sizing: border-box;

      .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        width: 100%;
        height: 100%;
        padding: 30px;
        border-radius: 10px;
        border: 4px solid rgba(1, 49, 88, .5);
        box-sizing: border-box;

        .close {
          display: flex;
          align-items: flex-end;
          position: absolute;
          top: 0;
          right: 0;
          width: 50px;
          height: 50px;
          background: linear-gradient(26.565deg, #2057a7 50%, transparent 0);
          transform: rotate(180deg);
          color: #fff;
          font-size: 24px;
          cursor: pointer;
        }

        .scroll {
          overflow-y: scroll;
          width: 100%;
          height: calc(100% - 40px);

          &::-webkit-scrollbar {
            width: 0;
          }
        }

        .tip {
          display: flex;
          text-align: center;
          flex-wrap: wrap;
          color: #01EEFD;
          width: 100%;
          padding: 0 20px;
          box-sizing: border-box;
          font-size: 16px;

          .span {
            margin-top: 6px;
            font-size: 16px;
          }

          .line {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 50%;
            margin-bottom: 20px;
            box-sizing: border-box;
            color: #01EEFD;
            font-size: 16px;

            .span {
              width: 130px;
              text-align: left;
            }

            .table {
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 500px;
              border: 4px solid #073F73;
              box-sizing: border-box;

              .head {
                display: flex;
                flex-direction: row;
                width: 100%;
                height: 44px;
                background-color: #024276;
                color: #01EEFD;
                font-size: 17px;

                .flex {
                  flex: 1;
                  height: 44px;
                  line-height: 44px;
                  text-align: center;
                }
              }

              .tr {
                display: flex;
                flex-direction: row;
                width: 100%;
                height: 44px;
                border-bottom: 1px solid #01EEFD;
                box-sizing: border-box;
                color: #01EEFD;
                font-size: 17px;

                .flex {
                  flex: 1;
                  height: 44px;
                  line-height: 44px;
                  border-right: 1px solid #01EEFD;
                  box-sizing: border-box;
                  text-align: center;
                }

                .flex1 {
                  border: none;
                }
              }
            }

            .margin {
              margin-right: 50px;
            }
          }

          .line1 {
            align-items: flex-start;
          }
        }

        .tip1 {
          border: none !important;
        }

        .btn {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 240px;
          margin: 0 auto;
        }

        .el-button {
          border-radius: 0;
          border: none;
          background-color: #33465C;
          color: #fff;
        }

        .el-button:nth-child(2) {
          background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
        }
      }
    }
  }
}
</style>
