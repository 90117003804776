<!-- 健康体检 -->
<template>
  <div class='hcontainer'>
    <template>
      <div class="">
        <van-search
          v-model="value"
          show-action
          placeholder="请输入搜索关键词"
          @search="onSearch"
        >
          <template #action>
            <div @click="onSearch">搜索</div>
          </template>
        </van-search>
      </div>
      <div class="info-item" v-for="(i,index) in dataList" :key="index" @click="goDetail(i)">
        <div class="info-item-top"><span>编号：{{i.tmcode}}</span><span>体检机构：{{i.tjjgmc}}{{i.tjjgcwhmc}}</span></div>
        <div class="info-item-center">
          <div>{{i.name}}</div>
          <div style="margin-left:-15%">18369656590</div>
          <div style="font-size:25px">></div>
        </div>
        <div class="info-item-bot">
          <div>{{i.xb==1?'男':'女'}}</div>
          <div style="margin-left:15%;letter-spacing:1px">{{hideIdno(i.sfzh)}}</div>
        </div>
      </div>
    </template>
    <!-- <template v-else><van-empty description="暂无数据" /></template> -->
    <van-dialog v-model="show" title="标题" show-cancel-button :before-close="confirmNo">
      <van-field v-model="number" label="身份证后六位" />
    </van-dialog>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';

export default {
// import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
  // 这里存放数据
    return {
      number: '',
      show: false,
      dataList: '',
      haveData: false,
      value: '',
      username: '',
      openId: '',
      tmcode: '',
      sfzh: '',
      cxtype: '',
      rowInfo: ''
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {

  },
  // 方法集合
  methods: {
    init () {
      this.username = this.getQueryString('username')
      this.openId = this.getQueryString('openId')
      this.tmcode = this.getQueryString('tmcode')
      this.cxtype = this.getQueryString('cxtype')
      this.sfzh = this.getQueryString('sfzh')
      this.onSearch()
    },
    hideIdno (num) {
      return num.substring(0, 12) + '▪▪▪▪▪▪'
    },
    confirmNo (action, done) {
      if (action === 'confirm') {
        // // console.log(this.number)
        // // console.log(this.rowInfo.sfzh.substring(12, 18))
        if (this.number + '' === this.rowInfo.sfzh.substring(12, 18)) {
          this.$message.success('输入正确')
          done()
          setTimeout(() => {
            this.$router.push({
              path: 'tjDetail',
              query: {
                userInfo: JSON.stringify(this.rowInfo),
                username: this.username,
                openId: this.openId,
                jktjid: this.rowInfo.jktjid,
                grjbxxid: this.rowInfo.grjbxxid
              }
            })
          }, 500)
        } else {
          this.$message.error('身份证号后六位输入不正确')
          done()
        }
      } else {
        done()
      }
    },
    async onSearch () {
      const url = '/api/wx/findWxtjbglist'
      const params = {
        username: this.username,
        openId: this.openId,
        cxtype: this.cxtype,
        tmcode: this.tmcode
      }
      const { data: res } = await this.$http.post(url, params)
      // // console.log(res)
      if (res.code === 0) {
        this.dataList = res.data
      } else {
        this.$message.error(res.msg)
      }
    },
    goDetail (i) {
      this.rowInfo = i
      this.show = true
    },
    getQueryString (name) {
      var search = window.location.search.substring(1)
      if (!search) {
        search = window.location.hash.split('?')[1]
      }
      if (search) {
        var obj = JSON.parse('{"' + decodeURIComponent(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
        return name ? obj[name] : obj
      }
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.init()
  },
  beforeCreate () {}, // 生命周期 - 创建之前
  beforeMount () {}, // 生命周期 - 挂载之前
  beforeUpdate () {}, // 生命周期 - 更新之前
  updated () {}, // 生命周期 - 更新之后
  beforeDestroy () {}, // 生命周期 - 销毁之前
  destroyed () {}, // 生命周期 - 销毁完成
  activated () {} // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.info-item {
  padding: 15px;
  box-sizing: border-box;
  background-color: #fff;
  margin-top: 15px;
  border-radius: 10px;
  &-center {
    height: 30px;
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &-top {
    color: #A1A1A1;
    display: flex;
    justify-content: space-between;
  }
  &-bot {
    color: #A1A1A1;
    height: 30px;
    display: flex;
    align-items: center;
  }
}
</style>
