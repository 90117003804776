import { render, staticRenderFns } from "./rwtx.vue?vue&type=template&id=4974ce99&scoped=true&"
import script from "./rwtx.vue?vue&type=script&lang=js&"
export * from "./rwtx.vue?vue&type=script&lang=js&"
import style0 from "./rwtx.vue?vue&type=style&index=0&id=4974ce99&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4974ce99",
  null
  
)

export default component.exports