<!-- pie -->
<template>
  <div class='com-container'>
    <div class="com-chart" ref="stock_rank"></div>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';

export default {
// import引入的组件需要注入到对象中才能使用
  components: {},
  props: {
    chartData: String
  },
  data () {
  // 这里存放数据
    return {
      allData: null, // 图表数据
      chartInstance: null // 图表实例
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {
    'chartData' (a) {
      this.allData = a
      this.updataChart()
    }
  },
  // 方法集合
  methods: {
    // 初始化图表
    initChart () {
      this.chartInstance = this.$echarts.init(this.$refs.stock_rank, 'chalk')
      const initOption = {}
      this.chartInstance.setOption(initOption)
    },
    // 获取数据
    async getData () {
      this.allData = 100
      this.updataChart()
    },
    // 更新图表数据
    updataChart () {
      const dataOption = {
        title: {
          text: this.allData,
          left: 'center',
          top: 'center',
          textStyle: {
            color: '#4FF6EA',
            fontSize: 12
          }
        },
        polar: {
          radius: ['45%', '60%'],
          center: ['50%', '50%']
        },
        // 极坐标角度轴
        angleAxis: {
          min: 0,
          max: 100,
          // startAngle: 0,
          // endAngle: -0,
          clockwise: true,
          show: false // 隐藏刻度线
        },
        // 极坐标径向轴
        radiusAxis: {
          type: 'category',
          // 隐藏极坐标轴线
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          }
        },
        series: [{
          // 进度条
          type: 'bar',
          name: '占比',
          coordinateSystem: 'polar',
          // 设置柱子背景灰色，需开启showBackground才能显示backgroundStyle
          showBackground: true,
          // 两端设置圆角
          roundCap: true,
          itemStyle: {
            color: {
              x: 0,
              y: 0,
              x1: 0,
              y1: 1,
              colorStops: [{
                offset: 0,
                color: '#00FFDE'
              }, {
                offset: 1,
                color: '#32C5FF'
              }]
            }
          },
          data: [this.allData]
        }]
      }
      this.chartInstance.setOption(dataOption)
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.initChart()
    this.getData()
  },
  beforeCreate () {}, // 生命周期 - 创建之前
  beforeMount () {}, // 生命周期 - 挂载之前
  beforeUpdate () {}, // 生命周期 - 更新之前
  updated () {}, // 生命周期 - 更新之后
  beforeDestroy () {}, // 生命周期 - 销毁之前
  destroyed () {
  }, // 生命周期 - 销毁完成
  activated () {} // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.com-container {
  background: none;
  .com-chart {
    height: 100%;
  }
}
</style>
