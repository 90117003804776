<!--  -->
<template>
  <div class='propDia'>
    <div class="top-tabs">
      <div class="top-tabs-item" :class="{select:topbtn==1}" @click="topbtn=1">基本信息</div>
      <div class="top-tabs-item" :class="{select:topbtn==2}" @click="topbtn=2">健康信息</div>
    </div>
    <el-descriptions>
      <el-descriptions-item label="姓名">{{datas.name}}</el-descriptions-item>
      <el-descriptions-item label="手机号"></el-descriptions-item>
      <el-descriptions-item label="身份证"></el-descriptions-item>
      <el-descriptions-item label="创建时间"></el-descriptions-item>
      <el-descriptions-item label="身高"></el-descriptions-item>
      <el-descriptions-item label="体重"></el-descriptions-item>
      <el-descriptions-item label="BMI"></el-descriptions-item>
      <el-descriptions-item label="出生日期"></el-descriptions-item>
      <el-descriptions-item label="职业"></el-descriptions-item>
      <el-descriptions-item label="现住址"></el-descriptions-item>
      <el-descriptions-item label="户籍地址"></el-descriptions-item>
      <el-descriptions-item label="介绍"></el-descriptions-item>
      <el-descriptions-item label="血糖测量次数"></el-descriptions-item>
      <el-descriptions-item label="绑定血糖仪"></el-descriptions-item>
      <el-descriptions-item label="最近测量血糖"></el-descriptions-item>
      <el-descriptions-item label="血压测量次数"></el-descriptions-item>
      <el-descriptions-item label="绑定血压计"></el-descriptions-item>
  </el-descriptions>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';

export default {
// import引入的组件需要注入到对象中才能使用
  components: {},
  props: ['datas'],
  data () {
  // 这里存放数据
    return {
      topbtn: 1
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {
    'topbtn' (v) {
      if (v === 2) {
        this.topbtn = 1
        this.$message.warning('暂未开放！')
      }
    }
  },
  // 方法集合
  methods: {

  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {

  },
  beforeCreate () {}, // 生命周期 - 创建之前
  beforeMount () {}, // 生命周期 - 挂载之前
  beforeUpdate () {}, // 生命周期 - 更新之前
  updated () {}, // 生命周期 - 更新之后
  beforeDestroy () {}, // 生命周期 - 销毁之前
  destroyed () {}, // 生命周期 - 销毁完成
  activated () {} // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang="less">
.propDia {
  .el-descriptions__body {
    background-color: rgba(0, 0, 0, 0);
    color: #ffffff;
    font-size: 16px !important;
    padding: 15px 30px;
  }
}
</style>
<style lang='less' scoped>
//@import url(); 引入公共css类
.top-tabs {
    display: flex;
    padding: 0 15px;
    margin-bottom: 15px;
    .top-tabs-item {
      width: 120px;
      text-align: center;
      background-image: url('../../../assets/img/spms/btn-default.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: center;
      padding: 3px 0;
      font-size: 14px;
      color: #81AFD6;
      margin: 0 15px;
      cursor: pointer;
    }
    .select {
      background-image: url('../../../assets/img/spms/btn-select.png');
      color: #27F6FF;
    }
  }
</style>
