<!-- 年度分析 -->
<template>
  <div class='com-container '>
    <div class="com-chart" ref="ref_rank"></div>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';

export default {
// import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
  // 这里存放数据
    return {
      chartInstance: null, // echarts实例
      allData: null, // 图表数据
      dataIndex: 0, // 区域缩放终点值
      timerId: null,
      timerId1: null
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    async initChart () {
      this.chartInstance = this.$echarts.init(this.$refs.ref_rank, 'chalk')
      const initOption = {
        grid: {
          containLabel: true,
          left: '2%',
          top: '10%',
          right: '2%',
          bottom: '2%'
        },
        tooltip: {
          show: true
        },
        xAxis: {
          type: 'value'
        },
        yAxis: {
          type: 'category'
        },
        series: [
          {
            name: '体检人数',
            type: 'bar'
          }
        ]
      }
      this.chartInstance.setOption(initOption)
      // 鼠标移入移出操作
      // this.chartInstance.on('mouseover', () => {
      //   clearInterval(this.timerId)
      // })
      // this.chartInstance.on('mouseout', () => {
      //   this.startInterval()
      // })
    },
    async getData () {
      // const { data: res } = await this.$http.get('rank')
      const params = {
        jgbh: this.$store.state.loginInfo.jgbh,
        jbmc: this.$store.state.loginInfo.jgmc,
        ssjgbhmc: this.$store.state.loginInfo.label,
        usercode: this.$store.state.loginInfo.usercode,
        username: this.$store.state.loginInfo.username,
        jgjb: this.$store.state.loginInfo.jgjb,
        ssjgbh: this.$store.state.loginInfo.value,
        sjjgbh: this.$store.state.loginInfo.sjjgbh
      }
      const { data: res } = await this.$http.post('api/jkct/app/zhdpgwfwxyxt', params)
      if (res.code !== 0) this.$message.error('res.msg')
      this.allData = res.data
      this.allData.map((item, index) => {
        if (item.name === '高危') {
          this.allData[index].itemStyle = {
            color: new this.$echarts.graphic.LinearGradient(0, 1, 1, 1, [
              { offset: 0, color: '#9A5022' },
              { offset: 1, color: '#F36202' }
            ])
          }
        } else if (item.name === '中危') {
          this.allData[index].itemStyle = {
            color: new this.$echarts.graphic.LinearGradient(0, 1, 1, 1, [
              { offset: 0, color: '#1867A7' },
              { offset: 1, color: '#008EFC' }
            ])
          }
        } else if (item.name === '低危') {
          this.allData[index].itemStyle = {
            color: new this.$echarts.graphic.LinearGradient(0, 1, 1, 1, [
              { offset: 0, color: '#3B8A81' },
              { offset: 1, color: '#44D5B5' }
            ])
          }
        }
      })
      this.updataChart()
    },
    updataChart () {
      const salvProMax = []
      for (let index = 0; index < this.allData.length; index++) {
        salvProMax.push(600)
      }
      const nameArr = this.allData.map(item => {
        return item.name
      })
      const valueArr = this.allData.map(item => {
        return { value: item.value, itemStyle: item.itemStyle }
      })
      const dataOption = {
        // legend: {
        //   data: ['高危', '中危', '低危']
        // },
        xAxis: {
          show: false,
          data: valueArr
        },
        yAxis: [{
          type: 'category',
          inverse: true,
          axisLabel: {
            show: false,
            textStyle: {
              fontSize: '10',
              color: '#ffffff'
            }
          },
          splitLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          data: nameArr
        }, {
          type: 'category',
          inverse: true,
          axisTick: 'none',
          axisLine: 'none',
          show: true,
          axisLabel: {
            interval: 0,
            shadowOffsetX: '-20px',
            shadowOffsetY: '-20px',
            margin: -15,
            color: ['#fff'],
            align: 'right',
            verticalAlign: 'bottom',
            lineHeight: 40,
            fontSize: 10,
            formatter: function (value, index) {
              return value
            }

          },
          data: valueArr
        }],
        series: [
          {
            label: {
              normal: {
                color: '#ffffff',
                show: true,
                position: [0, '-20px'],
                textStyle: {
                  fontSize: 10
                },
                formatter: function (a, b) {
                  return a.name
                }
              }
            },
            name: '值',
            type: 'bar',
            zlevel: 1,
            itemStyle: {
              normal: {
                barBorderRadius: 10,
                color: new this.$echarts.graphic.LinearGradient(0, 0, 1, 0, [{
                  offset: 0,
                  color: 'rgb(57,89,255,1)'
                }, {
                  offset: 1,
                  color: 'rgb(46,200,207,1)'
                }])
              }
            },
            barWidth: 10,
            data: valueArr
          },
          {
            name: '背景',
            type: 'bar',
            barWidth: 10,
            barGap: '-100%',
            data: salvProMax,
            itemStyle: {
              normal: {
                color: 'rgba(24,31,68,1)',
                barBorderRadius: 20
              }
            }
          }
        ]
      }
      this.chartInstance.setOption(dataOption)
    },
    startInterval () {
      if (this.timerId) {
        clearInterval(this.timerId)
      }
      this.timerId = setInterval(() => {
        this.getData()
      }, 1000 * 60 * 5)
      if (this.timerId1) {
        clearInterval(this.timerId1)
      }
      this.timerId1 = setInterval(() => {
        const obj = this.allData[0]
        this.allData.splice(0, 1)
        this.allData.push(obj)
        this.updataChart()
      }, 3000)
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.initChart()
    this.getData()
    // window.addEventListener('resize', this.screenAdapter)
    // this.screenAdapter()
    this.startInterval()
  },
  beforeCreate () {}, // 生命周期 - 创建之前
  beforeMount () {}, // 生命周期 - 挂载之前
  beforeUpdate () {}, // 生命周期 - 更新之前
  updated () {}, // 生命周期 - 更新之后
  beforeDestroy () {}, // 生命周期 - 销毁之前
  destroyed () {
    // window.removeEventListener('resize', this.screenAdapter)
    clearInterval(this.timerId)
    clearInterval(this.timerId1)
  }, // 生命周期 - 销毁完成
  activated () {} // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>

<style scoped lang="less">
/* // @import url(); 引入公共css类 */
.com-container {
  background: none;
  .com-chart {
    height: 100%;
  }
}
</style>
