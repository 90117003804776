<!-- 实时监控 -->
<template>
  <div class='ssjk'>
    <div class="header">实时监控</div>
    <div class="scrol-box">
      <div class="com-scroll"  @mouseover="onmouseover" @mouseout="onmouseout" ref="scroll" :style="{height:allData.length*190+'px'}">
        <div class="scroll-item"  v-for="(i,index) in allData" :key="index" @click="viewDialog(i)">
          <div><span>时间</span>{{i.time}}</div>
          <div><span>姓名</span>{{i.name || '暂无'}}<span style="text-align: center;width:60px"> | </span>{{i.xb}}</div>
          <div><span>所属机构</span>{{i.ssjgmc || '暂无'}}</div>
          <div v-if="i.lytype==='G'"><span>项目名称</span>高血压</div>
          <div v-if="i.lytype==='T'"><span>项目名称</span>高血糖</div>
          <div v-if="i.lytype==='X'"><span>项目名称</span>高血脂</div>
          <div v-else><span>项目名称</span>暂无</div>
          <div><span>监测结果</span>{{i.jcjg || '暂无'}}</div>
          <div><span>管理医生</span>{{i.glys || '暂无'}}</div>
        </div>
      </div>
    </div>
    <el-dialog
    top="5vh"
      :visible.sync="xttjVisible"
      width="1500px">
      <xttjgather :datas='datas'></xttjgather>
    </el-dialog>
    <el-dialog
    top="5vh"
      :visible.sync="xytjVisible"
      width="1500px">
      <xytjgather :datas='datas'></xytjgather>
    </el-dialog>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import xttjgather from './xttjDialog/gather.vue'
import xytjgather from './xytjDialog/gather.vue'
export default {
// import引入的组件需要注入到对象中才能使用
  components: { xttjgather, xytjgather },
  data () {
  // 这里存放数据
    return {
      xttjVisible: false,
      xytjVisible: false,
      allData: [],
      timer: null,
      datas: null
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    viewDialog (i) {
      if (i.lytype === 'T') {
        this.datas = i
        this.xttjVisible = true
      } else if (i.lytype === 'G') {
        this.datas = i
        this.xytjVisible = true
      } else {
        this.$message.warning('暂未开放！')
      }
    },
    async initData () {
      // // console.log('this.$store.state.loginInfo---------------', this.$store.state.loginInfo)
      const params = {
        jgbh: this.$store.state.loginInfo.jgbh,
        jbmc: this.$store.state.loginInfo.jgmc,
        ssjgbhmc: this.$store.state.loginInfo.label,
        usercode: this.$store.state.loginInfo.usercode,
        username: this.$store.state.loginInfo.username,
        jgjb: this.$store.state.loginInfo.jgjb,
        ssjgbh: this.$store.state.loginInfo.value,
        sjjgbh: this.$store.state.loginInfo.sjjgbh
      }
      // const params = this.$store.state.loginInfo
      // 暂时屏蔽
      // const { data: res } = await this.$http.post('api/jkct/app/zhdpyfrhssjc', params)
      // if (res.code !== 0) return this.$message.error(res.msg)
      // this.allData = res.data
    },
    onmouseover () {
      this.$refs.scroll.style.animationPlayState = 'paused'
    },
    onmouseout () {
      this.$refs.scroll.style.animationPlayState = 'running'
    },
    interval () {
      // // console.log()

      const styles = document.styleSheets
      const runkeyframes = `@keyframes rowup1 {
        0% {
            transform: translate3d(0, 0, 0);
        }
        100% {
            transform: translate3d(0, -${(this.allData.length - 7) * 190}px, 0);
            display: none;
        }
      }`
      styles[0].insertRule(runkeyframes)
      // Array.from(styles).map((item, index) => {
      //   item.insertRule(runkeyframes)
      // })
      this.$refs.scroll.style.animationName = 'rowup1'
      this.$refs.scroll.style.animationDuration = this.allData.length * 3 + 's'
      this.$refs.scroll.style.animationIterationCount = 'infinite'
      this.$refs.scroll.style.animationTimingFunction = 'linear'
      this.$refs.scroll.style.animationDelay = '0'
      if (this.timer) {
        clearInterval(this.timer)
      }
      this.timer = setInterval(() => {
        this.initData()
      }, 3 * 1000 * 60)
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  async mounted () {
    await this.initData()
    this.interval()
  },
  beforeCreate () {}, // 生命周期 - 创建之前
  beforeMount () {}, // 生命周期 - 挂载之前
  beforeUpdate () {}, // 生命周期 - 更新之前
  updated () {}, // 生命周期 - 更新之后
  beforeDestroy () {}, // 生命周期 - 销毁之前
  destroyed () {}, // 生命周期 - 销毁完成
  activated () {} // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style>
  .ssjk .el-dialog {
    background-image: url('../../assets/img/spms/bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border: 1px solid #27F6FF;
  }
</style>
<style lang='less' scoped>
//@import url(); 引入公共css类
.ssjk {
  height: 100%;
  width: 100%;
  background-image: url('../../assets/img/spms/ssjk-bg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  overflow: hidden;
  .header {
    text-align: center;
    color: #ffffff;
    font-size: 14px;
    font-weight: bold;
  }
  .scrol-box {
    height: 100%;
    overflow: hidden;
    .scroll-item {
      width: 90%;
      height: 190px;
      overflow: hidden;
      position: relative;
      margin-left: 5%;
      margin-bottom: 10px;
      background-image: url('../../assets/img/spms/ssjk-bg2.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: center;
      padding: 8px 14px;
      box-sizing: border-box;
      color: #ffffff;
      font-size: 14px;
      // @keyframes rowup {
      //   0% {
      //       -webkit-transform: translate3d(0, 0, 0);
      //       transform: translate3d(0, 0, 0);
      //   }
      //   100% {
      //       -webkit-transform: translate3d(0, -400px, 0);
      //       transform: translate3d(0, -400px, 0);
      //       display: none;
      //   }
      // }
      span {
        color: #15EAF3;
        display: inline-block;
        width: 100px;
      }
      div {
        padding: 5px 0;
      }
    }
  }
}
</style>
