import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant'
// 引入common公共样式
import './assets/css/common.less'
import 'vant/lib/index.css'
import './assets/font/iconfont.css'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import axios from 'axios'
import * as echarts from 'echarts'
import './utils/polyfill'
import Nprogress from 'nprogress'
import 'nprogress/nprogress.css'
import 'lib-flexible/flexible.js'
import AvueUeditor from 'avue-plugin-ueditor'
Vue.use(AvueUeditor)
// 配置请求基准路径
// axios.defaults.baseURL = 'http://gwtjcs.tjiancai.com/'
// axios.defaults.baseURL = 'https://api.gwtjpt.com/api'
// axios.defaults.baseURL = 'http://127.0.0.1:8086'
// axios.defaults.baseURL = 'http://gwtj.gwtjpt.com'
// axios.defaults.baseURL = 'http://zhtj.gwtjpt.com/api/'
// axios.defaults.baseURL = 'http://xstjnm.gwtjpt.com/api'
// axios.defaults.baseURL = 'http://zhfw.gwtjpt.com/'
axios.defaults.baseURL = 'http://tjsjsc.gwtjpt.com/api'
// axios.defaults.baseURL = 'http://zhcs.zkwl.cc:63060'
// 将axios挂载到vue原型对象上
// 调用this.$http
Vue.prototype.$http = axios
Vue.prototype.$uploadUrl = 'https://api.gwtjpt.com/api'
// Vue.prototype.$uploadUrl = 'https://192.168.1.20:8999/api'
// console.log(store.state.loginInfo.token)
axios.interceptors.request.use(
  config => {
    console.log('请求开始：', config)
    // if (config.url === '/ykdjmjkdnjls') {
    //   config.headers.Token = store.state.loginInfo.token
    // }
    Nprogress.start()
    return config
  },
  error => {
    console.log('-------异常--------')
    Nprogress.done()
    return Promise.reject(error)
  }
)
axios.interceptors.response.use(
  config => {
    console.log('请求结束：', config)
    Nprogress.done()
    return config
  },
  error => {
    console.log('-------异常--------')
    console.log(error)
    Nprogress.done()
    if (error.message && (error.message === '终止请求' || error.message === '请求被手动取消')) {} else {
      if (error.response && error.response.data) {
        store.commit('ChangeAppMessageInfo', {
          showMessage: true,
          messageInfo: error.response.data ? (error.response.data.msg || '请求异常') : ''
        })
      } else {
        store.commit('ChangeAppMessageInfo', {
          showMessage: true,
          messageInfo: '请求异常'
        })
      }
    }

    return Promise.reject(error.response)
  }
)
// 将全局echarts对象挂载到vue原型上
// 调用this.$echarts
// Vue.prototype.$echarts = window.echarts
Vue.prototype.$BMap = window.BMap
Vue.prototype.$echarts = echarts

Vue.config.productionTip = false
Vue.use(Vant)
Vue.use(ElementUI)
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
