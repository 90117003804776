<!-- 登录页 -->
<template>
  <div class='login'>
    <el-card class="my_card">
      <div slot="header">
        <div class="my_card_header">
          “三高共管六病同防”医防融合服务平台
        </div>
      </div>
      <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px">
        <el-form-item label="账号" prop="number">
          <el-input v-model="form.number"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input type="password" v-model="form.password"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('form')">登录</el-button>
          <!-- <el-button @click="resetForm('ruleForm')">重置</el-button> -->
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>
<script>
export default {
  components: {},
  data () {
    return {
      form: {
        number: '',
        password: ''
      },
      rules: {
        number: [
          { required: true, message: '请输入账号', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    submitForm (form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          this.$router.push({
            path: '/daping'
          })
        } else {
          return false
        }
      })
    },
    resetForm (form) {
      this.$refs[form].resetFields()
    }
  }
}
</script>
<style lang='less' scoped>
.login {
  width: 100%;
  height: 100%;
  position: relative;
  background-image: url('../assets/img/dp-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  .my_card {
    width: 30%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    &_header {
      text-align: center;
      font-weight: 600;
      font-size: 28px;
    }
  }
}
</style>
