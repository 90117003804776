import { render, staticRenderFns } from "./tjjd.vue?vue&type=template&id=3eea8bea&scoped=true&"
import script from "./tjjd.vue?vue&type=script&lang=js&"
export * from "./tjjd.vue?vue&type=script&lang=js&"
import style0 from "./tjjd.vue?vue&type=style&index=0&id=3eea8bea&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3eea8bea",
  null
  
)

export default component.exports