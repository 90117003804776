<template>
  <div class="screen-container" ref="container">
    <div class="close" @click="closes">
      <span class="el-icon-arrow-left"></span>
    </div>
    <!-- <div class="loginOut">
      <i style="font-size:22px" class="el-icon-switch-button" @click="loginOut"></i>
    </div>
    <div class="notice">
      <el-badge :value="bjNum" slot="reference">
        <i class="el-icon-message-solid" @click="openBjTable" style="color:#f56c6c;font-size:22px"></i>
      </el-badge>
    </div> -->
    <header class="screen-header">
      <div class="title-left-jgmc">
        {{ $store.state.loginInfo.jgmc }}
      </div>
      <span class="title">智慧公卫医防融合管理平台</span>
      <div class="title-right">
        <span class="datetime">{{ nowTime }}</span>
      </div>
    </header>
    <div class="screen-body">
      <section class="screen-left">
        <div class="left-top">
          <fwjl></fwjl>
        </div>
        <div class="left-bottom">
          <sjhz></sjhz>
        </div>
      </section>
      <section class="screen-middle">
        <div id="middle-top">
          <zl1></zl1>
        </div>
        <div id="middle-bottom">
          <zl2></zl2>
        </div>
      </section>
      <section class="screen-right">
        <div id="right-top">
          <div class="">

          </div>
          <tjndfx></tjndfx>
        </div>
        <div id="right-center">
          <tjycjgfx></tjycjgfx>
        </div>
        <div id="right-bottom">
          <jcrs></jcrs>
        </div>
      </section>
    </div>
    <dialogBj :dialogVisible="dialogVisible" dialogTitle="报警信息" :bjData="bjData" @close-dialog="dialogVisible = false">
    </dialogBj>
    <dialogBjTable :dialogVisible="dialogBjTableVisible" :bjTableData="bjTableData" dialogTitle="报警信息列表"
      @close-dialog="dialogBjTableVisible = false"></dialogBjTable>
  </div>
</template>

<script>
import tjndfx from '@/components/yiliao/tjndfx'
import tjycjgfx from '@/components/yiliao/tjycjgfx'
import sjhz from '@/components/yiliao/sjhz'
import jcrs from '@/components/yiliao/jcrs'
import fwjl from '@/components/yiliao/fwjl'
import zl1 from '@/components/yiliao/zl1'
import zl2 from '@/components/yiliao/zl2'
import dialogBj from '@/components/common/dialogBj'
import { mapGetters } from 'vuex'
import dialogBjTable from '@/components/common/dialogBjTable.vue'
export default {

  components: {
    tjndfx: tjndfx,
    tjycjgfx: tjycjgfx,
    sjhz: sjhz,
    jcrs: jcrs,
    fwjl: fwjl,
    zl1: zl1,
    zl2: zl2,
    dialogBj: dialogBj,
    dialogBjTable
  },
  created () {

  },
  destroyed () {

  },
  data () {
    return {
      noticeList: [],
      bjData: {},
      dialogVisible: false,
      dialogBjTableVisible: false,
      bjTableData: [],
      nowTime: '',
      showFull: false,
      socket: null,
      ws: {},
      bjNum: 0,
      showPopup: false,
      v: '',
      n: ''
    }
  },
  computed: {
    ...mapGetters([
      'getLoginStatus', 'getBjlist'
    ])
  },
  mounted () {
    setInterval(this.getTime, 1000)
    // this.initWebsocket()
    console.log(this.showPopup)
  },
  methods: {
    closes () {
      this.$router.go(-1)
    },
    openBjTable () {
      this.dialogBjTableVisible = true
    },
    loginOut () {
      this.$confirm('确定要退出登录吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // this.showLogin = true
        this.$store.dispatch('changeLogin', false)
        this.$store.dispatch('changeLogins', {})
        this.close()
        this.$router.go(-1)
        this.$message({
          type: 'success',
          message: '退出登录成功!'
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消退出登录'
        })
      })
    },
    login (v) {
      this.$store.commit('changeLoginStatus', true)
      this.showPopup = true
      this.v = v
    },
    select (index) {
      console.log('选择', index)
      this.getBjData()
      this.initWebsocket()
      // this.showPopup = false
      if (this.v && index === 1) {
        this.$router.push({
          path: '/run'
        })
      }
      if (index === 2) {
        this.$router.push({
          path: '/spms'
        })
      }
      if (index === 3) {
        this.$router.push({
          path: '/childhealthrun'
        })
      }
      // if (index === 2) {
      //   this.showLogin = false
      //   this.$store.dispatch('changeLogin', false)
      //   this.$router.push({
      //     path: '/zhyl'
      //   })
      // }
    },
    // 全屏
    full () {
      this.showFull = true
      if (this.$refs.container.requestFullscreen) {
        this.$refs.container.requestFullscreen()
      } else if (this.$refs.container.mozRequestFullScreen) {
        this.$refs.container.mozRequestFullScreen()
      } else if (this.$refs.container.webkitRequestFullscreen) {
        this.$refs.container.webkitRequestFullscreen()
      } else if (this.$refs.container.msRequestFullscreen) {
        this.$refs.container.msRequestFullscreen()
      }
    },
    // 退出全屏
    exitFullscreen () {
      this.showFull = false
      if (document.exitFullScreen) {
        document.exitFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen()
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      }
    },
    getTime () {
      var myDate = new Date()
      var myYear = myDate.getFullYear() // 获取完整的年份(4位,1970-????)
      var myMonth = myDate.getMonth() + 1 // 获取当前月份(0-11,0代表1月)
      var myToday = myDate.getDate() // 获取当前日(1-31)
      var myDay = myDate.getDay() // 获取当前星期X(0-6,0代表星期天)
      var myHour = myDate.getHours() // 获取当前小时数(0-23)
      var myMinute = myDate.getMinutes() // 获取当前分钟数(0-59)
      var mySecond = myDate.getSeconds() // 获取当前秒数(0-59)
      var week = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']

      this.nowTime = myYear + '-' + this.fillZero(myMonth) + '-' + this.fillZero(myToday) + '  ' + this.fillZero(myHour) + ':' +
        this.fillZero(myMinute) + ':' + this.fillZero(mySecond) + '  ' + week[myDay] + '  '
    },

    fillZero (str) {
      var realNum
      if (str < 10) {
        realNum = '0' + str
      } else {
        realNum = str
      }
      return realNum
    },
    openBjDialog (bjData) {
      this.bjData = bjData
      this.dialogVisible = true
    },
    initWebsocket () {
      // const host = document.location.host
      // 实例化socket，这里的实例化直接赋值给this.ws是为了后面可以在其它的函数中也能调用websocket方法，
      // 例如：this.ws.close(); 完成通信后关闭WebSocket连接
      console.log('this.$wsUrl', this.$wsUrl)
      console.log('this.$http', this.$http)
      const sjNum = Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000
      this.ws = new WebSocket(this.$wsUrl + this.$store.state.loginInfo.jgbh + sjNum)

      this.ws.onmessage = this.websocketOnmessage
      this.ws.onopen = this.websocketOnopen
      this.ws.onerror = this.websocketOnerror
      this.ws.onclose = this.websocketOnclose
    },
    websocketOnopen () {
      // 发送的参数
      const paramObj = {
        usercode: '3001',
        tjjgbh: '130430203000',
        tjjgcwhmcid: '',
        scsj: '2023-10-09 16:31:08',
        mkmc: 'zhdp',
        sfzh: '',
        name: '',
        lytype: '',
        msgtype: 'zhdp',
        type: 1
      }
      // 如果链接成功 发送参数
      if (this.ws.readyState === 1) {
        console.log('走进来发送消息了' + this.ws.readyState)
        this.websocketSend(paramObj)
      }
    },
    // 给服务端发送消息
    websocketSend (data) {
      // 'text'后端给的事件名 注意传的参数要stringify下
      this.ws.send(JSON.stringify(data))
    },
    // 接收服务器发送的消息
    websocketOnmessage (event) {
      console.log('接收到来自服务器的消息：')
      if (event.data && event.data !== '连接成功' && JSON.parse(event.data).msgtype === 'zhdpsyts') {
        // 执行弹窗操作
        console.log('数据展示', event.data)
        this.bjData = JSON.parse(event.data)
        if (this.dialogVisible) {
          console.log('执行添加数据')
          // this.$store.dispatch('changeBjLists', this.bjData)
        } else {
          console.log('执行弹窗操作')
          // this.$store.dispatch('changeBjLists', this.bjData)
          this.dialogVisible = true
          setTimeout(() => {
            this.dialogVisible = false
          }, 30 * 1000)
        }
      }
      // msgtype 判断下把，这个值为 zhdpsyts 你就取值弹出
    },
    // 链接错误
    websocketOnerror (error) {
      console.log('发生错误', error)
      // 链接失败不重连，在断开链接处理
    },
    // 关闭连接
    websocketOnclose (event) {
      console.log('关闭了之后ws连接状态：' + this.ws.readyState)
      setTimeout(() => {
        this.reconnectWebsocket()
      }, 1000 * 30)
    },
    // 重连
    reconnectWebsocket () {
      this.initWebsocket() // 重新链接
    },
    close () {
      this.ws.close()
    },
    async getBjData () {
      const params = {
        jgbh: this.$store.state.loginInfo.jgbh,
        msgtype: 'zhdpsyts'
      }
      const url = '/api/jkct/app/zhgwsyyj'
      const { data: res } = await this.$http.post(url, params)
      if (res.code !== 0) this.$message.error(res.msg)
      console.log(res)
      this.bjNum = res.data.length
    }

  },
  beforeDestroy () {
    // this.close()
    // this.$store.dispatch('clearBjLists')
  }
}
</script>
<style lang="less" scoped>
::-webkit-scrollbar {
  display: none;
}

// 全屏样式的定义
.loginBox {
  z-index: 9999;
  position: absolute;
  width: 40%;
  height: 500px;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  animation: toptobot .5s linear;
}

@keyframes toptobot {
  0% {
    top: 0%;
    width: 0%;
  }

  100% {
    top: 50%;
    width: 50%;
  }
}

.fullscreen {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  z-index: 100;
}

.screen-container {
  width: 100%;
  height: 100%;
  background-color: #081628;
  background-image: url('../../assets/img/dp-bg.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  color: #fff;
  box-sizing: border-box;
}

.close {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  height: 80px;
  padding-left: 30px;
  box-sizing: border-box;
  cursor: pointer;
  color: #fff;
  font-size: 30px;
  font-weight: 800;
}

.screen-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  font-size: 100px;
  position: relative;
  background-image: url('../../assets/img/header_dark.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;

  .title {
    flex: 1;
    height: 50px;
    line-height: 50px;
    text-align: center;
    font-size: 28px;
    color: #FFFFFF;
    letter-spacing: 10px;
    font-weight: bold;
  }

  .title-left-jgmc {
    flex: 1;
    height: 50px;
    line-height: 50px;
    padding-left: 60px;
    box-sizing: border-box;
    color: #BAE0FF;
    font-size: 16px;
  }

  .title-right {
    flex: 1;
    height: 50px;
    line-height: 50px;
    text-align: center;
    color: #BAE0FF;
    font-size: 16px;
  }

  .datetime {
    font-size: 16px;
    margin-left: 50px;
  }
}

.screen-body {
  width: 100%;
  height: calc(100% - 60px);
  display: flex;
  box-sizing: border-box;

  .screen-left {
    width: 27.6%;
    height: 100%;

    .left-top {
      width: 100%;
      height: calc(58% - 20px);
    }

    .left-bottom {
      width: 100%;
      height: 42%;
      margin-top: 20px;
    }
  }

  .screen-middle {
    height: 100%;
    width: 41.5%;
    margin-left: 1.6%;
    margin-right: 1.6%;

    #middle-top {
      width: 100%;
      height: 50%;
    }

    #middle-bottom {
      width: 100%;
      height: calc(50% - 20px);
      margin-top: 20px;
    }
  }

  .screen-right {
    height: 100%;
    width: 27.6%;

    #right-top {
      width: 100%;
      height: calc(26% - 20px);
    }

    #right-center {
      width: 100%;
      height: calc(26% - 20px);
      margin-top: 20px;
    }

    #right-bottom {
      width: 100%;
      height: 48%;
      margin-top: 20px;
    }
  }
}

.screen-size {
  position: absolute;
  top: 2%;
  right: 6rem;
  color: white;
  cursor: pointer;
  z-index: 999;

  div {
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 1;
  }
}

.loginOut {
  position: absolute;
  top: 1.6%;
  right: 2rem;
  z-index: 999;
  cursor: pointer;
}

.notice {
  position: absolute;
  top: 1.6%;
  right: 5rem;
  z-index: 9999;
  cursor: pointer;
}

.resize {
  position: absolute;
  right: 1.25rem;
  top: 1.25rem;
  cursor: pointer;
}
</style>
