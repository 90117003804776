<!-- 商家分布图表 -->
<template>
  <div class='charts-box'>
    <div class='charts-box' ref='map_ref'></div>
  </div>
</template>

<script>
// import axios from 'axios'
import { getDeptAndMap } from '@/utils/deptAndMap'
export default {
  data () {
    return {
      jgInfo: {},
      placeName: '',
      mapName: '',
      chartInstance: null,
      mapIndex: 0,
      itemData: null,
      mapData: null,
      timerId: null,
      timerId1: null
    }
  },
  created () {
  },
  mounted () {
    this.initChart()
    this.startInterval()
  },
  destroyed () {
  },
  methods: {
    async initChart () {
      if (this.$store.state.loginInfo.jgjb === 4 || this.$store.state.loginInfo.jgjb === 5) {
        this.placeName = getDeptAndMap()[this.$store.state.loginInfo.sjjgbh].name
        this.mapName = getDeptAndMap()[this.$store.state.loginInfo.sjjgbh].pinyin
      } else {
        this.placeName = getDeptAndMap()[this.$store.state.loginInfo.jgbh].name
        this.mapName = getDeptAndMap()[this.$store.state.loginInfo.jgbh].pinyin
      }
      this.chartInstance = this.$echarts.init(this.$refs.map_ref)
      this.chartInstance.on('click', 'series', (params) => {
        // // console.log(params)
        if (this.$store.state.loginInfo.jgbh === '130524202000') {
          this.$router.push({
            path: '/spms',
            query: {
              jgbh: this.$store.state.loginInfo.jgbh,
              name: this.$store.state.loginInfo.jgmc
            }
          })
        } else {
          this.$router.push({
            path: '/spms',
            query: {
              jgbh: params.data.datas[0].jgbh,
              name: params.data.datas[0].jgmc
            }
          })
        }
      })
      this.chartInstance.showLoading('default', {
        text: '统计中，请稍候...',
        maskColor: '#2957A2',
        textColor: '#fff',
        zlevel: 1
      })
      // const mapname = qx
      const marker1 = 'image://http://image.noreason.top/marker1.png'
      // const marker2 = 'image://http://image.noreason.top/marker2.png'
      // const marker3 = 'image://http://image.noreason.top/marker3.png'
      // const mapData = [
      // this.mapData = mapData
      // 获取中国地图的矢量数据
      // http://localhost:8999/static/map/china.json
      // 由于我们现在获取的地图矢量数据并不是位于KOA2的后台, 所以咱们不能使用this.$http
      const params = {
        jgbh: this.$store.state.loginInfo.jgbh,
        jbmc: this.$store.state.loginInfo.jgmc,
        ssjgbhmc: this.$store.state.loginInfo.label,
        usercode: this.$store.state.loginInfo.usercode,
        username: this.$store.state.loginInfo.username,
        jgjb: this.$store.state.loginInfo.jgjb,
        ssjgbh: this.$store.state.loginInfo.value,
        sjjgbh: this.$store.state.loginInfo.sjjgbh
      }
      const { data: ret1 } = await this.$http.post('api/jkct/app/zhdpgwzlsbxfsj', params)
      this.mapData = []
      ret1.data.map(item => {
        const itemData = {
          name: item.xzjdmc,
          value: item.dwzb.split(','),
          datas: item.sglbs,
          img: marker1
        }
        this.mapData.push(itemData)
      })
      const mapData = this.mapData
      const ret = require(`../../../public/static/map/${this.mapName}.json`)
      // const ret = await axios.get('http://image.noreason.top/qiuxian.json')
      // const ret = await axios.get(`http://image.noreason.top/${this.mapName}.json`)
      var domImg = document.createElement('img')
      domImg.style.height = '8px'
      domImg.style.width = '8px'
      domImg.src = 'http://image.noreason.top/mapItemBg.png'
      var domImgHover = document.createElement('img')
      domImgHover.height = '8px'
      domImgHover.width = '8px'
      domImgHover.src = 'http://image.noreason.top/mapItemBg.png'

      this.$echarts.registerMap('qiuxian', ret)
      const initOption = {
        title: {
          top: 20,
          left: 20,
          text: `${this.placeName}`,
          subtext: '',
          x: 'left',
          textStyle: {
            color: '#01EEFD',
            fontWeight: 100,
            fontSize: 30,
            textShadowColor: '#02ABD6',
            textShadowBlur: 5,
            textShadowOffsetX: 2,
            textShadowOffsetY: 2
          }
        },
        geo: {
          map: 'qiuxian',
          aspectScale: 0.75,
          layoutCenter: ['50%', '50%'],
          layoutSize: '100%',
          z: 0,
          silent: false,
          roam: false,
          zoom: 1,
          itemStyle: {
            normal: {
              areaColor: 'rgba(0, 15, 40, 0.5)',
              shadowColor: 'rgba(0, 0, 0, 1)',
              shadowBlur: 0,
              shadowOffsetX: 0,
              shadowOffsetY: 0,
              borderColor: 'rgba(0, 0, 0, 0.7)',
              borderWidth: 0.5
            },
            emphasis: {
              areaColor: '#2AB8FF',
              borderWidth: 1,
              color: 'red',
              label: {
                show: false
              }
            }
          }
        },
        series: [
          {
            type: 'map',
            aspectScale: 0.75,
            layoutCenter: ['50.5%', '50.5%'],
            layoutSize: '100%',
            silent: false,
            roam: false,
            label: {
              normal: {
                show: false,
                shadowOffsetX: 10,
                shadowOffsetY: 10,
                textStyle: {
                  color: '#fff'
                }
              },
              emphasis: {
                textStyle: {
                  color: '#fff'
                }
              }
            },
            itemStyle: {
              normal: {
                borderColor: '#2ab8ff',
                borderWidth: 1,
                areaColor: {
                  image: domImg,
                  repeat: 'repeat'
                },
                shadowColor: 'rgba(0, 0, 0, 0.5)',
                shadowBlur: 0,
                shadowOffsetX: 0,
                shadowOffsetY: 0
              },
              emphasis: {
                areaColor: {
                  image: domImg,
                  repeat: 'repeat'
                },
                borderColor: '#2ab8ff',
                borderWidth: 1,
                shadowColor: 'rgba(0, 255, 255, 0.7)',
                shadowBlur: 0,
                shadowOffsetX: 0,
                shadowOffsetY: 0,
                label: {
                  show: false
                }
              }
            },
            zoom: 1,
            map: 'qiuxian'
          },
          {
            // 图例阴影
            tooltip: {
              show: false
            },
            type: 'effectScatter',
            coordinateSystem: 'geo',
            rippleEffect: {
              scale: 10,
              brushType: 'stroke'
            },
            showEffectOn: 'render',
            itemStyle: {
              normal: {
                shadowColor: '#0ff',
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowOffsetY: 0,
                color: (params) => {
                  var colorList = [
                    new this.$echarts.graphic.LinearGradient(1, 0, 0, 0, [{
                      offset: 0,
                      color: '#64fbc5'
                    },
                    {
                      offset: 1,
                      color: '#018ace'
                    }
                    ]),
                    new this.$echarts.graphic.LinearGradient(1, 0, 0, 0, [{
                      offset: 0,
                      color: '#64fbc5'
                    },
                    {
                      offset: 1,
                      color: '#018ace'
                    }
                    ]),
                    new this.$echarts.graphic.LinearGradient(1, 0, 0, 0, [{
                      offset: 0,
                      color: '#168e6d'
                    },
                    {
                      offset: 1,
                      color: '#c78d7b'
                    }
                    ]),
                    new this.$echarts.graphic.LinearGradient(1, 0, 0, 0, [{
                      offset: 0,
                      color: '#61c0f1'
                    },
                    {
                      offset: 1,
                      color: '#6f2eb6'
                    }
                    ]),
                    new this.$echarts.graphic.LinearGradient(1, 0, 0, 0, [{
                      offset: 0,
                      color: '#168e6d'
                    },
                    {
                      offset: 1,
                      color: '#c78d7b'
                    }
                    ]),
                    new this.$echarts.graphic.LinearGradient(1, 0, 0, 0, [{
                      offset: 0,
                      color: '#61c0f1'
                    },
                    {
                      offset: 1,
                      color: '#6f2eb6'
                    }
                    ])
                  ]
                  return colorList[params.dataIndex]
                }
              }
            },
            label: {
              normal: {
                color: '#fff'
              }
            },
            symbol: 'circle',
            symbolSize: [10, 5],
            data: mapData,
            zlevel: 1
          },
          {
            type: 'scatter',
            coordinateSystem: 'geo',
            itemStyle: {
              color: '#f00'
            },
            symbol: function (index, params) {
              return params.data.img
            },
            symbolSize: [32, 41],
            symbolOffset: [0, -20],
            z: 9999,
            data: mapData
          },
          {
            type: 'scatter',
            coordinateSystem: 'geo',
            label: {
              normal: {
                show: false,
                formatter: function (params) {
                  // if (params.data.datas && params.data.datas.length) {
                  var name = params.name
                  var value = params.data.datas
                  let text = ''
                  // var text = `{fline|${name}}\n\n{tline|${value[0].name}:}\t{tline|${value[0].value}}\t\t{tline|${value[1].name}:}\t{tline|${value[1].value}}\t\t{tline|${value[2].name}:}\t{tline|${value[2].value}}\t\t
                  // \n{tline|${value[3].name}:}\t{tline|${value[3].value}}\t\t{tline|${value[4].name}:}\t{tline|${value[4].value}}\t\t{tline|${value[5].name}:}\t{tline|${value[5].value}}\t\t
                  // \n{tline|${value[6].name}:}\t{tline|${value[6].value}}\t\t{tline|${value[7].name}:}\t{tline|${value[7].value}}\t\t{tline|${value[8].name}:}\t{tline|${value[8].value}}\t\t`
                  var text1 = `{fline|${name}}\n\n`
                  if (value.length > 0) {
                    value.map((item, index) => {
                      let str = ''
                      str = `{tline|${item.name}:}\t{tline|${item.value}}\t\t`
                      if (index === 2) {
                        text = text + str + '\n\n'
                      } else if (index === 5) {
                        text = text + str + '\n\n'
                      } else {
                        text = text + str
                      }
                    })
                  } else {
                    text = '暂无数据'
                  }
                  text = text1 + text
                  return text
                },
                color: '#fff',
                rich: {
                  flines: {
                    padding: [2, 2],
                    color: '#fff',
                    textShadowColor: '#3A88FF',
                    textShadowBlur: '0',
                    textShadowOffsetX: 1,
                    textShadowOffsetY: 1,
                    fontSize: 14,
                    fontWeight: 600
                  },
                  fline: {
                    padding: [0, 5],
                    color: '#fff',
                    textShadowColor: '#030615',
                    textShadowBlur: '0',
                    textShadowOffsetX: 1,
                    textShadowOffsetY: 1,
                    fontSize: 14,
                    fontWeight: 600
                  },
                  tline: {
                    padding: [0, 0],
                    color: '#ABF8FF',
                    fontSize: 13
                  }
                }
              },
              emphasis: {
                show: true
              }
            }
          }
        ]
      }
      this.chartInstance.hideLoading()
      this.chartInstance.setOption(initOption)
      // 鼠标移入移出操作
      this.chartInstance.on('mouseover', () => {
        clearInterval(this.timerId)
      })
      this.chartInstance.on('mouseout', () => {
        this.startInterval()
      })
    },
    updataChart () {
      // 定时器来循环播放每个地区的数据
      var img2 = 'image://http://image.noreason.top/mapLabelBorderBg.png'
      const dataArr = []
      let symbolSizes = []
      const length = this.itemData.datas.length
      if (length > 6 && length < 10) {
        symbolSizes = [410, 140]
      } else if (length > 3 && length < 6) {
        symbolSizes = [410, 115]
      } else if (length > 0 && length < 3) {
        symbolSizes = [350, 90]
      } else {
        symbolSizes = [160, 70]
      }
      dataArr.push(this.itemData)
      const updataOption = {
        geo: {
          layoutCenter: ['50%', '60%'],
          center: this.itemData.value,
          zoom: 3
        },
        series: [
          {
            layoutCenter: ['50.5%', '60.5%'],
            type: 'map',
            center: this.itemData.value,
            zoom: 3
          },
          {
            type: 'effectScatter',
            coordinateSystem: 'geo',
            data: dataArr
          },
          {
            type: 'scatter',
            coordinateSystem: 'geo',
            data: dataArr
          },
          {
            type: 'scatter',
            coordinateSystem: 'geo',
            label: {
              normal: {
                show: true
              }
            },
            itemStyle: {
              color: '#00FFF6'
            },
            symbol: img2,
            // symbolSize: [440, 140],
            symbolSize: symbolSizes,
            symbolOffset: [0, -100],
            z: 999,
            data: dataArr
          }
        ]
      }
      this.chartInstance.setOption(updataOption)
    },
    startInterval () {
      if (this.timerId) {
        clearInterval(this.timerId)
      }
      this.timerId = setInterval(() => {
        if (this.mapIndex === this.mapData.length) {
          this.mapIndex = 0
          this.itemData = this.mapData[this.mapIndex]
        } else {
          this.itemData = this.mapData[this.mapIndex]
          this.mapIndex++
        }
        this.updataChart()
      }, 3000)
      if (this.timerId1) {
        clearInterval(this.timerId1)
      }
      this.timerId1 = setInterval(() => {
        this.initChart()
      }, 3 * 1000 * 60)
    }

  },
  computed: {

  },
  watch: {

  }
}
</script>

<style lang='less' scoped>
.charts-box {
  width: 100%;
  height: 100%;
}
</style>
