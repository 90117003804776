<!-- 年度分析 -->
<template>
  <div class='com-container com-container1 sjhz'>
    <div class="com-title">
      <div class="com-title-left">“三高六病”数据汇总</div>
    </div>
    <div class="sjhz-tips">
      <div class="sjhz-tips-item">
        <img src="../../assets/img/sjhz_icon1.png" style="height:80px" alt="">
        <div class="sjhz-tips-item-detail">
          <div class="top">总体检</div>
          <div class="bottom">{{ztj}}人</div>
        </div>
      </div>
      <div class="sjhz-tips-item">
        <img src="../../assets/img/sjhz_icon1.png" style="height:80px" alt="">
        <div class="sjhz-tips-item-detail">
          <div class="top">今日体检</div>
          <div class="bottom">{{jrtj}}人</div>
        </div>
      </div>
    </div>
    <div class="sjhz-tab">
      <div class="sjhz-tab-item" @click="getTabData('tjjd')">监测记录</div>
      <div class="sjhz-tab-item" @click="getTabData('ycsj')">实时异常分析</div>
    </div>
    <div class="com-chart com-chart1 com-chart2" ref="ref_rank"></div>
    <el-dialog
      top="25vh"
      :visible.sync="tjjdVisible"
      width="580px">
      <div class="bot-table">
        <div class="tbStyle">
          <table>
            <thead class="table-header">
              <td style="width:100px;">序号</td>
              <td style="width:300px;">机构名称</td>
              <td style="width:180px;">服务日期</td>
              <td style="width:180px;">人数</td>
            </thead>
            <div style="height:30px"></div>
            <tbody >
                <tr>
                  <td style="width:100px;">1</td>
                  <td style="width:300px;">xxx卫生室</td>
                  <td style="width:180px;">2022-06-21</td>
                  <td style="width:180px;">10</td>
                </tr>
                <tr>
                  <td style="width:100px;">2</td>
                  <td style="width:300px;">xxx卫生室</td>
                  <td style="width:180px;">2022-06-21</td>
                  <td style="width:180px;">20</td>
                </tr>
            </tbody>
          </table>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      top="25vh"
      :visible.sync="ycsjVisible"
      width="580px">
      <div class="bot-table">
        <div class="tbStyle">
          <table>
            <thead class="table-header">
              <td style="width:100px;">序号</td>
              <td style="width:300px;">机构名称</td>
              <td style="width:180px;">服务日期</td>
              <td style="width:180px;">人数</td>
            </thead>
            <div style="height:30px"></div>
            <tbody >
                <tr>
                  <td style="width:100px;">1</td>
                  <td style="width:300px;">xxx卫生室</td>
                  <td style="width:180px;">2022-06-21</td>
                  <td style="width:180px;">10</td>
                </tr>
                <tr>
                  <td style="width:100px;">2</td>
                  <td style="width:300px;">xxx卫生室</td>
                  <td style="width:180px;">2022-06-21</td>
                  <td style="width:180px;">20</td>
                </tr>
            </tbody>
          </table>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';

export default {
// import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
  // 这里存放数据
    return {
      tjjdVisible: false,
      ycsjVisible: false,
      titleFontSize: 12,
      chartInstance: null, // echarts实例
      allData: null, // 图表数据
      dataIndex: 0, // 区域缩放终点值
      timerId: null,
      timerId1: null,
      timerId2: null,
      ztj: 0,
      jrtj: 0,
      tabDataIndex: 1
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    async initChart () {
      this.chartInstance = this.$echarts.init(this.$refs.ref_rank, 'chalk')
      const initOption = {
        grid: {
          containLabel: true,
          left: '2%',
          top: '10%',
          right: '2%',
          bottom: '2%'
        },
        tooltip: {
          show: true
        },
        xAxis: {
          type: 'value'
        },
        yAxis: {
          type: 'category'
        },
        series: [
          {
            name: '体检人数',
            type: 'bar'
          }
        ]
      }
      this.chartInstance.setOption(initOption)
      // 鼠标移入移出操作
      this.chartInstance.on('mouseover', () => {
        clearInterval(this.timerId)
        clearInterval(this.timerId1)
      })
      this.chartInstance.on('mouseout', () => {
        this.startInterval()
      })
    },
    async getData () {
      // const { data: res } = await this.$http.get('rank')
      const params = {
        jgbh: this.$store.state.loginInfo.jgbh,
        jbmc: this.$store.state.loginInfo.jgmc,
        ssjgbhmc: this.$store.state.loginInfo.label,
        usercode: this.$store.state.loginInfo.usercode,
        username: this.$store.state.loginInfo.username,
        jgjb: this.$store.state.loginInfo.jgjb,
        ssjgbh: this.$store.state.loginInfo.value,
        sjjgbh: this.$store.state.loginInfo.sjjgbh
      }
      const { data: res } = await this.$http.post('api/jkct/app/zhdpgwfwsglb', params)
      if (res.code !== 0) this.$message.error('res.msg')
      this.allData = res.data
      this.updataChart()
      this.getTabData()
    },
    async getTabData (type) {
      if (type === 'tjjd') {
        this.tjjdVisible = true
      } else if (type === 'ycsj') {
        this.ycsjVisible = true
      }
    },
    async getTabData1 () {
      let type = 'tjjd'
      if (this.tabDataIndex === 1) {
        type = 'tjjd'
      } else {
        type = 'ycsj'
      }
      // // console.log('logonInfo', this.$store.state.loginInfo)
      const loginInfo = this.$store.state.loginInfo
      const params = {
        cxtype: type || 'tjjd', // tjjd  ycsj
        // lytype: this.lytype,
        username: loginInfo.username, // 类型：String  必有字段  备注：用户名
        ssjgbh: loginInfo.sjjgbh, // 类型：String  必有字段  备注：所属机构
        jgbh: loginInfo.jgbh, // 类型：String  必有字段  备注：机构编号
        beginsj: '2022-01-01', // 类型：String  必有字段  备注：开始时间
        endsj: '2022-06-21', // 类型：String  必有字段  备注：结束时间
        offset: 1, // 类型：Number  必有字段  备注：分页参数
        limit: 10, // 类型：Number  必有字段  备注：分页长度
        jgjb: loginInfo.jgjb,
        tjrqy: '2022'
      }
      const { data: res } = await this.$http.post('api/jkct/app/tjfindinfo', params)
      // // console.log(res)
      if (res.code === 0) {
        if (this.tabDataIndex === 1) {
          this.tabDataIndex++
        } else {
          this.tabDataIndex = 1
        }

        this.ztj = res?.data?.tjjdsstjlist?.sstjztjrs || 0
        this.jrtj = res?.data?.tjjdsstjlist?.sstjjrtj || 0
      }
    },
    updataChart () {
      const salvProMax = []
      for (let index = 0; index < this.allData.length; index++) {
        salvProMax.push(1000)
      }
      // 颜色渐变数组
      const colorArr = [
        ['#FF9A7C', '#FB7271'],
        ['#BE91F5', '#6A49F6'],
        ['#FF5E5E', '#E02020'],
        ['#62DFFF', '#15CDFF'],
        ['#1B7AF3', '#27A6FF'],
        ['#31F5AA', '#07A0C6'],
        ['#FF943D', '#FF943D'],
        ['#70FF29', '#52C41A'],
        ['#FFB422', '#FF5C39']
      ]
      const nameArr = this.allData.map(item => {
        return item.name
      })
      const valueArr = this.allData.map(item => {
        return item.value
      })
      const dataOption = {
        xAxis: {
          show: false,
          data: valueArr
        },
        yAxis: [{
          type: 'category',
          inverse: true,
          axisLabel: {
            show: true,
            textStyle: {
              fontSize: '12',
              color: '#01F0FF'
            }
          },
          splitLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          data: nameArr
        }, {
          type: 'category',
          inverse: true,
          axisTick: 'none',
          axisLine: 'none',
          show: true,
          axisLabel: {
            textStyle: {
              color: '#01F0FF',
              fontSize: '12'
            }
          },
          data: valueArr
        }],
        series: [
          {
            // name: '值',
            type: 'bar',
            zlevel: 1,
            itemStyle: {
              normal: {
                barBorderRadius: 30,
                color: msg => {
                  return new this.$echarts.graphic.LinearGradient(0, 0, 1, 0, [{
                    offset: 0,
                    color: colorArr[msg.dataIndex][1]
                  }, {
                    offset: 1,
                    color: colorArr[msg.dataIndex][0]
                  }])
                }
              }
            },
            barWidth: this.titleFontSize * 0.5,
            data: valueArr
          },
          {
            // name: '背景',
            type: 'bar',
            barWidth: this.titleFontSize * 0.5,
            barGap: '-100%',
            data: salvProMax,
            itemStyle: {
              normal: {
                color: 'rgba(24,31,68,1)',
                barBorderRadius: 30
              }
            }
          }
        ]
      }
      this.chartInstance.setOption(dataOption)
    },
    screenAdapter () {
      const titleFontSize = this.$refs.ref_rank.offsetWidth / 100 * 3.6
      this.titleFontSize = titleFontSize
      const adapterOption = {
        title: {
          textStyle: {
            fontSize: titleFontSize
          }
        },
        series: [
          {
            type: 'bar',
            barWidth: titleFontSize * 0.5,
            itemStyle: {
              barBorderRadius: [titleFontSize * 0.25, titleFontSize * 0.25, titleFontSize * 0.25, titleFontSize * 0.25]
            }
          }, {
            type: 'bar',
            barWidth: titleFontSize * 0.5,
            itemStyle: {
              barBorderRadius: [titleFontSize * 0.25, titleFontSize * 0.25, titleFontSize * 0.25, titleFontSize * 0.25]
            }
          }
        ]
      }
      this.chartInstance.setOption(adapterOption)
      this.chartInstance.resize()
    },
    startInterval () {
      if (this.timerId) {
        clearInterval(this.timerId)
      }
      this.timerId = setInterval(() => {
        this.getData()
        if (this.dataIndex === 4) {
          this.dataIndex = 0
        } else {
          this.dataIndex++
        }
        this.updataChart()
        // this.screenAdapter()
      }, 1000 * 60 * 5)
      if (this.timerId1) {
        clearInterval(this.timerId1)
      }
      this.timerId1 = setInterval(() => {
        const obj = this.allData[0]
        this.allData.splice(0, 1)
        this.allData.push(obj)
        this.updataChart()
      }, 3000)
      if (this.timerId2) {
        clearInterval(this.timerId2)
      }
      this.timerId2 = setInterval(() => {
        this.getTabData1()
      }, 3000)
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.initChart()
    this.getData()
    window.addEventListener('resize', this.screenAdapter)
    this.screenAdapter()
    this.startInterval()
  },
  beforeCreate () {}, // 生命周期 - 创建之前
  beforeMount () {}, // 生命周期 - 挂载之前
  beforeUpdate () {}, // 生命周期 - 更新之前
  updated () {}, // 生命周期 - 更新之后
  beforeDestroy () {}, // 生命周期 - 销毁之前
  destroyed () {
    window.removeEventListener('resize', this.screenAdapter)
    console.log('1111111111111')
    clearInterval(this.timerId)
    clearInterval(this.timerId1)
    clearInterval(this.timerId2)
  }, // 生命周期 - 销毁完成
  activated () {} // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang="less">
  .sjhz .el-dialog {
    background-image: url('../../assets/img/spms/bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border: 1px solid #27F6FF;
  }
</style>
<style scoped lang="less">
/* // @import url(); 引入公共css类 */
.com-chart2 {
   height: calc(100% - 175px) !important;
}
  .sjhz-tips {
    height: 60px;
    width: 80%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    &-item {
      display: flex;
      align-items: center;
      &-detail {
        font-size: 14px;
        color: #bdc2c8;
        .bottom {
          font-size: 20px;
          color: #00f5fb;
          font-weight: 600;
        }
      }
    }
  }
  .sjhz-tab {
    margin-top: 20px;
    width: 80%;
    height: 40px;
    display: flex;
    justify-content: space-around;
    &-item {
      width: 140px;
      height: 40px;
      border-radius: 4px;
      text-align: center;
      line-height: 40px;
      background-color: #008089;
      font-size: 16px;
      font-weight: 600;
    }
  }
  .bot-table {
    padding: 0 15px;
    font-size: 14px;
    .tag {
      display: flex;
      justify-content: center;
      .tag-item {
        margin: 0 5px;
        border: 1px solid #FCD042;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        color: #FCD042;
        background-color: rgba(252, 208, 66, 0.2);
      }
      .tang {
        border: 1px solid #FF6652;
        background: rgba(255, 102, 82, 0.2);
        color: #FF6652;
      }
    }
    .suifang {
      border: 1px solid #27F6FF;
      color: #27F6FF;
      cursor: pointer;
    }
    .color1 {
      background-color:  rgba(7, 46, 68, 0.8);
    }
    .color2 {
      background-color:  rgba(10, 74, 110, 0.8);;
    }
    .tbStyle {
      display: block;
      width: 500px;
      height: 300px;
      overflow-x: hidden;
      overflow-y: scroll;
      scrollbar-width: none; /* firefox */
      -ms-overflow-style: none; /* IE 10+ */
    }
    .tbStyle::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
    table {
      border-collapse: collapse;
      width: 500px;
      text-align: center;
        td {
          padding: 5px 0;
        }
      .table-header {
        color: #27F6FF;
        background-color: #072E44;
        border-top: 1px solid #27F6FF;
        border-bottom: 1px solid #27F6FF;
        margin-bottom: 10px;
        position: absolute;
        width: 500px;
      }
      tbody {
        td {
          color: #ffffff;
        }
      }
    }
  }
</style>
