<template>
  <div class="write">
    <div class="title">
      <span>入住登记</span>
    </div>
    <div class="content">
      <div @click="closePopup" class="close">
        <span class="el-icon-close"></span>
      </div>
      <div class="box">
        <el-table :data="tableData" height="356" :header-cell-style="{ background: '#024276' }">
          <el-table-column prop="name" label="床号">
          </el-table-column>
          <el-table-column prop="date" label="姓名">
          </el-table-column>
          <el-table-column prop="type" label="年龄">
          </el-table-column>
          <el-table-column prop="type1" label="身份证号">
          </el-table-column>
          <el-table-column prop="type1" label="家庭住址">
          </el-table-column>
          <el-table-column prop="type2" label="所属机构名称">
          </el-table-column>
          <el-table-column prop="type" label="服务人员">
          </el-table-column>
          <el-table-column prop="type" label="服务项目">
          </el-table-column>
          <el-table-column prop="type" label="服务机构名称">
          </el-table-column>
          <el-table-column prop="type" label="服务评价">
          </el-table-column>
          <el-table-column label="服务轨迹">
            <template>
              <!-- slot-scope="scope" -->
              <div class="flex">
                <span>详情</span>
                <span>切换分组</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="type" label="服务时间">
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      options: [{
        value: '选项1',
        label: '黄金糕'
      }],
      value: '',
      radio: '1',
      tableData: []
    }
  },
  methods: {
    closePopup () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.write {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 888;
  width: 100%;
  height: 100%;
  padding: 0 .5rem;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, .9);

  .title {
    width: 3.3375rem;
    height: .6625rem;
    padding-top: .0625rem;
    box-sizing: border-box;
    line-height: .6625rem;
    text-align: center;
    background-image: url('../img/ssjk/title.png');
    background-size: 100% 100%;
    color: #fff;
    font-size: .25rem;
    font-weight: 600;
  }

  .content {
    position: relative;
    width: 100%;
    height: 7.5rem;
    margin-top: .125rem;
    padding: .525rem 1.2125rem;
    background-image: url('../img/ssjk/background4.png');
    background-size: 100% 100%;
    box-sizing: border-box;

    .close {
      position: absolute;
      top: .275rem;
      right: .6rem;
      color: #fff;
      font-size: .3rem;
      cursor: pointer;
    }

    .box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
    }

    .item {
      display: flex;
      align-items: center;
      width: 23%;
      margin-top: .125rem;
      color: #01EEFD;
      font-size: .2rem;

      .span {
        width: .9rem;
        text-align-last: justify;
        margin-right: .125rem;
        white-space: nowrap;
      }

      .span1 {
        width: 1.35rem;
      }

      .el-input,
      .el-select {
        width: 1.875rem;
        height: .475rem;
      }
    }

    .item1 {
      width: 50%;
    }

    .btns {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 3rem;
      height: .575rem;
      margin: 1.25rem auto 0;

      .el-button {
        height: .575rem;
        line-height: .575rem;
        padding: 0 .125rem;
        text-align: center;
        color: #fff;
        font-size: .175rem;
        background-color: #33465C;
        border-radius: .1rem;
        border: none;
      }

      .button {
        background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
      }
    }
  }

  ::v-deep .el-table__body-wrapper {
    &::-webkit-scrollbar {
      width: 0rem;
    }
  }

  ::v-deep .el-table th.gutter {
    display: none;
    width: 0;
  }

  ::v-deep .el-table colgroup col[name='gutter'] {
    display: none;
    width: 0;
  }

  ::v-deep .el-table__body {
    width: 100% !important;
  }

  .el-table {
    background-color: #092B50;
    border: .05rem #073F73 solid;
  }

  // 表头文字颜色
  /deep/.el-table__header-wrapper {
    thead {
      th {
        div {
          color: #01EEFD;
          font-size: .2125rem;
          font-weight: 400;
        }
      }
    }
  }

  //表格内容颜色
  /deep/.el-table__body tbody tr {
    color: #fff;
    background-color: #092B50;
  }

  /deep/.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
    background-color: #092B50;
  }

  /deep/.el-table tbody tr:hover>td {
    background-color: transparent !important
  }

  // 改变边框颜色
  .el-table--border,
  .el-table--group {
    border: .025rem solid #ddd !important;
  }

  /**
  改变表格内竖线颜色
   */
  .el-table--border td,
  .el-table--border th,
  .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
    border-right: .0125rem solid #ddd !important;
  }

  /deep/.el-table td {
    border-bottom: .0125rem solid #01EEFD;
  }

  /deep/.el-table thead tr th {
    border-color: transparent;
  }

  /deep/ .el-table--border::after,
  /deep/ .el-table--group::after,
  /deep/ .el-table::before {
    background-color: #073F73;
  }

  /deep/.el-table--border {
    border: .0125rem solid #073F73 !important;
  }

  /deep/.el-table .cell {
    text-align: center;
    color: #01EEFD;
  }

  /deep/.el-checkbox__inner {
    background-color: transparent;
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: .5rem;

    ::v-deep.el-pagination__total,
    ::v-deep.el-pagination__jump {
      color: #01EEFD;

      .el-input__inner {
        color: #fff;
        background-color: transparent;
      }
    }

    ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: #01EEFD;
    }
  }

  .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    color: #0e75d4;
    font-size: .1875rem;

    span {
      cursor: pointer;
    }
  }
}
</style>
