<!--  -->
<template>
  <div class='com-container'>
    <div class="com-chart" ref="stock_rank"></div>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';

export default {
// import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
  // 这里存放数据
    return {
      allData: [
        { name: '高危', value: '100', itemStyle: { color: '#FA6400' } },
        { name: '中危', value: '250', itsmStyle: { color: '#0091FF' } },
        { name: '低危', value: '150', itsmStyle: { color: '#44D7B6' } }
      ], // 图表数据
      chartIndex: 0,
      chartInstance: null, // 图表实例
      currentStart: 0,
      timerId: null,
      timerId1: null
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    // 初始化图表
    initChart () {
      this.chartInstance = this.$echarts.init(this.$refs.stock_rank, 'chalk')
      const initOption = {
        // title: {
        //   text: '▎库存和销量分析图',
        //   top: 20,
        //   left: 20
        // }
      }
      this.chartInstance.setOption(initOption)
      // 鼠标移入移出操作
      this.chartInstance.on('mouseover', () => {
        clearInterval(this.timerId)
      })
      this.chartInstance.on('mouseout', () => {
        this.startInterval()
      })
    },
    // 获取数据
    async getData () {
      // this.allData =
      const params = {
        jgbh: this.$store.state.loginInfo.jgbh,
        jbmc: this.$store.state.loginInfo.jgmc,
        ssjgbhmc: this.$store.state.loginInfo.label,
        usercode: this.$store.state.loginInfo.usercode,
        username: this.$store.state.loginInfo.username,
        jgjb: this.$store.state.loginInfo.jgjb,
        ssjgbh: this.$store.state.loginInfo.value,
        sjjgbh: this.$store.state.loginInfo.sjjgbh
      }
      const { data: res } = await this.$http.post('api/jkct/app/zhdpgwfwxyxt', params)
      if (res.code !== 0) this.$message.error('res.msg')
      this.allData = res.data

      this.updataChart()
    },
    // 更新图表数据
    updataChart () {
      this.allData.map((item, index) => {
        if (item.name === '高危') {
          this.allData[index].itemStyle = { color: '#FA6400' }
        } else if (item.name === '中危') {
          this.allData[index].itemStyle = { color: '#0091FF' }
        } else if (item.name === '低危') {
          this.allData[index].itemStyle = { color: '#44D7B6' }
        }
      })
      const dataOption = {
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        legend: {
          data: ['高危', '中危', '低危'],
          bottom: 20,
          itemWidth: 20,
          itemHeight: 6
        },
        series: [
          {
            label: {
              normal: {
                color: '#ffffff',
                show: true,
                textStyle: {
                  fontSize: 8
                },
                formatter: function (a, b) {
                  return a.name + '\n\n' + a.value
                }
              }
            },
            radius: ['15%', '35%'],
            top: -40,
            type: 'pie',
            data: this.allData
          }
        ]
      }
      this.chartInstance.setOption(dataOption)
    },
    // 适配分辨率
    screenAdapter () {
      const titleFontSize = this.$refs.stock_rank.offsetWidth / 100 * 3.6
      const adapterOption = {
        legend: {
          itemWidth: titleFontSize * 2
        }
      }
      this.chartInstance.setOption(adapterOption)
      this.chartInstance.resize()
    },
    startInterval () {
      if (this.timerId) {
        clearInterval(this.timerId)
      }
      this.timerId = setInterval(() => {
        this.getData()
        // this.screenAdapter()
      }, 1000 * 60 * 5)
      if (this.timerId1) {
        clearInterval(this.timerId1)
      }
      this.timerId1 = setInterval(() => {
        const obj = this.allData[0]
        this.allData.splice(0, 1)
        this.allData.push(obj)
        this.updataChart()
      }, 3000)
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.initChart()
    this.getData()
    window.addEventListener('resize', this.screenAdapter)
    this.screenAdapter()
    this.startInterval()
  },
  beforeCreate () {}, // 生命周期 - 创建之前
  beforeMount () {}, // 生命周期 - 挂载之前
  beforeUpdate () {}, // 生命周期 - 更新之前
  updated () {}, // 生命周期 - 更新之后
  beforeDestroy () {}, // 生命周期 - 销毁之前
  destroyed () {
    window.removeEventListener('resize', this.screenAdapter)
    clearInterval(this.timerId)
    clearInterval(this.timerId1)
  }, // 生命周期 - 销毁完成
  activated () {} // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.com-container {
  background: none;
  .com-chart {
    height: 100%;
  }
}
</style>
