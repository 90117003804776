
const deptMap = {
  131182000000: {
    name: '深州市',
    pinyin: 'shenzhoushi'
  },
  131182202000: {
    name: '深州市',
    pinyin: 'shenzhoushi'
  },
  130430203000: {
    name: '邱县',
    pinyin: 'qiuxian'
  },
  130524202000: {
    name: '柏乡县',
    pinyin: 'baixiangxian'
  }
}
export function getDeptAndMap () {
  return deptMap
}
