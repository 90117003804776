<!-- 年度分析 -->
<template>
  <div class='com-container'>
    <div class="com-chart com-chart1"  ref="ref_rank"></div>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';

export default {
  props: ['lineData'],
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
  // 这里存放数据
    return {
      data1: [],
      chartInstance: null, // echarts实例
      allData: null, // 图表数据
      allData2: null,
      startValue: 0, // 区域缩放起始值
      endValue: 4, // 区域缩放终点值
      timerId: null
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {
    'lineData' (v) {
      this.data1.push(v.data1, v.data2, v.data3)
      this.initChart()
    }
  },
  // 方法集合
  methods: {
    async initChart () {
      // 数据
      var XName = ['周一', '周二', '周三', '周四', '周五', '周六', '周日']
      var Line = ['病情1', '病情2', '病情3']
      var color = ['#15EAF3', '#2696FE', '#FCD042']

      // 数据处理
      var datas = []
      Line.map((item, index) => {
        datas.push(
          {
            name: item,
            type: 'line',
            yAxisIndex: 1,
            data: this.data1[index],
            itemStyle: {
              normal: {
                borderWidth: 5,
                color: color[index]
              }
            }
          }
        )
      })
      this.chartInstance = this.$echarts.init(this.$refs.ref_rank)
      const initOption = {
        grid: {
          left: '8%',
          top: '5%',
          bottom: '10%',
          right: '5%'
        },
        legend: {
          icon: 'circle',
          data: Line,
          itemWidth: 18,
          itemHeight: 12,
          textStyle: {
            color: '#ffffff',
            fontSize: 14
          }
        },
        yAxis: [
          {
            type: 'value',
            position: 'right',
            splitLine: {
              show: false
            },
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false
            }
          },
          {
            type: 'value',
            position: 'left',
            nameTextStyle: {
              color: '#000000'
            },
            splitLine: {
              lineStyle: {
                type: 'dashed',
                color: 'rgba(135,140,147,0.8)'
              }
            },
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              formatter: '{value}',
              color: '#fff',
              fontSize: 14
            }
          }
        ],
        xAxis: [
          {
            type: 'category',
            axisTick: {
              show: false
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: '#6A989E'
              }
            },
            axisLabel: {
              inside: false,
              textStyle: {
                color: '#fff', // x轴颜色
                fontWeight: 'normal',
                fontSize: '14',
                lineHeight: 22
              }

            },
            data: XName
          }
        ],
        series: datas
      }
      this.chartInstance.setOption(initOption)
      // 鼠标移入移出操作
      this.chartInstance.on('mouseover', () => {
        clearInterval(this.timerId)
      })
      this.chartInstance.on('mouseout', () => {
        this.startInterval()
      })
    },
    screenAdapter () {
      this.chartInstance.resize()
    },
    startInterval () {
      // if (this.timerId) {
      //   clearInterval(this.timerId)
      // }
      // this.timerId = setInterval(() => {
      //   this.startValue++
      //   this.endValue++
      //   if (this.endValue > this.allData.length - 1) {
      //     this.startValue = 0
      //     this.endValue = 4
      //   }
      //   this.updataChart()
      // }, 3000)
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.initChart()
    // window.addEventListener('resize', this.screenAdapter)
    // this.screenAdapter()
    // this.startInterval()
  },
  beforeCreate () {}, // 生命周期 - 创建之前
  beforeMount () {}, // 生命周期 - 挂载之前
  beforeUpdate () {}, // 生命周期 - 更新之前
  updated () {}, // 生命周期 - 更新之后
  beforeDestroy () {}, // 生命周期 - 销毁之前
  destroyed () {
    window.removeEventListener('resize', this.screenAdapter)
    clearInterval(this.timerId)
  }, // 生命周期 - 销毁完成
  activated () {} // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>

<style scoped lang="less">
/* // @import url(); 引入公共css类 */

</style>
