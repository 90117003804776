<!-- 年度分析 -->
<template>
  <div class='com-container com-container1'>
    <div class="com-title">
      <div class="com-title-left">体检年度分析</div>
      <div class="com-title-right">同比变动</div>
    </div>
    <div class="com-chart com-chart1" ref="ref_rank"></div>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';

export default {
// import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
  // 这里存放数据
    return {
      chartInstance: null, // echarts实例
      allData: [], // 图表数据
      allData2: [],
      startValue: 0, // 区域缩放起始值
      endValue: 4, // 区域缩放终点值
      timerId: null,
      nameArrs: []
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    async initChart () {
      this.chartInstance = this.$echarts.init(this.$refs.ref_rank, 'chalk')
      const initOption = {
        grid: {
          containLabel: true,
          left: '2%',
          top: '10%',
          right: '2%',
          bottom: '2%'
        },
        tooltip: {
          show: true
        },
        xAxis: {
          type: 'category'
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '体检人数',
            type: 'bar'
          },
          {
            name: '任务数',
            type: 'bar'
          }
        ]
      }
      this.chartInstance.setOption(initOption)
      // 鼠标移入移出操作
      this.chartInstance.on('mouseover', () => {
        clearInterval(this.timerId)
      })
      this.chartInstance.on('mouseout', () => {
        this.startInterval()
      })
    },
    async getData () {
      const params = {
        jgbh: this.$store.state.loginInfo.jgbh,
        jbmc: this.$store.state.loginInfo.jgmc,
        ssjgbhmc: this.$store.state.loginInfo.label,
        usercode: this.$store.state.loginInfo.usercode,
        username: this.$store.state.loginInfo.username,
        jgjb: this.$store.state.loginInfo.jgjb,
        ssjgbh: this.$store.state.loginInfo.value,
        sjjgbh: this.$store.state.loginInfo.sjjgbh
      }
      const { data: res } = await this.$http.post('api/jkct/findhometjall', params)
      if (res.code !== 0) return this.$message.error(res.msg)
      const dataList = res.data.ykdjgxxtjList
      dataList.map(item => {
        this.allData.push({
          name: '体检人数',
          value: item.tjrss
        })
        this.allData2.push({
          name: '体检任务数',
          value: item.tjrwss
        })
        this.nameArrs.push(item.label)
      })
      this.updataChart()
    },
    updataChart () {
      // 颜色渐变数组
      const colorArr = [
        ['rgba(252,147,61,1)', 'rgba(252,147,61,.1)']
      ]
      const colorArr2 = [
        ['rgba(2,230,246,1)', 'rgba(2,230,246,.1)']
      ]
      const nameArr = this.nameArrs

      const valueArr = this.allData.map(item => {
        return item.value
      })
      const valueArr2 = this.allData2.map(item => {
        return item.value
      })
      const dataOption = {
        xAxis: {
          data: nameArr
        },
        dataZoom: {
          show: false,
          startValue: this.startValue,
          endValue: this.endValue
        },
        series: [
          {
            name: '体检人数',
            data: valueArr,
            label: {
              normal: {
                show: true,
                position: 'top',
                color: 'rgba(252,147,61,1)'
              }
            },
            itemStyle: {
              color: arg => {
                const targetColor = colorArr[0]
                return new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: targetColor[0]
                  },
                  {
                    offset: 1,
                    color: targetColor[1]
                  }
                ])
              }
            }
          },
          {
            name: '任务数',
            data: valueArr2,
            label: {
              normal: {
                show: true,
                position: 'top',
                color: 'rgba(2,230,246,1)'
              }
            },
            itemStyle: {
              color: arg => {
                const targetColor = colorArr2[0]
                return new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: targetColor[0]
                  },
                  {
                    offset: 1,
                    color: targetColor[1]
                  }
                ])
              }
            }
          }
        ]
      }
      this.chartInstance.setOption(dataOption)
    },
    screenAdapter () {
      const titleFontSize = this.$refs.ref_rank.offsetWidth / 100 * 3.6
      const adapterOption = {
        legend: {
          right: '5%',
          top: '0%',
          data: [
            {
              name: '体检人数',
              itemStyle: {
                color: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [{
                    offset: 0, color: '#FF943D' // 0% 处的颜色
                  }, {
                    offset: 1, color: '#FF943D' // 100% 处的颜色
                  }],
                  global: false // 缺省为 false
                }
              }
            },
            {
              name: '任务数',
              itemStyle: {
                color: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [{
                    offset: 0, color: '#01EEFD' // 0% 处的颜色
                  }, {
                    offset: 1, color: '#15CDFF' // 100% 处的颜色
                  }],
                  global: false // 缺省为 false
                }
              }
            }
          ]
        },
        series: [
          {
            type: 'bar',
            barWidth: titleFontSize * 0.7,
            itemStyle: {
              barBorderRadius: [titleFontSize * 0.25, titleFontSize * 0.25, titleFontSize * 0.25, titleFontSize * 0.25]
            }
          },
          {
            type: 'bar',
            barWidth: titleFontSize * 0.7,
            itemStyle: {
              barBorderRadius: [titleFontSize * 0.25, titleFontSize * 0.25, titleFontSize * 0.25, titleFontSize * 0.25]
            }
          }
        ]
      }
      this.chartInstance.setOption(adapterOption)
      this.chartInstance.resize()
    },
    startInterval () {
      if (this.timerId) {
        clearInterval(this.timerId)
      }
      this.timerId = setInterval(() => {
        this.startValue++
        this.endValue++
        if (this.endValue > this.allData.length - 1) {
          this.startValue = 0
          this.endValue = 4
        }
        this.updataChart()
      }, 3000)
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.initChart()
    this.getData()
    window.addEventListener('resize', this.screenAdapter)
    this.screenAdapter()
    this.startInterval()
  },
  beforeCreate () {}, // 生命周期 - 创建之前
  beforeMount () {}, // 生命周期 - 挂载之前
  beforeUpdate () {}, // 生命周期 - 更新之前
  updated () {}, // 生命周期 - 更新之后
  beforeDestroy () {}, // 生命周期 - 销毁之前
  destroyed () {
    window.removeEventListener('resize', this.screenAdapter)
    clearInterval(this.timerId)
  }, // 生命周期 - 销毁完成
  activated () {} // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>

<style scoped lang="less">
/* // @import url(); 引入公共css类 */

</style>
