<!-- 问卷调查 -->
<template>
  <div class='hcontainer'>
    <div style="position:absolute;top:10px;right:10px;z-index:99999">
      <van-icon @click="$router.go(-1)" name="close" size="30" color="#1989fa" />
    </div>
    <div class="qr-list">
      <div class="question">1.您精力充沛吗？（指精神头足，乐于做事）</div>
      <div class="reply">
        <van-radio-group v-model="dninfo.tmnjlcpm" :disabled="true">
          <van-radio v-for="i in DIC.ZYYJK" :name="i.value" :key="i.value">{{i.label}}</van-radio>
        </van-radio-group>
      </div>
      <div class="question">2.您容易疲乏吗？（指体力如何，是否稍微活动一下或做一点家务劳动就感到累）</div>
      <div class="reply">
        <van-radio-group v-model="dninfo.tmnrypfm" :disabled="true">
          <van-radio v-for="i in DIC.ZYYJK" :name="i.value" :key="i.value">{{i.label}}</van-radio>
        </van-radio-group>
      </div>
      <div class="question">3.您容易气短，呼吸短促，接不上气吗？</div>
      <div class="reply">
        <van-radio-group v-model="dninfo.tmnryqdjcm" :disabled="true">
          <van-radio v-for="i in DIC.ZYYJK" :name="i.value" :key="i.value">{{i.label}}</van-radio>
        </van-radio-group>
      </div>
      <div class="question">4.您说话声音低弱无力吗?(指说话没有力气)</div>
      <div class="reply">
        <van-radio-group v-model="dninfo.tmnshsydrwlm" :disabled="true">
          <van-radio v-for="i in DIC.ZYYJK" :name="i.value" :key="i.value">{{i.label}}</van-radio>
        </van-radio-group>
      </div>
      <div class="question">5.您感到闷闷不乐、情绪低沉吗?(指心情不愉快,情绪低落)</div>
      <div class="reply">
        <van-radio-group v-model="dninfo.tmngdmmblm" :disabled="true">
          <van-radio v-for="i in DIC.ZYYJK" :name="i.value" :key="i.value">{{i.label}}</van-radio>
        </van-radio-group>
      </div>
      <div class="question">6.您容易精神紧张,焦虑不安吗?(指遇事是否心情紧张)</div>
      <div class="reply">
        <van-radio-group v-model="dninfo.tmnryjsjzjlbam" :disabled="true">
          <van-radio v-for="i in DIC.ZYYJK" :name="i.value" :key="i.value">{{i.label}}</van-radio>
        </van-radio-group>
      </div>
      <div class="question">7.您因为生活状态改变而感到孤独、失落吗? </div>
      <div class="reply">
        <van-radio-group v-model="dninfo.tmnysshztgb" :disabled="true">
          <van-radio v-for="i in DIC.ZYYJK" :name="i.value" :key="i.value">{{i.label}}</van-radio>
        </van-radio-group>
      </div>
      <div class="question">8.您容易感到害怕或受到惊吓吗?</div>
      <div class="reply">
        <van-radio-group v-model="dninfo.tmnrygdhpjx" :disabled="true">
          <van-radio v-for="i in DIC.ZYYJK" :name="i.value" :key="i.value">{{i.label}}</van-radio>
        </van-radio-group>
      </div>
      <div class="question">9.您感到身体超重不轻松吗?(感觉身体沉重)[BM指数=体重(kg)/身高2(m)]</div>
      <div class="reply">
        <van-radio-group v-model="dninfo.tmngdstczbqs" :disabled="true">
          <van-radio v-for="i in DIC.ZYYJKBMI" :name="i.value" :key="i.value">{{i.label}}</van-radio>
        </van-radio-group>
      </div>
      <div class="question">10.您眼睛干涩吗?</div>
      <div class="reply">
        <van-radio-group v-model="dninfo.tmnyjgsm" :disabled="true">
          <van-radio v-for="i in DIC.ZYYJK" :name="i.value" :key="i.value">{{i.label}}</van-radio>
        </van-radio-group>
      </div>
      <div class="question">11.您手脚发凉吗?(不包含因周围温度低或穿的少导致的手脚发冷)</div>
      <div class="reply">
        <van-radio-group v-model="dninfo.tmnsjflm" :disabled="true">
          <van-radio v-for="i in DIC.ZYYJK" :name="i.value" :key="i.value">{{i.label}}</van-radio>
        </van-radio-group>
      </div>
      <div class="question">12.您胃脘部、背部或腰膝部怕冷吗?(指上腹部、背部、腰部或膝关节等,有一处或多处怕冷)</div>
      <div class="reply">
        <van-radio-group v-model="dninfo.tmnwwbbbpl" :disabled="true">
          <van-radio v-for="i in DIC.ZYYJK" :name="i.value" :key="i.value">{{i.label}}</van-radio>
        </van-radio-group>
      </div>
      <div class="question">13.您比一般人耐受不了寒冷吗?(指比别人容易害怕冬天或是夏天的冷空调、电扇等)</div>
      <div class="reply">
        <van-radio-group v-model="dninfo.tmnbybrnhl" :disabled="true">
          <van-radio v-for="i in DIC.ZYYJK" :name="i.value" :key="i.value">{{i.label}}</van-radio>
        </van-radio-group>
      </div>
      <div class="question">14.您容易患感冒吗?(指每年感冒的次数)</div>
      <div class="reply">
        <van-radio-group v-model="dninfo.tmnryhgmm" :disabled="true">
          <van-radio v-for="i in DIC.ZYYJKGANMAO" :name="i.value" :key="i.value">{{i.label}}</van-radio>
        </van-radio-group>
      </div>
      <div class="question">15.您没有感冒时也会鼻塞、流鼻涕吗?</div>
      <div class="reply">
        <van-radio-group v-model="dninfo.tmnmygmyhbslbt" :disabled="true">
          <van-radio v-for="i in DIC.ZYYJK" :name="i.value" :key="i.value">{{i.label}}</van-radio>
        </van-radio-group>
      </div>
      <div class="question">16.您有☐粘☐腻,或睡眠打鼾吗?</div>
      <div class="reply">
        <van-radio-group v-model="dninfo.tmnykzkydhm" :disabled="true">
          <van-radio v-for="i in DIC.ZYYJK" :name="i.value" :key="i.value">{{i.label}}</van-radio>
        </van-radio-group>
      </div>
      <div class="question">17.您容易过敏(对药物、食物、气味、花粉或在季节交昔、气候变化时吗?</div>
      <div class="reply">
        <van-radio-group v-model="dninfo.tmnrygm" :disabled="true">
          <van-radio v-for="i in DIC.ZYYJKGUOMIN" :name="i.value" :key="i.value">{{i.label}}</van-radio>
        </van-radio-group>
      </div>
      <div class="question">18.您的皮肤容易起荨麻疹吗?(包括风团、风疹块、风疙瘩)</div>
      <div class="reply">
        <van-radio-group v-model="dninfo.tmndpfryqmzm" :disabled="true">
          <van-radio v-for="i in DIC.ZYYJK" :name="i.value" :key="i.value">{{i.label}}</van-radio>
        </van-radio-group>
      </div>
      <div class="question">19.您的皮肤在不知不觉中会出现青紫瘀斑、皮下出血吗?(指皮肤在没有外伤的情况下出现青一 块紫一块的情况)</div>
      <div class="reply">
        <van-radio-group v-model="dninfo.tmndpfrycxqz" :disabled="true">
          <van-radio v-for="i in DIC.ZYYJK" :name="i.value" :key="i.value">{{i.label}}</van-radio>
        </van-radio-group>
      </div>
      <div class="question">20.您的皮肤一抓就红,并出现抓痕吗?(指被指甲或钝物划过后皮肤的反应)</div>
      <div class="reply">
        <van-radio-group v-model="dninfo.tmndpfyzjhzh" :disabled="true">
          <van-radio v-for="i in DIC.ZYYJK" :name="i.value" :key="i.value">{{i.label}}</van-radio>
        </van-radio-group>
      </div>
      <div class="question">21.您皮肤或☐唇干吗?</div>
      <div class="reply">
        <van-radio-group v-model="dninfo.tmnpfhkcgm" :disabled="true">
          <van-radio v-for="i in DIC.ZYYJK" :name="i.value" :key="i.value">{{i.label}}</van-radio>
        </van-radio-group>
      </div>
      <div class="question">22.您有肢体麻木或固定部位疼痛的感觉吗?</div>
      <div class="reply">
        <van-radio-group v-model="dninfo.tmnyztmm" :disabled="true">
          <van-radio v-for="i in DIC.ZYYJK" :name="i.value" :key="i.value">{{i.label}}</van-radio>
        </van-radio-group>
      </div>
      <div class="question">23.您面部或鼻部有油腻感或者油亮发光吗?(指验上或鼻子)</div>
      <div class="reply">
        <van-radio-group v-model="dninfo.tmnmbhbbyynm" :disabled="true">
          <van-radio v-for="i in DIC.ZYYJK" :name="i.value" :key="i.value">{{i.label}}</van-radio>
        </van-radio-group>
      </div>
      <div class="question">24.您面色或目眶晦黯,或出现褐色斑块/斑点吗?</div>
      <div class="reply">
        <van-radio-group v-model="dninfo.tmnmshmkham" :disabled="true">
          <van-radio v-for="i in DIC.ZYYJK" :name="i.value" :key="i.value">{{i.label}}</van-radio>
        </van-radio-group>
      </div>
      <div class="question">25.您有皮肤湿疹、疮疖吗?</div>
      <div class="reply">
        <van-radio-group v-model="dninfo.tmnypfszzhm" :disabled="true">
          <van-radio v-for="i in DIC.ZYYJK" :name="i.value" :key="i.value">{{i.label}}</van-radio>
        </van-radio-group>
      </div>
      <div class="question">26.您感到☐干咽燥、总想喝水吗?</div>
      <div class="reply">
        <van-radio-group v-model="dninfo.tmngdkgszzshsm" :disabled="true">
          <van-radio v-for="i in DIC.ZYYJK" :name="i.value" :key="i.value">{{i.label}}</van-radio>
        </van-radio-group>
      </div>
      <div class="question">27.您感到☐苦或嘴里有异味吗?(指☐苦或☐臭)</div>
      <div class="reply">
        <van-radio-group v-model="dninfo.tmngdkdzlywm" :disabled="true">
          <van-radio v-for="i in DIC.ZYYJK" :name="i.value" :key="i.value">{{i.label}}</van-radio>
        </van-radio-group>
      </div>
      <div class="question">28.您腹部肥大吗?(指腹部脂肪肥厚)</div>
      <div class="reply">
        <van-radio-group v-model="dninfo.tmnfbfdm" :disabled="true">
          <van-radio v-for="i in DIC.ZYYJKFUBU" :name="i.value" :key="i.value">{{i.label}}</van-radio>
        </van-radio-group>
      </div>
      <div class="question">29.您吃(喝)凉的东西会感到不舒服或者怕吃(喝)凉的东西吗?(指不喜欢吃凉的食物,或吃了凉 的食物后会不舒服)</div>
      <div class="reply">
        <van-radio-group v-model="dninfo.tmnclbsfm" :disabled="true">
          <van-radio v-for="i in DIC.ZYYJK" :name="i.value" :key="i.value">{{i.label}}</van-radio>
        </van-radio-group>
      </div>
      <div class="question">30.您有大便黏滞不爽、解不尽的感觉吗?(大便容易粘在马桶或便坑壁上)</div>
      <div class="reply">
        <van-radio-group v-model="dninfo.tmndbncbusm" :disabled="true">
          <van-radio v-for="i in DIC.ZYYJK" :name="i.value" :key="i.value">{{i.label}}</van-radio>
        </van-radio-group>
      </div>
      <div class="question">31.您容易大便干燥吗?</div>
      <div class="reply">
        <van-radio-group v-model="dninfo.tmnrhdbgzm" :disabled="true">
          <van-radio v-for="i in DIC.ZYYJK" :name="i.value" :key="i.value">{{i.label}}</van-radio>
        </van-radio-group>
      </div>
      <div class="question">32.您舌苔厚腻或有舌苔厚厚的感觉吗?(如果自我感觉不清楚可由调查员观察后填写)</div>
      <div class="reply">
        <van-radio-group v-model="dninfo.tmnsthyhhm" :disabled="true">
          <van-radio v-for="i in DIC.ZYYJK" :name="i.value" :key="i.value">{{i.label}}</van-radio>
        </van-radio-group>
      </div>
      <div class="question">33.您舌下静脉瘀紫或增粗吗?(可由调查员辅助观察后填写)</div>
      <div class="reply">
        <van-radio-group v-model="dninfo.tmnsxjmzcm" :disabled="true">
          <van-radio v-for="i in DIC.ZYYJK" :name="i.value" :key="i.value">{{i.label}}</van-radio>
        </van-radio-group>
      </div>
    </div>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import DIC from '@/const/dic_list'
export default {
// import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
  // 这里存放数据
    return {
      DIC: DIC,
      radio: '1',
      dninfo: {}
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    async init () {
      const url = '/ykdlnrzyyjkfwjls/finds'
      const params = {
        jktjid: this.$route.query.jktjid,
        grjbxxid: this.$route.query.grjbxxid
      }
      const { data: res } = await this.$http.post(url, params)
      this.dninfo = res.data[0]
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.init()
  },
  beforeCreate () {}, // 生命周期 - 创建之前
  beforeMount () {}, // 生命周期 - 挂载之前
  beforeUpdate () {}, // 生命周期 - 更新之前
  updated () {}, // 生命周期 - 更新之后
  beforeDestroy () {}, // 生命周期 - 销毁之前
  destroyed () {}, // 生命周期 - 销毁完成
  activated () {} // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang="less">
.van-radio {
  padding: 2px 0;
}
.van-radio__label {
  color: #6e6e91 !important;
}
.van-radio__icon--disabled.van-radio__icon--checked .van-icon {
    color: #fff !important;
    border-color: #1989fa !important;
      background-color: #1989fa !important;
}

</style>
<style lang='less' scoped>
//@import url(); 引入公共css类
.hcontainer {
  background-color: #fff;
}
.qr-list {
  padding: 0 10px;
  .question {
    padding: 15px 0;
  }
}

</style>
