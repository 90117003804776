<!-- 工作提醒 -->
<template>
  <div class='promp'>
    <div class="header">慢病管理</div>
    <div class="top-tabs">
      <div class="top-tabs-item" :class="{select:topbtn==1}" @click="topbtn=1">血糖</div>
      <div class="top-tabs-item" style="margin: 0 15px;" :class="{select:topbtn==2}" @click="topbtn=2">血压</div>
    </div>
    <div class="bot-table">
      <div class="tbStyle">
      <table>
        <thead class="table-header">
          <td style="width:300px;">计划随访日期</td>
          <td style="width:130px;">随访人</td>
          <td style="width:180px;">重点人群标签</td>
          <td style="width:180px;">本人电话</td>
          <td style="width:180px;">家人电话</td>
          <td style="width:180px;">随访业务</td>
          <td style="width:180px;">管理医生</td>
          <td style="width:100px;">操作</td>
        </thead>
        <div style="height:30px"></div>
        <tbody >
            <tr v-for="(i,index) in allData" :key="index" :class="[index % 2 == 0?'color1':'color2']">
              <td style="width:300px;">{{i.time}}</td>
              <td style="width:130px;">{{i.name}}</td>
              <td style="width:180px;">
                <div class="tag">
                  <div class="tag-item" v-if="i.value.indexOf('G') !== -1">高</div>
                  <div class="tag-item tang" v-if="i.value.indexOf('T') !== -1">糖</div>
                  <div v-else>无</div>
                </div>
              </td>
              <td style="width:180px;">{{i.brdh}}</td>
              <td style="width:180px;">{{i.jrdh}}</td>
              <td style="width:180px;">{{i.title}}</td>
              <td style="width:180px;">{{i.glys}}</td>
              <td style="width:100px;"><div class="suifang">随访</div></td>
            </tr>
        </tbody>
      </table>
      </div>
    </div>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';

export default {
// import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
  // 这里存放数据
    return {
      topbtn: 1,
      botbtn: 1,
      allData: [],
      allDatas: [],
      qttx: null,
      ssttx: null,
      bjddsf: null,
      yfwf: null,
      sfrwzs: null,
      timerId: null,
      timerId1: null
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {
    'topbtn' (v) {
      if (v === 1) {
        this.allData = this.allDatas[0].sfyj.sfyjlist
        this.qttx = this.allDatas[0].sfyj.qttx
        this.ssttx = this.allDatas[0].sfyj.ssttx
        this.bjddsf = this.allDatas[0].sfyj.bjddsf
        this.yfwf = this.allDatas[0].sfyj.yfwf
        this.sfrwzs = this.allDatas[0].sfyj.sfrwzs
      } else if (v === 2) {
        this.allData = this.allDatas[1].xyyj.xyyjlist
        this.qttx = this.allDatas[1].xyyj.qttx
        this.ssttx = this.allDatas[1].xyyj.ssttx
        this.bjddsf = this.allDatas[1].xyyj.bjddsf
        this.yfwf = this.allDatas[1].xyyj.yfwf
        this.sfrwzs = this.allDatas[1].xyyj.sfrwzs
      } else if (v === 3) {
        this.allData = this.allDatas[2].xtyj.xtyjlist
        this.qttx = this.allDatas[2].xtyj.qttx
        this.ssttx = this.allDatas[2].xtyj.ssttx
        this.bjddsf = this.allDatas[2].xtyj.bjddsf
        this.yfwf = this.allDatas[2].xtyj.yfwf
        this.sfrwzs = this.allDatas[2].xtyj.sfrwzs
      }
    }
  },
  // 方法集合
  methods: {
    async initData () {
      const params = {
        jgbh: this.$store.state.loginInfo.jgbh,
        jbmc: this.$store.state.loginInfo.jgmc,
        ssjgbhmc: this.$store.state.loginInfo.label,
        usercode: this.$store.state.loginInfo.usercode,
        username: this.$store.state.loginInfo.username,
        jgjb: this.$store.state.loginInfo.jgjb,
        ssjgbh: this.$store.state.loginInfo.value,
        sjjgbh: this.$store.state.loginInfo.sjjgbh
      }
      // const params = {
      //   jgbh: '',
      //   jbmc: '',
      //   ssjgbhmc: '',
      //   usercode: '',
      //   username: '',
      //   jgjb: '',
      //   ssjgbh: ''
      // }
      const { data: res } = await this.$http.post('api/jkct/app/zhdpyfrhsygztx', params)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.allDatas = res.data
      this.allData = res.data[0].sfyj.sfyjlist
      this.qttx = this.allDatas[0].sfyj.qttx
      this.ssttx = this.allDatas[0].sfyj.ssttx
      this.bjddsf = this.allDatas[0].sfyj.bjddsf
      this.yfwf = this.allDatas[0].sfyj.yfwf
      this.sfrwzs = this.allDatas[0].sfyj.sfrwzs
    },
    startInterval () {
      if (this.timerId) {
        clearInterval(this.timerId)
      }
      this.timerId = setInterval(() => {
        if (this.topbtn < 3) {
          this.topbtn++
        } else if (this.topbtn === 3) {
          this.topbtn = 1
        }
      }, 15000)
    },
    startInterval1 () {
      if (this.timerId1) {
        clearInterval(this.timerId1)
      }
      this.timerId = setInterval(() => {
        if (this.botbtn < 5) {
          this.botbtn++
        } else if (this.botbtn === 5) {
          this.botbtn = 1
        }
      }, 3000)
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  async mounted () {
    await this.initData()
    this.startInterval()
    this.startInterval1()
  },
  beforeCreate () {}, // 生命周期 - 创建之前
  beforeMount () {}, // 生命周期 - 挂载之前
  beforeUpdate () {}, // 生命周期 - 更新之前
  updated () {}, // 生命周期 - 更新之后
  beforeDestroy () {}, // 生命周期 - 销毁之前
  destroyed () {}, // 生命周期 - 销毁完成
  activated () {} // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.promp {
  width: 100%;
  height: 100%;
  background-image: url('../../assets/img/spms/workwarn.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  .header {
    text-align: center;
    color: #ffffff;
    font-size: 14px;
    font-weight: bold;
  }
  .top-tabs {
    display: flex;
    padding: 0 15px;
    margin-bottom: 15px;
    .top-tabs-item {
      width: 120px;
      text-align: center;
      background-image: url('../../assets/img/spms/btn-default.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: center;
      padding: 3px 0;
      font-size: 14px;
      color: #81AFD6;
      cursor: pointer;
    }
    .select {
      background-image: url('../../assets/img/spms/btn-select.png');
      color: #27F6FF;
    }
  }
  .bot-tabs {
    width: 100%;
    justify-content: space-between;
    box-sizing: border-box;
    font-size: 14px;
    &-item {
      width: 18% !important;
      padding: 5px 0;
    }
  }
  .bot-table {
    padding: 0 15px;
    font-size: 14px;
    .tag {
      display: flex;
      justify-content: center;
      .tag-item {
        margin: 0 5px;
        border: 1px solid #FCD042;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        color: #FCD042;
        background-color: rgba(252, 208, 66, 0.2);
      }
      .tang {
        border: 1px solid #FF6652;
        background: rgba(255, 102, 82, 0.2);
        color: #FF6652;
      }
    }
    .suifang {
      border: 1px solid #27F6FF;
      color: #27F6FF;
      cursor: pointer;
    }
    .color1 {
      background-color:  rgba(7, 46, 68, 0.8);
    }
    .color2 {
      background-color:  rgba(10, 74, 110, 0.8);;
    }
    .tbStyle {
      display: block;
      width: 1430px;
      height: 300px;
      overflow-x: hidden;
      overflow-y: scroll;
      scrollbar-width: none; /* firefox */
      -ms-overflow-style: none; /* IE 10+ */
    }
    .tbStyle::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
    table {
      border-collapse: collapse;
      width: 1430px;
      text-align: center;
        td {
          padding: 5px 0;
        }
      .table-header {
        color: #27F6FF;
        background-color: #072E44;
        border-top: 1px solid #27F6FF;
        border-bottom: 1px solid #27F6FF;
        margin-bottom: 10px;
        position: absolute;
        width: 1430px;
      }
      tbody {
        td {
          color: #ffffff;
        }
      }
    }
  }
}
</style>
