<!--  -->
<template>
  <div class='xyxthz'>
    <div class="qst">
      <xyqst :allData="allData"></xyqst>
    </div>
    <div class="tjt">
      <div class="tjt-item"><xytj1></xytj1></div>
      <div class="tjt-item"><xytj2></xytj2></div>
    </div>
  </div>
</template>
<script>
import xyqst from './xyqst.vue'
import xytj1 from './xytj1.vue'
import xytj2 from './xytj2.vue'
export default {
  components: { xyqst, xytj1, xytj2 },
  props: ['datas'],
  data () {
  // 这里存放数据
    return {
      allData: null
    }
  },
  watch: {
    datas (v) {
      this.allData = v
    }
  },
  mounted () {
    this.allData = this.datas
  }
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.xyxthz {
  width: 100%;
  height: 100%;
  padding: 20px;
  padding-right: 60px;
  box-sizing: border-box;

  .qst {
    width: 100%;
    height: 50%;
    border: 1px solid #03757F;
    border-radius: 6px;
  }
  .tjt {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 44%;
    margin-top: 10px;
    &-item {
      width: 48%;
      height: 100%;
      border: 1px solid #03757F;
      border-radius: 6px;
    }
  }
}
</style>
