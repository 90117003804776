<!--  -->
<template>
  <div class='com-container com-container1'>
    <div class="com-title">
      <div class="com-title-left">总览</div>
    </div>
    <div class="com-chart com-chart1 zl">
      <div class="zl-left">
        <div class="zl-left-zrks">
          <div>
          <div class="zl-left-zrks-top">{{allData[0].value}}</div>
          <div class="zl-left-zrks-bottom">{{allData[0].title}}</div></div>
        </div>
        <div class="zl-left-zrks zl-left-lnrzd">
          <div>
          <div class="zl-left-zrks-top">{{allData[1].value}}</div>
          <div class="zl-left-zrks-bottom">{{allData[1].title}}</div></div>
        </div>
        <div class="zl-left-zrks zl-left-jqrs">
          <div>
          <div class="zl-left-zrks-top">{{allData[2].value}}</div>
          <div class="zl-left-zrks-bottom">{{allData[2].title}}</div></div>
        </div>
        <div class="zl-left-zrks zl-left-mbrq">
          <div>
          <div class="zl-left-zrks-top">{{allData[3].value}}</div>
          <div class="zl-left-zrks-bottom">{{allData[3].title}}</div></div>
        </div>
      </div>
      <div class="zl-img"></div>
      <div class="zl-center">
        <div class="zl-left-zrks zl-left-jdrs">
          <div>
          <div class="zl-left-zrks-top">{{allData1[0].value}}</div>
          <div class="zl-left-zrks-bottom">{{allData1[0].title}}</div></div>
        </div>
        <div class="zl-left-zrks zl-left-tjrs">
          <div>
          <div class="zl-left-zrks-top">{{allData1[1].value}}</div>
          <div class="zl-left-zrks-bottom">{{allData1[1].title}}</div></div>
        </div>
        <div class="zl-left-zrks zl-left-qyrs">
          <div>
          <div class="zl-left-zrks-top">{{allData1[2].value}}</div>
          <div class="zl-left-zrks-bottom">{{allData1[2].title}}</div></div>
        </div>
        <div class="zl-left-zrks zl-left-jcrs">
          <div>
          <div class="zl-left-zrks-top">{{allData1[3].value}}</div>
          <div class="zl-left-zrks-bottom">{{allData1[3].title}}</div></div>
        </div>
      </div>
      <div class="zl-right">
        <div class="zl-right-item">
          <div class="zl-right-ybp">
            <ybp ref="ybp" :chartData="chartData[0]"></ybp>
          </div>
          <div>建档率</div>
        </div>
        <div class="zl-right-item">
          <div class="zl-right-ybp">
            <ybp ref="ybp1" :chartData="chartData[1]"></ybp>
          </div>
          <div>体检率</div>
        </div>
        <div class="zl-right-item">
          <div class="zl-right-ybp">
            <ybp ref="ybp2"  :chartData="chartData[2]"></ybp>
          </div>
          <div>签约率</div>
        </div>
        <div class="zl-right-item">
          <div class="zl-right-ybp">
            <ybp ref="ybp3" :chartData="chartData[3]"></ybp>
          </div>
          <div>监测率</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';

import ybp from '@/components/yiliao/ybp'
export default {
// import引入的组件需要注入到对象中才能使用
  components: { ybp: ybp },
  data () {
  // 这里存放数据
    return {
      timerId: null,
      dataIndex: 0,
      allData: [
        {
          title: '总人口数',
          value: '260000'
        },
        {
          title: '老年人专档',
          value: '1242'
        },
        {
          title: '家签人数',
          value: '3214'
        },
        {
          title: '总人口数',
          value: '4214'
        }
      ],
      allData1: [
        {
          title: '建档人数',
          value: '260000'
        },
        {
          title: '体检人数',
          value: '260000'
        },
        {
          title: '签约人数',
          value: '260000'
        },
        {
          title: '检测人数',
          value: '260000'
        }
      ],
      allData2: ['89', '94', '76', '87'],
      chartData: []
    }
  },
  // 监听属性 类似于data概念
  computed: { },
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    async getData () {
      const params = {
        jgbh: this.$store.state.loginInfo.jgbh,
        jbmc: this.$store.state.loginInfo.jgmc,
        ssjgbhmc: this.$store.state.loginInfo.label,
        usercode: this.$store.state.loginInfo.usercode,
        username: this.$store.state.loginInfo.username,
        jgjb: this.$store.state.loginInfo.jgjb,
        ssjgbh: this.$store.state.loginInfo.value,
        sjjgbh: this.$store.state.loginInfo.sjjgbh
      }
      const { data: res } = await this.$http.post('api/jkct/app/zhdpgwzlsbccsj', params)
      const { data: res1 } = await this.$http.post('api/jkct/app/zhdpgwzlsbycsj', params)
      if (res.code !== 0) this.$message.error('res.msg')
      this.allData = res.data
      this.allData1 = res1.data
      this.chartData = []
      // this.chartData.push(parseInt(this.allData1[0].value / this.allData[0].value) * 100)
      this.chartData.push(parseInt(this.allData1[0].value / this.allData[0].value * 100))
      this.chartData.push(parseInt(this.allData1[1].value / this.allData[1].value * 100))
      this.chartData.push(parseInt(this.allData1[2].value / this.allData[2].value * 100))
      this.chartData.push(parseInt(this.allData1[3].value / this.allData[3].value * 100))
    },
    startInterval () {
      if (this.timerId) {
        clearInterval(this.timerId)
      }
      this.timerId = setInterval(() => {
        // this.chartData = this.mockData[this.dataIndex]
        // this.dataIndex++
        // if (this.dataIndex === 3) {
        //   this.dataIndex = 0
        // }
        // this.updataChart()
        // this.$emit('up-chart')
        this.getData()
      }, 1000 * 60 * 5)
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {
    this.getData()
    this.startInterval()
  }
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
  .zl {
    padding: 0 10px;
    display: flex;
    &-img {
      width: 10%;
      height: 100%;
      background: url('../../assets/img/zl_rocket_bg.png') no-repeat center;
    }
    &-left {
      width: 32%;
      text-align: center;
      &-zrks {
        margin-top: 10px;
        height: 20%;
        background: url('../../assets/img/zrks_bg.png') no-repeat left;
        background-size: 100% 100%;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        &-top {
          height: 50%;
          font-size: 20px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #4FF6EA;
          // line-height: 100px;
        }
        &-bottom {
          height: 50%;
          font-size: 20px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #4FF6EA;
        }

      }
      &-lnrzd {
          background: url('../../assets/img/lnrzd_bg.png') no-repeat left;
          background-size: 100% 100%;
        }
        &-jqrs {
          background: url('../../assets/img/jqrs_bg.png') no-repeat left;
          background-size: 100% 100%;
        }
        &-mbrq {
          background: url('../../assets/img/mbrq_bg.png') no-repeat left;
          background-size: 100% 100%;
        }
        &-jdrs {
          background: url('../../assets/img/jdrs_bg.png') no-repeat right;
          background-size: 100% 100%;
        }
        &-tjrs {
          background: url('../../assets/img/tjrs_bg.png') no-repeat right;
          background-size: 100% 100%;
        }
        &-qyrs {
          background: url('../../assets/img/qyrs_bg.png') no-repeat right;
          background-size: 100% 100%;
        }
        &-jcrs {
          background: url('../../assets/img/jcrs_bg.png') no-repeat right;
          background-size: 100% 100%;
        }
    }
    &-center {
      width: 32%;
      text-align: center;
    }
    &-right {
      width: 26%;
      text-align: center;
      font-size: 20px;
      padding-left: 10px;
      &-item {
        height: 20%;
        display: flex;
        align-items: center;
        color: #4FF6EA;
        margin-top: 20px;
      }
      &-ybp {
        width: 325px;
        height: 325px;
      }
    }
  }
</style>
