<!-- 老年人中医药管理 -->
<template>
  <div class='hcontainer'>
    <div style="height:30px"> </div>
    <div style="position:absolute;top:10px;right:10px;z-index:99999">
      <van-icon @click="$router.go(-1)" name="close" size="30" color="#1989fa" />
    </div>
    <van-cell-group>
      <van-cell title="记录日期" :value="dninfo.createTime" />
      <!-- <van-cell title="问卷调查表" is-link /> -->
      <van-cell title="问卷调查表" @click="goLnrwj" >
        <!-- 使用 right-icon 插槽来自定义右侧图标 -->
        <template #right-icon>
          <van-icon name="todo-list-o" color="#1989fa" />
        </template>
      </van-cell>
      <van-cell title="联系电话" value="暂无" />
    </van-cell-group>
    <div class="itemCard">
      <div class="itemCard-top">
        <div class="itemCard-top-title">气虚质</div>
        <div class="itemCard-top-fen"><span>{{dninfo.tzlxqxzdf}}</span> 分</div>
      </div>
      <div class="itemCard-center">
        <van-radio-group :disabled="true" v-model="dninfo.tzlxqxzbs" direction="horizontal">
          <van-radio v-for="i in DIC.TZBB" :key="i.value" :name="i.value">{{i.label}}</van-radio>
        </van-radio-group>
      </div>
      <div class="itemCard-bot" v-if="dninfo.tzlxqxzzd">
        <div class="itemCard-bot-tip" v-if="dninfo.tzlxqxzzd.indexOf('1') != -1">情志调摄</div>
        <div class="itemCard-bot-tip" v-if="dninfo.tzlxqxzzd.indexOf('2') != -1">饮食调养</div>
        <div class="itemCard-bot-tip" v-if="dninfo.tzlxqxzzd.indexOf('3') != -1">起居调摄</div>
        <div class="itemCard-bot-tip" v-if="dninfo.tzlxqxzzd.indexOf('4') != -1">运动保健</div>
        <div class="itemCard-bot-tip" v-if="dninfo.tzlxqxzzd.indexOf('5') != -1">穴位保健</div>
        <div class="itemCard-bot-tip" v-if="dninfo.tzlxqxzzd.indexOf('6') != -1">其他</div>
      </div>
    </div>
    <div class="itemCard">
      <div class="itemCard-top">
        <div class="itemCard-top-title">阳虚质</div>
        <div class="itemCard-top-fen"><span>{{dninfo.tzlxyxzdf}}</span> 分</div>
      </div>
      <div class="itemCard-center">
        <van-radio-group :disabled="true" v-model="dninfo.tzlxyxzbs" direction="horizontal">
          <van-radio v-for="i in DIC.TZBB" :key="i.value" :name="i.value">{{i.label}}</van-radio>
        </van-radio-group>
      </div>
      <div class="itemCard-bot" v-if="dninfo.tzlxyxzzd">
        <div class="itemCard-bot-tip" v-if="dninfo.tzlxyxzzd.indexOf('1') != -1">情志调摄</div>
        <div class="itemCard-bot-tip" v-if="dninfo.tzlxyxzzd.indexOf('2') != -1">饮食调养</div>
        <div class="itemCard-bot-tip" v-if="dninfo.tzlxyxzzd.indexOf('3') != -1">起居调摄</div>
        <div class="itemCard-bot-tip" v-if="dninfo.tzlxyxzzd.indexOf('4') != -1">运动保健</div>
        <div class="itemCard-bot-tip" v-if="dninfo.tzlxyxzzd.indexOf('5') != -1">穴位保健</div>
        <div class="itemCard-bot-tip" v-if="dninfo.tzlxyxzzd.indexOf('6') != -1">其他</div>
      </div>
    </div>
    <div class="itemCard">
      <div class="itemCard-top">
        <div class="itemCard-top-title">阴虚质</div>
        <div class="itemCard-top-fen"><span>{{dninfo.tzlxyinxzdf}}</span> 分</div>
      </div>
      <div class="itemCard-center">
        <van-radio-group :disabled="true" v-model="dninfo.tzlxyinxzbs" direction="horizontal">
          <van-radio v-for="i in DIC.TZBB" :key="i.value" :name="i.value">{{i.label}}</van-radio>
        </van-radio-group>
      </div>
      <div class="itemCard-bot" v-if="dninfo.tzlxyinxzzd">
        <div class="itemCard-bot-tip" v-if="dninfo.tzlxyinxzzd.indexOf('1') != -1">情志调摄</div>
        <div class="itemCard-bot-tip" v-if="dninfo.tzlxyinxzzd.indexOf('2') != -1">饮食调养</div>
        <div class="itemCard-bot-tip" v-if="dninfo.tzlxyinxzzd.indexOf('3') != -1">起居调摄</div>
        <div class="itemCard-bot-tip" v-if="dninfo.tzlxyinxzzd.indexOf('4') != -1">运动保健</div>
        <div class="itemCard-bot-tip" v-if="dninfo.tzlxyinxzzd.indexOf('5') != -1">穴位保健</div>
        <div class="itemCard-bot-tip" v-if="dninfo.tzlxyinxzzd.indexOf('6') != -1">其他</div>
      </div>
    </div>
    <div class="itemCard">
      <div class="itemCard-top">
        <div class="itemCard-top-title">痰湿质</div>
        <div class="itemCard-top-fen"><span>{{dninfo.tzlxtszdf}}</span> 分</div>
      </div>
      <div class="itemCard-center">
        <van-radio-group :disabled="true" v-model="dninfo.tzlxtszbs" direction="horizontal">
          <van-radio v-for="i in DIC.TZBB" :key="i.value" :name="i.value">{{i.label}}</van-radio>
        </van-radio-group>
      </div>
      <div class="itemCard-bot" v-if="dninfo.tzlxtszzd">
        <div class="itemCard-bot-tip" v-if="dninfo.tzlxtszzd.indexOf('1') != -1">情志调摄</div>
        <div class="itemCard-bot-tip" v-if="dninfo.tzlxtszzd.indexOf('2') != -1">饮食调养</div>
        <div class="itemCard-bot-tip" v-if="dninfo.tzlxtszzd.indexOf('3') != -1">起居调摄</div>
        <div class="itemCard-bot-tip" v-if="dninfo.tzlxtszzd.indexOf('4') != -1">运动保健</div>
        <div class="itemCard-bot-tip" v-if="dninfo.tzlxtszzd.indexOf('5') != -1">穴位保健</div>
        <div class="itemCard-bot-tip" v-if="dninfo.tzlxtszzd.indexOf('6') != -1">其他</div>
      </div>
    </div>
    <div class="itemCard">
      <div class="itemCard-top">
        <div class="itemCard-top-title">湿热质</div>
        <div class="itemCard-top-fen"><span>{{dninfo.tzlxsrzdf}}</span> 分</div>
      </div>
      <div class="itemCard-center">
        <van-radio-group :disabled="true" v-model="dninfo.tzlxsrzbs" direction="horizontal">
          <van-radio v-for="i in DIC.TZBB" :key="i.value" :name="i.value">{{i.label}}</van-radio>
        </van-radio-group>
      </div>
      <div class="itemCard-bot" v-if="dninfo.tzlxsrzzd">
        <div class="itemCard-bot-tip" v-if="dninfo.tzlxsrzzd.indexOf('1') != -1">情志调摄</div>
        <div class="itemCard-bot-tip" v-if="dninfo.tzlxsrzzd.indexOf('2') != -1">饮食调养</div>
        <div class="itemCard-bot-tip" v-if="dninfo.tzlxsrzzd.indexOf('3') != -1">起居调摄</div>
        <div class="itemCard-bot-tip" v-if="dninfo.tzlxsrzzd.indexOf('4') != -1">运动保健</div>
        <div class="itemCard-bot-tip" v-if="dninfo.tzlxsrzzd.indexOf('5') != -1">穴位保健</div>
        <div class="itemCard-bot-tip" v-if="dninfo.tzlxsrzzd.indexOf('6') != -1">其他</div>
      </div>
    </div>
    <div class="itemCard">
      <div class="itemCard-top">
        <div class="itemCard-top-title">血瘀质</div>
        <div class="itemCard-top-fen"><span>{{dninfo.tzlxxyzdf}}</span> 分</div>
      </div>
      <div class="itemCard-center">
        <van-radio-group :disabled="true" v-model="dninfo.tzlxxyzbs" direction="horizontal">
          <van-radio v-for="i in DIC.TZBB" :key="i.value" :name="i.value">{{i.label}}</van-radio>
        </van-radio-group>
      </div>
      <div class="itemCard-bot" v-if="dninfo.tzlxxyzzd">
        <div class="itemCard-bot-tip" v-if="dninfo.tzlxxyzzd.indexOf('1') != -1">情志调摄</div>
        <div class="itemCard-bot-tip" v-if="dninfo.tzlxxyzzd.indexOf('2') != -1">饮食调养</div>
        <div class="itemCard-bot-tip" v-if="dninfo.tzlxxyzzd.indexOf('3') != -1">起居调摄</div>
        <div class="itemCard-bot-tip" v-if="dninfo.tzlxxyzzd.indexOf('4') != -1">运动保健</div>
        <div class="itemCard-bot-tip" v-if="dninfo.tzlxxyzzd.indexOf('5') != -1">穴位保健</div>
        <div class="itemCard-bot-tip" v-if="dninfo.tzlxxyzzd.indexOf('6') != -1">其他</div>
      </div>
    </div>
    <div class="itemCard">
      <div class="itemCard-top">
        <div class="itemCard-top-title">气郁质</div>
        <div class="itemCard-top-fen"><span>{{dninfo.tzlxqyzdf}}</span> 分</div>
      </div>
      <div class="itemCard-center">
        <van-radio-group :disabled="true" v-model="dninfo.tzlxqyzbs" direction="horizontal">
          <van-radio v-for="i in DIC.TZBB" :key="i.value" :name="i.value">{{i.label}}</van-radio>
        </van-radio-group>
      </div>
      <div class="itemCard-bot" v-if="dninfo.tzlxqyzzd">
        <div class="itemCard-bot-tip" v-if="dninfo.tzlxqyzzd.indexOf('1') != -1">情志调摄</div>
        <div class="itemCard-bot-tip" v-if="dninfo.tzlxqyzzd.indexOf('2') != -1">饮食调养</div>
        <div class="itemCard-bot-tip" v-if="dninfo.tzlxqyzzd.indexOf('3') != -1">起居调摄</div>
        <div class="itemCard-bot-tip" v-if="dninfo.tzlxqyzzd.indexOf('4') != -1">运动保健</div>
        <div class="itemCard-bot-tip" v-if="dninfo.tzlxqyzzd.indexOf('5') != -1">穴位保健</div>
        <div class="itemCard-bot-tip" v-if="dninfo.tzlxqyzzd.indexOf('6') != -1">其他</div>
      </div>
    </div>
    <div class="itemCard">
      <div class="itemCard-top">
        <div class="itemCard-top-title">特禀质</div>
        <div class="itemCard-top-fen"><span>{{dninfo.tzlxtbzdf}}</span> 分</div>
      </div>
      <div class="itemCard-center">
        <van-radio-group :disabled="true" v-model="dninfo.tzlxtbzbs" direction="horizontal">
          <van-radio v-for="i in DIC.TZBB" :key="i.value" :name="i.value">{{i.label}}</van-radio>
        </van-radio-group>
      </div>
      <div class="itemCard-bot" v-if="dninfo.tzlxtbzzd">
        <div class="itemCard-bot-tip" v-if="dninfo.tzlxtbzzd.indexOf('1') != -1">情志调摄</div>
        <div class="itemCard-bot-tip" v-if="dninfo.tzlxtbzzd.indexOf('2') != -1">饮食调养</div>
        <div class="itemCard-bot-tip" v-if="dninfo.tzlxtbzzd.indexOf('3') != -1">起居调摄</div>
        <div class="itemCard-bot-tip" v-if="dninfo.tzlxtbzzd.indexOf('4') != -1">运动保健</div>
        <div class="itemCard-bot-tip" v-if="dninfo.tzlxtbzzd.indexOf('5') != -1">穴位保健</div>
        <div class="itemCard-bot-tip" v-if="dninfo.tzlxtbzzd.indexOf('6') != -1">其他</div>
      </div>
    </div>
    <div class="itemCard">
      <div class="itemCard-top">
        <div class="itemCard-top-title">平和质</div>
        <div class="itemCard-top-fen"><span>{{dninfo.tzlxphzdf}}</span> 分</div>
      </div>
      <div class="itemCard-center">
        <van-radio-group :disabled="true" v-model="dninfo.tzlxphzbs" direction="horizontal">
          <van-radio v-for="i in DIC.TZBB" :key="i.value" :name="i.value">{{i.label}}</van-radio>
        </van-radio-group>
      </div>
      <div class="itemCard-bot" v-if="dninfo.tzlxphzzd">
        <div class="itemCard-bot-tip" v-if="dninfo.tzlxphzzd.indexOf('1') != -1">情志调摄</div>
        <div class="itemCard-bot-tip" v-if="dninfo.tzlxphzzd.indexOf('2') != -1">饮食调养</div>
        <div class="itemCard-bot-tip" v-if="dninfo.tzlxphzzd.indexOf('3') != -1">起居调摄</div>
        <div class="itemCard-bot-tip" v-if="dninfo.tzlxphzzd.indexOf('4') != -1">运动保健</div>
        <div class="itemCard-bot-tip" v-if="dninfo.tzlxphzzd.indexOf('5') != -1">穴位保健</div>
        <div class="itemCard-bot-tip" v-if="dninfo.tzlxphzzd.indexOf('6') != -1">其他</div>
      </div>
    </div>
    <van-cell-group>
      <van-cell title="医生签名" :value="dninfo.ysqm" />
      <van-cell title="客户签名" :value="dninfo.name" />
    </van-cell-group>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';

import DIC from '@/const/dic_list'
export default {
// import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
  // 这里存放数据
    return {
      DIC: DIC,
      radio: '2',
      dninfo: {},
      bjzd: [
        { label: '情志调摄', value: '1' },
        { label: '饮食调养', value: '2' },
        { label: '起居调摄', value: '3' },
        { label: '运动保健', value: '4' },
        { label: '穴位保健', value: '5' },
        { label: '其他', value: '6' }
      ]
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    goLnrwj () {
      this.$router.push({
        path: '/hlnrwj',
        query: {
          jktjid: this.$route.query.jktjid,
          grjbxxid: this.$route.query.grjbxxid
        }
      })
    },
    async init () {
      const url = '/ykdlnrzyyjkfwjls/finds'
      const params = {
        jktjid: this.$route.query.jktjid,
        grjbxxid: this.$route.query.grjbxxid
      }
      const { data: res } = await this.$http.post(url, params)
      this.dninfo = res.data[0]
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.init()
  }
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.hcontainer {
  background: #fff;
}
.itemCard {
  padding: 0 15px;
  border-bottom: 1px solid #ccc;
  &-top {
    height: 30px;
    padding: 10px 0;
    line-height: 1;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px dashed #eee;
    &-title {
      line-height: 30px;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 1px;

    }
    &-fen {
      span {
        line-height: 30px;
        padding: 0 25px;
        display: inline-block;
        border: 1px solid #ccc;
        margin-right: 20px;
      }
    }
  }
  &-center {
    padding: 15px 0;
  }
  &-bot {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    &-tip {
      height: 40px;
      width: 80px;
      border: 1px solid #ccc;
      border-radius: 4px;
      font-size: 14px;
      text-align: center;
      line-height: 40px;
      margin-bottom: 15px;
    }
  }
}
</style>
