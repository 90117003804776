<!-- tabs -->
<template>
  <div class='tabs'>
    <div class="tabsl">
      <div @click="tabclick(index)" class="tabslItem" :class="{active:index == inx}" v-for="(item,index) in tabList1" :key="index">
        <span>></span>{{item.label}}<span>&lt;</span>
      </div>
    </div>
    <div class="tabsr">
      <div @click="tabclick(index + 4)" class="tabsrItem"  :class="{active:(index + 4) == inx}" v-for="(item,index) in tabList2" :key="index">
        <span>></span>{{item.label}}<span>&lt;</span>
      </div>
    </div>
    <el-dialog
      :visible.sync="gztxShow"
      width="1500px">
      <gztx></gztx>
    </el-dialog>
    <el-dialog
      :visible.sync="mbglShow"
      width="1500px">
      <mbgl></mbgl>
    </el-dialog>
    <el-dialog
      :visible.sync="rwtxShow"
      width="1500px">
      <rwtx></rwtx>
    </el-dialog>
    <el-dialog
      :visible.sync="rwtsShow"
      width="1500px">
      <rwts></rwts>
    </el-dialog>
    <el-dialog
      :visible.sync="tjfxShow"
      top="2vh"
      width="1500px">
      <tjfx></tjfx>
    </el-dialog>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import gztx from '../../views/yfrh/gztx.vue'
import mbgl from '../../views/yfrh/mbgl.vue'
import rwtx from '../../views/yfrh/rwtx.vue'
import rwts from '../../views/yfrh/rwts.vue'
import tjfx from '../../views/yfrh/tjfx.vue'
export default {
// import引入的组件需要注入到对象中才能使用
  components: { gztx, mbgl, rwtx, rwts, tjfx },
  data () {
  // 这里存放数据
    return {
      inx: 0,
      gztxShow: false,
      mbglShow: false,
      rwtxShow: false,
      rwtsShow: false,
      tjfxShow: false,
      tabList1: [
        {
          label: '首页',
          value: '1'
        },
        {
          label: '工作提醒',
          value: '1'
        },
        {
          label: '慢病管理',
          value: '1'
        },
        {
          label: '家签看板',
          value: '1'
        }
      ],
      tabList2: [
        {
          label: '智慧诊疗',
          value: '1'
        },
        {
          label: '任务提醒',
          value: '1'
        },
        {
          label: '进展推送',
          value: '1'
        },
        {
          label: '统计分析',
          value: '1'
        }
      ]
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    tabclick (index) {
      this.inx = index
      if (index === 7) {
        // this.$router.push('/tjfx')
        this.tjfxShow = true
      }
      if (index === 1) {
        this.gztxShow = true
      }
      if (index === 2) {
        this.mbglShow = true
      }
      if (index === 5) {
        this.rwtxShow = true
      }
      if (index === 6) {
        this.rwtsShow = true
      }
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {

  },
  beforeCreate () {}, // 生命周期 - 创建之前
  beforeMount () {}, // 生命周期 - 挂载之前
  beforeUpdate () {}, // 生命周期 - 更新之前
  updated () {}, // 生命周期 - 更新之后
  beforeDestroy () {}, // 生命周期 - 销毁之前
  destroyed () {}, // 生命周期 - 销毁完成
  activated () {} // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style>
  .tabs .el-dialog {
    background-image: url('../../assets/img/spms/bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border: 1px solid #27F6FF;
  }
</style>
<style lang='less' scoped>
//@import url(); 引入公共css类
.tabs {
  display: flex;
  color: #FFFFFF;
  justify-content: space-between;
  font-size: 14px;
  .tabsl,.tabsr {
    width: 30%;
    display: flex;
    justify-content: space-around;
  }
  .active {
    color: #15EAF3;
  }
  .active::after {
    content: '';
    position: absolute;
    bottom: -7px;
    height: 3px;
    width: 60%;
    background-color: #15EAF3;
  }
  .tabslItem, .tabsrItem {
  position: relative;
    width: 23%;
    text-align: center;
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
    span {
      font-size: 12px;
    }
  }
}
</style>
