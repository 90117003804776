<!--  -->
<template>
  <div class='headers'>
    <div class="headers-item headers-info">
      <div class="avtor">
        <img src="../../../assets/img/other/avtor.jpg" alt="">
      </div>
      <div>李明敏</div>
      <div>女</div>
      <div>电话：1831235987</div>
      <div>地址：河北省石家庄市桥西区友谊街道258号</div>
      <div class="tags">
        <el-tag type="" effect="dark">高</el-tag>
        <el-tag type="success" effect="dark">糖</el-tag>
        <el-tag type="info" effect="dark">冠</el-tag>
        <el-tag type="danger" effect="dark">肿</el-tag>
        <el-tag type="warning" effect="dark">精</el-tag>
      </div>
    </div>
    <div class="headers-item headers-search">
      <!-- <input  type="text"> -->
      <i class="el-icon-search" style="cursor: pointer;"></i>
    </div>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';

export default {
// import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
  // 这里存放数据
    return {

    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {

  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {

  },
  beforeCreate () {}, // 生命周期 - 创建之前
  beforeMount () {}, // 生命周期 - 挂载之前
  beforeUpdate () {}, // 生命周期 - 更新之前
  updated () {}, // 生命周期 - 更新之后
  beforeDestroy () {}, // 生命周期 - 销毁之前
  destroyed () {}, // 生命周期 - 销毁完成
  activated () {} // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.headers {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #0C4969;
  border-radius: 4px;
  padding: 5px 10px;
  font-size: 14px;
  &-item {
    display: flex;
    align-items: center;
    .avtor {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: #fff;
      img {
      width: 40px;
      height: 40px;
      border-radius: 50%;

      }
    }
    .tags {
      width: 200px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    div {
      margin: 0 10px;
    }
  }
  &-search {
    justify-content: end;
    width: 300px;
    input {
      width: 0px;
    }
  }
  &-search:hover {
    input {
      width: 150px;
    }
  }
}
</style>
