<!--  -->
<template>
  <div class="dialogBox">
    <el-dialog :title="dialogTitle" :visible.sync="dialogShow" @close="$emit('close-dialog')" @open="initData"
      :before-close="handleClose" width="70%">
      <el-row v-loading="loading">
        <el-table :data="tableData" height="500px" max-height="500px"  stripe
          :style="{ backgroundColor: 'rgba(0, 0, 0, 0)',border: '4px solid #024276',borderRadius: '4px'}"
            :header-row-style="headerRowStyle" :header-cell-style="headerCellStyle" :row-style="rowStyle"
            :cell-style="cellStyle">
          <el-table-column label="序号" type="index" width="50">
          </el-table-column>
          <el-table-column property="lytype" label="预警来源"></el-table-column>
          <el-table-column property="name" label="姓名"></el-table-column>
          <el-table-column property="sfzh" label="身份证号" show-overflow-tooltip></el-table-column>
          <el-table-column property="mkmc" label="服务内容" show-overflow-tooltip></el-table-column>
          <el-table-column property="msg" label="预警信息" show-overflow-tooltip></el-table-column>
          <el-table-column property="tjjgmc " label="机构名称" show-overflow-tooltip></el-table-column>
          <el-table-column property="fromUserId " label="上报人"></el-table-column>
          <el-table-column label="操作" width="150">
            <template slot-scope="scope">
              <el-button plain type="primary" size="mini" @click="edit(scope.row)">详情</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
    </el-dialog>
    <el-dialog title="详情" :visible.sync="detailShow">
    <div class="form-row-input">
      <el-form ref="searchForm" :model="personData" :inline="true" label-width="120px">
        <el-form-item label="序号">
          <el-input readonly v-model="personData.id"></el-input>
        </el-form-item>
        <el-form-item label="姓名">
          <el-input readonly v-model="personData.name"></el-input>
        </el-form-item>
        <el-form-item label="身份证号">
          <el-input readonly v-model="personData.sfzh"></el-input>
        </el-form-item>
        <el-form-item label="预警来源">
          <el-input readonly v-model="personData.lytype"></el-input>
        </el-form-item>
        <el-form-item label="预警分类">
          <el-input readonly v-model="personData.msgtype"></el-input>
        </el-form-item>
        <el-form-item label="预警模块">
          <el-input readonly v-model="personData.mkmc"></el-input>
        </el-form-item>
        <el-form-item label="服务内容">
          <el-input readonly v-model="personData.fwnr"></el-input>
        </el-form-item>
        <el-form-item label="预警信息">
          <el-input readonly v-model="personData.msg"></el-input>
        </el-form-item>
        <el-form-item label="机构名称">
          <el-input readonly v-model="personData.tjjgmc"></el-input>
        </el-form-item>
        <el-form-item label="上报人">
          <el-input readonly v-model="personData.usercode"></el-input>
        </el-form-item>
        <el-form-item label="上报时间">
          <el-input readonly v-model="personData.fwsj"></el-input>
        </el-form-item>
        <el-form-item label="处置人">
          <el-input readonly v-model="personData.czr"></el-input>
        </el-form-item>
        <el-form-item label="处置意见">
          <el-input readonly v-model="personData.czryj"></el-input>
        </el-form-item>
      </el-form>
    </div>
    </el-dialog>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  props: ['dialogVisible', 'dialogTitle', 'bjTableData'],
  data () {
    // 这里存放数据
    return {
      detailShow: false,
      loading: false,
      dialogShow: this.dialogVisible,
      tableData: this.bjTableData,
      personData: {}
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {
    dialogVisible (v) {
      this.dialogShow = v
    }
  },
  // 方法集合
  methods: {
    headerRowStyle ({ row, rowIndex }) {
      return {
        backgroundColor: 'rgba(0, 0, 0, 0)',
        color: '#27F6FF'
      }
    },
    headerCellStyle ({ row, column, rowIndex, columnIndex }) {
      return {
        backgroundColor: '#024276',
        borderBottom: 'none'
      }
    },
    rowStyle ({ row, rowIndex }) {
      return {
        backgroundColor: 'rgba(0, 0, 0, 0)',
        color: '#27F6FF'
      }
    },
    cellStyle ({ row, column, rowIndex, columnIndex }) {
      return {
        backgroundColor: 'rgba(0, 0, 0, 0)',
        borderBottom: '1px solid #27F6FF'
      }
    },
    handleClose () {
      this.$emit('close-dialog')
    },
    edit (row) {
      this.personData = row
      this.detailShow = true
    },
    handleCloseDetail () {
      this.detailShow = false
    },
    async initData () {
      this.loading = true
      this.tableData = []
      const params = {
        jgbh: this.$store.state.loginInfo.jgbh,
        msgtype: 'zhdpsyts'
      }
      const url = '/api/jkct/app/zhgwsyyj'
      const { data: res } = await this.$http.post(url, params)
      this.loading = false
      if (res.code !== 0) this.$message.error('res.msg')
      this.tableData = res.data
      // this.tableData.push({
      //   id: '',
      //   name: '',
      //   sfzh: ''
      // })
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {

  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { } // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang="less">
//引入公共css类

</style>
<style lang="less" scoped>
//@import url(); 引入公共css类
@import url("../../assets/css/resetElStyle.less");

</style>
