<!--  -->
<template>
<div class='com-container com-container1'  @dblclick="revertMap()">
    <div class="com-title">
      <div class="com-title-left" style="color:#fff;">用户分布地图</div>
    </div>
    <div class="com-chart com-chart1" ref="map_ref"></div>
  </div>
  <!-- <div class='com-container' @dblclick="revertMap()">
    <div class="com-chart" ref="map_ref"></div>
  </div> -->
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import axios from 'axios'
// import { getProvinceMapInfo } from '@/utils/map_utils'
export default {
// import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
  // 这里存放数据
    return {
      chartInstance: null, // echarts实例
      allData: null, // 图表数据
      provinceData: {} // 省份数据
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    async initChart () {
      this.chartInstance = this.$echarts.init(this.$refs.map_ref, 'chalk')
      const { data: chinares } = await axios.get('http://localhost:8999/static/map/china.json')
      window.dataList = [{
        name: '南海诸岛',
        value: 0,
        gxy: '高血压',
        gxyValue: 1000,
        gxt: '高血糖',
        gxtValue: 2000
      },
      {
        name: '北京',
        value: 54,
        gxy: '高血压',
        gxyValue: 1000,
        gxt: '高血糖',
        gxtValue: 2000
      },
      {
        name: '天津',
        value: 13,
        gxy: '高血压',
        gxyValue: 1000,
        gxt: '高血糖',
        gxtValue: 2000
      },
      {
        name: '上海',
        value: 40,
        gxy: '高血压',
        gxyValue: 1000,
        gxt: '高血糖',
        gxtValue: 2000
      },
      {
        name: '重庆',
        value: 75,
        gxy: '高血压',
        gxyValue: 1000,
        gxt: '高血糖',
        gxtValue: 2000
      },
      {
        name: '河北',
        value: 13,
        gxy: '高血压',
        gxyValue: 1000,
        gxt: '高血糖',
        gxtValue: 2000
      },
      {
        name: '河南',
        value: 83,
        gxy: '高血压',
        gxyValue: 1000,
        gxt: '高血糖',
        gxtValue: 2000
      },
      {
        name: '云南',
        value: 11,
        gxy: '高血压',
        gxyValue: 1000,
        gxt: '高血糖',
        gxtValue: 2000
      },
      {
        name: '辽宁',
        value: 19,
        gxy: '高血压',
        gxyValue: 1000,
        gxt: '高血糖',
        gxtValue: 2000
      },
      {
        name: '黑龙江',
        value: 15,
        gxy: '高血压',
        gxyValue: 1000,
        gxt: '高血糖',
        gxtValue: 2000
      },
      {
        name: '湖南',
        value: 69,
        gxy: '高血压',
        gxyValue: 1000,
        gxt: '高血糖',
        gxtValue: 2000
      },
      {
        name: '安徽',
        value: 60,
        gxy: '高血压',
        gxyValue: 1000,
        gxt: '高血糖',
        gxtValue: 2000
      },
      {
        name: '山东',
        value: 39,
        gxy: '高血压',
        gxyValue: 1000,
        gxt: '高血糖',
        gxtValue: 2000
      },
      {
        name: '新疆',
        value: 4,
        gxy: '高血压',
        gxyValue: 1000,
        gxt: '高血糖',
        gxtValue: 2000
      },
      {
        name: '江苏',
        value: 31,
        gxy: '高血压',
        gxyValue: 1000,
        gxt: '高血糖',
        gxtValue: 2000
      },
      {
        name: '浙江',
        value: 104,
        gxy: '高血压',
        gxyValue: 1000,
        gxt: '高血糖',
        gxtValue: 2000
      },
      {
        name: '江西',
        value: 36,
        gxy: '高血压',
        gxyValue: 1000,
        gxt: '高血糖',
        gxtValue: 2000
      },
      {
        name: '湖北',
        value: 1052,
        gxy: '高血压',
        gxyValue: 1000,
        gxt: '高血糖',
        gxtValue: 2000
      },
      {
        name: '广西',
        value: 33,
        gxy: '高血压',
        gxyValue: 1000,
        gxt: '高血糖',
        gxtValue: 2000
      },
      {
        name: '甘肃',
        value: 7,
        gxy: '高血压',
        gxyValue: 1000,
        gxt: '高血糖',
        gxtValue: 2000
      },
      {
        name: '山西',
        value: 9,
        gxy: '高血压',
        gxyValue: 1000,
        gxt: '高血糖',
        gxtValue: 2000
      },
      {
        name: '内蒙古',
        value: 7,
        gxy: '高血压',
        gxyValue: 1000,
        gxt: '高血糖',
        gxtValue: 2000
      },
      {
        name: '陕西',
        value: 22,
        gxy: '高血压',
        gxyValue: 1000,
        gxt: '高血糖',
        gxtValue: 2000
      },
      {
        name: '吉林',
        value: 4,
        gxy: '高血压',
        gxyValue: 1000,
        gxt: '高血糖',
        gxtValue: 2000
      },
      {
        name: '福建',
        value: 18,
        gxy: '高血压',
        gxyValue: 1000,
        gxt: '高血糖',
        gxtValue: 2000
      },
      {
        name: '贵州',
        value: 5,
        gxy: '高血压',
        gxyValue: 1000,
        gxt: '高血糖',
        gxtValue: 2000
      },
      {
        name: '广东',
        value: 98,
        gxy: '高血压',
        gxyValue: 1000,
        gxt: '高血糖',
        gxtValue: 2000
      },
      {
        name: '青海',
        value: 1,
        gxy: '高血压',
        gxyValue: 1000,
        gxt: '高血糖',
        gxtValue: 2000
      },
      {
        name: '西藏',
        value: 0,
        gxy: '高血压',
        gxyValue: 1000,
        gxt: '高血糖',
        gxtValue: 2000
      },
      {
        name: '四川',
        value: 44,
        gxy: '高血压',
        gxyValue: 1000,
        gxt: '高血糖',
        gxtValue: 2000
      },
      {
        name: '宁夏',
        value: 4,
        gxy: '高血压',
        gxyValue: 1000,
        gxt: '高血糖',
        gxtValue: 2000
      },
      {
        name: '海南',
        value: 22,
        gxy: '高血压',
        gxyValue: 1000,
        gxt: '高血糖',
        gxtValue: 2000
      },
      {
        name: '台湾',
        value: 3,
        gxy: '高血压',
        gxyValue: 1000,
        gxt: '高血糖',
        gxtValue: 2000
      },
      {
        name: '香港',
        value: 5,
        gxy: '高血压',
        gxyValue: 1000,
        gxt: '高血糖',
        gxtValue: 2000
      },
      {
        name: '澳门',
        value: 5,
        gxy: '高血压',
        gxyValue: 1000,
        gxt: '高血糖',
        gxtValue: 2000
      }
      ]
      this.$echarts.registerMap('china', chinares)
      const initOption = {
        tooltip: {
          triggerOn: 'mousemove',
          formatter: function (e, t, n) {
            return e.seriesName + '<br />' + e.data.gxy + '：' + e.data.gxyValue + '<br />' + e.data.gxt + '：' + e.data.gxtValue
            // return e.value === 0.5 ? e.name + '：有疑似病例' : e.seriesName + '<br />' + e.name + '：' + e.value
          }
        },
        visualMap: {
          min: 0,
          max: 10000,
          left: 26,
          bottom: 40,
          showLabel: !0,
          text: ['高', '低'],
          pieces: [{
            gt: 100,
            label: '> 100 人',
            color: '#FE5B5B'
          }, {
            gte: 10,
            lte: 100,
            label: '10 - 100 人',
            color: '#FFA625'
          }, {
            gte: 1,
            lt: 10,
            label: '1 - 9 人',
            color: '#259FFD'
          }, {
            gt: 0,
            lt: 1,
            label: '疑似',
            color: '#1FD1B6'
          }, {
            value: 0,
            color: '#3ED7FF'
          }],
          show: false
        },
        geo: {
          map: 'china',
          roam: !1,
          scaleLimit: {
            min: 1,
            max: 2
          },
          zoom: 1.23,
          top: 50,
          label: {
            normal: {
              show: !0,
              fontSize: '14',
              color: 'rgba(0,0,0,0.7)'
            }
          },
          itemStyle: {
            normal: {
              // shadowBlur: 50,
              // shadowColor: 'rgba(0, 0, 0, 0.2)',
              borderColor: 'rgba(0, 0, 0, 0.2)'
            },
            emphasis: {
              areaColor: '#f2d5ad',
              shadowOffsetX: 0,
              shadowOffsetY: 0,
              borderWidth: 0
            }
          }
        },
        series: [{
          name: '病例数量',
          type: 'map',
          geoIndex: 0,
          data: window.dataList
        }]
      }
      this.chartInstance.setOption(initOption)
    },
    async getData () {
      // this.$http.get()
      // const { data: res } = await this.$http.get('map')
      // // console.log(res)
      // this.allData = res
      // this.updataChart()
    },
    updataChart () {
      // // 处理图表数据
      // const legendArr = this.allData.map(item => {
      //   return item.name
      // })
      // const seriesArr = this.allData.map(item => {
      //   // 返回类别下所有散点数据
      //   // 如果想要地图中显示散点数据，就要在散点图表增加一个配置，coordinateSystem: 'geo'
      //   return {
      //     type: 'effectScatter',
      //     rippleEffect: {
      //       scale: 5,
      //       brushType: 'stroke'
      //     },
      //     name: item.name,
      //     data: item.children,
      //     coordinateSystem: 'geo'
      //   }
      // })
      // const dataOption = {
      //   legend: {
      //     data: legendArr
      //   },
      //   series: seriesArr
      // }
      // this.chartInstance.setOption(dataOption)
    },
    // 图表自适应尺寸
    screenAdapter () {
      const titleFontSize = this.$refs.map_ref.offsetWidth / 100 * 3.6
      const adapterOption = {
        title: {
          textStyle: {
            fontSize: titleFontSize
          }
        },
        legend: {
          itemWidth: titleFontSize / 2,
          itemHeight: titleFontSize / 2,
          itemGap: 10,
          textStyle: {
            fontSize: titleFontSize / 2
          }
        }
      }
      this.chartInstance.setOption(adapterOption)
      this.chartInstance.resize()
    },
    // 返回中国地图
    revertMap () {
      const revertOption = {
        geo: {
          map: 'china'
        }
      }
      this.chartInstance.setOption(revertOption)
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.initChart()
    this.getData()
    window.addEventListener('resize', this.screenAdapter)
    // 适配
    this.screenAdapter()
  },
  beforeCreate () {}, // 生命周期 - 创建之前
  beforeMount () {}, // 生命周期 - 挂载之前
  beforeUpdate () {}, // 生命周期 - 更新之前
  updated () {}, // 生命周期 - 更新之后
  beforeDestroy () {}, // 生命周期 - 销毁之前
  destroyed () {
    window.removeEventListener('resize', this.screenAdapter)
  }, // 生命周期 - 销毁完成
  activated () {} // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类

</style>
