<!-- 年度分析 -->
<template>
  <div class='com-container com-container1'>
    <div class="com-title">
      <div class="com-title-left" style="color:#fff;">机构排名</div>
    </div>
    <div class="com-chart com-chart1" ref="ref_rank"></div>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';

export default {
// import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
  // 这里存放数据
    return {
      titleFontSize: 12,
      chartInstance: null, // echarts实例
      allData: null, // 图表数据
      dataIndex: 0, // 区域缩放终点值
      timerId: null
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    async initChart () {
      this.chartInstance = this.$echarts.init(this.$refs.ref_rank, 'chalk')
      const initOption = {
        grid: {
          containLabel: true,
          left: '2%',
          top: '10%',
          right: '2%',
          bottom: '2%'
        },
        tooltip: {
          show: true
        },
        xAxis: {
          type: 'value'
        },
        yAxis: {
          type: 'category'
        },
        series: [
          {
            name: '体检人数',
            type: 'bar'
          }
        ]
      }
      this.chartInstance.setOption(initOption)
      // 鼠标移入移出操作
      // this.chartInstance.on('mouseover', () => {
      //   clearInterval(this.timerId)
      // })
      // this.chartInstance.on('mouseout', () => {
      //   this.startInterval()
      // })
    },
    async getData () {
      // const { data: res } = await this.$http.get('rank')
      this.allData = [
        {
          name: '机构1',
          value: 467
        }, {
          name: '机构2',
          value: 302
        }, {
          name: '机构3',
          value: 279
        }, {
          name: '机构4',
          value: 186
        }, {
          name: '机构5',
          value: 186
        }, {
          name: '机构6',
          value: 302
        }, {
          name: '机构7',
          value: 279
        }, {
          name: '机构8',
          value: 186
        }, {
          name: '机构9',
          value: 186
        }
      ]
      this.updataChart()
    },
    updataChart () {
      const salvProMax = []
      for (let index = 0; index < this.allData.length; index++) {
        salvProMax.push(1000)
      }
      // 颜色渐变数组
      const colorArr = [
        ['#FF9A7C', '#FB7271'],
        ['#BE91F5', '#6A49F6'],
        ['#FF5E5E', '#E02020'],
        ['#62DFFF', '#15CDFF'],
        ['#1B7AF3', '#27A6FF'],
        ['#31F5AA', '#07A0C6'],
        ['#FF943D', '#FF943D'],
        ['#70FF29', '#52C41A'],
        ['#FFB422', '#FF5C39']
      ]
      const nameArr = this.allData.map(item => {
        return item.name
      })
      const valueArr = this.allData.map(item => {
        return item.value
      })
      const dataOption = {
        xAxis: {
          show: false,
          data: valueArr
        },
        yAxis: [{
          type: 'category',
          inverse: true,
          axisLabel: {
            show: true,
            textStyle: {
              fontSize: '12',
              color: '#01F0FF'
            }
          },
          splitLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          data: nameArr
        }, {
          type: 'category',
          inverse: true,
          axisTick: 'none',
          axisLine: 'none',
          show: true,
          axisLabel: {
            textStyle: {
              color: '#01F0FF',
              fontSize: '12'
            }
          },
          data: valueArr
        }],
        series: [
          {
            // name: '值',
            type: 'bar',
            zlevel: 1,
            itemStyle: {
              normal: {
                barBorderRadius: 30,
                color: msg => {
                  return new this.$echarts.graphic.LinearGradient(0, 0, 1, 0, [{
                    offset: 0,
                    color: colorArr[msg.dataIndex][1]
                  }, {
                    offset: 1,
                    color: colorArr[msg.dataIndex][0]
                  }])
                }
              }
            },
            barWidth: this.titleFontSize * 0.5,
            data: valueArr
          },
          {
            // name: '背景',
            type: 'bar',
            barWidth: this.titleFontSize * 0.5,
            barGap: '-100%',
            data: salvProMax,
            itemStyle: {
              normal: {
                color: 'rgba(24,31,68,1)',
                barBorderRadius: 30
              }
            }
          }
        ]
      }
      this.chartInstance.setOption(dataOption)
    },
    screenAdapter () {
      const titleFontSize = this.$refs.ref_rank.offsetWidth / 100 * 3.6
      this.titleFontSize = titleFontSize
      const adapterOption = {
        title: {
          textStyle: {
            fontSize: titleFontSize
          }
        },
        series: [
          {
            type: 'bar',
            barWidth: titleFontSize * 0.5,
            itemStyle: {
              barBorderRadius: [titleFontSize * 0.25, titleFontSize * 0.25, titleFontSize * 0.25, titleFontSize * 0.25]
            }
          }, {
            type: 'bar',
            barWidth: titleFontSize * 0.5,
            itemStyle: {
              barBorderRadius: [titleFontSize * 0.25, titleFontSize * 0.25, titleFontSize * 0.25, titleFontSize * 0.25]
            }
          }
        ]
      }
      this.chartInstance.setOption(adapterOption)
      this.chartInstance.resize()
    },
    startInterval () {
      if (this.timerId) {
        clearInterval(this.timerId)
      }
      this.timerId = setInterval(() => {
        const dataArr = this.allData.map((item, index) => {
          return {
            name: item.name,
            value: Math.ceil(Math.random() * 10) * 100
          }
        })
        this.allData = dataArr
        if (this.dataIndex === 4) {
          this.dataIndex = 0
        } else {
          this.dataIndex++
        }
        this.updataChart()
        // this.screenAdapter()
      }, 3000)
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.initChart()
    this.getData()
    window.addEventListener('resize', this.screenAdapter)
    this.screenAdapter()
    this.startInterval()
  },
  beforeCreate () {}, // 生命周期 - 创建之前
  beforeMount () {}, // 生命周期 - 挂载之前
  beforeUpdate () {}, // 生命周期 - 更新之前
  updated () {}, // 生命周期 - 更新之后
  beforeDestroy () {}, // 生命周期 - 销毁之前
  destroyed () {
    window.removeEventListener('resize', this.screenAdapter)
    clearInterval(this.timerId)
  }, // 生命周期 - 销毁完成
  activated () {} // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>

<style scoped lang="less">
/* // @import url(); 引入公共css类 */

</style>
