<!--  -->
<template>
  <div class=''>
    <van-row style="background:#ffffff;margin-top:10px;padding: 10px">
      <div style="font-size: 14px; color: red;line-height: 2">
        注：<span style="color:green">√：正常</span> <span style="color: red">×</span>：缺失
      </div>
        <van-col :span="24">
          <table style="width: 100%">
            <thead style="background:#F1F2F7;font-size: 13px;">
              <td>姓名</td>
              <td>年龄</td>
              <td>日期</td>
              <td>条形码</td>
              <td @click="showStr('血常规')">{{hideStr('血常规')}}</td>
              <td @click="showStr('尿常规')">{{hideStr('尿常规')}}</td>
              <td @click="showStr('生化')">{{hideStr('生化')}}</td>
              <td @click="showStr('B超')">{{hideStr('B超')}}</td>
              <td @click="showStr('心电图')">{{hideStr('心电图')}}</td>
            </thead>
            <tbody style="font-size: 13px;" v-if="dataList.length > 0">
              <tr v-for="(item,index) in dataList" :key="index">
                <td>{{item.name}}</td>
                <td>{{item.age}}</td>
                <td>{{item.tjrq}}</td>
                <td>{{item.tmcode}}</td>
                <td :class="[item.xcgyzflag === '1'?'dui':'cuo']">{{item.xcgyzflag === '1'?'√':'×'}}</td>
                <td :class="[item.ncgyzflag === '1'?'dui':'cuo']">{{item.ncgyzflag === '1'?'√':'×'}}</td>
                <td :class="[item.shyzflag === '1'?'dui':'cuo']">{{item.shyzflag === '1'?'√':'×'}}</td>
                <td :class="[item.bcyzflag === '1'?'dui':'cuo']">{{item.bcyzflag  === '1'?'√':'×'}}</td>
                <td :class="[item.xdtyzflag === '1'?'dui':'cuo']">{{item.xdtyzflag === '1'?'√':'×'}}</td>
              </tr>
            </tbody>
            <tbody style="font-size: 13px;" v-else>
              <tr style="text-align:center">
                <td colspan="10">暂无数据</td>
              </tr>
            </tbody>
          </table>
        </van-col>
      </van-row>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';

export default {
// import引入的组件需要注入到对象中才能使用
  components: {},
  props: ['ycsjData'],
  data () {
  // 这里存放数据
    return {
      dataList: []
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {
    ycsjData (v) {
      if (v.length && v.length > 0) {
        this.dataList = v
      } else {
        this.dataList = []
      }
    }
  },
  // 方法集合
  methods: {
    showStr (str) {
      this.$toast(str)
    },
    hideStr (str) {
      if (str.length > 1) {
        return str.substring(0, 1) + '...'
      } else {
        return str
      }
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    if (this.ycsjData.length && this.ycsjData.length > 0) {
      this.dataList = this.ycsjData
    } else {
      this.dataList = []
    }
  },
  beforeCreate () {}, // 生命周期 - 创建之前
  beforeMount () {}, // 生命周期 - 挂载之前
  beforeUpdate () {}, // 生命周期 - 更新之前
  updated () {}, // 生命周期 - 更新之后
  beforeDestroy () {}, // 生命周期 - 销毁之前
  destroyed () {}, // 生命周期 - 销毁完成
  activated () {} // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
td {
  padding: 10px 0;
  text-align: center;
}
tr {
  border-bottom: 1px solid #eee;
}
table
  {
    border-collapse:collapse;
  }
  .dui {
    color: green;
  }
  .cuo {
    color: red;
  }
</style>
