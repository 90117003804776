<!-- 体检进度 -->
<template>
  <div class='hcontainer'>
    <template v-if="haveData">
      <div class="typeCard">
        <div class="typeCard-item item1" style="width:19%">
          <div>{{tjjdData.sumlnr}}</div>
          <div>老年人</div>
        </div>
        <div class="typeCard-item item2" style="width:19%">
          <div>{{tjjdData.summbrq}}</div>
          <div>慢病人群</div>
        </div>
        <div class="typeCard-item item3" style="width:23%">
          <div>{{tjjdData.sumtjrs}}</div>
          <div>体检人群</div>
        </div>
        <div class="typeCard-item item4" style="width:23%">
          <div>{{tjjdData.sumtjl}}</div>
          <div>体检率</div>
        </div>
        <div class="typeCard-item item5">
          <div>{{tjjdData.sumzytzbs}}</div>
          <div>中医体质辨识</div>
        </div>
        <div class="typeCard-item item6">
          <div>{{tjjdData.sumgxysf}}</div>
          <div>高血压随访</div>
        </div>
        <div class="typeCard-item item7">
          <div>{{tjjdData.sumtnbsf}}</div>
          <div>糖尿病随访</div>
        </div>
      </div>
      <div style="margin-top: 20px">
        <van-tabs v-model="active">
          <van-tab title="实时数据统计">
            <div class="tjCard">
              <div class="tjCard-item">
                <div class="numbers">{{tjjdData.tjjdsstjlist.sstjytjrs}}</div>
                <div class="titles">应体检人数</div>
              </div>
              <div class="tjCard-item">
                <div class="numbers">{{tjjdData.tjjdsstjlist.sstjjrtj}}</div>
                <div class="titles">今日体检人数</div>
              </div>
              <div class="tjCard-item">
                <div class="numbers">{{tjjdData.tjjdsstjlist.sstjyjwj}}</div>
                <div class="titles">应检未检人数</div>
              </div>
              <div class="tjCard-item">
                <div class="numbers">{{tjjdData.tjjdsstjlist.sstjwcl}}</div>
                <div class="titles">完成率</div>
              </div>
            </div>
            <div class="tjInfo">
              <table style="width:95%;margin:0 auto">
                <tr>
                  <td style="background:#FAFAFA;">血压异常</td>
                  <td>{{tjjdData.tjjdsstjlist.sstjxyyc}}人</td>
                  <td style="background:#FAFAFA;">肝功能异常</td>
                  <td>{{tjjdData.tjjdsstjlist.sstjggnyc}}人</td>
                </tr>
                <tr>
                  <td style="background:#FAFAFA;">血糖异常</td>
                  <td>{{tjjdData.tjjdsstjlist.sstjxtyc}}人</td>
                  <td style="background:#FAFAFA;">B超异常</td>
                  <td>{{tjjdData.tjjdsstjlist.sstjbcyc}}人</td>
                </tr>
                <tr>
                  <td style="background:#FAFAFA;">血常规异常</td>
                  <td>{{tjjdData.tjjdsstjlist.sstjxcgyc}}人</td>
                  <td style="background:#FAFAFA;">心电图异常</td>
                  <td>{{tjjdData.tjjdsstjlist.sstjxdtyc}}人</td>
                </tr>
                <tr>
                  <td style="background:#FAFAFA;">尿常规异常</td>
                  <td>{{tjjdData.tjjdsstjlist.sstjncgyc}}人</td>
                  <td style="background:#FAFAFA;">非平和质</td>
                  <td>{{tjjdData.tjjdsstjlist.sstjfphz}}人</td>
                </tr>
              </table>
            </div>
          </van-tab>
          <van-tab title="异常数据统计">
           <van-empty description="异常数据统计暂未开放" />
          </van-tab>
          <van-tab title="各机构统计">
            <van-empty description="各机构统计暂未开放" />
          </van-tab>
        </van-tabs>
      </div>
    </template>
    <template v-else>
      <van-empty description="暂无数据" />
    </template>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';

export default {
// import引入的组件需要注入到对象中才能使用
  components: {},
  props: ['tjjdData'],
  data () {
  // 这里存放数据
    return {
      haveData: false,
      active: 0
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {
    tjjdData (v) {
      if (v) {
        this.haveData = true
      }
    }
  },
  // 方法集合
  methods: {

  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    // // console.log('props', this.tjjdData)
    if (this.tjjdData) {
      this.haveData = true
    }
  }
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.hcontainer {
  padding: 0;
  background: #fff;
}
.typeCard {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding-top: 20px;
  &-item {
    width: 21%;
    height: 80px;
    font-size: 12px;
    padding-top: 25px;
    box-sizing: border-box;
    margin: 5px 0;
    border-radius: 6px;
    color: #fff;
    text-align: center;
  }
  .item1 {
    background-image: linear-gradient(135deg, rgba(250,164,102,1), rgba(250,164,102,0.5));
  }
  .item2 {
    background-image: linear-gradient(135deg, rgb(20, 233, 116), rgba(20, 233, 116,0.5));
  }
  .item3 {
    background-image: linear-gradient(135deg, rgb(185, 80, 245), rgba(185, 80, 245,0.5));
  }
  .item4 {
    background-image: linear-gradient(135deg, rgb(228, 207, 23), rgba(228, 207, 23,0.5));
  }
  .item5 {
    background-image: linear-gradient(135deg, rgb(34, 71, 235), rgba(34, 71, 235,0.5));
  }
  .item6 {
    background-image: linear-gradient(135deg, rgb(8, 191, 247), rgba(8, 191, 247,0.5));
  }
  .item7 {
    background-image: linear-gradient(135deg, rgb(238, 155, 61), rgba(238, 155, 61,0.5));
  }
}
.tjCard {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100px;
  &-item {
    text-align: center;
    .numbers {
      font-weight: 600;
      font-size: 20px;
      font-style: italic;
      padding: 10px;
    }
    .titles {
      font-size: 14px;
      color: #9b9c9e;
    }
  }
}
.tjInfo {
  font-size: 15px;
  table {
    border-collapse: collapse;
  }
  tr {
    height: 60px;
  }
  td {
    border: 1px solid #ccc;
  }
}
</style>
