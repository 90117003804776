<!-- 管理平台 -->
<template>
  <div class='container' ref="container">
    <div class="backBtn" @click="$router.go(-1)">
      返 回
    </div>
    <header class="header">
      “三高共管六病同防”医防融合服务平台
    </header>
    <div class="loginOut">
      <i style="font-size:22px" class="el-icon-switch-button" @click="loginOut"></i>
    </div>
    <div class="tab">
      <tabs></tabs>
    </div>
    <div class="screen-body">
      <div class="screen-left">
        <ssjk></ssjk>
      </div>
      <div class="screen-middle">
        <zl></zl>
      </div>
      <div class="screen-right">
        <task></task>
      </div>
    </div>
    <div class="screen-bottom">
      <div class="screen-bottom-left">
        <promp></promp>
      </div>
      <div class="screen-bottom-right">
        <manage></manage>
      </div>
    </div>

  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import tabs from '@/components/yiliao2/tabs'
import zl from '@/components/yiliao2/zl'
import ssjk from '@/components/yiliao2/ssjk'
import task from '@/components/yiliao2/task'
import promp from '@/components/yiliao2/promp'
import manage from '@/components/yiliao2/manage'
// import login from '@/components/login'
import { mapGetters } from 'vuex'
export default {
  // import引入的组件需要注入到对象中才能使用
  components: { tabs, zl, ssjk, task, promp, manage },
  data () {
    // 这里存放数据
    return {
      showFull: false,
      showLogin: false,
      dialogTableVisible: true
    }
  },
  // 监听属性 类似于data概念
  computed: {
    ...mapGetters([
      'getLoginStatus'
    ])
  },
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    loginOut () {
      this.$confirm('确定要退出登录吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.showLogin = true
        this.$store.dispatch('changeLogin', true)
        this.$store.dispatch('changeLogins', {})
        this.$router.push({ path: '/daping' })
        this.$message({
          type: 'success',
          message: '退出登录成功!'
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消退出登录'
        })
      })
    },
    // 全屏
    full () {
      this.showFull = true
      if (this.$refs.container.requestFullscreen) {
        this.$refs.container.requestFullscreen()
      } else if (this.$refs.container.mozRequestFullScreen) {
        this.$refs.container.mozRequestFullScreen()
      } else if (this.$refs.container.webkitRequestFullscreen) {
        this.$refs.container.webkitRequestFullscreen()
      } else if (this.$refs.container.msRequestFullscreen) {
        this.$refs.container.msRequestFullscreen()
      }
    },
    // 退出全屏
    exitFullscreen () {
      this.showFull = false
      if (document.exitFullScreen) {
        document.exitFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen()
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      }
    },
    login (v) {
      if (v) {
        this.showLogin = false
        this.$store.dispatch('changeLogin', false)
      }
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {

  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { } // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.container {
  width: 100%;
  height: 100%;
  background-image: url('../assets/img/spms/bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;

  .backBtn {
    width: 80px;
    height: 40px;
    background-image: url('../assets/img/spms/选择.png');
    background-repeat: no-repeat;
    background-size: 100%;
    position: absolute;
    top: 5px;
    left: 15px;
    line-height: 1.75;
    text-align: center;
    color: #FFFFFF;
    cursor: pointer;
  }

  .loginOut {
    color: #FFFFFF;
    position: absolute;
    top: 1.2%;
    right: 2rem;
    z-index: 999;
    cursor: pointer;
  }

  .screen-size {
    position: absolute;
    top: 1.8%;
    right: 6rem;
    color: white;
    cursor: pointer;
    z-index: 999;

    div {
      width: 40px;
      height: 40px;
      text-align: center;
      line-height: 1;
    }
  }

  .loginBox {
    z-index: 9999;
    position: absolute;
    width: 40%;
    height: 500px;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    animation: toptobot .5s linear;
  }

  @keyframes toptobot {
    0% {
      top: 0%;
      width: 0%;
    }

    100% {
      top: 50%;
      width: 50%;
    }
  }

  .shade {
    z-index: 999;
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.9);
  }

  .header {
    background-image: url('../assets/img/spms/header.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 72px;
    text-align: center;
    line-height: 72px;
    color: #FFFFFF;
    font-size: 26px;
    font-weight: 600;
    font-family: PangMenZhengDao;
  }

  .tab {
    width: 100%;
    position: absolute;
    top: 36px;
  }

  .screen-body {
    width: 100%;
    height: 54%;
    padding: 0 100px;
    display: flex;
    margin-top: 50px;
    box-sizing: border-box;
    margin-bottom: 1%;

    .screen-left {
      height: 100%;
      width: 26%;
    }

    .screen-middle {
      height: 100%;
      width: 48%;
      margin-left: 1.6%;
      margin-right: 1.6%;
    }

    .screen-right {
      height: 100%;
      width: 26%;
    }
  }

  .screen-bottom {
    width: 100%;
    height: 34%;
    padding: 0 100px;
    display: flex;
    justify-content: space-between;
    margin-top: 56px;
    box-sizing: border-box;

    &-left {
      width: 49%;
      height: 100%;
    }

    &-right {
      width: 49%;
      height: 100%;
    }
  }
}</style>
