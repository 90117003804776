const dic = {
  // 老年人中医药健康管理
  ZYYJK: [
    {
      label: '没有(根本不/从来没有)',
      value: '1'
    },
    {
      label: '很少(有一点/偶尔)',
      value: '2'
    },
    {
      label: '有时(有些/少数时间)',
      value: '3'
    },
    {
      label: '经常(相当/多数时间)',
      value: '4'
    },
    {
      label: '总是(非常/每天)',
      value: '5'
    }
  ],

  ZYYJKBMI: [
    {
      label: '没有(BMI＜24)',
      value: '1'
    },
    {
      label: '很少(24≤BMI＜25)',
      value: '2'
    },
    {
      label: '有时(25≤BMI＜26)',
      value: '3'
    },
    {
      label: '经常(26≤BMI＜28)',
      value: '4'
    },
    {
      label: '总是(BMI≥28)',
      value: '5'
    }
  ],
  ZYYJKGANMAO: [
    {
      label: '没有(一年＜2次)',
      value: '1'
    },
    {
      label: '很少(一年感冒2-4次)',
      value: '2'
    },
    {
      label: '有时(一年感冒5-6次)',
      value: '3'
    },
    {
      label: '经常(一年8次以上)',
      value: '4'
    },
    {
      label: '总是(几乎每月)',
      value: '5'
    }
  ],
  ZYYJKGUOMIN: [
    {
      label: '从来没有',
      value: '1'
    },
    {
      label: '很少(一年1、2次)',
      value: '2'
    },
    {
      label: '有时(一年3、4次)',
      value: '3'
    },
    {
      label: '经常(一年5、6次)',
      value: '4'
    },
    {
      label: '每次遇到上述原因都过敏',
      value: '5'
    }
  ],
  ZYYJKFUBU: [
    {
      label: '腹围<80cm，相当于2.4尺',
      value: '1'
    },
    {
      label: '腹围80-85cm，2.4-2.55尺',
      value: '2'
    },
    {
      label: '腹围86-90cm，2.56-2.7尺',
      value: '3'
    },
    {
      label: '腹围1-105cm，2.71-3.15尺',
      value: '4'
    },
    {
      label: '腹围>105cm或3.15尺',
      value: '5'
    }
  ],
  TZBB: [
    {
      label: '是',
      value: '1'
    },
    {
      label: '倾向是',
      value: '2'
    },
    {
      label: '否',
      value: '3'
    }
  ],
  BJZD: [
    {
      label: '情志调摄',
      value: '1'
    },
    {
      label: '饮食调养',
      value: '2'
    },
    {
      label: '起居调摄',
      value: '3'
    },
    {
      label: '运动保健',
      value: '4'
    },
    {
      label: '穴位保健',
      value: '5'
    },
    {
      label: '其他',
      value: '6'
    }
  ]
}
export default dic
