<template>
  <!-- 读取医疗影像(dcm文件) -->
  <div class="app">
    <div id="dicom_canvas" ref="dicom_canvas" style="height: 300px;width:300px" class="imgContainer"></div>
  </div>
</template>

<script>
import cornerstone from 'cornerstone-core'
import * as dicomParser from 'dicom-parser'
import * as cornerstoneWADOImageLoader from 'cornerstone-wado-image-loader'
export default {
  data () {
    return {}
  },
  mounted () {
    this.initCornerstoneTools()
  },
  methods: {
    // 初始化标注工具
    initCornerstoneTools () {
      var element = document.getElementById('dicom_canvas')
      cornerstone.enable(element)
      cornerstoneWADOImageLoader.external.dicomParser = dicomParser
      cornerstoneWADOImageLoader.external.cornerstone = cornerstone
      cornerstoneWADOImageLoader.configure({ beforeSend: function (xhr) { } })
      const url = 'wadouri: http://jkct.oss-cn-beijing.aliyuncs.com/dcm/1.2.840.887072.1.9.1.1.20230602193419.392.172160131(2).dcm'
      this.loadAndViewImage(url)
    },
    loadAndViewImage (imageId) {
      var element = document.getElementById('dicom_canvas')
      // eslint-disable-next-line no-useless-catch
      try {
        cornerstone.loadAndCacheImage(imageId).then(
          image => {
            var viewport = cornerstone.getDefaultViewportForImage(element, image)
            cornerstone.displayImage(element, image, viewport)
          },
          function (err) {
            throw err
          }
        )
      } catch (err) {
        throw err
      }
    }
  }
}
</script>

<style>
.app {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #333;
}
</style>
