export function getExpectDate (nowTime = '', type = null, number = 0) {
  const nowdate = new Date(nowTime)
  let y; let m; let d; let retrundate; let weekdate = null
  switch (type) {
    case 'day': // 取number天前、后的时间
      nowdate.setTime(nowdate.getTime() + (24 * 3600 * 1000) * number)
      y = nowdate.getFullYear()
      m = nowdate.getMonth() + 1
      d = nowdate.getDate()
      retrundate = y + '/' + m + '/' + d
      break
    case 'week': // 取number周前、后的时间
      weekdate = new Date(nowdate + (7 * 24 * 3600 * 1000) * number)
      y = weekdate.getFullYear()
      m = weekdate.getMonth() + 1
      d = weekdate.getDate()
      retrundate = y + '/' + m + '/' + d
      break
    case 'month': // 取number月前、后的时间
      nowdate.setMonth(nowdate.getMonth() + number)
      y = nowdate.getFullYear()
      m = nowdate.getMonth() + 1
      d = nowdate.getDate()
      retrundate = y + '/' + m + '/' + d
      break
    case 'year': // 取number年前、后的时间
      nowdate.setFullYear(nowdate.getFullYear() + number)
      y = nowdate.getFullYear()
      m = nowdate.getMonth() + 1
      d = nowdate.getDate()
      retrundate = y + '/' + m + '/' + d
      break
    default: // 取当前时间
      y = nowdate.getFullYear()
      m = nowdate.getMonth() + 1
      d = nowdate.getDate()
      retrundate = y + '/' + m + '/' + d
  }
  return retrundate
}
// 函数参数必须是字符串，因为二代身份证号码是十八位，而在javascript中，十八位的数值会超出计算范围，造成不精确的结果，导致最后两位和计算的值不一致，从而该函数出现错误。
// 详情查看javascript的数值范围
export function checkIDCard (idcode) {
  // 加权因子
  var weightFactor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]
  // 校验码
  var checkCode = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2']

  var code = idcode + ''
  var last = idcode[17]// 最后一位

  var seventeen = code.substring(0, 17)

  // ISO 7064:1983.MOD 11-2
  // 判断最后一位校验码是否正确
  var arr = seventeen.split('')
  var len = arr.length
  var num = 0
  for (var i = 0; i < len; i++) {
    num = num + arr[i] * weightFactor[i]
  }

  // 获取余数
  var resisue = num % 11
  var lastNo = checkCode[resisue]

  // 格式的正则
  // 正则思路
  /*
  第一位不可能是0
  第二位到第六位可以是0-9
  第七位到第十位是年份，所以七八位为19或者20
  十一位和十二位是月份，这两位是01-12之间的数值
  十三位和十四位是日期，是从01-31之间的数值
  十五，十六，十七都是数字0-9
  十八位可能是数字0-9，也可能是X
  */
  var idcardPatter = /^[1-9][0-9]{5}([1][9][0-9]{2}|[2][0][0|1][0-9])([0][1-9]|[1][0|1|2])([0][1-9]|[1|2][0-9]|[3][0|1])[0-9]{3}([0-9]|[X])$/

  // 判断格式是否正确
  var format = idcardPatter.test(idcode)

  // 返回验证结果，校验码和格式同时正确才算是合法的身份证号码
  return !!(last === lastNo && format)
}
