<template>
  <div class='login'>
    <div class="title">
      <span>智慧慢病综合管理系统</span>
      <span class="span">welcome to system</span>
    </div>
    <form class="form">
      <div class="form-title">
        <span class="el-icon-d-arrow-right"></span>
        <span class="span">系统登录</span>
        <span class="el-icon-d-arrow-left"></span>
      </div>
      <div class="form-input">
        <div class="input-span">
          <span>账号</span>
        </div>
        <input v-model="username" type="text" placeholder="请输入用户名" autocomplete>
      </div>
      <div class="form-input">
        <div class="input-span">
          <span>密码</span>
        </div>
        <input v-model="password" type="password" placeholder="请输入密码" autocomplete>
      </div>
      <div class="form-input1">
        <div class="input-code">
          <input v-model="code" type="text" placeholder="请输入验证码" autocomplete>
        </div>
        <div class="input-number">
          <span>7538</span>
        </div>
      </div>
      <div class="input-label">
        <el-checkbox v-model="checked">记住密码</el-checkbox>
      </div>
      <div class="form-btn">
        <div class="button">
          <div class="border"></div>
        </div>
        <div class="button" @click="login">
          <span>ENTER</span>
        </div>
      </div>
    </form>

  </div>
</template>
<script>
import { mapState } from 'vuex'
import qs from 'qs'
export default {
  components: {},
  data () {
    return {
      username: '',
      password: '',
      tabIndex: 1,
      dialogTableVisible: true,
      isLoging: false,
      code: '',
      checked: false
    }
  },
  computed: {
    ...mapState(['loginInfo', 'SocketInfo'])
  },
  methods: {
    changeTab (val) {
      this.tabIndex = val
      this.$store.commit('ChageLoginType', val)
    },
    async login () {
      if (!this.username) return this.$message.error('请填写正确的用户名！')
      if (!this.password) return this.$message.error('请填写正确的密码！')
      if (this.isLoging) return
      this.isLoging = true
      const params = {
        username: this.username,
        password: this.password,
        type: 3,
        code: '',
        jgbh: '',
        openid: '',
        gwbz: '',
        unionid: ''
      }
      try {
        console.log(qs.stringify(params))
        const { data: res } = await this.$http.post('/api/wx/loginin', qs.stringify(params), { headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8' } })
        console.log(res)
        this.isLoging = false
        if (res.code !== '200') return this.$message.error(res.msg || res.message)
        localStorage.setItem('token', res.token)
        this.$store.dispatch('changeLogins', res)
        this.$router.push('/zhyl')
        // this.$emit('child-login', true)
      } catch (error) {
        console.log('------', error)
        this.isLoging = false
      }
    },
    throttle (fn, delay) {
      let timer = null
      return function () {
        const context = this
        const args = arguments
        if (!timer) {
          timer = setTimeout(function () {
            fn.apply(context, args)
            timer = null
          }, delay)
        }
      }
    },
    loginSocket () {
      try {
        // 创建WebSocket连接
        let code = ''
        if (!this.code) {
          code = parseInt(Math.random() * 100000) + ''
          this.code = code.padStart(5, '0')
        }
        this.$store.commit('ChangeSocketCode', this.code)
        console.log(this.code)
        this.socket = new WebSocket('ws://zhtj.zkwl.cc:63000/api/WebSocketwx/' + this.loginInfo.jgbh + this.SocketCode + '/')
        // console.log(socket)

        this.$store.commit('ChangeSocketInfo', this.socket)

        // 连接成功时的回调函数
        this.socket.onopen = function () {
          console.log('WebSocket连接已打开')
          this.reconnectAttempts = 0
          // 发送消息
          // socket.send('Hello WebSocket')

          // setTimeout(() => {
          //   socket.close()
          // }, 2000)
        }

        // 监听接收到消息事件
        this.socket.onmessage = data => {
          console.log('WebSocket监听消息:', data)
          if (data.data && data.data.length > 5) {
            this.$store.commit('ChangeSocketData', JSON.parse(data.data))
          }
        }
        // 连接错误
        this.socket.onerror = error => {
          console.error('WebSocket连接错误:', error)
          this.$store.commit('ChangeSocketInfo', null)
        }

        // 关闭连接
        this.socket.onclose = () => {
          console.log('WebSocket连接已关闭')
          console.log('将在60秒后尝试重新连接...')
          this.$store.commit('ChangeSocketInfo', null)
          setTimeout(this.loginSocket, 60000) // 等待一段时间后重新连接
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.login {
  width: 100%;
  height: 100%;
  position: relative;
  animation: hidetoshow 2s linear;

  @keyframes hidetoshow {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    text-align: center;
    letter-spacing: 6px;
    color: #fff;
    font-size: 40px;
    font-weight: 700;

    .span {
      letter-spacing: 2px;
      color: #fff;
      font-size: 14px;
      font-weight: bold;
    }
  }
  .form {
    display: flex;
    flex-direction: column;
    width: 532px;
    height: 434px;
    margin: 13px auto 0;
    padding: 0 70px;
    background-image: url('../assets/img/login1.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    box-sizing: border-box;

    .form-title {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 89px;
      padding-top: 20px;
      box-sizing: border-box;
      color: #4B9CF5;
      font-size: 20px;

      .span {
        margin: 0 7px;
      }
    }

    &-input {
      display: flex;
      align-items: center;
      width: 100%;
      height: 40px;
      margin: 11px 0;
      background-image: url('../assets/img/input.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;

      input {
        height: 30px;
        margin-left: 26px;
        background: none;
        outline: none;
        border: none;
        color: #fff;
        font-size: 14px;
      }

      input::placeholder {
        color: #8FA9C8;
      }

      .input-span {
        width: 96px;
        height: 21px;
        line-height: 21px;
        text-align: center;
        border-right: 1px solid #fff;
        box-sizing: border-box;
        color: #fff;
        font-size: 16px;
      }
    }

    .form-input1 {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 40px;
      margin: 11px 0;

      .input-code {
        display: flex;
        align-items: center;
        width: 164px;
        height: 40px;
        background-image: url('../assets/img/input.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }

      input {
        height: 30px;
        margin-left: 26px;
        background: none;
        outline: none;
        border: none;
        color: #fff;
        font-size: 14px;
      }

      input::placeholder {
        color: #8FA9C8;
      }

      .input-number {
        width: 86px;
        height: 40px;
        line-height: 40px;
        margin-left: 10px;
        text-align: center;
        letter-spacing: 2px;
        background-image: url('../assets/img/input.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        color: #fff;
        font-size: 14px;
      }
    }

    .input-label {
      margin: 11px 0;

      /deep/.el-checkbox__label {
        letter-spacing: 3px;
        color: #fff;
        font-size: 14px;
      }
    }

    &-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 60px;

      .button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 104px;
        height: 43px;
        margin: 0 5px;
        letter-spacing: 2px;
        background-color: #4B9CF5;
        transform: skew(-30deg);
        color: #fff;
        font-size: 14px;
        cursor: pointer;

        .border {
          width: 23px;
          height: 8px;
          margin-right: 10px;
          border-right: 2px solid #fff;
          border-bottom: 1px solid #fff;
          transform: skew(60deg);
        }

        &:last-child {
          width: 203px;
        }
      }
    }
  }

}
</style>
