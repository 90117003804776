<!-- 慢病管理 -->
<template>
  <div class='manage'>
    <div class="header">慢病管理</div>
    <div class="top-tabs">
      <div class="top-tabs-item" :class="{select:topbtn==1}" @click="topbtn=1">血压监测</div>
      <div class="top-tabs-item" style="margin: 0 15px;" :class="{select:topbtn==2}" @click="topbtn=2">血糖监测</div>
      <div class="top-tabs-item" :class="{select:topbtn==3}" @click="topbtn=3">血脂监测</div>
    </div>
    <div class="chart-box">
      <div class="chart-left">
        <div class="chart-left-item">
          <div class="item-left">
            <div style="color: #fff;">指标1</div>
            <div class="borderB">{{allData.zb1.renshu || 'null'}} <span  style="color: #fff;font-size:12px">人</span></div>
            <div>同比: {{allData.zb1.tongbi || 'null'}}% ↑</div>
          </div>
          <div class="pie">
            <pie  :chart-data="pieData[0]"></pie>
          </div>
        </div>
        <div class="chart-left-item">
          <div class="item-left">
            <div style="color: #fff;">指标2</div>
            <div class="borderB">{{allData.zb2.renshu || 'null'}} <span style="color: #fff;font-size:12px">人</span></div>
            <div>同比: {{allData.zb2.tongbi || 'null'}}% ↑</div>
          </div>
          <div class="pie">
            <pie  :chart-data="pieData[1]"></pie>
          </div>
        </div>
        <div class="chart-left-item">
          <div class="item-left">
            <div style="color: #fff;">指标3</div>
            <div class="borderB">{{allData.zb3.renshu || 'null'}} <span style="color: #fff;font-size:12px">人</span></div>
            <div>同比: {{allData.zb3.tongbi || 'null'}}% ↑</div>
          </div>
          <div class="pie">
            <pie  :chart-data="pieData[2]"></pie>
          </div>
        </div>
      </div>
      <div class="chart-right">
        <lines :lineData="allData"></lines>
      </div>
    </div>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import pie from './pie.vue'
import lines from './line.vue'
export default {
// import引入的组件需要注入到对象中才能使用
  components: { pie, lines },
  data () {
  // 这里存放数据
    return {
      allData: {
        data1: [],
        data2: [],
        data3: [],
        zb1: {},
        zb2: {},
        zb3: {}
      },
      allDatas: [],
      topbtn: 1,
      timerId: null,
      dataIndex: 0,
      pieData: []
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {
    'topbtn' (v) {
      if (v === 1) {
        this.allData = this.allDatas[0].xueya
        this.pieData = [this.allData.zb1.chartData, this.allData.zb2.chartData, this.allData.zb3.chartData]
      } else if (v === 2) {
        this.allData = this.allDatas[1].xueta
        this.pieData = [this.allData.zb1.chartData, this.allData.zb2.chartData, this.allData.zb3.chartData]
      } else if (v === 3) {
        this.allData = this.allDatas[2].bmi
        this.pieData = [this.allData.zb1.chartData, this.allData.zb2.chartData, this.allData.zb3.chartData]
      }
      this.startInterval()
    }
  },
  // 方法集合
  methods: {
    async initData () {
      const params = {
        jgbh: this.$store.state.loginInfo.jgbh,
        jbmc: this.$store.state.loginInfo.jgmc,
        ssjgbhmc: this.$store.state.loginInfo.label,
        usercode: this.$store.state.loginInfo.usercode,
        username: this.$store.state.loginInfo.username,
        jgjb: this.$store.state.loginInfo.jgjb,
        ssjgbh: this.$store.state.loginInfo.value,
        sjjgbh: this.$store.state.loginInfo.sjjgbh
      }
      // 暂时屏蔽
      // const { data: res } = await this.$http.post('api/jkct/app/zhdpyfrhsymbgl', params)
      // if (res.code !== 0) return this.$message.error(res.msg)
      // this.allDatas = res.data
      // this.allData = this.allDatas[0].xueya
      // this.pieData = [this.allData.zb1.chartData, this.allData.zb2.chartData, this.allData.zb3.chartData]
    },
    startInterval () {
      if (this.timerId) {
        clearInterval(this.timerId)
      }
      this.timerId = setInterval(() => {
        if (this.topbtn < 3) {
          this.topbtn++
        } else if (this.topbtn === 3) {
          this.topbtn = 1
        }
      }, 3000)
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.initData()
    this.startInterval()
  },
  beforeCreate () {}, // 生命周期 - 创建之前
  beforeMount () {}, // 生命周期 - 挂载之前
  beforeUpdate () {}, // 生命周期 - 更新之前
  updated () {}, // 生命周期 - 更新之后
  beforeDestroy () {}, // 生命周期 - 销毁之前
  destroyed () {}, // 生命周期 - 销毁完成
  activated () {} // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类

.manage {
  width: 100%;
  height: 100%;
  background-image: url('../../assets/img/spms/workwarn.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  .header {
    text-align: center;
    color: #ffffff;
    font-size: 14px;
    font-weight: bold;
  }
  .top-tabs {
    display: flex;
    padding: 0 15px;
    margin-bottom: 15px;
    .top-tabs-item {
      width: 120px;
      text-align: center;
      background-image: url('../../assets/img/spms/btn-default.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: center;
      padding: 3px 0;
      font-size: 14px;
      color: #81AFD6;
      cursor: pointer;
    }
    .select {
      background-image: url('../../assets/img/spms/btn-select.png');
      color: #27F6FF;
    }
  }
  .chart-box {
    width: 100%;
    height: calc(100% - 70px);
    box-sizing: border-box;
    padding: 0 15px;
    display: flex;
    .chart-left {
      width: 30%;
      color: #15EAF3;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      &-item {
        font-size: 14px;
        height: 31%;
        display: flex;
        justify-content: space-around;
        background-image: url('../../assets/img/spms/rect.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: center;
        .item-left {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          .borderB {
            font-size: 16px;
            border-bottom: 1px solid #27F6FF;
          }
        }
        .pie {
          height: 80px;
          width: 80px;
        }
      }
    }
    .chart-right {
      width: 66%;
      margin-left: 2%;
    }
  }
}
</style>
