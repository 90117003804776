<!--  -->
<template>
  <div class='container-three com-container1'>
    <div class="com-title">
      <div class="com-title-left">血压、血糖监测实况</div>
    </div>
    <div class="container-three-top">
      <jcrspie></jcrspie>
      <jcrsbar1></jcrsbar1>
    </div>
    <div class="container-three-bottom">
      <jcrsbar2></jcrsbar2>
    </div>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import jcrspie from '@/components/yiliao/jcrs-pie'
import jcrsbar1 from '@/components/yiliao/jcrs-bar1'
import jcrsbar2 from '@/components/yiliao/jcrs-bar2'
export default {
// import引入的组件需要注入到对象中才能使用
  components: {
    jcrspie: jcrspie,
    jcrsbar1: jcrsbar1,
    jcrsbar2: jcrsbar2
  },
  data () {
  // 这里存放数据
    return {

    }
  },
  // 监听属性 类似于data概念
  computed: {
  },
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {

  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {

  },
  beforeCreate () {}, // 生命周期 - 创建之前
  beforeMount () {}, // 生命周期 - 挂载之前
  beforeUpdate () {}, // 生命周期 - 更新之前
  updated () {}, // 生命周期 - 更新之后
  beforeDestroy () {}, // 生命周期 - 销毁之前
  destroyed () {}, // 生命周期 - 销毁完成
  activated () {} // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
  .container-three {
    height: 100%;
    width: 100%;
    &-top {
      width: 100%;
      height: 50%;
      display: flex;
    }
    &-bottom {
      width: 100%;
      height: 50%;
    }
  }
</style>
