<template>
  <div id="app">
    <!-- 路由占位符 -->
      <router-view/>
      <div class="app-message" v-if="AppMessageInfo.showMessage">
        <div class="app-box">
          <div class="app-head">
            <span>系统提示</span>
          </div>
          <div class="app-tip">
            <span>{{ AppMessageInfo.messageInfo || '异常' }}</span>
          </div>
          <div class="app-bottom">
            <div class="app-left">
              <img src="./assets/img/wechatcode.png" alt="" class="app-img">
              <span>微信扫码，直连客服反馈</span>
            </div>
            <el-button type="primary" @click="close">继续使用</el-button>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState(['AppMessageInfo'])
  },
  methods: {
    close () {
      this.$store.commit('ChangeAppMessageInfo', {
        showMessage: false,
        messageInfo: ''
      })
    }
  }
}
</script>

<style lang="less">
* {
  // scrollbar-width: none;
}

#app {
  position: relative;
}

.app-message {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .7);

  .app-box {
    width: 50%;
    // height: 50%;
    padding: 40px;
    background-color: #fff;
    border-radius: 12px;

    .app-head {
      display: flex;
      align-items: center;
      width: 100%;
      height: 60px;
      padding: 20px;
      color: #000;
      font-size: 18px;;
    }

    .app-tip {
      width: 90%;
      height: 100px;
      line-height: 100px;
      margin: 20px auto;
      padding: 0 20px;
      text-align: center;
      border: 1px solid rgb(165, 165, 165);
      box-sizing: border-box;
      font-size: 16px;
      overflow: hidden;/*超出文本隐藏*/
      text-overflow:ellipsis; /*超出部分省略号显示 */
      white-space: nowrap;
    }

    .app-bottom {
      display: flex;
      align-items: flex-end;
      justify-content: space-evenly;
      width: 100%;
      height: 200px;
      padding: 0 40px;
      box-sizing: border-box;

      .app-left {
        display: flex;
        align-items: flex-end;
        font-size: 16px;

        .app-img {
          width: 100px;
          height: 100px;
          margin-right: 20px;
          background-color: #fff;
        }
      }
    }
  }
}
</style>
