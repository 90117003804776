import { render, staticRenderFns } from "./xsjg.vue?vue&type=template&id=7c181ea6&scoped=true&"
import script from "./xsjg.vue?vue&type=script&lang=js&"
export * from "./xsjg.vue?vue&type=script&lang=js&"
import style0 from "./xsjg.vue?vue&type=style&index=0&id=7c181ea6&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c181ea6",
  null
  
)

export default component.exports