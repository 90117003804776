<!--  -->
<template>
  <div class='' style="padding: 0 15px;">
    <div style="height:30px"> </div>
    <van-form @submit="onSubmit">
      <van-field
        v-model="name"
        name="姓名"
        label="姓名"
        placeholder="姓名"
        :rules="[{ required: true, message: '请填写姓名' }]"
      />
      <van-field
        v-model="idNum"
        name="身份证号"
        label="身份证号"
        placeholder="身份证号"
        :rules="[{ required: true, message: '请填写身份证号' }]">
        <template #right-icon>
          <van-uploader v-model="fileList" :after-read="afterRead" :preview-image="false">
            <van-icon name="scan" />
          </van-uploader>
        </template>
      </van-field>
      <div style="margin: 16px;">
        <van-button round block type="info" native-type="submit">提交</van-button>
      </div>
    </van-form>
    <van-notify v-model="show" type="success">
      <van-icon name="bell" style="margin-right: 4px;" />
      <span>绑定成功</span>
    </van-notify>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import { checkIDCard } from '../../utils/utils'
export default {
// import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
  // 这里存放数据
    return {
      name: '',
      idNum: '',
      fileList: [],
      openid: '',
      unionid: '',
      show: false,
      idCardurl: '',
      checkIDCard: checkIDCard
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    async onSubmit (values) {
      if (!this.checkIDCard(this.idNum)) {
        return this.$message.error('身份证格式不正确！')
      }
      const url = 'api/wx/SaveWxappinfo'
      const params = {
        openId: this.openid,
        unionid: this.unionid,
        realName: this.name,
        idCard: this.idNum,
        idCardurl: this.idCardurl
      }
      const { data: res } = await this.$http.post(url, params)
      // // console.log(res)
      if (res.code === 0) {
        this.$message.success('绑定成功！')
      } else if (res.code === -99) {
        this.$message.warning(res.msg)
      } else {
        this.$message.error(res.msg)
      }
    },
    async afterRead (file) {
      // 此时可以自行将文件上传至服务器
      const url = 'files/uploadfilehsysqm'
      const params = {
        jgbh: '',
        fileData: file.content.replace(/^data:image\/\w+;base64,/, ''),
        qmtype: ''
      }
      const { data: res } = await this.$http.post(url, params)
      if (res.code !== 0) {
        this.$message.error(res.msg)
      }
      this.idCardurl = res.data.sfztpurl
      this.sfzsb(res.data)
    },
    async sfzsb (data) {
      const url = '/api/wx/findwxinfoocr'
      const params = {
        imageurl: data.sfztpurl,
        openid: this.openid,
        unionid: this.unionid,
        ocrtype: 'sfz'
      }
      const { data: res } = await this.$http.post(url, params)
      if (res.code !== 0) {
        this.$message.error(res.msg)
      } else if (res.code === -100) {
        this.name = res.data.name
        this.idNum = res.data.sfzh
        this.$message.success(res.msg)
      } else {
        this.$message.success('识别成功!')
        this.name = res.data.name
        this.idNum = res.data.sfzh
      }
    },
    getQueryString (name) {
      var search = window.location.search.substring(1)
      if (!search) {
        search = window.location.hash.split('?')[1]
      }
      if (search) {
        var obj = JSON.parse('{"' + decodeURIComponent(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
        return name ? obj[name] : obj
      }
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.openid = this.getQueryString('openid')
    this.unionid = this.getQueryString('unionid')
    // // console.log(this.openid)
    // // console.log(this.unionid)
  }
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类

</style>
