<template>
  <div class="after">
    <div class="after-box">
      <div class="after-head">
        <div class="after-text"></div>
        <div class="after-text">
          <span>编辑</span>
        </div>
        <div class="after-close" @click="close">
          <span class="el-icon-close"></span>
        </div>
      </div>
      <div class="after-button">
        <el-button>新增工单</el-button>
        <el-button>工单记录</el-button>
      </div>
      <div class="after-content">
        <div class="after-item" v-for="(item, index) in 12" :key="index">
          <div class="after-line">
            <span class="after-span">工程师信息</span>
            <span>序号1</span>
          </div>
          <div class="after-info">
            <div class="after-info-left"></div>
            <div class="after-info-right">
              <span>姓名：-</span>
              <span>微信：-</span>
              <span>服务电话：-</span>
            </div>
          </div>
          <el-button>评价</el-button>
        </div>
      </div>
      <div class="after-footer">
        <el-button class="gray" @click="close">取消</el-button>
        <el-button @click="close">关闭</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
  .after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 88;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .82);

    .after-box {
      overflow: hidden;
      width: 80%;
      height: 90%;
      background-color: #fff;
      border-radius: 6px;

      .after-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 45px;
        padding: 0 21px;
        background-color: #006EFF;
        box-sizing: border-box;

        .after-text {
          color: #fff;
          font-size: 18px;
          font-weight: bold;
        }

        .after-close {
          color: #fff;
          font-size: 22px;
          cursor: pointer;
        }
      }

      .after-button {
        display: flex;
        align-items: center;
        width: 100%;
        height: 60px;
        padding: 0 20px;
        box-sizing: border-box;

        .el-button {
          margin-right: 30px;
          padding: 4px 15px;
          background-color: #006EFF;
          border-radius: 3px;
          color: #fff;
          font-size: 18px;
        }
      }

      .after-content {
        overflow-y: scroll;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        height: calc(100% - 175px);
        padding: 0 10px;
        box-sizing: border-box;

        &::-webkit-scrollbar {
          width: 0;
        }

        .after-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-evenly;
          width: calc(25% - 20px);
          height: 208px;
          margin: 0 10px;
          margin-bottom: 20px;
          padding: 0 15px;
          background-color: #006EFF;
          border-radius: 6px;
          box-sizing: border-box;

          .after-line {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            color: #fff;
            font-size: 14px;

            .after-span {
              font-size: 18px;
              font-weight: bold;
            }
          }

          .after-info {
            display: flex;
            width: 100%;
            height: 113px;
            border-bottom: 1px solid #fff;

            .after-info-left {
              width: 80px;
              height: 100px;
              background-color: #fff;
            }

            .after-info-right {
              display: flex;
              flex-direction: column;
              justify-content: space-evenly;
              width: calc(100% - 90px);
              height: 100px;
              margin-left: 10px;
              color: #fff;
              font-size: 16px;
            }
          }

          .el-button {
            background-color: #539AF8;
            border: none;
            padding: 4px 9px;
            color: #fff;
            font-size: 16px;
          }
        }
      }

      .after-footer {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 70px;

        .el-button {
          margin-right: 30px;
          padding: 5px 35px;
          background-color: #006EFF;
          border-radius: 6px;
          color: #fff;
          font-size: 22px;
        }

        .gray {
          background-color: #999999;
        }
      }
    }
  }
</style>
