<!--  -->
<template>
  <div class='com-container'>
    <van-row>
      <van-col class="my-row" span="24">
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
          <van-swipe-item>智慧公卫1</van-swipe-item>
          <van-swipe-item>智慧公卫2</van-swipe-item>
          <van-swipe-item>智慧公卫3</van-swipe-item>
        </van-swipe>
      </van-col>
    </van-row>
    <van-row style="margin-top: 10px;">
      <van-col class="my-row" span="24">
        <van-form @submit="onSubmit">
          <van-field
            v-model="patientid"
            name="patientid"
            label="用户名"
            placeholder="用户名"
            :rules="[{ required: true, message: '请填写用户名' }]"
          />
          <van-field
            v-model="password"
            type="password"
            name="password"
            label="密码"
            placeholder="密码"
            :rules="[{ required: true, message: '请填写密码' }]"
          />
          <van-field
            v-model="role"
            name="role"
            label="选择角色"
            readonly
            placeholder="选择角色"
            @click="roleShow = !roleShow"
            :rules="[{ required: true, message: '选择角色' }]"
          />
          <!-- <van-field
            v-model="org"
            name="org"
            label="选择机构"
            readonly
            placeholder="选择机构"
            @click="orgShow = !orgShow"
            :rules="[{ required: true, message: '请选择机构' }]"
          /> -->
          <div style="font-size: 13px; color: red;text-align:center">
            注：绑定公卫账号后，数据会上传到公卫系统！
          </div>
          <div style="margin: 16px;" v-if="showUserInfo">
            <van-button round block type="info" native-type="submit">绑定</van-button>
          </div>
          <div style="margin: 16px;" v-if="!showUserInfo">
            <van-button round block type="info" native-type="button" @click="checkInfo">验证</van-button>
          </div>
        </van-form>
        <div class="resultCard" v-if="showUserInfo">
          <div class="title">查询结果 <span>已绑定</span></div>
          <div class="item"><span>公卫账号：</span>{{userInfo.usercode}}</div>
          <div class="item"><span>公卫用户名</span>{{userInfo.username}}</div>
          <div class="item"><span>所属区域：</span>{{userInfo.ssqy}}</div>
          <div class="item"><span>上级机构：</span>{{userInfo.sjjgmc}}</div>
          <div class="item"><span>所属机构：</span>{{userInfo.ssjgmc}}</div>
          <div class="item"><span>责任医生：</span>{{userInfo.zrys}}</div>
        </div>
        <div class="resultCard" v-if="showResult">
          <div class="title">查询结果 <span>已绑定</span></div>
          <div class="item"><span>公卫账号：</span>{{userInfo.usercode}}</div>
          <div class="item"><span>公卫用户名</span>{{userInfo.username}}</div>
          <div class="item"><span>所属区域：</span>{{userInfo.ssqy}}</div>
          <div class="item"><span>上级机构：</span>{{userInfo.sjjgmc}}</div>
          <div class="item"><span>所属机构：</span>{{userInfo.ssjgmc}}</div>
          <div class="item"><span>责任医生：</span>{{userInfo.zrys}}</div>
        </div>
      </van-col>
    </van-row>
    <van-dialog v-model="confirmShow" title="是否绑定" show-cancel-button @confirm="bindConfirm">
      <div class="resultCard">
        <div class="title">查询结果</div>
          <div class="item"><span>公卫账号：</span>{{cehckUserInfo.usercode}}</div>
          <div class="item"><span>公卫用户名</span>{{cehckUserInfo.username}}</div>
        <div class="item"><span>所属区域：</span>{{cehckUserInfo.ssqy}}</div>
        <div class="item"><span>上级机构：</span>{{cehckUserInfo.sjjgmc}}</div>
        <div class="item"><span>所属机构：</span>{{cehckUserInfo.ssjgmc}}</div>
        <div class="item"><span>责任医生：</span>{{cehckUserInfo.zrys}}</div>
      </div>
    </van-dialog>
    <van-popup round position="bottom" v-model="orgShow">
      <div class="content">
        <van-picker show-toolbar title="选择机构" value-key="label" :columns="orgColumns" @cancel="orgShow = !orgShow" @change="orgChange" @confirm="orgConfirm" />
      </div>
    </van-popup>
    <van-popup round position="bottom" v-model="roleShow">
      <div class="content">
        <van-picker show-toolbar title="选择角色" value-key="label" :columns="roleColumns" @cancel="roleShow = !roleShow" @change="roleChange" @confirm="roleConfirm" />
      </div>
    </van-popup>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
// import { getQueryVariable } from '@/utils/h5/util'
export default {
// import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
  // 这里存放数据
    return {
      patientid: '',
      password: '',
      org: '',
      orgValue: '',
      role: '',
      roleValue: '',
      confirmShow: false,
      orgShow: false,
      roleShow: false,
      roleColumns: [
        {
          label: '公卫',
          value: '01'
        },
        {
          label: '村医',
          value: '02'
        },
        {
          label: '化验室',
          value: '03'
        },
        {
          label: 'B超',
          value: '04'
        },
        {
          label: '心电图',
          value: '05'
        },
        {
          label: '医疗',
          value: '06'
        }
      ],
      cehckUserInfo: {
        ssqy: '',
        ssjgmc: '',
        zrys: ''
      },
      userInfo: {
        ssqy: '区域',
        ssjgmc: '机构名称',
        zrys: '张三'
      },
      showUserInfo: false,
      showResult: false,
      orgColumns: [],
      username: '',
      openId: '',
      jgbh: '',
      unionid: '',
      lytype: ''
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    // {"username":"17633240490","openId":"oO1_5wJ6kuhprcvTq0_p42aGGOFg","jgbh":"130430203000","unionid":"oel8W1SVd_A3jXPcf-F5uE6VTkjI"}
    async binduserfind () {
      this.$toast.loading({
        duration: 0,
        message: '加载中...',
        forbidClick: true
      })
      const params = { username: '', openId: this.openId, unionid: this.unionid, jgbh: '' }
      const { data: res } = await this.$http.post('/api/wx/binduserfind', params)
      this.$toast.clear()
      // this.orgColumns = res.data.children
      if (res.code === 0) {
        this.showUserInfo = true
        this.userInfo = res.data
      } else {
        this.showUserInfo = false
      }
    },
    async findjglist () {
      this.$toast.loading({
        duration: 0,
        message: '加载中...',
        forbidClick: true
      })
      const params = {
        jgbh: '130430203000',
        jgjb: '4'
      }
      const { data: res } = await this.$http.post('ykdjgxxs/findjglist', params)
      this.$toast.clear()
      this.orgColumns = res.data
    },
    orgConfirm (value, index) {
      this.org = value.label
      this.orgValue = value.value
      this.orgShow = false
    },
    orgChange (picker, value, index) {

    },
    roleConfirm (value, index) {
      this.role = value.label
      this.roleValue = value.value
      this.roleShow = false
    },
    roleChange (picker, value, index) {

    },
    async checkInfo () {
      this.$toast.loading({
        duration: 0,
        message: '加载中...',
        forbidClick: true
      })
      const params = {
        username: this.patientid, // 类型：String  必有字段  备注：用户名
        ptusername: this.patientid, // 类型：String  必有字段  备注：平台用户名
        password: this.password, // 类型：String  必有字段  备注：用户密码
        ptpassword: this.password, // 类型：String  必有字段  备注：平台密码
        lytype: this.lytype // 类型：String  必有字段  备注：1普通密码调整，2平台密码调整
      }
      const { data: res } = await this.$http.post('dock/wxsfyz', params)
      this.$toast.clear()
      if (res.code !== -99) {
        // // console.log(res)
        this.cehckUserInfo = res.data
        this.confirmShow = !this.confirmShow
      } else {
        this.$message.error(res.msg)
      }
    },
    bindConfirm () {
      this.onSubmit()
      // // console.log('绑定成功！')
    },
    async onSubmit () {
      this.$toast.loading({
        duration: 0,
        message: '加载中...',
        forbidClick: true
      })
      const params = {
        lytype: this.lytype,
        patientid: this.patientid, // 类型：String  必有字段  备注：用户名
        openId: this.openId, // 类型：String  必有字段  备注：微信openid
        // ssjgbh: this.orgValue, // 类型：String  必有字段  备注：所属机构
        // jgbh: this.jgbh, // 类型：String  必有字段  备注：机构编号
        unionid: this.unionid, // 类型：String  必有字段  备注：微信unionid
        password: this.password, // 类型：String  必有字段  备注：密码
        ryfl: this.roleValue
      }
      const { data: res } = await this.$http.post('/api/wx/bindusersave', params)
      this.$toast.clear()
      if (res.code === 0) {
        this.showResult = true
        this.patientid = ''
        this.password = ''
        this.role = ''
        this.$toast('绑定成功')
      } else {
        this.$toast(res.msg)
      }
    },
    getQueryString (name) {
      var search = window.location.search.substring(1)
      if (!search) {
        search = window.location.hash.split('?')[1]
      }
      if (search) {
        var obj = JSON.parse('{"' + decodeURIComponent(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
        return name ? obj[name] : obj
      }
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  async mounted () {
    this.username = this.getQueryString('username')
    this.openId = this.getQueryString('openId')
    this.jgbh = this.getQueryString('jgbh')
    this.unionid = this.getQueryString('unionid')
    await this.binduserfind()
    // this.findjglist()
  },
  beforeCreate () {}, // 生命周期 - 创建之前
  beforeMount () {}, // 生命周期 - 挂载之前
  beforeUpdate () {}, // 生命周期 - 更新之前
  updated () {}, // 生命周期 - 更新之后
  beforeDestroy () {}, // 生命周期 - 销毁之前
  destroyed () {}, // 生命周期 - 销毁完成
  activated () {} // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.com-container {
  background-color: #F2F2F2;
}
.van-row {
  padding: 20px;
  background-color: #ffffff;
}
.my-swipe .van-swipe-item {
    color: #fff;
    font-size: 20px;
    line-height: 150px;
    text-align: center;
    background-color: #39a9ed;
}
.resultCard {
  text-align: left;
  .title {
    font-size: 15px;
    font-weight: 600;
    padding: 10px 16px;
  }
  .item {
    font-size: 14px;
    padding: 5px 16px;
    color: rgb(107, 106, 106);
  }
  span {
    display: inline-block;
    width: 6.2em;
  }
}
</style>
