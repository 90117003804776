<template>
  <div class="form">
    <div class="form-box">
      <div class="form-close">
        <span class="el-icon-close"></span>
      </div>
      <div class="form-title">
        <span>0-3岁男童生长发育监测图</span>
      </div>
      <div class="form-head">
        <span>姓名：程霞</span>
        <span>编号：</span>
      </div>
      <div class="form-scroll">
        <div class="echarts" id="echarts"></div>
        <div class="echarts" id="echarts1"></div>
      </div>
      <div class="form-button">
        <el-button type="primary" size="medium">确认</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {
      tabIndex: 0,
      tabs: Object.freeze(['新生儿随访', '1-8月龄', '12-30 月龄', '3-6岁', '男童生长监测图', '女童生长监测图']),
      sex: '0',
      checks: [],
      myChart: null,
      myChart1: null
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.canvas()
      this.canvas1()
    })
    window.addEventListener('resize', this.getresize)
  },
  beforeDestroy () {
    // 页面销毁时销毁监听事件
    window.removeEventListener('resize', this.getresize)
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    },
    getresize () {
      this.myChart.resize()
      this.myChart1.resize()
    },
    canvas () {
      var chartDom = document.getElementById('echarts')
      this.myChart = this.$echarts.init(chartDom)
      const option = {
        xAxis: {
          type: 'category',
          data: ['1/4', '1/24', '1/31', '2/4', '2/14', '2/18', '3/1'],
          axisLabel: {
            show: true,
            color: '#01EEFD',
            fontSize: 15
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#01EEFD'
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#01EEFD'
            }
          }
        },
        yAxis: {
          type: 'value',
          name: '身高（cm）',
          nameTextStyle: {
            color: '#01EEFD',
            nameLocation: 'start'
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#01EEFD'
            }
          },
          axisLabel: {
            show: true,
            color: '#01EEFD',
            fontSize: 15
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#01EEFD'
            }
          }
        },
        series: [
          {
            data: [150, 230, 224, 218, 135, 147, 260],
            type: 'line',
            itemStyle: {
              color: '#FF0000', // 改变折线点的颜色
              lineStyle: {
                color: '#FF0000' // 改变折线颜色
              }
            }
          }
        ]
      }

      option && this.myChart.setOption(option)
    },
    canvas1 () {
      var chartDom = document.getElementById('echarts1')
      this.myChart1 = this.$echarts.init(chartDom)
      const option = {
        xAxis: {
          type: 'category',
          data: ['0', '2', '4', '6', '8', '10', '12'],
          axisLabel: {
            show: true,
            color: '#01EEFD',
            fontSize: 15
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#01EEFD'
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#01EEFD'
            }
          }
        },
        yAxis: {
          type: 'value',
          name: '体重（kg）',
          nameTextStyle: {
            color: '#01EEFD',
            nameLocation: 'start'
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#01EEFD'
            }
          },
          axisLabel: {
            show: true,
            color: '#01EEFD',
            fontSize: 15
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#01EEFD'
            }
          }
        },
        series: [
          {
            data: [150, 230, 224, 218, 135, 147, 260],
            type: 'line',
            itemStyle: {
              color: '#FF0000', // 改变折线点的颜色
              lineStyle: {
                color: '#FF0000' // 改变折线颜色
              }
            }
          }
        ]
      }

      option && this.myChart1.setOption(option)
    }
  }
}
</script>

<style lang="less" scoped>
.form {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 888;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .9);

  .form-box {
    position: relative;
    width: 80%;
    height: 95%;
    padding: 0 73px;
    border: 4px solid #21D6E0;
    box-sizing: border-box;

    .form-close {
      position: absolute;
      right: 30px;
      height: 50px;
      line-height: 50px;
      color: #fff;
      font-size: 20px;
      cursor: pointer;
    }

    .form-title {
      width: 100%;
      height: 60px;
      line-height: 60px;
      text-align: center;
      color: #01EEFD;
      font-size: 22px;
    }

    .form-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 50px;
      color: #01EEFD;
      font-size: 22px;
    }

    .form-scroll {
      overflow-y: scroll;
      width: 100%;
      height: calc(100% - 180px);

      .echarts {
        width: 100%;
        height: 300px;
        margin-bottom: 20px;
      }
    }

    .form-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 70px;
    }
  }
}
</style>
