<!--  -->
<template>
  <div class="fwjl-index">
    <headers></headers>
    <div class="content">
      <div class="content-abs">
        <div class="content-abs-title">患者摘要</div>
        <div class="content-abs-list">
          <div class="content-abs-list-item" v-for="(item, index) in absList" :key="index">
            <div class="content-abs-list-item-title">{{ item.title }}</div>
            <div class="content-abs-list-item-info">
              <div class v-for="(info, index) in item.list" :key="index">{{ info.title }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="content-tables">
        <div class="content-tables-table">
          <div class="content-tables-table-title">诊疗服务</div>
          <el-table :data="zlfwTableData" max-height="250px" stripe
            :style="{ backgroundColor: 'rgba(0, 0, 0, 0)', border: '4px solid #024276', borderRadius: '4px' }"
            :header-row-style="headerRowStyle" :header-cell-style="headerCellStyle" :row-style="rowStyle"
            :cell-style="cellStyle">
            <el-table-column property="time" label="就诊时间"></el-table-column>
            <el-table-column property="type" label="就诊类型"></el-table-column>
            <el-table-column property="jzjg" label="就诊机构"></el-table-column>
            <el-table-column property="jgjb" label="机构级别"></el-table-column>
            <el-table-column property="zd" label="诊断"></el-table-column>
          </el-table>
        </div>
        <div class="content-tables-table" style="width: 30%;">
          <div class="content-tables-table-title">检查记录</div>
          <el-table :data="jcjlTableData" max-height="250px" stripe
            :style="{ backgroundColor: 'rgba(0, 0, 0, 0)', border: '4px solid #024276', borderRadius: '4px' }"
            :header-row-style="headerRowStyle" :header-cell-style="headerCellStyle" :row-style="rowStyle"
            :cell-style="cellStyle">
            <el-table-column property="time" label="报告时间"></el-table-column>
            <el-table-column property="name" label="项目名称"></el-table-column>
          </el-table>
        </div>
        <div class="content-tables-table" style="width: 30%;margin: 0 5%;">
          <div class="content-tables-table-title">检验记录</div>
          <el-table :data="jcjlTableData" max-height="250px" stripe
            :style="{ backgroundColor: 'rgba(0, 0, 0, 0)', border: '4px solid #024276', borderRadius: '4px' }"
            :header-row-style="headerRowStyle" :header-cell-style="headerCellStyle" :row-style="rowStyle"
            :cell-style="cellStyle">
            <el-table-column property="time" label="报告时间"></el-table-column>
            <el-table-column property="name" label="项目名称"></el-table-column>
          </el-table>
        </div>
        <div class="content-tables-table" style="width: 30%;">
          <div class="content-tables-table-title">用药记录</div>
          <el-table :data="yyjlTableData" max-height="250px" stripe
            :style="{ backgroundColor: 'rgba(0, 0, 0, 0)', border: '4px solid #024276', borderRadius: '4px' }"
            :header-row-style="headerRowStyle" :header-cell-style="headerCellStyle" :row-style="rowStyle"
            :cell-style="cellStyle">
            <el-table-column property="time" label="报告时间"></el-table-column>
            <el-table-column property="name" label="项目名称"></el-table-column>
          </el-table>
        </div>
        <div class="content-tables-table" style="width: 30%;">
          <div class="content-tables-table-title">高血压管理</div>
          <el-table :data="gxyglTableData" max-height="250px" stripe
            :style="{ backgroundColor: 'rgba(0, 0, 0, 0)', border: '4px solid #024276', borderRadius: '4px' }"
            :header-row-style="headerRowStyle" :header-cell-style="headerCellStyle" :row-style="rowStyle"
            :cell-style="cellStyle">
            <el-table-column property="time" label="报告时间"></el-table-column>
            <el-table-column property="name" label="项目名称"></el-table-column>
          </el-table>
        </div>
        <div class="content-tables-table" style="width: 30%;margin: 0 5%;">
          <div class="content-tables-table-title">高血压管理</div>
          <el-table :data="tnbglTableData" max-height="250px" stripe
            :style="{ backgroundColor: 'rgba(0, 0, 0, 0)', border: '4px solid #024276', borderRadius: '4px' }"
            :header-row-style="headerRowStyle" :header-cell-style="headerCellStyle" :row-style="rowStyle"
            :cell-style="cellStyle">
            <el-table-column property="time" label="报告时间"></el-table-column>
            <el-table-column property="name" label="项目名称"></el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import headers from './headers.vue'
export default {
  // import引入的组件需要注入到对象中才能使用
  components: { headers },
  data () {
    // 这里存放数据
    return {
      absList: [
        {
          title: '诊断',
          list: [
            {
              title: '便秘：2次',
              icon: 'icon-jiancha'
            },
            {
              title: '带状疱疹：3次',
              icon: 'icon-daizao'
            }
          ]
        },
        {
          title: '体征',
          list: [
            {
              title: '血压：122/83mmHg',
              icon: 'icon-jiancha'
            },
            {
              title: '血糖：3.2mmol',
              icon: 'icon-daizao'
            },
            {
              title: 'BMI:24.46',
              icon: 'icon-daizao'
            }
          ]
        },
        {
          title: '生活习惯',
          list: [
            {
              title: '吸烟状况：无',
              icon: 'icon-jiancha'
            },

            {
              title: '饮食状况：嗜糖',
              icon: 'icon-jiancha'
            },
            {
              title: '饮酒状况：从不',
              icon: 'icon-jiancha'
            },
            {
              title: '运动状况：0次/周，0分钟/次',
              icon: 'icon-jiancha'
            }
          ]
        },
        {
          title: '门诊用药',
          list: [
            {
              title: '2022-08-03：阿司匹林胶囊',
              icon: 'icon-jiancha'
            },
            {
              title: '2022-08-03：阿司匹林胶囊',
              icon: 'icon-daizao'
            }
          ]
        },
        {
          title: '异常检测项目',
          list: [
            {
              title: '2022-08-03：CT常规检查',
              icon: 'icon-jiancha'
            }
          ]
        },
        {
          title: '异常检验',
          list: [
            {
              title: '2022-08-03：生化全套',
              icon: 'icon-jiancha'
            }
          ]
        }
      ],
      zlfwTableData: [
        {
          time: '2022-08-03',
          type: '门诊',
          jzjg: 'FED',
          jgjb: '二级医院',
          zd: '高血压'
        },
        {
          time: '2022-08-03',
          type: '门诊',
          jzjg: 'FED',
          jgjb: '二级医院',
          zd: '高血压'
        },
        {
          time: '2022-08-03',
          type: '门诊',
          jzjg: 'FED',
          jgjb: '二级医院',
          zd: '高血压'
        },
        {
          time: '2022-08-03',
          type: '门诊',
          jzjg: 'FED',
          jgjb: '二级医院',
          zd: '高血压'
        }
      ],
      jcjlTableData: [
        {
          time: '2022-08-03',
          name: '超声常规检查'
        },
        {
          time: '2022-08-03',
          name: '超声常规检查'
        },
        {
          time: '2022-08-03',
          name: '超声常规检查'
        },
        {
          time: '2022-08-03',
          name: '超声常规检查'
        }
      ],
      jyjlTableData: [
        {
          time: '2022-08-03',
          name: '超声常规检查'
        },
        {
          time: '2022-08-03',
          name: '超声常规检查'
        },
        {
          time: '2022-08-03',
          name: '超声常规检查'
        },
        {
          time: '2022-08-03',
          name: '超声常规检查'
        }
      ],
      yyjlTableData: [
        {
          time: '2022-08-03',
          name: '阿司匹林胶囊'
        },
        {
          time: '2022-08-03',
          name: '阿司匹林胶囊'
        },
        {
          time: '2022-08-03',
          name: '阿司匹林胶囊'
        },
        {
          time: '2022-08-03',
          name: '阿司匹林胶囊'
        }
      ],
      gxyglTableData: [
        {
          time: '2020(3次)',
          name: '随访登记'
        },
        {
          time: '2020(3次)',
          name: '随访登记'
        },
        {
          time: '2020(3次)',
          name: '随访登记'
        },
        {
          time: '2020(3次)',
          name: '随访登记'
        }
      ],
      tnbglTableData: [
        {
          time: '2020(3次)',
          name: '随访登记'
        },
        {
          time: '2020(3次)',
          name: '随访登记'
        },
        {
          time: '2020(3次)',
          name: '随访登记'
        },
        {
          time: '2020(3次)',
          name: '随访登记'
        }
      ]
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    headerRowStyle ({ row, rowIndex }) {
      return {
        backgroundColor: 'rgba(0, 0, 0, 0)',
        color: '#27F6FF'
      }
    },
    headerCellStyle ({ row, column, rowIndex, columnIndex }) {
      return {
        backgroundColor: '#024276',
        borderBottom: 'none',
        padding: '6px 0'
      }
    },
    rowStyle ({ row, rowIndex }) {
      return {
        backgroundColor: 'rgba(0, 0, 0, 0)',
        color: '#27F6FF'
      }
    },
    cellStyle ({ row, column, rowIndex, columnIndex }) {
      // if(rowIndex == data.length - 1) {
      //   return {
      //     backgroundColor: 'rgba(0, 0, 0, 0)',
      //     borderBottom: 'none',
      //   }
      // }else {
      return {
        backgroundColor: 'rgba(0, 0, 0, 0)',
        borderBottom: '1px solid #27F6FF',
        padding: '6px 0'
      }
      // }
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () { },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { } // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang="less"></style>
<style lang='less' scoped>
//@import url(); 引入公共css类
.fwjl-index {
  .content {
    margin-top: 10px;
    font-size: 14px;
    display: flex;

    .content-abs {
      width: 220px;
      padding: 0 15px;
      background-color: #0c4969;
      border-radius: 4px;

      &-title {
        padding: 6px 0;
        border-bottom: 1px solid #27f4ff80;
        font-size: 15px;
      }

      &-list {
        padding: 10px 0;

        &-item {
          &-title {
            font-size: 15px;
            margin-bottom: 10px;
          }

          &-title::before {
            content: "";
            width: 3px;
            height: 13px;
            color: #27f6ff;
            margin-right: 5px;
            // vertical-align: middle;
            background: #27f6ff;
            display: inline-block;
          }

          &-info {
            margin-left: 6px;

            div {
              margin-bottom: 10px;
            }
          }
        }
      }
    }

    .content-tables {
      flex: 1;
      padding: 0 20px;
      display: flex;
      flex-wrap: wrap;

      &-table {
        width: 100%;

        &-title {
          font-size: 15px;
          border-bottom: 1px solid #27f4ff;
          margin-bottom: 6px;
          padding: 6px;
        }
      }
    }
  }
}
</style>
