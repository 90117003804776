import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'
import daping from '@/views/daping'
import spms from '@/views/spms'
import tjfx from '@/views/yfrh/tjfx'
import login from '@/views/login'
// 手机

import tjDetail from '@/views/h5/jktj/tjDetail'
import htjdn from '@/views/h5/jktj/index'
import hlnr from '@/views/h5/jktj/lnr'
import hlnrwj from '@/views/h5/jktj/lnrwj'
import htjjd from '@/views/h5/tjjd'
import bind from '@/views/h5/bind'
import search from '@/views/h5/search'
import hidbind from '@/views/h5/idBind'
// 智慧养老
import Nprogress from 'nprogress'
import 'nprogress/nprogress.css'

import zhyl from '@/views/zhyl/index'
import Run from '@/views/run/run.vue'
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/daping'
  },
  {
    path: '/404',
    component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/notOpen/notOpen.vue')
  },
  {
    path: '/run',
    component: Run,
    meta: { title: '智慧公卫医防融合管理平台' }
  },
  {
    path: '/daping',
    component: daping,
    meta: { title: '三高共管六病同防”医防融合服务平台' }
  },
  {
    path: '/chart',
    component: () => import(/* webpackChunkName: "runkanban" */ '@/views/chart.vue'),
    meta: { title: '三高共管六病同防”医防融合服务平台' }
  },
  {
    path: '/spms',
    component: spms,
    meta: { title: '管理平台' }
  },
  {
    path: '/login',
    component: login,
    meta: { title: '登录' }
  },
  {
    path: '/tjfx',
    component: tjfx,
    meta: { title: '统计分析' }
  },
  // 手机版
  {
    path: '/bind',
    component: bind,
    meta: { title: '医生绑定' }
  },
  {
    path: '/search',
    component: search,
    meta: { title: '查询' }
  },
  {
    path: '/htjdn',
    component: htjdn,
    meta: { title: '健康档案' }
  },
  {
    path: '/tjDetail',
    component: tjDetail,
    meta: { title: '健康档案' }
  },
  {
    path: '/htjjd',
    component: htjjd,
    meta: { title: '体检进度' }
  },
  {
    path: '/hlnr',
    component: hlnr,
    meta: { title: '老年人中医药健康管理服务' }
  },
  {
    path: '/hlnrwj',
    component: hlnrwj,
    meta: { title: '老年人问卷调查' }
  },
  {
    path: '/hidbind',
    component: hidbind,
    meta: { title: '绑定' }
  },
  { // 智慧养老
    path: '/zhyl',
    component: zhyl,
    redirect: '/runkanban',
    children: [
      { // 首页 健康看板
        path: '/runkanban',
        component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/runkanban/runkanban.vue')
      },
      { // 智慧医疗
        path: '/smarthealthcare',
        component: () => import(/* webpackChunkName: "smartHealthcare" */ '@/views/zhyl/smartHealthcare/smartHealthcare.vue'),
        redirect: '/smarthealthcare/index',
        children: [
          { // 看板
            path: '/smarthealthcare/index',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartHealthcare/index.vue')
          },
          { // 智慧问诊
            path: '/smartconsultation',
            component: () => import(/* webpackChunkName: "homeSign" */ '@/views/zhyl/smartConsultation/smartConsultation.vue')
          },
          { // 两病管理 首页
            path: '/smarthealthcare/twoDiseaseManagement',
            component: () => import(/* webpackChunkName: "bultrasonic" */ '@/views/zhyl/smartHealthcare/twoDiseaseManagement/twoDiseaseManagement.vue')
          },
          { // 两病管理 申报审批管理
            path: '/smarthealthcare/applicationapprovalmanagement',
            component: () => import(/* webpackChunkName: "bultrasonic" */ '@/views/zhyl/smartHealthcare/twoDiseaseManagement/applicationApprovalManagement/applicationApprovalManagement.vue')
          },
          { // 两病管理 待遇资质信息
            path: '/smarthealthcare/benefitsQualificationManagement',
            component: () => import(/* webpackChunkName: "bultrasonic" */ '@/views/zhyl/smartHealthcare/twoDiseaseManagement/benefitsQualificationManagement/benefitsQualificationManagement.vue')
          },
          { // 两病管理 系统管理
            path: '/smarthealthcare/systemRun',
            component: () => import(/* webpackChunkName: "bultrasonic" */ '@/views/zhyl/smartHealthcare/twoDiseaseManagement/systemRun/systemRun.vue')
          },
          { // 远程B超
            path: '/bultrasonic',
            component: () => import(/* webpackChunkName: "bultrasonic" */ '@/views/zhyl/telemedicine/bUltrasonic.vue')
          },
          { // 远程医疗 心电
            path: '/ecg',
            component: () => import(/* webpackChunkName: "bultrasonic" */ '@/views/zhyl/telemedicine/ecg.vue')
          },
          { // 远程医疗 影像
            path: '/image',
            component: () => import(/* webpackChunkName: "bultrasonic" */ '@/views/zhyl/telemedicine/image.vue')
          },
          { // 会诊
            path: '/consultation',
            component: () => import(/* webpackChunkName: "consultation" */ '@/views/zhyl/consultation/consultation.vue')
          },
          { // 转诊
            path: '/referral',
            component: () => import(/* webpackChunkName: "referral" */ '@/views/zhyl/referral/referral.vue')
          },
          { // 互联网医院 问诊管理
            path: '/smarthealthcare/consultationManagement',
            component: () => import(/* webpackChunkName: "referral" */ '@/views/zhyl/smartHealthcare/consultationManagement/consultationManagement.vue')
          },
          { // 互联网医院 药品订单管理
            path: '/smarthealthcare/drugordermanagement',
            component: () => import(/* webpackChunkName: "referral" */ '@/views/zhyl/smartHealthcare/drugOrderManagement/drugOrderManagement.vue')
          },
          { // 互联网医院 医生管理
            path: '/smarthealthcare/doctormanagement',
            component: () => import(/* webpackChunkName: "referral" */ '@/views/zhyl/smartHealthcare/doctorManagement/doctorManagement.vue')
          },
          { // 互联网医院 财务管理
            path: '/smarthealthcare/financialmanagement',
            component: () => import(/* webpackChunkName: "referral" */ '@/views/zhyl/smartHealthcare/financialManagement/financialManagement.vue')
          },
          { // 互联网医院 运营管理
            path: '/smarthealthcare/operationmanagement',
            component: () => import(/* webpackChunkName: "referral" */ '@/views/zhyl/smartHealthcare/operationManagement/operationManagement.vue')
          },
          { // 互联网医院 就诊人管理
            path: '/smarthealthcare/encountermgmt',
            component: () => import(/* webpackChunkName: "referral" */ '@/views/zhyl/smartHealthcare/encounterMgmt/encounterMgmt.vue')
          },
          { // 互联网医院 运营管理
            path: '/smarthealthcare/operationrun',
            component: () => import(/* webpackChunkName: "referral" */ '@/views/zhyl/smartHealthcare/operationRun/operationRun.vue')
          },
          { // 互联网医院 药房管理
            path: '/smarthealthcare/pharmacymanagement',
            component: () => import(/* webpackChunkName: "referral" */ '@/views/zhyl/smartHealthcare/pharmacyManagement/pharmacyManagement.vue')
          },
          { // 互联网医院 药品管理
            path: '/smarthealthcare/drugmanagement',
            component: () => import(/* webpackChunkName: "referral" */ '@/views/zhyl/smartHealthcare/drugManagement/drugManagement.vue')
          },
          { // 互联网医院 系统管理
            path: '/smarthealthcare/systemmanagement',
            component: () => import(/* webpackChunkName: "ncd" */ '@/views/zhyl/smartHealthcare/systemManagement/systemManagement.vue')
          },
          { // 药品管理 药品管理
            path: '/smarthealthcare/drugrun',
            component: () => import(/* webpackChunkName: "ncd" */ '@/views/zhyl/runManage/drugRun/drugRun.vue')
          },
          { // 药品管理 疾病管理
            path: '/smarthealthcare/diseaserun',
            component: () => import(/* webpackChunkName: "ncd" */ '@/views/zhyl/runManage/diseaseRun/diseaseRun.vue')
          },
          { // 疾病管理
            path: '/smarthealthcare/diseaseruns',
            component: () => import(/* webpackChunkName: "ncd" */ '@/views/zhyl/runManage/diseaseRun/diseaseRun.vue')
          },
          { // 药品管理 处方管理
            path: '/smarthealthcare/reciperun',
            component: () => import(/* webpackChunkName: "ncd" */ '@/views/zhyl/runManage/recipeRun/recipeRun.vue')
          },
          { // 处方管理
            path: '/smarthealthcare/reciperuns',
            component: () => import(/* webpackChunkName: "ncd" */ '@/views/zhyl/runManage/recipeRun/recipeRun.vue')
          },
          { // 药品管理 科室管理
            path: '/smarthealthcare/roomrun',
            component: () => import(/* webpackChunkName: "ncd" */ '@/views/zhyl/runManage/roomRun/roomRun.vue')
          },
          { // 科室管理
            path: '/smarthealthcare/roomruns',
            component: () => import(/* webpackChunkName: "ncd" */ '@/views/zhyl/runManage/roomRun/roomRun.vue')
          },
          { // 药品管理 医生管理
            path: '/smarthealthcare/doctorrun',
            component: () => import(/* webpackChunkName: "ncd" */ '@/views/zhyl/runManage/doctorRun/doctorRun.vue')
          },
          { // 医生管理
            path: '/smarthealthcare/doctorruns',
            component: () => import(/* webpackChunkName: "ncd" */ '@/views/zhyl/runManage/doctorRun/doctorRun.vue')
          },
          { // 药品管理 医院管理
            path: '/smarthealthcare/hospitalrun',
            component: () => import(/* webpackChunkName: "ncd" */ '@/views/zhyl/runManage/hospitalRun/hospitalRun.vue')
          },
          { // 医院管理
            path: '/smarthealthcare/hospitalruns',
            component: () => import(/* webpackChunkName: "ncd" */ '@/views/zhyl/runManage/hospitalRun/hospitalRun.vue')
          },
          { // 药品管理 订单管理
            path: '/smarthealthcare/listrun',
            component: () => import(/* webpackChunkName: "ncd" */ '@/views/zhyl/runManage/listRun/listRun.vue')
          },
          { // 订单管理
            path: '/smarthealthcare/orderrun/requirementupload',
            component: () => import(/* webpackChunkName: "ncd" */ '@/views/zhyl/smartHealthcare/orderRun/requirementUpload/requirementUpload.vue')
          },
          { // 订单管理 群众购药需求上报
            path: '/smarthealthcare/listruns',
            component: () => import(/* webpackChunkName: "ncd" */ '@/views/zhyl/runManage/listRun/listRun.vue')
          }
        ]
      },
      { // 智慧公卫
        path: '/smartpublicguard',
        component: () => import(/* webpackChunkName: "smartPublicGuard" */ '@/views/zhyl/smartPublicGuard/smartPublicGuard.vue'),
        redirect: '/smartpublicguard/index',
        children: [
          // { // 看板
          //   path: '/smartpublicguard/index',
          //   component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartPublicGuard/index.vue')
          // },
          { // 首页
            path: '/smartpublicguard/index',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartPhysicalExamination/index/index.vue')
          },
          { // 智慧慢病
            path: '/smartpublicguard/ncd',
            component: () => import(/* webpackChunkName: "ncd" */ '@/views/zhyl/ncd/ncd.vue'),
            redirect: '/smartpublicguard/patientrun',
            children: [
              { // 患者管理
                path: '/smartpublicguard/patientrun',
                component: () => import(/* webpackChunkName: "ncd" */ '@/views/zhyl/ncd/patientRun/patientRun.vue')
              },
              {
                // 数据管理
                path: '/smartpublicguard/inforun',
                component: () => import(/* webpackChunkName: "ncd" */ '@/views/zhyl/ncd/infoRun/infoRun.vue')
              },
              {
                // 门诊管理
                path: '/smartpublicguard/outpatientrun',
                component: () => import(/* webpackChunkName: "ncd" */ '@/views/zhyl/ncd/outpatientRun/outpatientRun.vue')
              },
              {
                // 智能提醒
                path: '/smartpublicguard/smartreminder',
                component: () => import(/* webpackChunkName: "ncd" */ '@/views/zhyl/ncd/smartReminder/smartReminder.vue')
              },
              {
                // 质控管理
                path: '/smartpublicguard/controlrun',
                component: () => import(/* webpackChunkName: "ncd" */ '@/views/zhyl/ncd/controlRun/controlRun.vue')
              },
              {
                // 设备管理
                path: '/smartpublicguard/devicerun',
                component: () => import(/* webpackChunkName: "ncd" */ '@/views/zhyl/ncd/deviceRun/deviceRun.vue')
              },
              {
                // 系统管理
                path: '/smartpublicguard/systemrun',
                component: () => import(/* webpackChunkName: "ncd" */ '@/views/zhyl/ncd/systemRun/systemRun.vue')
              }
            ]
          },
          { // 老年人健康管理
            path: '/smartpublicguard/oldrun',
            component: () => import(/* webpackChunkName: "ncd" */ '@/views/zhyl/intelligentMonitoring/manageArchives/manageArchives.vue')
          },
          { // 健康体检管理 体检管理
            path: '/smartpublicguard/healthtestrun/healthtest',
            component: () => import(/* webpackChunkName: "ncd" */ '@/views/zhyl/smartPublicGuard/healthTestRun/healthTest/healthTest.vue')
          },
          { // 健康体检管理 体检报告
            path: '/smartpublicguard/healthtestrun/healthreport',
            component: () => import(/* webpackChunkName: "ncd" */ '@/views/zhyl/smartPublicGuard/healthTestRun/healthReport/healthReport.vue')
          },
          { // 健康体检管理 数据上传
            path: '/smartpublicguard/healthtestrun/dataupload',
            component: () => import(/* webpackChunkName: "ncd" */ '@/views/zhyl/smartPublicGuard/healthTestRun/dataUpload/dataUpload.vue')
          },
          { // 健康体检管理 进度看板
            path: '/smartpublicguard/healthtestrun/progressdashboard',
            component: () => import(/* webpackChunkName: "ncd" */ '@/views/zhyl/smartPublicGuard/healthTestRun/progressDashboard/progressDashboard.vue')
          },
          { // 孕产妇管理
            path: '/smartpublicguard/maternalrun',
            component: () => import(/* webpackChunkName: "ncd" */ '@/views/zhyl/smartPublicGuard/healthTest/healthTest.vue')
          },
          { // 儿童管理
            path: '/smartpublicguard/childrenrun',
            component: () => import(/* webpackChunkName: "ncd" */ '@/views/zhyl/smartPublicGuard/healthTest/healthTest.vue')
          },
          { // 重性精神病管理
            path: '/smartpublicguard/spiritrun',
            component: () => import(/* webpackChunkName: "ncd" */ '@/views/zhyl/smartPublicGuard/healthTest/healthTest.vue')
          },
          { // 会诊
            path: '/smartpublicguard/consultation',
            component: () => import(/* webpackChunkName: "consultation" */ '@/views/zhyl/consultation/consultation.vue')
          },
          { // 转诊
            path: '/smartpublicguard/referral',
            component: () => import(/* webpackChunkName: "referral" */ '@/views/zhyl/referral/referral.vue')
          },
          { // 0-6岁儿童健康管理记录表
            path: '/smartpublicguard/children',
            component: () => import(/* webpackChunkName: "smartpublicguard" */ '@/views/zhyl/smartPublicGuard/children/children.vue'),
            redirect: '/smartpublicguard/children/item1',
            children: [
              { // 新生儿随访
                path: '/smartpublicguard/children/item1',
                component: () => import(/* webpackChunkName: "referral" */ '@/views/zhyl/smartPublicGuard/children/item1.vue')
              },
              { // 1-8月龄
                path: '/smartpublicguard/children/item2',
                component: () => import(/* webpackChunkName: "referral" */ '@/views/zhyl/smartPublicGuard/children/item2.vue')
              },
              { // 12-30月龄
                path: '/smartpublicguard/children/item3',
                component: () => import(/* webpackChunkName: "referral" */ '@/views/zhyl/smartPublicGuard/children/item3.vue')
              },
              { // 3-6岁
                path: '/smartpublicguard/children/item4',
                component: () => import(/* webpackChunkName: "referral" */ '@/views/zhyl/smartPublicGuard/children/item4.vue')
              },
              { // 男童生长监测图
                path: '/smartpublicguard/children/item5',
                component: () => import(/* webpackChunkName: "referral" */ '@/views/zhyl/smartPublicGuard/children/item5.vue')
              },
              { // 女童生长监测图
                path: '/smartpublicguard/children/item6',
                component: () => import(/* webpackChunkName: "referral" */ '@/views/zhyl/smartPublicGuard/children/item6.vue')
              }
            ]
          },
          { // 孕产妇健康管理记录表
            path: '/smartpublicguard/maternal',
            component: () => import(/* webpackChunkName: "smartpublicguard" */ '@/views/zhyl/smartPublicGuard/maternal/maternal.vue'),
            redirect: '/smartpublicguard/maternal/item1',
            children: [
              { // 第一次产前记录
                path: '/smartpublicguard/maternal/item1',
                component: () => import(/* webpackChunkName: "referral" */ '@/views/zhyl/smartPublicGuard/maternal/item1.vue')
              },
              { // 2-5次产前记录
                path: '/smartpublicguard/maternal/item2',
                component: () => import(/* webpackChunkName: "referral" */ '@/views/zhyl/smartPublicGuard/maternal/item2.vue')
              },
              { // 产后访视记录表
                path: '/smartpublicguard/maternal/item3',
                component: () => import(/* webpackChunkName: "referral" */ '@/views/zhyl/smartPublicGuard/maternal/item3.vue')
              },
              { // 产后42天记录表
                path: '/smartpublicguard/maternal/item4',
                component: () => import(/* webpackChunkName: "referral" */ '@/views/zhyl/smartPublicGuard/maternal/item4.vue')
              }
            ]
          },
          { // 高血压患者随访记录表
            path: '/smartpublicguard/hypertension',
            component: () => import(/* webpackChunkName: "smartpublicguard" */ '@/views/zhyl/smartPublicGuard/hypertension/hypertension.vue'),
            redirect: '/smartpublicguard/hypertension/item1',
            children: [
              { //
                path: '/smartpublicguard/hypertension/item1',
                component: () => import(/* webpackChunkName: "referral" */ '@/views/zhyl/smartPublicGuard/hypertension/item1.vue')
              }
            ]
          },
          { // 高血压患者随访记录表
            path: '/smartpublicguard/diabetes',
            component: () => import(/* webpackChunkName: "smartpublicguard" */ '@/views/zhyl/smartPublicGuard/diabetes/diabetes.vue'),
            redirect: '/smartpublicguard/diabetes/index',
            children: [
              { //
                path: '/smartpublicguard/diabetes/index',
                component: () => import(/* webpackChunkName: "referral" */ '@/views/zhyl/smartPublicGuard/diabetes/item1.vue')
              }
            ]
          },
          { // 严重精神障碍患者管理记录表
            path: '/smartpublicguard/psychosis',
            component: () => import(/* webpackChunkName: "smartpublicguard" */ '@/views/zhyl/smartPublicGuard/psychosis/psychosis.vue'),
            redirect: '/smartpublicguard/psychosis/item1',
            children: [
              { // 严重精神病患者个人信息补充表
                path: '/smartpublicguard/psychosis/item1',
                component: () => import(/* webpackChunkName: "referral" */ '@/views/zhyl/smartPublicGuard/psychosis/item1.vue')
              },
              { // 严重精神障碍患者随访服务记录表
                path: '/smartpublicguard/psychosis/item2',
                component: () => import(/* webpackChunkName: "referral" */ '@/views/zhyl/smartPublicGuard/psychosis/item2.vue')
              }
            ]
          },
          { // 肺结核患者管理记录表
            path: '/smartpublicguard/phthisis',
            component: () => import(/* webpackChunkName: "smartpublicguard" */ '@/views/zhyl/smartPublicGuard/phthisis/phthisis.vue'),
            redirect: '/smartpublicguard/phthisis/item1',
            children: [
              { // 肺结核患者第一次入户随访记录表
                path: '/smartpublicguard/phthisis/item1',
                component: () => import(/* webpackChunkName: "referral" */ '@/views/zhyl/smartPublicGuard/phthisis/item1.vue')
              },
              { // 肺结核患者随访服务记录表
                path: '/smartpublicguard/phthisis/item2',
                component: () => import(/* webpackChunkName: "referral" */ '@/views/zhyl/smartPublicGuard/phthisis/item2.vue')
              }
            ]
          },
          { // 中医药健康管理服务记录表
            path: '/smartpublicguard/chinesemedicine',
            component: () => import(/* webpackChunkName: "smartpublicguard" */ '@/views/zhyl/smartPublicGuard/chineseMedicine/chineseMedicine.vue'),
            redirect: '/smartpublicguard/chineseMedicine/item1',
            children: [
              { // 老年人中医药健康管理服务记录表
                path: '/smartpublicguard/chinesemedicine/item1',
                component: () => import(/* webpackChunkName: "referral" */ '@/views/zhyl/smartPublicGuard/chineseMedicine/item1.vue')
              },
              { // 儿童中医药健康管理服务记录表
                path: '/smartpublicguard/chinesemedicine/item2',
                component: () => import(/* webpackChunkName: "referral" */ '@/views/zhyl/smartPublicGuard/chineseMedicine/item2.vue')
              }
            ]
          },
          { // 系统管理 机构用户管理
            path: '/smartpublicguard/systemrun/organuserrun',
            component: () => import(/* webpackChunkName: "smartpublicguard" */ '@/views/zhyl/smartPublicGuard/systemRun/organUserRun/organUserRun.vue')
          },
          { // 系统管理 机构列表
            path: '/smartpublicguard/systemrun/organlist',
            component: () => import(/* webpackChunkName: "smartpublicguard" */ '@/views/zhyl/smartPublicGuard/systemRun/organList/organList.vue')
          },
          { // 系统管理 菜单角色设置
            path: '/smartpublicguard/systemrun/menuroleset',
            component: () => import(/* webpackChunkName: "smartpublicguard" */ '@/views/zhyl/smartPublicGuard/systemRun/menuRoleSet/menuRoleSet.vue'),
            redirect: '/smartpublicguard/systemrun/menuroleset/tab1',
            children: [
              { // 菜单
                path: '/smartpublicguard/systemrun/menuroleset/tab1',
                component: () => import(/* webpackChunkName: "referral" */ '@/views/zhyl/smartPublicGuard/systemRun/menuRoleSet/tab1/tab1.vue')
              },
              { // 角色
                path: '/smartpublicguard/systemrun/menuroleset/tab2',
                component: () => import(/* webpackChunkName: "referral" */ '@/views/zhyl/smartPublicGuard/systemRun/menuRoleSet/tab2/tab2.vue')
              }
            ]
          },
          { // 系统管理 全局配置
            path: '/smartpublicguard/systemrun/globalconfig',
            component: () => import(/* webpackChunkName: "smartpublicguard" */ '@/views/zhyl/smartPublicGuard/systemRun/globalConfig/globalConfig.vue')
          },
          { // 系统管理 监测项目范围
            path: '/smartpublicguard/systemrun/monitoritems',
            component: () => import(/* webpackChunkName: "smartpublicguard" */ '@/views/zhyl/smartPublicGuard/systemRun/monitorItems/monitorItems.vue')
          },
          { // 系统管理 药品目录
            path: '/smartpublicguard/systemrun/drugcatalogue',
            component: () => import(/* webpackChunkName: "smartpublicguard" */ '@/views/zhyl/smartPublicGuard/systemRun/drugCatalogue/drugCatalogue.vue')
          },
          { // 系统管理 操作日志
            path: '/smartpublicguard/systemrun/operationlog',
            component: () => import(/* webpackChunkName: "smartpublicguard" */ '@/views/zhyl/smartPublicGuard/systemRun/operationLog/operationLog.vue')
          },
          { // 系统管理 异常日志
            path: '/smartpublicguard/systemrun/abnormallog',
            component: () => import(/* webpackChunkName: "smartpublicguard" */ '@/views/zhyl/smartPublicGuard/systemRun/abnormalLog/abnormalLog.vue')
          },
          { // 查询统计 体检分析
            path: '/smartpublicguard/querystatistics/testanalysis',
            component: () => import(/* webpackChunkName: "smartpublicguard" */ '@/views/zhyl/smartPublicGuard/queryStatistics/testAnalysis/testAnalysis.vue')
          },
          { // 辅助监测
            path: '/smartpublicguard/auxiliarymonitor',
            component: () => import(/* webpackChunkName: "smartpublicguard" */ '@/views/zhyl/smartPublicGuard/auxiliaryMonitor/auxiliaryMonitor.vue')
          }
        ]
      },
      { // 智慧查体
        path: '/smartphysicalexamination',
        component: () => import(/* webpackChunkName: "wisdomHomeSignature" */ '@/views/zhyl/smartPhysicalExamination/smartPhysicalExamination.vue'),
        redirect: '/smartphysicalexamination/index',
        children: [
          { // 首页
            path: '/smartphysicalexamination/index',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartPhysicalExamination/index/index.vue')
          },
          { // 预约管理 预约限额管理
            path: '/smartphysicalexamination/bookingrun/appointmentpermissionrun',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartPhysicalExamination/bookingRun/appointmentPermissionRun/appointmentPermissionRun.vue')
          },
          { // 预约管理 预约记录管理
            path: '/smartphysicalexamination/bookingrun/appointmentrecordrun',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartPhysicalExamination/bookingRun/appointmentRecordRun/appointmentRecordRun.vue')
          },
          { // 档案管理 健康档案
            path: '/smartphysicalexamination/filerun/healthfile',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartPhysicalExamination/fileRun/healthFile/healthFile.vue')
          },
          { // 查体管理 查体信息记录管理
            path: '/smartphysicalexamination/physicalexaminationrun/physicalexaminationinforecordrun',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartPhysicalExamination/physicalExaminationRun/physicalExaminationInfoRecordRun/physicalExaminationInfoRecordRun.vue')
          },
          { // 查体管理 查体分类进度查询
            path: '/smartphysicalexamination/physicalexaminationrun/physicalexaminationclassification',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartPhysicalExamination/physicalExaminationRun/physicalExaminationClassification/physicalExaminationClassification.vue')
          },
          { // 查体管理 报告管理
            path: '/smartphysicalexamination/physicalexaminationrun/reportrun',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartPhysicalExamination/physicalExaminationRun/reportRun/reportRun.vue')
          },
          { // 查体管理 离线数据管理
            path: '/smartphysicalexamination/physicalexaminationrun/offlinedatarun',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartPhysicalExamination/physicalExaminationRun/offlineDataRun/offlineDataRun.vue')
          },
          { // 数据管理 上传管理
            path: '/smartphysicalexamination/datarun/uploadrun',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartPhysicalExamination/dataRun/uploadRun/uploadRun.vue')
          },
          { // 数据管理 审核管理
            path: '/smartphysicalexamination/datarun/auditsrun',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartPhysicalExamination/dataRun/auditsRun/auditsRun.vue')
          },
          { // 统计分析 常见疾病状况
            path: '/smartphysicalexamination/statisticanalysis/commondiseaseconditions',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartPhysicalExamination/statisticAnalysis/commonDiseaseConditions/commonDiseaseConditions.vue')
          },
          { // 统计分析 学生综合体检报告
            path: '/smartphysicalexamination/statisticanalysis/medicalreport',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartPhysicalExamination/statisticAnalysis/medicalReport/medicalReport.vue')
          },
          { // 统计分析 学校学生健康监测结果汇总表
            path: '/smartphysicalexamination/statisticanalysis/healthmonitoringresults',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartPhysicalExamination/statisticAnalysis/healthMonitoringResults/healthMonitoringResults.vue')
          },
          { // 统计分析 学校学生健康检查情况汇总
            path: '/smartphysicalexamination/statisticanalysis/healthsummary',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartPhysicalExamination/statisticAnalysis/healthSummary/healthSummary.vue')
          },
          { // 统计分析 学校学生视力统计表
            path: '/smartphysicalexamination/statisticanalysis/visionstatistics',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartPhysicalExamination/statisticAnalysis/visionStatistics/visionStatistics.vue')
          },
          { // 体检计划
            path: '/smartphysicalexamination/physicalexaminationplan',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartPhysicalExamination/physicalExaminationPlan/physicalExaminationPlan.vue')
          },
          { // 系统配置 导检单管理
            path: '/smartphysicalexamination/systemconfig/medicalreport',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartPhysicalExamination/systemConfig/medicalReport/medicalReport.vue')
          },
          { // 系统配置 体检项目管理
            path: '/smartphysicalexamination/systemconfig/testprojectrun',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartPhysicalExamination/systemConfig/testProjectRun/testProjectRun.vue'),
            redirect: '/smartphysicalexamination/systemconfig/testprojectrun/projecttype',
            children: [
              // 项目类别
              {
                path: '/smartphysicalexamination/systemconfig/testprojectrun/projecttype',
                component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartPhysicalExamination/systemConfig/testProjectRun/projectType/projectType.vue')
              },
              // 体检项目
              {
                path: '/smartphysicalexamination/systemconfig/testprojectrun/testproject',
                component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartPhysicalExamination/systemConfig/testProjectRun/testProject/testProject.vue')
              }
            ]
          },
          { // 系统配置 套餐管理
            path: '/smartphysicalexamination/systemconfig/packagerun',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartPhysicalExamination/systemConfig/packageRun/packageRun.vue'),
            redirect: '/smartphysicalexamination/systemconfig/packageRun/projecttype',
            children: [
              // 项目类型
              {
                path: '/smartphysicalexamination/systemconfig/packagerun/projecttype',
                component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartPhysicalExamination/systemConfig/packageRun/projectType/projectType.vue')
              },
              // 套餐类别
              {
                path: '/smartphysicalexamination/systemconfig/packagerun/packagetype',
                component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartPhysicalExamination/systemConfig/packageRun/packageType/packageType.vue')
              },
              // 体检项目
              {
                path: '/smartphysicalexamination/systemconfig/packagerun/packageproject',
                component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartPhysicalExamination/systemConfig/packageRun/packageProject/packageProject.vue')
              }
            ]
          },
          { // 系统配置 异常模版管理
            path: '/smartphysicalexamination/systemconfig/abnormalmodulerun',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartPhysicalExamination/systemConfig/abnormalModuleRun/abnormalModuleRun.vue')
          },
          { // 系统配置 用户管理
            path: '/smartphysicalexamination/systemconfig/userrun',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartPhysicalExamination/systemConfig/userRun/userRun.vue')
          },
          { // 系统配置 学校列表
            path: '/smartphysicalexamination/systemconfig/schoollist',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartPhysicalExamination/systemConfig/schoolList/schoolList.vue')
          },
          { // 系统配置 菜单角色设置
            path: '/smartphysicalexamination/systemconfig/menuroleset',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartPhysicalExamination/systemConfig/menuRoleSet/menuRoleSet.vue'),
            redirect: '/smartphysicalexamination/systemconfig/menuroleset/tab1',
            children: [
              { // 菜单
                path: '/smartphysicalexamination/systemconfig/menuroleset/tab1',
                component: () => import(/* webpackChunkName: "referral" */ '@/views/zhyl/smartPhysicalExamination/systemConfig/menuRoleSet/tab1/tab1.vue')
              },
              { // 角色
                path: '/smartphysicalexamination/systemconfig/menuroleset/tab2',
                component: () => import(/* webpackChunkName: "referral" */ '@/views/zhyl/smartPhysicalExamination/systemConfig/menuRoleSet/tab2/tab2.vue')
              }
            ]
          },
          { // 系统配置 终端管理
            path: '/smartphysicalexamination/systemconfig/terminalrun',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartPhysicalExamination/systemConfig/terminalRun/terminalRun.vue')
          },
          { // 系统配置 设备型号管理
            path: '/smartphysicalexamination/systemconfig/devicemodelrun',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartPhysicalExamination/systemConfig/deviceModelRun/deviceModelRun.vue')
          },
          { // 影像工作站 b超工作站
            path: '/smartphysicalexamination/imageworkstation/busworkstation',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartPhysicalExamination/imageWorkstation/bUsWorkstation/bUsWorkstation.vue')
          }
        ]
      },
      { // 智慧养老
        path: '/smartelderlycare',
        component: () => import(/* webpackChunkName: "smartElderlyCare" */ '@/views/zhyl/smartElderlyCare/smartElderlyCare.vue'),
        redirect: '/smartelderlycare/index',
        children: [
          { // 看板
            path: '/smartelderlycare/index',
            // component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartElderlyCare/index.vue')
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/runkanban/runkanban.vue')
          },
          { // 智慧巡房
            path: '/smartelderlycare/smarthousepatrol',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartElderlyCare/smartHousePatrol/smartHousePatrol.vue')
          },
          { // 养老 任务管理
            path: '/smartelderlycare/retirement',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartElderlyCare/retirement/retirement.vue'),
            redirect: '/smartelderlycare/retirement/taskkanban',
            children: [
              { // 任务看板
                path: '/smartelderlycare/retirement/taskkanban',
                component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartElderlyCare/retirement/taskKanban.vue')
              },
              { // 交接记录
                path: '/smartelderlycare/retirement/handoverrecord',
                component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartElderlyCare/retirement/handoverRecord.vue')
              }
            ]
          },
          // { // 养老 养老护理
          //   path: '/smartelderlycare/elderlycare',
          //   component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartElderlyCare/elderlyCare/elderlyCare.vue'),
          //   redirect: '/smartelderlycare/elderlyCare/nursingrun',
          //   children: [
          //     { // 护理管理
          //       path: '/smartelderlycare/elderlyCare/nursingrun',
          //       component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartElderlyCare/elderlyCare/nursingRun/nursingRun.vue')
          //     },
          //     { // 外出管理
          //       path: '/smartelderlycare/elderlyCare/outrun',
          //       component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartElderlyCare/elderlyCare/outRun/outRun.vue')
          //     },
          //     { // 院内活动
          //       path: '/smartelderlycare/elderlyCare/houseactivities',
          //       component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartElderlyCare/elderlyCare/houseActivities/houseActivities.vue')
          //     },
          //     { // 洗衣服务
          //       path: '/smartelderlycare/elderlyCare/laundryservice',
          //       component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartElderlyCare/elderlyCare/laundryService/laundryService.vue')
          //     }
          //   ]
          // },
          { // 养老护理 护理管理
            path: '/smartelderlycare/elderlyCare/nursingrun',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartElderlyCare/elderlyCare/nursingRun/nursingRun.vue')
          },
          { // 养老护理 外出管理
            path: '/smartelderlycare/elderlyCare/outrun',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartElderlyCare/elderlyCare/outRun/outRun.vue')
          },
          { // 院内活动
            path: '/smartelderlycare/elderlyCare/houseactivities',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartElderlyCare/elderlyCare/houseActivities/houseActivities.vue')
          },
          { // 养老护理 洗衣服务
            path: '/smartelderlycare/elderlyCare/laundryservice',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartElderlyCare/elderlyCare/laundryService/laundryService.vue')
          },
          { // 医护管理 委托用药
            path: '/smartelderlycare/managedcare/entrustedmedication',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartElderlyCare/managedCare/entrustedMedication/entrustedMedication.vue')
          },
          { // 医护管理 健康档案
            path: '/smartelderlycare/managedcare/healthrecords',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartElderlyCare/managedCare/healthRecords/healthRecords.vue')
          },
          { // 医护管理 查房管理
            path: '/smartelderlycare/managedcare/roomrun',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartElderlyCare/managedCare/roomRun/roomRun.vue')
          },
          { // 后勤管理 采购申请
            path: '/smartelderlycare/logisticsrun/purchasingrequisition',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartElderlyCare/logisticsRun/purchasingRequisition/purchasingRequisition.vue')
          },
          { // 市场销售 潜在客户
            path: '/smartelderlycare/marketsales/potentialcustomers',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartElderlyCare/marketSales/potentialCustomers/potentialCustomers.vue')
          },
          { // 市场销售 出入院管理
            path: '/smartelderlycare/marketsales/accessrun',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartElderlyCare/marketSales/accessRun/accessRun.vue'),
            redirect: '/smartelderlycare/marketsales/accessrun/tab1',
            children: [
              { // 推荐人管理
                path: '/smartelderlycare/marketsales/accessrun/tab1',
                component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartElderlyCare/marketSales/accessRun/tab1/tab1.vue')
              },
              { // 合作组织管理
                path: '/smartelderlycare/marketsales/accessrun/tab2',
                component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartElderlyCare/marketSales/accessRun/tab2/tab2.vue')
              },
              { // 入院管理
                path: '/smartelderlycare/marketsales/accessrun/tab3',
                component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartElderlyCare/marketSales/accessRun/tab3/tab3.vue')
              },
              { // 出院管理
                path: '/smartelderlycare/marketsales/accessrun/tab4',
                component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartElderlyCare/marketSales/accessRun/tab4/tab4.vue')
              },
              { // 护理等级变更
                path: '/smartelderlycare/marketsales/accessrun/tab5',
                component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartElderlyCare/marketSales/accessRun/tab5/tab5.vue')
              }
            ]
          },
          { // 市场销售 关系管理
            path: '/smartelderlycare/marketsales/relationshiprun',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartElderlyCare/marketSales/relationshipRun/relationshipRun.vue'),
            redirect: '/smartelderlycare/marketsales/relationshiprun/tab1',
            children: [
              { // 推荐人管理
                path: '/smartelderlycare/marketsales/relationshiprun/tab1',
                component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartElderlyCare/marketSales/relationshipRun/tab1/tab1.vue')
              },
              { // 合作组织管理
                path: '/smartelderlycare/marketsales/relationshiprun/tab2',
                component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartElderlyCare/marketSales/relationshipRun/tab2/tab2.vue')
              }
            ]
          },
          { // 市场销售 服务质量
            path: '/smartelderlycare/marketsales/servequality',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartElderlyCare/marketSales/serveQuality/serveQuality.vue')
          },
          { // 财务收支 业务收支管理
            path: '/smartelderlycare/financeIncome/business',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartElderlyCare/financeIncome/business/business.vue'),
            redirect: '/smartelderlycare/financeIncome/collectionrun',
            children: [
              { // 催收管理
                path: '/smartelderlycare/financeIncome/collectionrun',
                component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartElderlyCare/financeIncome/business/collectionRun/collectionRun.vue')
              },
              { // 入院费用核定管理
                path: '/smartelderlycare/financeIncome/behospitalized',
                component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartElderlyCare/financeIncome/business/beHospitalized/beHospitalized.vue')
              },
              { // 出院核算
                path: '/smartelderlycare/financeIncome/discharge',
                component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartElderlyCare/financeIncome/business/discharge/discharge.vue')
              }
            ]
          },
          { // 人事行政管理 人事管理
            path: '/smartelderlycare/personnelmattersrun',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartElderlyCare/personnelMattersRun/personnelMattersRun.vue'),
            redirect: '/smartelderlycare/elderlyCare/nursingrun',
            children: [
              { // 人事管理 部门管理
                path: '/smartelderlycare/personnelmattersrun/personnelrun',
                component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartElderlyCare/personnelMattersRun/personnelRun/personnelRun.vue')
              },
              { // 人事管理 岗位管理
                path: '/smartelderlycare/personnelmattersrun/postrun',
                component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartElderlyCare/personnelMattersRun/postRun/postRun.vue')
              },
              { // 人事管理 员工管理
                path: '/smartelderlycare/personnelmattersrun/staffrun',
                component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartElderlyCare/personnelMattersRun/staffRun/staffRun.vue')
              }
            ]
          },

          { // 财务收支 运作收支管理
            path: '/smartelderlycare/financeIncome/operate',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartElderlyCare/financeIncome/operate/operate.vue')
          },
          { // 人事行政管理 薪资管理
            path: '/smartelderlycare/payroll',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartElderlyCare/personnelMattersRun/payroll/payroll.vue'),
            redirect: '/smartelderlycare/payroll/supportworker',
            children: [
              { // 人事管理 护工薪资管理
                path: '/smartelderlycare/payroll/supportworker',
                component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartElderlyCare/personnelMattersRun/payroll/supportWorker/supportWorker.vue')
              },
              { // 人事管理 任务奖励设置
                path: '/smartelderlycare/payroll/task',
                component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartElderlyCare/personnelMattersRun/payroll/task/task.vue')
              }
            ]
          },
          { // 审批管理 床位更换申请审批
            path: '/smartelderlycare/examineapproverun/bed',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartElderlyCare/examineApproveRun/bed/bed.vue')
          },
          { // 审批管理 外出申请审批
            path: '/smartelderlycare/examineapproverun/goout',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartElderlyCare/examineApproveRun/goout/goout.vue')
          },
          { // 审批管理 护理等级变更申请审批
            path: '/smartelderlycare/examineapproverun/nurse',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartElderlyCare/examineApproveRun/nurse/nurse.vue')
          },
          { // 养老 医护管理 作废
            path: '/smartelderlycare/medicalcarerun',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartElderlyCare/medicalCareRun/medicalCareRun.vue'),
            redirect: '/smartelderlycare/medicalcarerun/entrustedmedication',
            children: [
              { // 委托用药
                path: '/smartelderlycare/medicalcarerun/entrustedmedication',
                component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartElderlyCare/medicalCareRun/entrustedMedication/entrustedMedication.vue')
              },
              { // 健康档案
                path: '/smartelderlycare/medicalcarerun/healthrecords',
                component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartElderlyCare/medicalCareRun/healthRecords/healthRecords.vue')
              },
              { // 查房管理
                path: '/smartelderlycare/medicalcarerun/roomrun',
                component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartElderlyCare/medicalCareRun/roomRun/roomRun.vue')
              }
            ]
          },
          { // 基础设置 用户管理
            path: '/smartelderlycare/basicset/userrun',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartElderlyCare/basicSet/userRun/userRun.vue')
          },
          { // 基础设置 机构管理
            path: '/smartelderlycare/basicset/organrun',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartElderlyCare/basicSet/organRun/organRun.vue')
          },
          { // 基础设置 护理设置
            path: '/smartelderlycare/basicset/nurseset',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartElderlyCare/basicSet/nurseSet/nurseSet.vue'),
            redirect: '/smartelderlycare/basicset/nurseset/run',
            children: [
              { // 护工管理
                path: '/smartelderlycare/basicset/nurseset/run',
                component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartElderlyCare/basicSet/nurseSet/run/run.vue')
              },
              { // 护理服务
                path: '/smartelderlycare/basicset/nurseset/serve',
                component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartElderlyCare/basicSet/nurseSet/serve/serve.vue')
              },
              { // 护理等级
                path: '/smartelderlycare/basicset/nurseset/grade',
                component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartElderlyCare/basicSet/nurseSet/grade/grade.vue')
              },
              { // 护理项目
                path: '/smartelderlycare/basicset/nurseset/project',
                component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartElderlyCare/basicSet/nurseSet/project/project.vue')
              }
            ]
          },
          { // 基础设置 护理设置
            path: '/smartelderlycare/basicset/bed',
            component: () => import(/* webpackChunkName: "bed" */ '@/views/zhyl/smartElderlyCare/basicSet/bed/bed.vue'),
            redirect: '/smartelderlycare/basicset/bed/tab1',
            children: [
              { // 楼层管理
                path: '/smartelderlycare/basicset/bed/tab1',
                component: () => import(/* webpackChunkName: "bed" */ '@/views/zhyl/smartElderlyCare/basicSet/bed/tab1/tab1.vue')
              },
              { // 房间管理
                path: '/smartelderlycare/basicset/bed/tab2',
                component: () => import(/* webpackChunkName: "bed" */ '@/views/zhyl/smartElderlyCare/basicSet/bed/tab2/tab2.vue')
              },
              { // 床位管理
                path: '/smartelderlycare/basicset/bed/tab3',
                component: () => import(/* webpackChunkName: "bed" */ '@/views/zhyl/smartElderlyCare/basicSet/bed/tab3/tab3.vue')
              },
              { // 审批设置
                path: '/smartelderlycare/basicset/bed/tab4',
                component: () => import(/* webpackChunkName: "bed" */ '@/views/zhyl/smartElderlyCare/basicSet/bed/tab4/tab4.vue')
              }
            ]
          },
          { // 基础设置 审批设置
            path: '/smartelderlycare/basicset/carefulset',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartElderlyCare/basicSet/carefulSet/carefulSet.vue')
          },
          { // 基础设置 表单组件
            path: '/smartelderlycare/basicset/form',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartElderlyCare/basicSet/form/form.vue')
          },
          { // 基础设置 表单组件
            path: '/smartelderlycare/basicset/data',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartElderlyCare/basicSet/data/data.vue')
          },
          { // 基础设置 账号设置
            path: '/smartelderlycare/basicset/name',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartElderlyCare/basicSet/name/name.vue')
          },
          { // 首页 健康看板
            path: '/smartelderlycare/runkanban',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/runkanban/runkanban.vue')
          },
          { // 入院管理
            path: '/smartelderlycare/admissionregistration',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartElderlyCare/admissionRegistration/admissionRegistration.vue')
          },
          { // 入出院管理 入院登记
            path: '/smartelderlycare/accessrun/admissionregistration',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartElderlyCare/accessRun/admissionRegistration/admissionRegistration.vue')
          },
          { // 入出院管理 出院登记
            path: '/smartelderlycare/accessrun/dischargeregistration',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartElderlyCare/accessRun/dischargeRegistration/dischargeRegistration.vue')
          },
          { // 入出院管理 预约登记
            path: '/smartelderlycare/accessrun/appointmentregistration',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartElderlyCare/accessRun/appointmentRegistration/appointmentRegistration.vue')
          },
          { // 任务管理 任务看板
            path: '/smartelderlycare/taskKanban',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartElderlyCare/taskRun/taskKanban.vue')
          },
          { // 任务管理 交接记录
            path: '/smartelderlycare/taskrun/handoverrecord',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartElderlyCare/taskRun/handoverRecord.vue')
          },
          { // 监测看板
            path: '/smartelderlycare/monitorkanban',
            component: () => import(/* webpackChunkName: "monitorkanban" */ '@/views/zhyl/runkanban/runkanban.vue')
          },
          { // 实时监护
            path: '/smartelderlycare/monitoring',
            component: () => import(/* webpackChunkName: "monitoring" */ '@/views/zhyl/monitoring/monitoring.vue'
            ),
            redirect: '/smartelderlycare/basicinfo',
            children: [
              { // 实时监控 基础信息
                path: '/smartelderlycare/basicinfo',
                component: () => import(/* webpackChunkName: "monitoring" */ '@/views/zhyl/monitoring/basicInfo/basicInfo')
              },
              { // 实时监控 健康档案
                path: '/smartelderlycare/healtharchives',
                component: () => import(/* webpackChunkName: "monitoring" */ '@/views/zhyl/monitoring/healthArchives/healthArchives.vue')
              },
              { // 实时监控 处方管理
                path: '/smartelderlycare/prescriptionrun',
                component: () => import(/* webpackChunkName: "monitoring" */ '@/views/zhyl/monitoring/prescriptionRun/prescriptionRun.vue')
              },
              { // 实时监控 健康干预
                path: '/smartelderlycare/healthintervene',
                component: () => import(/* webpackChunkName: "monitoring" */ '@/views/zhyl/monitoring/healthIntervene/healthIntervene.vue')
              },
              { // 实时监控 健康评估
                path: '/smartelderlycare/healthvisit',
                component: () => import(/* webpackChunkName: "monitoring" */ '@/views/zhyl/monitoring/healthVisit/healthVisit.vue')
              },
              { // 实时监控 健康评估
                path: '/smartelderlycare/healthassess',
                component: () => import(/* webpackChunkName: "monitoring" */ '@/views/zhyl/monitoring/healthAssess/healthAssess.vue')
              },
              { // 实时监控 健康监测
                path: '/smartelderlycare/healthmonitoring',
                component: () => import(/* webpackChunkName: "monitoring" */ '@/views/zhyl/monitoring/healthMonitoring/healthMonitoring.vue')
              },
              { // 实时监控 健康报告
                path: '/smartelderlycare/healthreport',
                component: () => import(/* webpackChunkName: "monitoring" */ '@/views/zhyl/monitoring/healthReport/healthReport.vue')
              },
              { // 实时监控 签约记录
                path: '/smartelderlycare/signingrecord',
                component: () => import(/* webpackChunkName: "monitoring" */ '@/views/zhyl/monitoring/signingRecord/signingRecord.vue')
              }
            ]
          },
          { // 基础设置 健康档案 老年人专档
            path: '/smartelderlycare/healthrecords/old',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartElderlyCare/healthRecords/old/old.vue')
          },
          { // 基础设置 在院管理 老年管理
            path: '/smartelderlycare/inhospitalrun/oldrun',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartElderlyCare/inHospitalRun/oldRun/oldRun.vue')
          },
          { // 基础设置 在院管理 护理等级变更
            path: '/smartelderlycare/inhospitalrun/nursinggrade',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartElderlyCare/inHospitalRun/nursingGrade/nursingGrade.vue')
          },
          { // 上门服务
            path: '/service',
            component: () => import(/* webpackChunkName: "bed" */ '@/views/zhyl/bed/service/service.vue')
          },
          { // 健康预警
            path: '/healthwarning',
            component: () => import(/* webpackChunkName: "ncd" */ '@/views/zhyl/healthWarning/healthWarning.vue')
          },
          { // 健康干预 电子病历
            path: '/emr',
            component: () => import(/* webpackChunkName: "healthIntervention" */ '@/views/zhyl/healthIntervention/emr/emr.vue')
          },
          { // 健康干预 医嘱
            path: '/doctoradvice',
            component: () => import(/* webpackChunkName: "healthIntervention" */ '@/views/zhyl/healthIntervention/doctorAdvice/doctorAdvice.vue')
          },
          { // 健康干预 用药
            path: '/smartelderlycare/medication',
            component: () => import(/* webpackChunkName: "intelligentmonitoring" */ '@/views/zhyl/healthIntervention/medication/medication.vue')
          },
          { // 健康干预 运动与饮食建议
            path: '/exercisediet',
            component: () => import(/* webpackChunkName: "healthIntervention" */ '@/views/zhyl/healthIntervention/exerciseDiet/exerciseDiet.vue')
          },
          { // 健康干预 患者管理
            path: '/patientmanage',
            component: () => import(/* webpackChunkName: "healthIntervention" */ '@/views/zhyl/healthIntervention/patientManage/patientManage.vue')
          },
          { // 护理服务 护理计划
            path: '/smartelderlycare/nursing',
            component: () => import(/* webpackChunkName: "nursingService" */ '@/views/zhyl/nursingService/nursing/nursing.vue')
          },
          { // 护理服务 用药计划
            path: '/smartelderlycare/medications',
            component: () => import(/* webpackChunkName: "nursingService" */ '@/views/zhyl/nursingService/medication/medication.vue')
          },
          { // 护理服务 康复计划
            path: '/smartelderlycare/recovery',
            component: () => import(/* webpackChunkName: "bed" */ '@/views/zhyl/nursingService/recovery/recovery.vue')
          },
          { // 评估管理
            path: '/smartelderlycare/reviewRun',
            component: () => import(/* webpackChunkName: "reviewRun" */ '@/views/zhyl/reviewRun/reviewRun.vue')
          },
          { // 护理字典维护
            path: '/smartelderlycare/dictionary',
            component: () => import(/* webpackChunkName: "referral" */ '@/views/zhyl/dictionary/dictionary.vue')
          },
          { // 设备管理
            path: '/smartelderlycare/devicetube',
            component: () => import(/* webpackChunkName: "devicetube" */ '@/views/zhyl/devicetube/devicetube.vue')
          },
          { // 设备安装位置
            path: '/smartelderlycare/deviceposition',
            component: () => import(/* webpackChunkName: "devicetube" */ '@/views/zhyl/smartElderlyCare/devicePosition/devicePosition.vue')
          },
          { // 健康档案 基础档案
            path: '/smartelderlycare/healthrecords/basicarchives',
            component: () => import(/* webpackChunkName: "intelligentmonitoring" */ '@/views/zhyl/intelligentMonitoring/manageArchives/manageArchives.vue')
          }
        ]
      },
      { // 智慧家签
        path: '/wisdomhomesignature',
        component: () => import(/* webpackChunkName: "wisdomHomeSignature" */ '@/views/zhyl/wisdomHomeSignature/wisdomHomeSignature.vue'),
        redirect: '/wisdomhomesignature/index',
        children: [
          { // 看板
            path: '/wisdomhomesignature/index',
            component: () => import(/* webpackChunkName: "wisdomHomeSignature" */ '@/views/zhyl/wisdomHomeSignature/index.vue')
          },
          { // 家签 签约管理
            path: '/wisdomhomesignature/signingrun',
            component: () => import(/* webpackChunkName: "wisdomHomeSignature" */ '@/views/zhyl/homeSign/signingRun/signingRun.vue')
          },
          { // 签约管理 签约成员列表
            path: '/wisdomhomesignature/signingmemberlist',
            component: () => import(/* webpackChunkName: "wisdomHomeSignature" */ '@/views/zhyl/wisdomHomeSignature/signingMemberList/signingMemberList.vue')
          },
          { // 签约管理 居民续约
            path: '/wisdomhomesignature/residentfnewal',
            component: () => import(/* webpackChunkName: "wisdomHomeSignature" */ '@/views/zhyl/wisdomHomeSignature/residentEnewal/residentEnewal.vue')
          },
          { // 签约管理 签约协议签订
            path: '/wisdomhomesignature/signingagreement',
            component: () => import(/* webpackChunkName: "wisdomHomeSignature" */ '@/views/zhyl/wisdomHomeSignature/signingAgreement/signingAgreement.vue')
          },
          { // 签约管理 新增签约居民
            path: '/wisdomhomesignature/addsignedresidents',
            component: () => import(/* webpackChunkName: "wisdomHomeSignature" */ '@/views/zhyl/wisdomHomeSignature/addSignedResidents/addSignedResidents.vue')
          },
          { // 签约服务 轻随访
            path: '/wisdomhomesignature/lightvisit',
            component: () => import(/* webpackChunkName: "wisdomHomeSignature" */ '@/views/zhyl/wisdomHomeSignature/lightVisit/lightVisit.vue')
          },
          { // 签约服务 健康咨询推送
            path: '/wisdomhomesignature/healthconsultationpush',
            component: () => import(/* webpackChunkName: "wisdomHomeSignature" */ '@/views/zhyl/wisdomHomeSignature/healthConsultationPush/healthConsultationPush.vue')
          },
          { // 签约服务 团队履约记录
            path: '/wisdomhomesignature/teamperformancerecord',
            component: () => import(/* webpackChunkName: "wisdomHomeSignature" */ '@/views/zhyl/wisdomHomeSignature/teamPerformanceRecord/teamPerformanceRecord.vue')
          },
          { // 签约服务 处理意见模板
            path: '/wisdomhomesignature/handlingopiniontemplate',
            component: () => import(/* webpackChunkName: "wisdomHomeSignature" */ '@/views/zhyl/wisdomHomeSignature/handlingOpinionTemplate/handlingOpinionTemplate.vue')
          },
          { // 家签 签约团队
            path: '/wisdomhomesignature/signingteam',
            component: () => import(/* webpackChunkName: "wisdomHomeSignature" */ '@/views/zhyl/wisdomHomeSignature/signingTeam/signingTeam.vue')
          },
          { // 家签 上门服务需求申报
            path: '/wisdomhomesignature/doorservicerequirementdeclaration',
            component: () => import(/* webpackChunkName: "wisdomHomeSignature" */ '@/views/zhyl/wisdomHomeSignature/doorServiceRequirementDeclaration/doorServiceRequirementDeclaration.vue')
          },
          { // 家签 履约记录汇总
            path: '/wisdomhomesignature/summaryperformancerecords',
            component: () => import(/* webpackChunkName: "wisdomHomeSignature" */ '@/views/zhyl/wisdomHomeSignature/summaryPerformanceRecords/summaryPerformanceRecords.vue')
          },
          { // 家签 服务管理
            path: '/wisdomhomesignature/servicerun',
            component: () => import(/* webpackChunkName: "wisdomHomeSignature" */ '@/views/zhyl/homeSign/serviceRun/serviceRun.vue')
          },
          { // 家签 居民管理
            path: '/wisdomhomesignature/residentrun',
            component: () => import(/* webpackChunkName: "wisdomHomeSignature" */ '@/views/zhyl/homeSign/residentRun/residentRun.vue')
          },
          { // 家签 消息管理
            path: '/wisdomhomesignature/newsrun',
            component: () => import(/* webpackChunkName: "wisdomHomeSignature" */ '@/views/zhyl/homeSign/newsRun/newsRun.vue')
          },
          { // 家签 资料管理
            path: '/wisdomhomesignature/datamanagement',
            component: () => import(/* webpackChunkName: "wisdomHomeSignature" */ '@/views/zhyl/homeSign/dataManagement/dataManagement.vue')
          },
          { // 家签 数据统计
            path: '/wisdomhomesignature/datastatistics',
            component: () => import(/* webpackChunkName: "wisdomHomeSignature" */ '@/views/zhyl/homeSign/dataStatistics/dataStatistics.vue')
          },
          { // 家签 系统管理
            path: '/wisdomhomesignature/systemmanagement',
            component: () => import(/* webpackChunkName: "wisdomHomeSignature" */ '@/views/zhyl/homeSign/systemManagement/systemManagement.vue')
          }
        ]
      },
      { // 健康教育
        path: '/healtheducation',
        component: () => import(/* webpackChunkName: "wisdomHomeSignature" */ '@/views/zhyl/healthEducation/healthEducation.vue'),
        redirect: '/healtheducation/index',
        children: [
          { // 看板
            path: '/healtheducation/index',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/healthEducation/index.vue')
          },
          { // 健康服务
            path: '/healtheducation/healthservices',
            component: () => import(/* webpackChunkName: "healthservices" */ '@/views/zhyl/healthServices/healthServices.vue')
          },
          { // 知识库维护
            path: '/healtheducation/knowledgebase',
            component: () => import(/* webpackChunkName: "systemrun" */ '@/views/zhyl/systemrun/knowledgeBase/knowledgeBase.vue')
          }
        ]
      },
      { // 智能监测/智慧慢病
        path: '/intelligentmonitoring',
        component: () => import(/* webpackChunkName: "wisdomHomeSignature" */ '@/views/zhyl/intelligentMonitoring/intelligentMonitoring.vue'),
        redirect: '/intelligentmonitoring/taskkanban',
        children: [
          { // 任务看板
            path: '/intelligentmonitoring/taskkanban',
            component: () => import(/* webpackChunkName: "intelligentmonitoring" */ '@/views/zhyl/intelligentMonitoring/taskKanban/taskKanban.vue')
          },
          { // 档案总览
            path: '/intelligentmonitoring/overviewArchives',
            component: () => import(/* webpackChunkName: "intelligentmonitoring" */ '@/views/zhyl/intelligentMonitoring/overviewArchives/overviewArchives.vue')
          },
          { // 管理档案
            path: '/intelligentmonitoring/managearchives',
            component: () => import(/* webpackChunkName: "intelligentmonitoring" */ '@/views/zhyl/intelligentMonitoring/manageArchives/manageArchives.vue')
          },
          { // 服务记录
            path: '/intelligentmonitoring/servicerecord',
            component: () => import(/* webpackChunkName: "intelligentmonitoring" */ '@/views/zhyl/intelligentMonitoring/serviceRecord/serviceRecord.vue'),
            redirect: '/intelligentmonitoring/servicerecord/tab1',
            children: [
              { // 服务记录
                path: '/intelligentmonitoring/servicerecord/tab1',
                component: () => import(/* webpackChunkName: "intelligentmonitoring" */ '@/views/zhyl/intelligentMonitoring/serviceRecord/tab1.vue')
              },
              { // 随访预警
                path: '/intelligentmonitoring/servicerecord/tab2',
                component: () => import(/* webpackChunkName: "intelligentmonitoring" */ '@/views/zhyl/intelligentMonitoring/serviceRecord/tab2.vue')
              },
              { // 7天提醒
                path: '/intelligentmonitoring/servicerecord/tab3',
                component: () => import(/* webpackChunkName: "intelligentmonitoring" */ '@/views/zhyl/intelligentMonitoring/serviceRecord/tab3.vue')
              },
              { // 14天提醒
                path: '/intelligentmonitoring/servicerecord/tab4',
                component: () => import(/* webpackChunkName: "intelligentmonitoring" */ '@/views/zhyl/intelligentMonitoring/serviceRecord/tab4.vue')
              },
              { // 本季度待随访
                path: '/intelligentmonitoring/servicerecord/tab5',
                component: () => import(/* webpackChunkName: "intelligentmonitoring" */ '@/views/zhyl/intelligentMonitoring/serviceRecord/tab5.vue')
              },
              { // 应防未访
                path: '/intelligentmonitoring/servicerecord/tab6',
                component: () => import(/* webpackChunkName: "intelligentmonitoring" */ '@/views/zhyl/intelligentMonitoring/serviceRecord/tab6.vue')
              }
            ]
          },
          { // 智能监测
            path: '/intelligentmonitoring/monitordata',
            component: () => import(/* webpackChunkName: "intelligentmonitoring" */ '@/views/zhyl/monitordata/monitordata.vue')
          },
          { // 服务监测
            path: '/intelligentmonitoring/servicemonitoring',
            component: () => import(/* webpackChunkName: "intelligentmonitoring" */ '@/views/zhyl/intelligentMonitoring/serviceMonitoring/serviceMonitoring.vue')
          },
          { // 设备管理 定位设备
            path: '/intelligentmonitoring/positiondevice',
            component: () => import(/* webpackChunkName: "intelligentmonitoring" */ '@/views/zhyl/intelligentMonitoring/deviceRun/positionDevice/positionDevice.vue')
          },
          { // 设备管理 萤石用户
            path: '/intelligentmonitoring/fluoriteuser',
            component: () => import(/* webpackChunkName: "intelligentmonitoring" */ '@/views/zhyl/intelligentHardware/fluoriteUser/fluoriteUser.vue')
          },
          { // 设备管理 血压计设备
            path: '/intelligentmonitoring/bloodpressureequipment',
            component: () => import(/* webpackChunkName: "intelligentmonitoring" */ '@/views/zhyl/intelligentHardware/bloodPressureEquipment/bloodPressureEquipment.vue')
          },
          { // 设备管理 血糖计设备
            path: '/intelligentmonitoring/bloodglucoseequipment',
            component: () => import(/* webpackChunkName: "intelligentmonitoring" */ '@/views/zhyl/intelligentHardware/bloodGlucoseEquipment/bloodGlucoseEquipment.vue')
          },
          { // 设备管理 自定义报警
            path: '/intelligentmonitoring/customalarms',
            component: () => import(/* webpackChunkName: "intelligentmonitoring" */ '@/views/zhyl/intelligentHardware/customAlarms/customAlarms.vue')
          },
          { // 随访管理 高血压随访
            path: '/intelligentmonitoring/hypertensionvisit',
            component: () => import(/* webpackChunkName: "intelligentmonitoring" */ '@/views/zhyl/intelligentMonitoring/hypertensionVisit/hypertensionVisit.vue')
          },
          { // 随访管理 糖尿病随访
            path: '/intelligentmonitoring/diabetesvisit',
            component: () => import(/* webpackChunkName: "intelligentmonitoring" */ '@/views/zhyl/intelligentMonitoring/diabetesVisit/diabetesVisit.vue')
          },
          { // 随访管理 精神病随访
            path: '/intelligentmonitoring/psychosisvisit',
            component: () => import(/* webpackChunkName: "intelligentmonitoring" */ '@/views/zhyl/intelligentMonitoring/psychosisVisit/psychosisVisit.vue')
          },
          { // 随访管理 肺结核随访
            path: '/intelligentmonitoring/phthisisvisit',
            component: () => import(/* webpackChunkName: "intelligentmonitoring" */ '@/views/zhyl/intelligentMonitoring/phthisisVisit/phthisisVisit.vue')
          },
          { // 实时监测
            path: '/intelligentmonitoring/monitor',
            component: () => import(/* webpackChunkName: "intelligentmonitoring" */ '@/views/zhyl/monitor/monitor.vue')
          },
          { // 健康预警
            path: '/intelligentmonitoring/healthwarning',
            component: () => import(/* webpackChunkName: "intelligentmonitoring" */ '@/views/zhyl/healthWarning/healthWarning.vue')
          },
          { // 健康干预 电子病历
            path: '/intelligentmonitoring/emr',
            component: () => import(/* webpackChunkName: "intelligentmonitoring" */ '@/views/zhyl/healthIntervention/emr/emr.vue')
          },
          { // 健康干预 医嘱
            path: '/intelligentmonitoring/doctoradvice',
            component: () => import(/* webpackChunkName: "intelligentmonitoring" */ '@/views/zhyl/healthIntervention/doctorAdvice/doctorAdvice.vue')
          },
          { // 健康干预 用药
            path: '/intelligentmonitoring/medication',
            component: () => import(/* webpackChunkName: "intelligentmonitoring" */ '@/views/zhyl/healthIntervention/medication/medication.vue')
          },
          { // 健康干预 运动与饮食建议
            path: '/intelligentmonitoring/exercisediet',
            component: () => import(/* webpackChunkName: "intelligentmonitoring" */ '@/views/zhyl/healthIntervention/exerciseDiet/exerciseDiet.vue')
          },
          { // 健康干预 患者管理
            path: '/intelligentmonitoring/patientmanage',
            component: () => import(/* webpackChunkName: "intelligentmonitoring" */ '@/views/zhyl/healthIntervention/patientManage/patientManage.vue')
          },
          { // 评估管理
            path: '/intelligentmonitoring/reviewrun',
            component: () => import(/* webpackChunkName: "intelligentmonitoring" */ '@/views/zhyl/reviewRun/reviewRun.vue')
          },
          { // 智慧慢病
            path: '/intelligentmonitoring/ncd',
            component: () => import(/* webpackChunkName: "intelligentmonitoring" */ '@/views/zhyl/intelligentMonitoring/ncd/ncd.vue'),
            redirect: '/intelligentmonitoring/patientrun',
            children: [
              { // 患者管理
                path: '/intelligentmonitoring/patientrun',
                component: () => import(/* webpackChunkName: "intelligentmonitoring" */ '@/views/zhyl/intelligentMonitoring/ncd/patientRun/patientRun.vue')
              },
              {
                // 数据管理
                path: '/intelligentmonitoring/inforun',
                component: () => import(/* webpackChunkName: "intelligentmonitoring" */ '@/views/zhyl/intelligentMonitoring/ncd/infoRun/infoRun.vue')
              },
              {
                // 门诊管理
                path: '/intelligentmonitoring/outpatientrun',
                component: () => import(/* webpackChunkName: "intelligentmonitoring" */ '@/views/zhyl/intelligentMonitoring/ncd/outpatientRun/outpatientRun.vue')
              },
              {
                // 智能提醒
                path: '/intelligentmonitoring/smartreminder',
                component: () => import(/* webpackChunkName: "intelligentmonitoring" */ '@/views/zhyl/intelligentMonitoring/ncd/smartReminder/smartReminder.vue')
              },
              {
                // 质控管理
                path: '/intelligentmonitoring/controlrun',
                component: () => import(/* webpackChunkName: "intelligentmonitoring" */ '@/views/zhyl/intelligentMonitoring/ncd/controlRun/controlRun.vue')
              },
              {
                // 设备管理
                path: '/intelligentmonitoring/devicerun',
                component: () => import(/* webpackChunkName: "intelligentmonitoring" */ '@/views/zhyl/intelligentMonitoring/ncd/deviceRun/deviceRun.vue')
              },
              {
                // 系统管理
                path: '/intelligentmonitoring/systemrun',
                component: () => import(/* webpackChunkName: "intelligentmonitoring" */ '@/views/zhyl/intelligentMonitoring/ncd/systemRun/systemRun.vue')
              }
            ]
          },
          // 健康教育
          {
            path: '/intelligentmonitoring/healthedu',
            component: () => import(/* webpackChunkName: "intelligentmonitoring" */ '@/views/zhyl/organRun/healthedu/healthedu.vue')
          },
          // 双向转诊
          {
            path: '/intelligentmonitoring/referral',
            component: () => import(/* webpackChunkName: "intelligentmonitoring" */ '@/views/zhyl/referral/referral.vue')
          },
          // 双向转诊 上转记录
          {
            path: '/intelligentmonitoring/twoWayreferral/upstreamecord',
            component: () => import(/* webpackChunkName: "intelligentmonitoring" */ '@/views/zhyl/intelligentMonitoring/twoWayReferral/upstreamRecord/upstreamRecord.vue'),
            redirect: '/intelligentmonitoring/twoWayreferral/upstreamecord/tab1',
            children: [
              { // 我上转的
                path: '/intelligentmonitoring/twoWayreferral/upstreamecord/tab1',
                component: () => import(/* webpackChunkName: "intelligentmonitoring" */ '@/views/zhyl/intelligentMonitoring/twoWayReferral/upstreamRecord/tab1/tab1.vue')
              },
              { // 上转给我的
                path: '/intelligentmonitoring/twoWayreferral/upstreamecord/tab2',
                component: () => import(/* webpackChunkName: "intelligentmonitoring" */ '@/views/zhyl/intelligentMonitoring/twoWayReferral/upstreamRecord/tab2/tab2.vue')
              }
            ]
          },
          // 线上问诊服务
          {
            path: '/intelligentmonitoring/onlineconsultationservice',
            component: () => import(/* webpackChunkName: "referral" */ '@/views/zhyl/intelligentMonitoring/OnlineConsultationService/OnlineConsultationService.vue')
          },
          // AI慢病助手系统
          {
            path: '/intelligentmonitoring/aichronicdiseaseassistantsystem',
            component: () => import(/* webpackChunkName: "referral" */ '@/views/zhyl/intelligentMonitoring/aiChronicDiseaseAssistantSystems/aiChronicDiseaseAssistantSystems.vue')
          },
          // AI慢病助手系统 详情信息
          {
            path: '/intelligentmonitoring/aichronicdiseaseassistantsystem/detailedinfo',
            component: () => import(/* webpackChunkName: "intelligentmonitoring" */ '@/views/zhyl/intelligentMonitoring/aiChronicDiseaseAssistantSystems/detailedInfo/detailedInfo.vue')
            // redirect: '/intelligentmonitoring/aichronicdiseaseassistantsystem/detailedinfo/tab1',
            // children: [
            //   { // 总览
            //     path: '/intelligentmonitoring/aichronicdiseaseassistantsystem/detailedinfo/tab1',
            //     component: () => import(/* webpackChunkName: "intelligentmonitoring" */ '@/views/zhyl/intelligentMonitoring/aiChronicDiseaseAssistantSystems/detailedInfo/tab1/tab1.vue')
            //   },
            //   { // 健康监测
            //     path: '/intelligentmonitoring/aichronicdiseaseassistantsystem/detailedinfo/tab2',
            //     component: () => import(/* webpackChunkName: "intelligentmonitoring" */ '@/views/zhyl/intelligentMonitoring/aiChronicDiseaseAssistantSystems/detailedInfo/tab2/tab2.vue')
            //   }
            // ]
          },
          // 高危人群筛查 档案管理
          {
            path: '/intelligentmonitoring/highriskpopulationscreening/filerun',
            component: () => import(/* webpackChunkName: "intelligentmonitoring" */ '@/views/zhyl/intelligentMonitoring/highRiskPopulationScreening/fileRun/fileRun.vue')
          },
          // 高危人群筛查 院外筛查
          {
            path: '/intelligentmonitoring/highriskpopulationscreening/outhospitalscreen',
            component: () => import(/* webpackChunkName: "intelligentmonitoring" */ '@/views/zhyl/intelligentMonitoring/highRiskPopulationScreening/outHospitalScreen/outHospitalScreen.vue')
          },
          // 高危人群筛查 就诊筛查
          {
            path: '/intelligentmonitoring/highriskpopulationscreening/medicalscreen',
            component: () => import(/* webpackChunkName: "intelligentmonitoring" */ '@/views/zhyl/intelligentMonitoring/highRiskPopulationScreening/medicalScreen/medicalScreen.vue'),
            redirect: '/intelligentmonitoring/highriskpopulationscreening/medicalscreen/tab1',
            children: [
              { // 门诊患者
                path: '/intelligentmonitoring/highriskpopulationscreening/medicalscreen/tab1',
                component: () => import(/* webpackChunkName: "intelligentmonitoring" */ '@/views/zhyl/intelligentMonitoring/highRiskPopulationScreening/medicalScreen/tab1/tab1.vue')
              },
              { // 住院患者
                path: '/intelligentmonitoring/highriskpopulationscreening/medicalscreen/tab2',
                component: () => import(/* webpackChunkName: "intelligentmonitoring" */ '@/views/zhyl/intelligentMonitoring/highRiskPopulationScreening/medicalScreen/tab2/tab2.vue')
              }
            ]
          },
          // 高危人群筛查 体检筛查
          {
            path: '/intelligentmonitoring/highriskpopulationscreening/testscreen',
            component: () => import(/* webpackChunkName: "intelligentmonitoring" */ '@/views/zhyl/intelligentMonitoring/highRiskPopulationScreening/testScreen/testScreen.vue')
          },
          // 高危人群筛查 居民申请
          {
            path: '/intelligentmonitoring/highriskpopulationscreening/residentapplication',
            component: () => import(/* webpackChunkName: "intelligentmonitoring" */ '@/views/zhyl/intelligentMonitoring/highRiskPopulationScreening/residentApplication/residentApplication.vue')
          },
          // 高危人群筛查 档案详情
          {
            path: '/intelligentmonitoring/highriskpopulationscreening/archivedetails',
            component: () => import(/* webpackChunkName: "intelligentmonitoring" */ '@/views/zhyl/intelligentMonitoring/highRiskPopulationScreening/archiveDetails/archiveDetails.vue')
          },
          // 高危人群筛查 新建居民档案
          {
            path: '/intelligentmonitoring/highriskpopulationscreening/addresidentfiles',
            component: () => import(/* webpackChunkName: "intelligentmonitoring" */ '@/views/zhyl/intelligentMonitoring/highRiskPopulationScreening/addResidentFiles/addResidentFiles.vue')
          },
          // 高危人群筛查 完善居民档案
          {
            path: '/intelligentmonitoring/highriskpopulationscreening/improveresidentfiles',
            component: () => import(/* webpackChunkName: "intelligentmonitoring" */ '@/views/zhyl/intelligentMonitoring/highRiskPopulationScreening/improveResidentFiles/improveResidentFiles.vue')
          },
          // 高危人群筛查 新建居民档案
          {
            path: '/intelligentmonitoring/highriskpopulationscreening/addsign',
            component: () => import(/* webpackChunkName: "intelligentmonitoring" */ '@/views/zhyl/intelligentMonitoring/highRiskPopulationScreening/addSign/addSign.vue')
          },
          // 高危人群筛查 签约详情
          {
            path: '/intelligentmonitoring/highriskpopulationscreening/signdetails',
            component: () => import(/* webpackChunkName: "intelligentmonitoring" */ '@/views/zhyl/intelligentMonitoring/highRiskPopulationScreening/signDetails/signDetails.vue')
          },
          // 高危人群筛查 智能筛查
          {
            path: '/intelligentmonitoring/highriskpopulationscreening/intelligentscreen',
            component: () => import(/* webpackChunkName: "intelligentmonitoring" */ '@/views/zhyl/intelligentMonitoring/highRiskPopulationScreening/intelligentScreen/intelligentScreen.vue')
          },
          // 高危人群筛查 详情
          {
            path: '/intelligentmonitoring/highriskpopulationscreening/details',
            component: () => import(/* webpackChunkName: "intelligentmonitoring" */ '@/views/zhyl/intelligentMonitoring/highRiskPopulationScreening/details/details.vue')
          },
          // 自动化管理
          {
            path: '/intelligentmonitoring/automaterun',
            component: () => import(/* webpackChunkName: "intelligentmonitoring" */ '@/views/zhyl/intelligentMonitoring/automateRun/automateRun.vue')
          },
          // 人群管理
          {
            path: '/intelligentmonitoring/crowdrun',
            component: () => import(/* webpackChunkName: "intelligentmonitoring" */ '@/views/zhyl/intelligentMonitoring/crowdRun/crowdRun.vue'),
            redirect: '/intelligentmonitoring/crowdrun/tab1',
            children: [
              { // 一般人群
                path: '/intelligentmonitoring/crowdrun/tab1',
                component: () => import(/* webpackChunkName: "intelligentmonitoring" */ '@/views/zhyl/intelligentMonitoring/crowdRun/tab1/tab1.vue')
              },
              { // 高危人群
                path: '/intelligentmonitoring/crowdrun/tab2',
                component: () => import(/* webpackChunkName: "intelligentmonitoring" */ '@/views/zhyl/intelligentMonitoring/crowdRun/tab2/tab2.vue')
              },
              { // 慢病患者
                path: '/intelligentmonitoring/crowdrun/tab3',
                component: () => import(/* webpackChunkName: "intelligentmonitoring" */ '@/views/zhyl/intelligentMonitoring/crowdRun/tab3/tab3.vue')
              },
              { // 高血压患者
                path: '/intelligentmonitoring/crowdrun/tab4',
                component: () => import(/* webpackChunkName: "intelligentmonitoring" */ '@/views/zhyl/intelligentMonitoring/crowdRun/tab4/tab4.vue')
              },
              { // 糖尿病患者
                path: '/intelligentmonitoring/crowdrun/tab5',
                component: () => import(/* webpackChunkName: "intelligentmonitoring" */ '@/views/zhyl/intelligentMonitoring/crowdRun/tab5/tab5.vue')
              },
              { // 脑卒中患者
                path: '/intelligentmonitoring/crowdrun/tab6',
                component: () => import(/* webpackChunkName: "intelligentmonitoring" */ '@/views/zhyl/intelligentMonitoring/crowdRun/tab6/tab6.vue')
              },
              { // 冠心病患者
                path: '/intelligentmonitoring/crowdrun/tab7',
                component: () => import(/* webpackChunkName: "intelligentmonitoring" */ '@/views/zhyl/intelligentMonitoring/crowdRun/tab7/tab7.vue')
              },
              { // 慢阻肺患者
                path: '/intelligentmonitoring/crowdrun/tab8',
                component: () => import(/* webpackChunkName: "intelligentmonitoring" */ '@/views/zhyl/intelligentMonitoring/crowdRun/tab8/tab8.vue')
              },
              { // 慢性肾脏病患者
                path: '/intelligentmonitoring/crowdrun/tab9',
                component: () => import(/* webpackChunkName: "intelligentmonitoring" */ '@/views/zhyl/intelligentMonitoring/crowdRun/tab9/tab9.vue')
              },
              { // 合并症患者
                path: '/intelligentmonitoring/crowdrun/tab10',
                component: () => import(/* webpackChunkName: "intelligentmonitoring" */ '@/views/zhyl/intelligentMonitoring/crowdRun/tab10/tab10.vue')
              }
            ]
          }
        ]
      },
      { // 预约服务
        path: '/reservationservice',
        component: () => import(/* webpackChunkName: "wisdomHomeSignature" */ '@/views/zhyl/reservationService/reservationService.vue'),
        redirect: '/reservationservice/index',
        children: [
          { // 看板
            path: '/reservationservice/index',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/reservationService/index.vue')
          },
          { // 疫苗预约 预约管理
            path: '/reservationservice/vaccineappointment/bookingrun',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/reservationService/vaccineAppointment/bookingRun/bookingRun.vue')
          },
          { // 预约管理 疫苗预约 数据概述
            path: '/reservationservice/vaccineappointment/dataoverview',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/reservationService/vaccineAppointment/dataOverview/dataOverview.vue')
          },
          { // 预约管理 疫苗预约 疫苗管理
            path: '/reservationservice/vaccineappointment/vaccinerun',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/reservationService/vaccineAppointment/vaccineRun/vaccineRun.vue'),
            redirect: '/reservationservice/vaccineappointment/vaccinerun/tab1',
            children: [
              {
                path: '/reservationservice/vaccineappointment/vaccinerun/tab1',
                component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/reservationService/vaccineAppointment/vaccineRun/tab1/tab1.vue')
              },
              {
                path: '/reservationservice/vaccineappointment/vaccinerun/tab2',
                component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/reservationService/vaccineAppointment/vaccineRun/tab2/tab2.vue')
              }
            ]
          },
          { // 预约管理 疫苗预约 用户管理
            path: '/reservationservice/vaccineappointment/userrun',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/reservationService/vaccineAppointment/userRun/userRun.vue')
          },
          { // 预约管理 疫苗预约 文章管理
            path: '/reservationservice/vaccineappointment/articlerun',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/reservationService/vaccineAppointment/articleRun/articleRun.vue')
          },
          { // 预约管理 疫苗预约 其他设置
            path: '/reservationservice/vaccineappointment/otherset',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/reservationService/vaccineAppointment/otherSet/otherSet.vue')
          },
          { // 预约管理 体检预约  预约体检
            path: '/reservationservice/testreservation/reservationtest',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/reservationService/testReservation/reservationTest/reservationTest.vue')
          },
          { // 预约管理 体检预约  预约体检
            path: '/reservationservice/testreservation/testschedul',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/reservationService/testReservation/testSchedul/testSchedul.vue')
          },
          { // 预约管理 预约挂号
            path: '/reservationservice/reservationrun/reservationregister',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/reservationService/reservationRun/reservationRegister/reservationRegister.vue')
          },
          { // 预约管理 医生排班
            path: '/reservationservice/reservationrun/doctorschedul',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/reservationService/reservationRun/doctorSchedul/doctorSchedul.vue')
          },
          { // 系统设置 医院信息 医生管理
            path: '/reservationservice/hospitalinfo/doctorrun',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/reservationService/hospitalInfo/doctorRun/doctorRun.vue')
          },
          { // 系统设置 医院信息 科室管理
            path: '/reservationservice/hospitalinfo/departmentrun',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/reservationService/hospitalInfo/departmentRun/departmentRun.vue')
          },
          { // 系统设置 医院信息 体检套餐
            path: '/reservationservice/hospitalinfo/testpackage',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/reservationService/hospitalInfo/testPackage/testPackage.vue'),
            redirect: '/reservationservice/hospitalinfo/testpackage/tab1',
            children: [
              { // 体检套餐
                path: '/reservationservice/hospitalinfo/testpackage/tab1',
                component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/reservationService/hospitalInfo/testPackage/tab1/tab1.vue')
              },
              { // 体检项目
                path: '/reservationservice/hospitalinfo/testpackage/tab2',
                component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/reservationService/hospitalInfo/testPackage/tab2/tab2.vue')
              },
              { // 套餐类型
                path: '/reservationservice/hospitalinfo/testpackage/tab3',
                component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/reservationService/hospitalInfo/testPackage/tab3/tab3.vue')
              }
            ]
          },
          { // 系统设置 医院信息 健康百科
            path: '/reservationservice/hospitalinfo/healthencyclopedia',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/reservationService/hospitalInfo/healthEncyclopedia/healthEncyclopedia.vue')
          },
          { // 系统设置 医院信息 医院导航
            path: '/reservationservice/hospitalinfo/hospitalnavigation',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/reservationService/hospitalInfo/hospitalNavigation/hospitalNavigation.vue')
          },
          { // 系统设置 医院信息 医院简介
            path: '/reservationservice/hospitalinfo/aboutus',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/reservationService/hospitalInfo/aboutUs/aboutUs.vue')
          },
          { // 系统设置 医院信息 预约须知
            path: '/reservationservice/hospitalinfo/appointmentnotice',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/reservationService/hospitalInfo/appointmentNotice/appointmentNotice.vue')
          },
          { // 系统设置 医院信息 病案配送通知
            path: '/reservationservice/hospitalinfo/medicalrecord',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/reservationService/hospitalInfo/medicalRecord/medicalRecord.vue')
          },
          { // 系统设置 医院信息 用户反馈管理
            path: '/reservationservice/hospitalinfo/userrun',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/reservationService/hospitalInfo/userRun/userRun.vue')
          },
          { // 系统设置 系统设置 管理员管理
            path: '/reservationservice/systemset/administratorrun',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/reservationService/systemSet/administratorRun/administratorRun.vue')
          },
          { // 系统设置 系统设置 角色管理
            path: '/reservationservice/systemset/roles',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/reservationService/systemSet/roles/roles.vue')
          },
          { // 系统设置 系统设置 职称管理
            path: '/reservationservice/systemset/titlerun',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/reservationService/systemSet/titleRun/titleRun.vue')
          },
          { // 系统设置 系统设置 消息管理
            path: '/reservationservice/systemset/messageannouncement',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/reservationService/systemSet/messageAnnouncement/messageAnnouncement.vue')
          },
          { // 系统设置 系统设置 修改密码
            path: '/reservationservice/systemset/updatepassword',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/reservationService/systemSet/updatePassword/updatePassword.vue')
          }
        ]
      },
      { // 机构管理
        path: '/organrun',
        component: () => import(/* webpackChunkName: "smartHealthcare" */ '@/views/zhyl/organRun/organRun.vue'),
        // redirect: '/smarthealthcare/index',
        children: [
          { // 药品管理 药品管理
            path: '/organrun/drugrun',
            component: () => import(/* webpackChunkName: "organrun" */ '@/views/zhyl/runManage/drugRun/drugRun.vue')
          },
          { // 药品管理 疾病管理
            path: '/organrun/diseaserun',
            component: () => import(/* webpackChunkName: "organrun" */ '@/views/zhyl/runManage/diseaseRun/diseaseRun.vue')
          },
          { // 药品管理 处方管理
            path: '/organrun/reciperun',
            component: () => import(/* webpackChunkName: "organrun" */ '@/views/zhyl/runManage/recipeRun/recipeRun.vue')
          },
          { // 药品管理 科室管理
            path: '/organrun/roomrun',
            component: () => import(/* webpackChunkName: "organrun" */ '@/views/zhyl/runManage/roomRun/roomRun.vue')
          },
          { // 药品管理 医生管理
            path: '/organrun/doctorrun',
            component: () => import(/* webpackChunkName: "organrun" */ '@/views/zhyl/runManage/doctorRun/doctorRun.vue')
          },
          { // 药品管理 医院管理
            path: '/organrun/hospitalrun',
            component: () => import(/* webpackChunkName: "organrun" */ '@/views/zhyl/runManage/hospitalRun/hospitalRun.vue')
          },
          { // 药品管理 订单管理
            path: '/organrun/listrun',
            component: () => import(/* webpackChunkName: "organrun" */ '@/views/zhyl/runManage/listRun/listRun.vue')
          },
          { // 药品管理 用户基本信息
            path: '/organrun/basicuserinfo',
            component: () => import(/* webpackChunkName: "organrun" */ '@/views/zhyl/organRun/basicUserInfo/basicUserInfo.vue')
          },
          { // 药品管理 用户基本信息
            path: '/organrun/institutionalusermaintenance',
            component: () => import(/* webpackChunkName: "organrun" */ '@/views/zhyl/organRun/institutionalUserMaintenance/institutionalUserMaintenance.vue')
          },
          { // 药品管理 机构列表
            path: '/organrun/organlist',
            component: () => import(/* webpackChunkName: "organrun" */ '@/views/zhyl/organRun/organList/organList.vue'),
            redirect: '/organrun/organlist/list',
            children: [
              { // 机构列表
                path: '/organrun/organlist/list',
                component: () => import(/* webpackChunkName: "organrun" */ '@/views/zhyl/organRun/organList/list/list.vue')
              },
              { // 下属机构
                path: '/organrun/organlist/nextorgan',
                component: () => import(/* webpackChunkName: "organrun" */ '@/views/zhyl/organRun/organList/nextOrgan/nextOrgan.vue')
              }
            ]
          },
          { // 药品管理 系统配置
            path: '/organrun/systemset',
            component: () => import(/* webpackChunkName: "organrun" */ '@/views/zhyl/organRun/systemSet/systemSet.vue')
          },
          { // 药品管理 APP版本控制
            path: '/organrun/appversioncontrol',
            component: () => import(/* webpackChunkName: "organrun" */ '@/views/zhyl/organRun/appVersionControl/appVersionControl.vue')
          },
          { // 药品管理 菜单管理
            path: '/organrun/menumanagement',
            component: () => import(/* webpackChunkName: "organrun" */ '@/views/zhyl/organRun/menuManagement/menuManagement.vue')
          },
          { // 小程序维护
            path: '/organrun/programrun/programmaintenance',
            component: () => import(/* webpackChunkName: "organrun" */ '@/views/zhyl/organRun/programRun/programMaintenance/programMaintenance.vue')
          },
          { // 小程序管理 医生简介
            path: '/organrun/docterDetil',
            component: () => import(/* webpackChunkName: "organrun" */ '@/views/zhyl/organRun/docterDetil/docterDetil.vue')
          },
          { // 小程序管理 导航维护
            path: '/organrun/navigationMaintenance',
            component: () => import(/* webpackChunkName: "organrun" */ '@/views/zhyl/organRun/navigationMaintenance/navigationMaintenance.vue')
          },
          { // 小程序管理 专家出诊
            path: '/organrun/rotatingPage',
            component: () => import(/* webpackChunkName: "organrun" */ '@/views/zhyl/organRun/rotatingPage/rotatingPage.vue')
          },
          { // 小程序管理 医生出诊
            path: '/organrun/consultationInformation',
            component: () => import(/* webpackChunkName: "organrun" */ '@/views/zhyl/organRun/consultationInformation/consultationInformation.vue')
          },
          { // 小程序管理 健康讲座
            path: '/organrun/healthTalking',
            component: () => import(/* webpackChunkName: "organrun" */ '@/views/zhyl/organRun/healthTalking/healthTalking.vue')
          },
          { // 知识库维护
            path: '/organrun/knowledgebase',
            component: () => import(/* webpackChunkName: "organrun" */ '@/views/zhyl/organRun/knowledgeBase/knowledgeBase.vue'),
            // redirect: '/organrun/knowledgebase/drugs',
            children: [
              { // 药品管理
                path: '/organrun/knowledgebase/drugs',
                component: () => import(/* webpackChunkName: "organrun" */ '@/views/zhyl/organRun/knowledgeBase/drugs/drugs.vue')
              },
              { // 饮食管理
                path: '/organrun/knowledgebase/diet',
                component: () => import(/* webpackChunkName: "organrun" */ '@/views/zhyl/organRun/knowledgeBase/diet/diet.vue')
              },
              { // 运动管理
                path: '/organrun/knowledgebase/motion',
                component: () => import(/* webpackChunkName: "organrun" */ '@/views/zhyl/organRun/knowledgeBase/motion/motion.vue')
              },
              { // 健康评估管理
                path: '/organrun/knowledgebase/health',
                component: () => import(/* webpackChunkName: "organrun" */ '@/views/zhyl/organRun/knowledgeBase/health/health.vue')
              },
              { // 干预方案管理
                path: '/organrun/knowledgebase/intervene',
                component: () => import(/* webpackChunkName: "organrun" */ '@/views/zhyl/organRun/knowledgeBase/intervene/intervene.vue')
              },
              { // 综合干预管理
                path: '/organrun/knowledgebase/combine',
                component: () => import(/* webpackChunkName: "organrun" */ '@/views/zhyl/organRun/knowledgeBase/combine/combine.vue')
              },
              { // 中草药管理
                path: '/organrun/knowledgebase/indrug',
                component: () => import(/* webpackChunkName: "organrun" */ '@/views/zhyl/organRun/knowledgeBase/inDrug/inDrug.vue')
              }
            ]
          },
          { // 健康教育
            path: '/organrun/healthedu',
            component: () => import(/* webpackChunkName: "organrun" */ '@/views/zhyl/organRun/healthedu/healthedu.vue')
          },
          { // 菜单角色设置
            path: '/organrun/menuroleset',
            component: () => import(/* webpackChunkName: "smartpublicguard" */ '@/views/zhyl/organRun/menuRoleSet/menuRoleSet.vue'),
            redirect: '/organrun/menuroleset/tab1',
            children: [
              { // 菜单
                path: '/organrun/menuroleset/tab1',
                component: () => import(/* webpackChunkName: "referral" */ '@/views/zhyl/organRun/menuRoleSet/tab1/tab1.vue')
              },
              { // 角色
                path: '/organrun/menuroleset/tab2',
                component: () => import(/* webpackChunkName: "referral" */ '@/views/zhyl/organRun/menuRoleSet/tab2/tab2.vue')
              }
            ]
          }
        ]
      },
      { // 智能病区
        path: '/smartbeds',
        component: () => import(/* webpackChunkName: "smartHealthcare" */ '@/views/zhyl/smartBeds/smartBeds.vue'),
        // redirect: '/smarthealthcare/index',
        children: [
          { // 智能输液 数据看板
            path: '/smartbeds/intelligentinfusion/databoard',
            component: () => import(/* webpackChunkName: "smartbeds" */ '@/views/zhyl/smartBeds/intelligentInfusion/dataBoard/dataBoard.vue')
          },
          { // 智能输液 设备管理
            path: '/smartbeds/intelligentinfusion/devicerun',
            component: () => import(/* webpackChunkName: "smartbeds" */ '@/views/zhyl/smartBeds/intelligentInfusion/deviceRun/deviceRun.vue')
          },
          { // 智能输液 人员管理
            path: '/smartbeds/intelligentinfusion/personnelrun',
            component: () => import(/* webpackChunkName: "smartbeds" */ '@/views/zhyl/smartBeds/intelligentInfusion/personnelRun/personnelRun.vue')
          },
          { // 智能输液 预警管理
            path: '/smartbeds/intelligentinfusion/earlywarnrun',
            component: () => import(/* webpackChunkName: "smartbeds" */ '@/views/zhyl/smartBeds/intelligentInfusion/earlyWarnRun/earlyWarnRun.vue')
          },
          { // 护士站 病人列表
            path: '/smartbeds/nursestation/patientlist',
            component: () => import(/* webpackChunkName: "smartbeds" */ '@/views/zhyl/smartBeds/nurseStation/patientList/patientList.vue')
          },
          { // 护士站 护理任务
            path: '/smartbeds/nursestation/nurstasks',
            component: () => import(/* webpackChunkName: "smartbeds" */ '@/views/zhyl/smartBeds/nurseStation/nursTasks/nursTasks.vue')
          },
          { // 护士站 交班报告
            path: '/smartbeds/nursestation/handoverreport',
            component: () => import(/* webpackChunkName: "smartbeds" */ '@/views/zhyl/smartBeds/nurseStation/handoverReport/handoverReport.vue')
          },
          { // 护士站 护理巡视单
            path: '/smartbeds/nursestation/nursinspectionform',
            component: () => import(/* webpackChunkName: "smartbeds" */ '@/views/zhyl/smartBeds/nurseStation/nursInspectionForm/nursInspectionForm.vue')
          },
          { // 护士站 出院病人查询
            path: '/smartbeds/nursestation/dischargedpatient',
            component: () => import(/* webpackChunkName: "smartbeds" */ '@/views/zhyl/smartBeds/nurseStation/dischargedPatient/dischargedPatient.vue')
          },
          { // 医嘱 医嘱列表
            path: '/smartbeds/doctorsadvice/doctorsadvicelist',
            component: () => import(/* webpackChunkName: "smartbeds" */ '@/views/zhyl/smartBeds/doctorsAdvice/doctorsAdviceList/doctorsAdviceList.vue')
          },
          { // 医嘱 执行明细
            path: '/smartbeds/doctorsadvice/executiondetails',
            component: () => import(/* webpackChunkName: "smartbeds" */ '@/views/zhyl/smartBeds/doctorsAdvice/executionDetails/executionDetails.vue')
          },
          { // 护理文书 出入量记录单
            path: '/smartbeds/nursdocument/record',
            component: () => import(/* webpackChunkName: "smartbeds" */ '@/views/zhyl/smartBeds/nursDocument/record/record.vue')
          },
          { // 护理文书 评估记录单
            path: '/smartbeds/nursdocument/estimate',
            component: () => import(/* webpackChunkName: "smartbeds" */ '@/views/zhyl/smartBeds/nursDocument/estimate/estimate.vue'),
            redirect: '/smartbeds/nursdocument/estimate/tab1',
            children: [
              {
                path: '/smartbeds/nursdocument/estimate/tab1',
                component: () => import(/* webpackChunkName: "smartbeds" */ '@/views/zhyl/smartBeds/nursDocument/estimate/tab1/tab1.vue')
              },
              {
                path: '/smartbeds/nursdocument/estimate/tab2',
                component: () => import(/* webpackChunkName: "smartbeds" */ '@/views/zhyl/smartBeds/nursDocument/estimate/tab2/tab2.vue')
              },
              {
                path: '/smartbeds/nursdocument/estimate/tab3',
                component: () => import(/* webpackChunkName: "smartbeds" */ '@/views/zhyl/smartBeds/nursDocument/estimate/tab3/tab3.vue')
              },
              {
                path: '/smartbeds/nursdocument/estimate/tab4',
                component: () => import(/* webpackChunkName: "smartbeds" */ '@/views/zhyl/smartBeds/nursDocument/estimate/tab4/tab4.vue')
              }
            ]
          },
          { // 护理评分 Braden评分
            path: '/smartbeds/nursscore/braden',
            component: () => import(/* webpackChunkName: "smartbeds" */ '@/views/zhyl/smartBeds/nursScore/braden/braden.vue')
          },
          { // 护理评分 坠床/跌倒危险因素评分
            path: '/smartbeds/nursscore/riskfactor',
            component: () => import(/* webpackChunkName: "smartbeds" */ '@/views/zhyl/smartBeds/nursScore/riskFactor/riskFactor.vue')
          },
          { // 护理评分 格拉斯哥(GCS)昏迷评分
            path: '/smartbeds/nursscore/gcs',
            component: () => import(/* webpackChunkName: "smartbeds" */ '@/views/zhyl/smartBeds/nursScore/gcs/gcs.vue')
          },
          { // 系统(病区)设置 用户管理
            path: '/smartbeds/wardset/userrun',
            component: () => import(/* webpackChunkName: "smartbeds" */ '@/views/zhyl/smartBeds/wardSet/userRun/userRun.vue')
          },
          { // 系统(病区)设置 文书模板维护
            path: '/smartbeds/wardset/documenttemplate',
            component: () => import(/* webpackChunkName: "smartbeds" */ '@/views/zhyl/smartBeds/wardSet/documentTemplate/documentTemplate.vue')
          },
          { // 系统(病区)设置 文书菜单配置
            path: '/smartbeds/wardset/documentmenu',
            component: () => import(/* webpackChunkName: "smartbeds" */ '@/views/zhyl/smartBeds/wardSet/documentMenu/documentMenu.vue')
          },
          { // 系统(病区)设置 文书菜单配置
            path: '/smartbeds/wardset/custom',
            component: () => import(/* webpackChunkName: "smartbeds" */ '@/views/zhyl/smartBeds/wardSet/custom/custom.vue')
          }
        ]
      },
      { // 智能硬件
        path: '/intelligenthardware',
        component: () => import(/* webpackChunkName: "wisdomHomeSignature" */ '@/views/zhyl/intelligentHardware/intelligentHardware.vue'),
        // redirect: '/reservationservice/index',
        children: [
          { // 设备管理
            path: '/intelligenthardware/devicerun',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/intelligentHardware/deviceRun/deviceRun.vue')
          },
          { // 居家安全 安防报警
            path: '/intelligenthardware/securityalarm',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/intelligentHardware/securityAlarm/securityAlarm.vue')
          },
          { // 物联网管理 定位设备
            path: '/intelligenthardware/locater',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/intelligentHardware/locater/locater.vue')
          },
          { // 物联网管理 萤石用户
            path: '/intelligenthardware/fluoriteuser',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/intelligentHardware/fluoriteUser/fluoriteUser.vue')
          },
          { // 物联网管理 安防设备
            path: '/intelligenthardware/iotrun/securityequipment',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/intelligentHardware/iotRun/securityEquipment/securityEquipment.vue')
          },
          { // 物联网管理 血压计设备
            path: '/intelligenthardware/bloodpressureequipment',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/intelligentHardware/bloodPressureEquipment/bloodPressureEquipment.vue')
          },
          { // 设备管理 血糖计设备
            path: '/intelligenthardware/bloodglucoseequipment',
            component: () => import(/* webpackChunkName: "intelligentmonitoring" */ '@/views/zhyl/intelligentHardware/bloodGlucoseEquipment/bloodGlucoseEquipment.vue')
          },
          { // 物联网管理 自定义报警
            path: '/intelligenthardware/customalarms',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/intelligentHardware/customAlarms/customAlarms.vue')
          },
          { // 异常监控 血压异常监控
            path: '/intelligenthardware/bloodpressure',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/intelligentHardware/bloodPressure/bloodPressure.vue')
          }
        ]
      },
      { // 远程会议
        path: '/problemfeedback',
        component: () => import(/* webpackChunkName: "wisdomHomeSignature" */ '@/views/zhyl/problemFeedback/problemFeedback.vue'),
        redirect: '/problemfeedback/index',
        children: [
          { // 看板
            path: '/problemfeedback/index',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/problemFeedback/index.vue')
          },
          { // 管理看板
            path: '/problemFeedback/runkanban',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/runkanban/runkanban.vue')
          },
          // { // 实时监控
          //   path: '/problemFeedback/monitoring',
          //   component: () => import(/* webpackChunkName: "monitoring" */ '@/views/zhyl/monitoring/monitoring.vue'
          //   ),
          //   redirect: '/basicinfo',
          //   children: [
          //     { // 实时监控 基础信息
          //       path: '/basicinfo',
          //       component: () => import(/* webpackChunkName: "monitoring" */ '@/views/zhyl/monitoring/basicInfo/basicInfo')
          //     },
          //     { // 实时监控 健康档案
          //       path: '/healtharchives',
          //       component: () => import(/* webpackChunkName: "monitoring" */ '@/views/zhyl/monitoring/healthArchives/healthArchives.vue')
          //     },
          //     { // 实时监控 处方管理
          //       path: '/prescriptionrun',
          //       component: () => import(/* webpackChunkName: "monitoring" */ '@/views/zhyl/monitoring/prescriptionRun/prescriptionRun.vue')
          //     },
          //     { // 实时监控 健康干预
          //       path: '/healthintervene',
          //       component: () => import(/* webpackChunkName: "monitoring" */ '@/views/zhyl/monitoring/healthIntervene/healthIntervene.vue')
          //     },
          //     { // 实时监控 健康评估
          //       path: '/healthvisit',
          //       component: () => import(/* webpackChunkName: "monitoring" */ '@/views/zhyl/monitoring/healthVisit/healthVisit.vue')
          //     },
          //     { // 实时监控 健康评估
          //       path: '/healthassess',
          //       component: () => import(/* webpackChunkName: "monitoring" */ '@/views/zhyl/monitoring/healthAssess/healthAssess.vue')
          //     },
          //     { // 实时监控 健康监测
          //       path: '/healthmonitoring',
          //       component: () => import(/* webpackChunkName: "monitoring" */ '@/views/zhyl/monitoring/healthMonitoring/healthMonitoring.vue')
          //     },
          //     { // 实时监控 健康报告
          //       path: '/healthreport',
          //       component: () => import(/* webpackChunkName: "monitoring" */ '@/views/zhyl/monitoring/healthReport/healthReport.vue')
          //     },
          //     { // 实时监控 签约记录
          //       path: '/signingrecord',
          //       component: () => import(/* webpackChunkName: "monitoring" */ '@/views/zhyl/monitoring/signingRecord/signingRecord.vue')
          //     }
          //   ]
          // },
          { // 智能监测
            path: '/problemFeedback/monitor',
            component: () => import(/* webpackChunkName: "monitor" */ '@/views/zhyl/monitor/monitor.vue')
          },
          { // 监测看板
            path: '/problemfeedback/monitorkanban',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/problemFeedback/index.vue')
          },
          { // 监测数据
            path: '/problemfeedback/monitordata',
            component: () => import(/* webpackChunkName: "monitordata" */ '@/views/zhyl/monitordata/monitordata.vue'),
            redirect: '/problemfeedback/datarun',
            children: [
              {
                path: '/problemfeedback/datarun',
                component: () => import(/* webpackChunkName: "ncd" */ '@/views/zhyl/monitordata/datarun/datarun.vue')
              }
            ]
          },
          { // 上门服务
            path: '/problemFeedback/service',
            component: () => import(/* webpackChunkName: "bed" */ '@/views/zhyl/bed/service/service.vue')
          },
          { // 护理服务 护理计划
            path: '/problemFeedback/nursing',
            component: () => import(/* webpackChunkName: "nursingService" */ '@/views/zhyl/nursingService/nursing/nursing.vue')
          },
          { // 护理服务 用药计划
            path: '/problemFeedback/medication',
            component: () => import(/* webpackChunkName: "nursingService" */ '@/views/zhyl/nursingService/medication/medication.vue')
          },
          { // 护理服务 康复计划
            path: '/problemFeedback/recovery',
            component: () => import(/* webpackChunkName: "bed" */ '@/views/zhyl/nursingService/recovery/recovery.vue')
          },
          { // 评估管理
            path: '/problemFeedback/reviewRun',
            component: () => import(/* webpackChunkName: "reviewRun" */ '@/views/zhyl/reviewRun/reviewRun.vue')
          },
          { // 健康服务
            path: '/problemFeedback/healthservices',
            component: () => import(/* webpackChunkName: "healthservices" */ '@/views/zhyl/healthServices/healthServices.vue')
          },
          { // 健康管理
            path: '/problemFeedback/healthedu',
            component: () => import(/* webpackChunkName: "healthedu" */ '@/views/zhyl/healthedu/healthedu.vue')
          },
          { // 设备管理
            path: '/problemFeedback/devicetube',
            component: () => import(/* webpackChunkName: "devicetube" */ '@/views/zhyl/devicetube/devicetube.vue')
          },
          { // 系统管理 机构设置
            path: '/problemFeedback/organsetup',
            component: () => import(/* webpackChunkName: "systemrun" */ '@/views/zhyl/systemrun/organsetup/organsetup.vue')
          },
          { // 系统管理 知识库
            path: '/problemFeedback/knowledgebase',
            component: () => import(/* webpackChunkName: "systemrun" */ '@/views/zhyl/systemrun/knowledgeBase/knowledgeBase.vue')
          },
          { // 系统管理 护理字典
            path: '/problemFeedback/nursingdictionary',
            component: () => import(/* webpackChunkName: "systemrun" */ '@/views/zhyl/systemrun/nursingDictionary/nursingDictionary.vue')
          },
          { // 系统管理 人员管理
            path: '/problemFeedback/personnelrun',
            component: () => import(/* webpackChunkName: "systemrun" */ '@/views/zhyl/systemrun/personnelRun/personnelRun.vue')
          },
          { // 系统管理 角色管理
            path: '/problemFeedback/rolerun',
            component: () => import(/* webpackChunkName: "systemrun" */ '@/views/zhyl/systemrun/roleRun/roleRun.vue')
          },
          { // 系统管理 菜单管理
            path: '/problemFeedback/menurun',
            component: () => import(/* webpackChunkName: "systemrun" */ '@/views/zhyl/systemrun/menuRun/menuRun.vue')
          },
          { // 系统管理 部门管理
            path: '/problemFeedback/departmentrun',
            component: () => import(/* webpackChunkName: "systemrun" */ '@/views/zhyl/systemrun/departmentRun/departmentRun.vue')
          },
          { // 系统管理 部门管理
            path: '/problemFeedback/postrun',
            component: () => import(/* webpackChunkName: "systemrun" */ '@/views/zhyl/systemrun/postRun/postRun.vue')
          },
          { // 系统管理 我的部门
            path: '/problemFeedback/meDepartment',
            component: () => import(/* webpackChunkName: "systemrun" */ '@/views/zhyl/systemrun/me/me.vue')
          },
          { // 系统管理 体检套餐
            path: '/problemFeedback/testsetmeal',
            component: () => import(/* webpackChunkName: "systemrun" */ '@/views/zhyl/systemrun/testSetmeal/testSetmeal.vue')
          },
          { // 远程B超
            path: '/problemFeedback/bultrasonic',
            component: () => import(/* webpackChunkName: "bultrasonic" */ '@/views/zhyl/telemedicine/bUltrasonic.vue')
          },
          { // 远程医疗 心电
            path: '/problemFeedback/ecg',
            component: () => import(/* webpackChunkName: "bultrasonic" */ '@/views/zhyl/telemedicine/ecg.vue')
          },
          { // 远程医疗 影像
            path: '/problemFeedback/image',
            component: () => import(/* webpackChunkName: "bultrasonic" */ '@/views/zhyl/telemedicine/image.vue')
          },
          { // 会诊
            path: '/problemFeedback/consultation',
            component: () => import(/* webpackChunkName: "consultation" */ '@/views/zhyl/consultation/consultation.vue')
          },
          { // 转诊
            path: '/problemFeedback/referral',
            component: () => import(/* webpackChunkName: "referral" */ '@/views/zhyl/referral/referral.vue')
          },
          { // 智慧慢病
            path: '/problemFeedback/ncd',
            component: () => import(/* webpackChunkName: "ncd" */ '@/views/zhyl/ncd/ncd.vue'),
            redirect: '/problemFeedback/patientrun',
            children: [
              { // 患者管理
                path: '/problemFeedback/patientrun',
                component: () => import(/* webpackChunkName: "ncd" */ '@/views/zhyl/ncd/patientRun/patientRun.vue')
              },
              {
                // 数据管理
                path: '/problemFeedback/inforun',
                component: () => import(/* webpackChunkName: "ncd" */ '@/views/zhyl/ncd/infoRun/infoRun.vue')
              },
              {
                // 门诊管理
                path: '/problemFeedback/outpatientrun',
                component: () => import(/* webpackChunkName: "ncd" */ '@/views/zhyl/ncd/outpatientRun/outpatientRun.vue')
              },
              {
                // 智能提醒
                path: '/problemFeedback/smartreminder',
                component: () => import(/* webpackChunkName: "ncd" */ '@/views/zhyl/ncd/smartReminder/smartReminder.vue')
              },
              {
                // 质控管理
                path: '/problemFeedback/controlrun',
                component: () => import(/* webpackChunkName: "ncd" */ '@/views/zhyl/ncd/controlRun/controlRun.vue')
              },
              {
                // 设备管理
                path: '/problemFeedback/devicerun',
                component: () => import(/* webpackChunkName: "ncd" */ '@/views/zhyl/ncd/deviceRun/deviceRun.vue')
              },
              {
                // 系统管理
                path: '/problemFeedback/systemrun',
                component: () => import(/* webpackChunkName: "ncd" */ '@/views/zhyl/ncd/systemRun/systemRun.vue')
              }
            ]
          },
          { // 护理字典维护
            path: '/problemFeedback/dictionary',
            component: () => import(/* webpackChunkName: "referral" */ '@/views/zhyl/dictionary/dictionary.vue')
          },
          { // 系统管理 知识库
            path: '/problemFeedback/knowledgebase',
            component: () => import(/* webpackChunkName: "systemrun" */ '@/views/zhyl/systemrun/knowledgeBase/knowledgeBase.vue')
          },
          { // 报告管理
            path: '/problemfeedback/reportingrun',
            component: () => import(/* webpackChunkName: "systemrun" */ '@/views/zhyl/problemFeedback/reportingRun/reportingRun.vue')
          }

        ]
      },
      { // 智慧大屏
        path: '/smartlargescreen',
        component: () => import(/* webpackChunkName: "wisdomHomeSignature" */ '@/views/zhyl/smartLargeScreen/smartLargeScreen.vue'),
        redirect: '/smartlargescreen/index',
        children: [
          { // 看板
            path: '/smartlargescreen/index',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartLargeScreen/index.vue')
          },
          { // 数据概览 数据概览
            path: '/smartlargescreen/dataoverview',
            component: () => import(/* webpackChunkName: "runkanban" */ '@/views/zhyl/smartLargeScreen/dataOverview/dataOverview.vue')
          }
        ]
      },
      { // 实时监控
        path: '/monitoring',
        component: () => import(/* webpackChunkName: "monitoring" */ '@/views/zhyl/monitoring/monitoring.vue'
        ),
        redirect: '/basicinfo',
        children: [
          { // 实时监控 基础信息
            path: '/basicinfo',
            component: () => import(/* webpackChunkName: "monitoring" */ '@/views/zhyl/monitoring/basicInfo/basicInfo')
          },
          { // 实时监控 健康档案
            path: '/healtharchives',
            component: () => import(/* webpackChunkName: "monitoring" */ '@/views/zhyl/monitoring/healthArchives/healthArchives.vue')
          },
          { // 实时监控 处方管理
            path: '/prescriptionrun',
            component: () => import(/* webpackChunkName: "monitoring" */ '@/views/zhyl/monitoring/prescriptionRun/prescriptionRun.vue')
          },
          { // 实时监控 健康干预
            path: '/healthintervene',
            component: () => import(/* webpackChunkName: "monitoring" */ '@/views/zhyl/monitoring/healthIntervene/healthIntervene.vue')
          },
          { // 实时监控 健康评估
            path: '/healthvisit',
            component: () => import(/* webpackChunkName: "monitoring" */ '@/views/zhyl/monitoring/healthVisit/healthVisit.vue')
          },
          { // 实时监控 健康评估
            path: '/healthassess',
            component: () => import(/* webpackChunkName: "monitoring" */ '@/views/zhyl/monitoring/healthAssess/healthAssess.vue')
          },
          { // 实时监控 健康监测
            path: '/healthmonitoring',
            component: () => import(/* webpackChunkName: "monitoring" */ '@/views/zhyl/monitoring/healthMonitoring/healthMonitoring.vue')
          },
          { // 实时监控 健康报告
            path: '/healthreport',
            component: () => import(/* webpackChunkName: "monitoring" */ '@/views/zhyl/monitoring/healthReport/healthReport.vue')
          },
          { // 实时监控 签约记录
            path: '/signingrecord',
            component: () => import(/* webpackChunkName: "monitoring" */ '@/views/zhyl/monitoring/signingRecord/signingRecord.vue')
          }
        ]
      },
      { // 智能监测
        path: '/monitor',
        component: () => import(/* webpackChunkName: "monitor" */ '@/views/zhyl/monitor/monitor.vue')
      },
      { // 监测数据
        path: '/monitordata',
        component: () => import(/* webpackChunkName: "monitordata" */ '@/views/zhyl/monitordata/monitordata.vue'),
        redirect: '/datarun',
        children: [
          {
            path: '/datarun',
            component: () => import(/* webpackChunkName: "ncd" */ '@/views/zhyl/monitordata/datarun/datarun.vue')
          }
        ]
      },
      { // 健康看板
        path: '/monitorkanban',
        component: () => import(/* webpackChunkName: "monitorkanban" */ '@/views/zhyl/runkanban/runkanban.vue')
      },
      { // 家庭床位 智能监测
        path: '/service',
        component: () => import(/* webpackChunkName: "bed" */ '@/views/zhyl/bed/service/service.vue')
      },
      { // 远程B超
        path: '/bultrasonics',
        component: () => import(/* webpackChunkName: "bultrasonic" */ '@/views/zhyl/bultrasonics/bultrasonics.vue'),
        redirect: '/bultrasonics/index',
        children: [
          {
            path: '/bultrasonics/index',
            component: () => import(/* webpackChunkName: "ncd" */ '@/views/zhyl/bultrasonics/index.vue')
          },
          { // 远程B超
            path: '/bultrasonics/bultrasonic',
            component: () => import(/* webpackChunkName: "bultrasonic" */ '@/views/zhyl/telemedicine/bUltrasonic.vue')
          },
          { // 转诊
            path: '/bultrasonics/referral',
            component: () => import(/* webpackChunkName: "referral" */ '@/views/zhyl/referral/referral.vue')
          },
          { // 报告管理
            path: '/bultrasonics/reportingrun',
            component: () => import(/* webpackChunkName: "systemrun" */ '@/views/zhyl/problemFeedback/reportingRun/reportingRun.vue')
          }
        ]
      },
      { // 远程心电
        path: '/ecgs',
        // component: () => import(/* webpackChunkName: "bultrasonic" */ '@/views/zhyl/telemedicine/ecg.vue')
        component: () => import(/* webpackChunkName: "bultrasonic" */ '@/views/zhyl/ecgs/ecgs.vue'),
        redirect: '/ecgs/index',
        children: [
          {
            path: '/ecgs/index',
            component: () => import(/* webpackChunkName: "ncd" */ '@/views/zhyl/ecgs/index.vue')
          },
          { // 远程心电
            path: '/ecgs/ecg',
            component: () => import(/* webpackChunkName: "bultrasonic" */ '@/views/zhyl/telemedicine/ecg.vue')
          },
          { // 转诊
            path: '/ecgs/referral',
            component: () => import(/* webpackChunkName: "referral" */ '@/views/zhyl/referral/referral.vue')
          },
          { // 报告管理
            path: '/ecgs/reportingrun',
            component: () => import(/* webpackChunkName: "systemrun" */ '@/views/zhyl/problemFeedback/reportingRun/reportingRun.vue')
          }
        ]
      },
      { // 远程影像
        path: '/images',
        // component: () => import(/* webpackChunkName: "bultrasonic" */ '@/views/zhyl/telemedicine/image.vue')
        component: () => import(/* webpackChunkName: "bultrasonic" */ '@/views/zhyl/images/images.vue'),
        redirect: '/images/index',
        children: [
          {
            path: '/images/index',
            component: () => import(/* webpackChunkName: "ncd" */ '@/views/zhyl/images/index.vue')
          },
          { // 远程影像
            path: '/images/image',
            component: () => import(/* webpackChunkName: "bultrasonic" */ '@/views/zhyl/telemedicine/image.vue')
          },
          { // 转诊
            path: '/images/referral',
            component: () => import(/* webpackChunkName: "referral" */ '@/views/zhyl/referral/referral.vue')
          },
          { // 报告管理
            path: '/images/reportingrun',
            component: () => import(/* webpackChunkName: "systemrun" */ '@/views/zhyl/problemFeedback/reportingRun/reportingRun.vue')
          }
        ]
      },
      // { // 护理服务 护理计划
      //   path: '/nursing',
      //   component: () => import(/* webpackChunkName: "nursingService" */ '@/views/zhyl/nursingService/nursing/nursing.vue')
      // },
      // { // 护理服务 用药计划
      //   path: '/medication',
      //   component: () => import(/* webpackChunkName: "nursingService" */ '@/views/zhyl/nursingService/medication/medication.vue')
      // },
      // { // 家庭床位 康复计划
      //   path: '/recovery',
      //   component: () => import(/* webpackChunkName: "bed" */ '@/views/zhyl/nursingService/recovery/recovery.vue')
      // },
      // { // 评估管理
      //   path: '/reviewRun',
      //   component: () => import(/* webpackChunkName: "reviewRun" */ '@/views/zhyl/reviewRun/reviewRun.vue')
      // },
      // { // 健康服务
      //   path: '/healthservices',
      //   component: () => import(/* webpackChunkName: "healthservices" */ '@/views/zhyl/healthServices/healthServices.vue')
      // },
      // { // 健康管理
      //   path: '/healthedu',
      //   component: () => import(/* webpackChunkName: "healthedu" */ '@/views/zhyl/healthedu/healthedu.vue')
      // },
      // { // 设备管理
      //   path: '/devicetube',
      //   component: () => import(/* webpackChunkName: "devicetube" */ '@/views/zhyl/devicetube/devicetube.vue')
      // },
      // { // 系统管理 机构设置
      //   path: '/organsetup',
      //   component: () => import(/* webpackChunkName: "systemrun" */ '@/views/zhyl/systemrun/organsetup/organsetup.vue')
      // },
      // { // 系统管理 知识库
      //   path: '/knowledgebase',
      //   component: () => import(/* webpackChunkName: "systemrun" */ '@/views/zhyl/systemrun/knowledgeBase/knowledgeBase.vue')
      // },
      // { // 系统管理 护理字典
      //   path: '/nursingdictionary',
      //   component: () => import(/* webpackChunkName: "systemrun" */ '@/views/zhyl/systemrun/nursingDictionary/nursingDictionary.vue')
      // },
      // { // 系统管理 人员管理
      //   path: '/personnelrun',
      //   component: () => import(/* webpackChunkName: "systemrun" */ '@/views/zhyl/systemrun/personnelRun/personnelRun.vue')
      // },
      // { // 系统管理 角色管理
      //   path: '/rolerun',
      //   component: () => import(/* webpackChunkName: "systemrun" */ '@/views/zhyl/systemrun/roleRun/roleRun.vue')
      // },
      // { // 系统管理 菜单管理
      //   path: '/menurun',
      //   component: () => import(/* webpackChunkName: "systemrun" */ '@/views/zhyl/systemrun/menuRun/menuRun.vue')
      // },
      // { // 系统管理 部门管理
      //   path: '/departmentrun',
      //   component: () => import(/* webpackChunkName: "systemrun" */ '@/views/zhyl/systemrun/departmentRun/departmentRun.vue')
      // },
      // { // 系统管理 部门管理
      //   path: '/postrun',
      //   component: () => import(/* webpackChunkName: "systemrun" */ '@/views/zhyl/systemrun/postRun/postRun.vue')
      // },
      // { // 系统管理 我的部门
      //   path: '/meDepartment',
      //   component: () => import(/* webpackChunkName: "systemrun" */ '@/views/zhyl/systemrun/me/me.vue')
      // },
      // { // 系统管理 体检套餐
      //   path: '/testsetmeal',
      //   component: () => import(/* webpackChunkName: "systemrun" */ '@/views/zhyl/systemrun/testSetmeal/testSetmeal.vue')
      // },
      // { // 远程医疗 远程B超
      //   path: '/bultrasonic',
      //   component: () => import(/* webpackChunkName: "bultrasonic" */ '@/views/zhyl/telemedicine/bUltrasonic.vue')
      // },
      // { // 远程医疗 心电
      //   path: '/ecg',
      //   component: () => import(/* webpackChunkName: "bultrasonic" */ '@/views/zhyl/telemedicine/ecg.vue')
      // },
      // { // 远程医疗 影像
      //   path: '/image',
      //   component: () => import(/* webpackChunkName: "bultrasonic" */ '@/views/zhyl/telemedicine/image.vue')
      // },
      // { // 会诊
      //   path: '/consultation',
      //   component: () => import(/* webpackChunkName: "consultation" */ '@/views/zhyl/consultation/consultation.vue')
      // },
      // { // 转诊
      //   path: '/referral',
      //   component: () => import(/* webpackChunkName: "referral" */ '@/views/zhyl/referral/referral.vue')
      // },
      // { // 智慧慢病
      //   path: '/ncd',
      //   component: () => import(/* webpackChunkName: "ncd" */ '@/views/zhyl/ncd/ncd.vue'),
      //   redirect: '/patientrun',
      //   children: [
      //     { // 患者管理
      //       path: '/patientrun',
      //       component: () => import(/* webpackChunkName: "ncd" */ '@/views/zhyl/ncd/patientRun/patientRun.vue')
      //     },
      //     {
      //       // 数据管理
      //       path: '/inforun',
      //       component: () => import(/* webpackChunkName: "ncd" */ '@/views/zhyl/ncd/infoRun/infoRun.vue')
      //     },
      //     {
      //       // 门诊管理
      //       path: '/outpatientrun',
      //       component: () => import(/* webpackChunkName: "ncd" */ '@/views/zhyl/ncd/outpatientRun/outpatientRun.vue')
      //     },
      //     {
      //       // 智能提醒
      //       path: '/smartreminder',
      //       component: () => import(/* webpackChunkName: "ncd" */ '@/views/zhyl/ncd/smartReminder/smartReminder.vue')
      //     },
      //     {
      //       // 质控管理
      //       path: '/controlrun',
      //       component: () => import(/* webpackChunkName: "ncd" */ '@/views/zhyl/ncd/controlRun/controlRun.vue')
      //     },
      //     {
      //       // 设备管理
      //       path: '/devicerun',
      //       component: () => import(/* webpackChunkName: "ncd" */ '@/views/zhyl/ncd/deviceRun/deviceRun.vue')
      //     },
      //     {
      //       // 系统管理
      //       path: '/systemrun',
      //       component: () => import(/* webpackChunkName: "ncd" */ '@/views/zhyl/ncd/systemRun/systemRun.vue')
      //     }
      //   ]
      // },
      // { // 护理字典维护
      //   path: '/dictionary',
      //   component: () => import(/* webpackChunkName: "referral" */ '@/views/zhyl/dictionary/dictionary.vue')
      // },
      { // 运营管理 药品管理
        path: '/drugrun',
        component: () => import(/* webpackChunkName: "ncd" */ '@/views/zhyl/runManage/drugRun/drugRun.vue')
      },
      { // 运营管理 疾病管理
        path: '/diseaserun',
        component: () => import(/* webpackChunkName: "ncd" */ '@/views/zhyl/runManage/diseaseRun/diseaseRun.vue')
      },
      { // 运营管理 处方管理
        path: '/reciperun',
        component: () => import(/* webpackChunkName: "ncd" */ '@/views/zhyl/runManage/recipeRun/recipeRun.vue')
      },
      { // 运营管理 科室管理
        path: '/roomrun',
        component: () => import(/* webpackChunkName: "ncd" */ '@/views/zhyl/runManage/roomRun/roomRun.vue')
      },
      { // 运营管理 医生管理
        path: '/doctorrun',
        component: () => import(/* webpackChunkName: "ncd" */ '@/views/zhyl/runManage/doctorRun/doctorRun.vue')
      },
      { // 运营管理 医院管理
        path: '/hospitalrun',
        component: () => import(/* webpackChunkName: "ncd" */ '@/views/zhyl/runManage/hospitalRun/hospitalRun.vue')
      },
      { // 运营管理 订单管理
        path: '/listrun',
        component: () => import(/* webpackChunkName: "ncd" */ '@/views/zhyl/runManage/listRun/listRun.vue')
      },
      // { // 健康干预 电子病历
      //   path: '/emr',
      //   component: () => import(/* webpackChunkName: "healthIntervention" */ '@/views/zhyl/healthIntervention/emr/emr.vue')
      // },
      // { // 健康干预 医嘱
      //   path: '/doctoradvice',
      //   component: () => import(/* webpackChunkName: "healthIntervention" */ '@/views/zhyl/healthIntervention/doctorAdvice/doctorAdvice.vue')
      // },
      // { // 健康干预 运动与饮食建议
      //   path: '/exercisediet',
      //   component: () => import(/* webpackChunkName: "healthIntervention" */ '@/views/zhyl/healthIntervention/exerciseDiet/exerciseDiet.vue')
      // },
      // { // 健康干预 患者管理
      //   path: '/patientmanage',
      //   component: () => import(/* webpackChunkName: "healthIntervention" */ '@/views/zhyl/healthIntervention/patientManage/patientManage.vue')
      // },
      { // 健康预警
        path: '/healthwarning',
        component: () => import(/* webpackChunkName: "ncd" */ '@/views/zhyl/healthWarning/healthWarning.vue')
      },
      { // 疫苗管理 数据概述
        path: '/dataoverview',
        component: () => import(/* webpackChunkName: "vaccineRun" */ '@/views/zhyl/vaccineRun/dataOverview/dataOverview.vue')
      },
      { // 疫苗管理 疫苗管理
        path: '/vaccinumrun',
        component: () => import(/* webpackChunkName: "vaccineRun" */ '@/views/zhyl/vaccineRun/vaccinumRun/vaccinumRun.vue')
      },
      { // 疫苗管理 用户管理
        path: '/userrun',
        component: () => import(/* webpackChunkName: "vaccineRun" */ '@/views/zhyl/vaccineRun/userRun/userRun.vue')
      },
      { // 疫苗管理 文章管理
        path: '/essayrun',
        component: () => import(/* webpackChunkName: "vaccineRun" */ '@/views/zhyl/vaccineRun/essayRun/essayRun.vue')
      },
      { // 疫苗管理 其他设置
        path: '/othersettings',
        component: () => import(/* webpackChunkName: "vaccineRun" */ '@/views/zhyl/vaccineRun/otherSettings/otherSettings.vue')
      },
      // { // 家签 签约管理
      //   path: '/signingrun',
      //   component: () => import(/* webpackChunkName: "homeSign" */ '@/views/zhyl/homeSign/signingRun/signingRun.vue')
      // },
      // { // 家签 服务管理
      //   path: '/servicerun',
      //   component: () => import(/* webpackChunkName: "homeSign" */ '@/views/zhyl/homeSign/serviceRun/serviceRun.vue')
      // },
      // { // 家签 居民管理
      //   path: '/residentrun',
      //   component: () => import(/* webpackChunkName: "homeSign" */ '@/views/zhyl/homeSign/residentRun/residentRun.vue')
      // },
      // { // 家签 消息管理
      //   path: '/newsrun',
      //   component: () => import(/* webpackChunkName: "homeSign" */ '@/views/zhyl/homeSign/newsRun/newsRun.vue')
      // },
      // { // 家签 资料管理
      //   path: '/datamanagement',
      //   component: () => import(/* webpackChunkName: "homeSign" */ '@/views/zhyl/homeSign/dataManagement/dataManagement.vue')
      // },
      // { // 家签 数据统计
      //   path: '/datastatistics',
      //   component: () => import(/* webpackChunkName: "homeSign" */ '@/views/zhyl/homeSign/dataStatistics/dataStatistics.vue')
      // },
      // { // 家签 系统管理
      //   path: '/systemmanagement',
      //   component: () => import(/* webpackChunkName: "homeSign" */ '@/views/zhyl/homeSign/systemManagement/systemManagement.vue')
      // },
      { // 预约管理
        path: '/bookingrun',
        component: () => import(/* webpackChunkName: "homeSign" */ '@/views/zhyl/bookingRun/bookingRun.vue')
      }
      // ,
      // { // 智慧问诊
      //   path: '/smartconsultation',
      //   component: () => import(/* webpackChunkName: "homeSign" */ '@/views/zhyl/smartConsultation/smartConsultation.vue')
      // }
    ],
    meta: { title: '智慧养老' }
  },
  { // 智慧大屏
    path: '/zhdp',
    component: () => import(/* webpackChunkName: "zhdp" */ '@/views/zhdp/zhdp.vue'),
    // redirect: '/zhdp/generalcontrol',
    children: [
      { // 总控
        path: '/zhdp/generalcontrol',
        component: () => import(/* webpackChunkName: "zhdp" */ '@/views/zhdp/generalControl/generalControl.vue'),
        activeIndex: 0
      },
      { // 总控
        path: '/zhdp/generalcontrols',
        component: () => import(/* webpackChunkName: "zhdp" */ '@/views/zhdp/generalControl/generalControls.vue'),
        activeIndex: 0
      },
      { // 居家养老服务
        path: '/zhdp/homecareserve',
        component: () => import(/* webpackChunkName: "zhdp" */ '@/views/zhdp/homeCareServe/homeCareServe.vue')
      },
      { // 医防融合
        path: '/zhdp/medicalprevention',
        component: () => import(/* webpackChunkName: "zhdp" */ '@/views/zhdp/medicalPrevention/medicalPrevention.vue')
      },
      // { // 医防融合
      //   path: '/zhdp/medicalpreventions',
      //   component: () => import(/* webpackChunkName: "zhdp" */ '@/views/zhdp/spms/spms.vue')
      // },
      { // 智慧体检
        path: '/zhdp/smarttest',
        component: () => import(/* webpackChunkName: "zhdp" */ '@/views/zhdp/smartTest/smartTest.vue')
      },
      { // 智慧体检
        path: '/zhdp/smarttests',
        component: () => import(/* webpackChunkName: "zhdp" */ '@/views/zhdp/smartTest/demo.vue')
      },
      { // 智慧慢病
        path: '/zhdp/chronicdisease',
        component: () => import(/* webpackChunkName: "zhdp" */ '@/views/zhdp/chronicDisease/chronicDisease.vue')
      },
      { // 辅助监测
        path: '/zhdp/auxiliarymonitoring',
        component: () => import(/* webpackChunkName: "zhdp" */ '@/views/zhdp/auxiliaryMonitoring/auxiliaryMonitoring.vue')
      },
      { // 辅助监测
        path: '/zhdp/auxiliarymonitorings',
        component: () => import(/* webpackChunkName: "zhdp" */ '@/views/zhdp/auxiliaryMonitoring/auxiliaryMonitorings.vue')
      },
      { // 实时监测
        path: '/zhdp/serviceMonitoring',
        component: () => import(/* webpackChunkName: "zhdp" */ '@/views/zhdp/serviceMonitoring/serviceMonitoring.vue')
      },
      { // 实时监测后面添加
        path: '/zhdp/data',
        component: () => import(/* webpackChunkName: "zhdp" */ '@/views/zhdp/data/data.vue')
      },
      { // 防走丢监护
        path: '/zhdp/antilossmonitoring',
        component: () => import(/* webpackChunkName: "zhdp" */ '@/views/zhdp/antiLossMonitoring/antiLossMonitoring.vue')
      },
      { // 生命体征监测
        path: '/zhdp/vitalsignmonitoring',
        component: () => import(/* webpackChunkName: "zhdp" */ '@/views/zhdp/vitalSignMonitoring/vitalSignMonitoring.vue')
      },
      { // 预警监测
        path: '/zhdp/earlywarnmonitor',
        component: () => import(/* webpackChunkName: "zhdp" */ '@/views/zhdp/earlyWarnMonitor/earlyWarnMonitor.vue')
      },
      { // 数据总览
        path: '/zhdp/datascreen',
        component: () => import(/* webpackChunkName: "zhdp" */ '@/views/zhdp/dataScreen/dataScreen.vue')
      },
      { // 智慧家签
        path: '/zhdp/homesignature',
        component: () => import(/* webpackChunkName: "zhdp" */ '@/views/zhdp/homeSignature/homeSignature.vue')
      },
      { // 远程心电
        path: '/zhdp/remoteecg',
        component: () => import(/* webpackChunkName: "zhdp" */ '@/views/zhdp/remoteEcg/remoteEcg.vue')
      },
      { // 远程会诊
        path: '/zhdp/remotediagnosis',
        component: () => import(/* webpackChunkName: "zhdp" */ '@/views/zhdp/remoteDiagnosis/remoteDiagnosis.vue')
      },
      { // 远程影像
        path: '/zhdp/remoteimage',
        component: () => import(/* webpackChunkName: "zhdp" */ '@/views/zhdp/remoteImage/remoteImage.vue')
      },
      { // 远程B超
        path: '/zhdp/remoteb',
        component: () => import(/* webpackChunkName: "zhdp" */ '@/views/zhdp/remoteB/remoteB.vue')
      },
      { // 两慢病监测
        path: '/zhdp/chronicdiseasemonitor',
        component: () => import(/* webpackChunkName: "zhdp" */ '@/views/zhdp/chronicDiseaseMonitor/chronicDiseaseMonitor.vue')
      }
    ],
    meta: { title: '智慧养老平台数据中心' }
  },
  { // 智慧大屏
    path: '/zhdp/studenttest',
    component: () => import(/* webpackChunkName: "zhdp" */ '@/views/zhdp/studentTest/studentTest.vue'),
    meta: { title: '智慧学生查体' }
  }
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  let urls = ['generalcontrol', 'smarttest', 'medicalprevention', 'serviceMonitoring', 'data', 'homecareserve', 'antilossmonitoring', 'vitalsignmonitoring', 'earlywarnmonitor', 'datascreen']
  if (store.state.loginInfo.usercode === 'xxsqlhj') {
    if (store.state.ShowHeadTab.length === 1) {
      const arr = store.state.ShowHeadTab.filter(item => item.text === '智慧查体')
      if (arr.length > 0) {
        urls = ['studenttest']
      }
    } else {
      urls = ['generalcontrols', 'medicalpreventions', 'smarttests', 'chronicdisease', 'auxiliarymonitorings', 'antilossmonitoring', 'homesignature', 'studenttest', 'chronicdiseasemonitor']
    }
  } else if (store.state.loginInfo.usercode === 'rcxyfrh') {
    urls = ['generalcontrols', 'medicalpreventions', 'smarttests', 'chronicdisease', 'auxiliarymonitorings', 'antilossmonitoring', 'homesignature', 'remoteecg', 'remotediagnosis', 'remoteimage', 'remoteb']
    if (store.state.loginInfo.permissions.findIndex(item => item.name === '两慢病筛查') > -1) {
      urls = ['generalcontrols', 'medicalpreventions', 'smarttests', 'chronicdisease', 'chronicdiseasemonitor', 'auxiliarymonitorings', 'antilossmonitoring', 'homesignature', 'remoteecg', 'remotediagnosis', 'remoteimage', 'remoteb']
    }
  } else if (store.state.ShowHeadTab.findIndex(item => item.text === '智慧养老') === -1) {
    urls = ['generalcontrols', 'medicalpreventions', 'smarttests', 'chronicdisease', 'auxiliarymonitorings', 'antilossmonitoring', 'homesignature', 'remoteecg', 'remotediagnosis', 'remoteimage', 'remoteb']
  } else {
    if (store.state.ShowHeadTab.length === 1) {
      const arr = store.state.ShowHeadTab.filter(item => item.text === '智慧查体')
      if (arr.length > 0) {
        urls = ['studenttest']
      }
    } else if (store.state.ShowHeadTab.length > 1) {
      const arr = store.state.ShowHeadTab.filter(item => item.text === '智慧查体')
      if (arr.length > 0) {
        urls = ['generalcontrol', 'smarttest', 'medicalprevention', 'serviceMonitoring', 'data', 'studenttest', 'homecareserve', 'antilossmonitoring', 'vitalsignmonitoring', 'earlywarnmonitor', 'datascreen']
      }
    }
  }
  if (to.path.indexOf('zhdp') > -1) {
    store.commit('changeSmartLargeScreenIndex', urls.findIndex(item => item === to.path.split('/')[2]))
  }

  if (to.path === '/' || to.path === '/daping') {
    next()
  } else {
    Nprogress.start()
    if (localStorage.getItem('token')) {
      next()
    } else {
      next('/')
    }
  }
})

router.afterEach(() => {
  Nprogress.done()
})

export default router
