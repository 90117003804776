<!--  -->
<template>
  <div class='com-container com-container1'>
    <div class="com-title">
      <div class="com-title-left">辖区内公卫服务记录</div>
    </div>
    <div class="scroll-box">
      <div class="com-chart1 com-scroll" @mouseover="onmouseover" @mouseout="onmouseout" ref="scroll" :style="{height:fwjlList.length*53+'px'}">
        <div class="scroll-item" v-for="(item, index) in fwjlList" :key="index">
          <span class="scroll-item-left"> {{item.title}} </span>
          <span class="scroll-item-right">{{item.time}}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';

export default {
// import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
  // 这里存放数据
    return {
      fwjlList: [

      ]
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    async initData () {
      const params = {
        jgbh: this.$store.state.loginInfo.jgbh,
        jbmc: this.$store.state.loginInfo.jgmc,
        ssjgbhmc: this.$store.state.loginInfo.label,
        usercode: this.$store.state.loginInfo.usercode,
        username: this.$store.state.loginInfo.username,
        jgjb: this.$store.state.loginInfo.jgjb,
        ssjgbh: this.$store.state.loginInfo.value,
        sjjgbh: this.$store.state.loginInfo.sjjgbh
      }
      const { data: res } = await this.$http.post('api/jkct/app/zhdpgwfw', params)
      if (res.code === 0) {
        // // console.log('---------', res.data)
        this.fwjlList = res.data
      } else {
        this.$message.error(res.msg)
      }
    },
    onmouseover () {
      this.$refs.scroll.style.animationPlayState = 'paused'
    },
    onmouseout () {
      this.$refs.scroll.style.animationPlayState = 'running'
    },
    interval () {
      // // console.log()

      const styles = document.styleSheets
      const runkeyframes = `@keyframes rowup {
        0% {
            transform: translate3d(0, 0, 0);
        }
        100% {
            transform: translate3d(0, -${(this.fwjlList.length - 7) * 53}px, 0);
            display: none;
        }
      }`
      styles[0].insertRule(runkeyframes)
      // Array.from(styles).map((item, index) => {
      //   item.insertRule(runkeyframes)
      // })
      this.$refs.scroll.style.animationName = 'rowup'
      this.$refs.scroll.style.animationDuration = this.fwjlList.length + 's'
      this.$refs.scroll.style.animationIterationCount = 'infinite'
      this.$refs.scroll.style.animationTimingFunction = 'linear'
      this.$refs.scroll.style.animationDelay = '0'
      if (this.timer) {
        clearInterval(this.timer)
      }
      this.timer = setInterval(() => {
        this.initData()
      }, 3 * 1000 * 60)
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  beforeDestroy () {
    if (this.timer) {
      clearInterval(this.timer)
    }
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  async mounted () {
    await this.initData()
    // this.interval()
  }
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
  .com-container {
    .scroll-box {
        height: calc(100% - .875rem);
        overflow: hidden;
      .com-scroll {
        width: 92%;
        margin: 0 auto;
        overflow: hidden;
        position: relative;
        animation: 100s rowup linear infinite normal;
        .scroll-item {
          height: .5375rem;
          width: 100%;
          margin: .125rem 0;
          background: url('../../assets/img/scroll-bg.png') no-repeat center;
          position: relative;
          color: #01EEFD;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;

          &-left {
            font-size: 20px;
          }
          &-right {
            font-size: 20px;
          }
        }
      }
    }
  }
</style>
