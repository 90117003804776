<!-- 总览 -->
<template>
  <div class='zl'>
    <div class="top-btn">
      <div class="top-item">{{$store.state.loginInfo.jgmc}}<span style="color: #15EAF3;margin-left:30px">▼</span></div>
      <div class="top-item" style="color: #15EAF3;" @click="xsjgDia">下级机构：14 <span style="color: #15EAF3;margin-left:10px">></span></div>
      <div class="top-item" style="color: #15EAF3;" @click="jgpmDia">机构排名 ></div>
    </div>
    <div class="center"></div>
    <div class="zl-top zl-com">
      <div class="zl-item">
        <div class="label">{{allData[0].title}}</div>
        <div class="value">{{allData[0].value}} <span>人</span></div>
      </div>
      <div class="zl-item">
        <div class="label">{{allData[1].title}}</div>
        <div class="value">{{allData[1].value}} <span>个</span></div>
      </div>
    </div>
    <div class="zl-middle zl-com">
      <div class="zl-item">
        <div class="label">{{allData[2].title}}</div>
        <div class="value">{{allData[2].value}} <span>人</span></div>
      </div>
      <div class="zl-item">
        <div class="label">{{allData[3].title}}</div>
        <div class="value">{{allData[3].value}} <span>台 </span></div>
      </div>
    </div>
    <div class="zl-bottom zl-com">
      <div class="zl-item">
        <div class="label">{{allData[4].title}}</div>
        <div class="value">{{allData[4].value}} <span>人</span></div>
      </div>
      <div class="zl-item">
        <div class="label">{{allData[5].title}}</div>
        <div class="value">{{allData[5].value}} <span>人</span></div>
      </div>
    </div>
    <div class="zl-items">
      <div class="label">{{allData[6].title}}</div>
      <div class="value">{{allData[6].value}} <span>人</span></div>
    </div>
    <el-dialog
    top="5vh"
      :visible.sync="jgpmShow"
      width="1500px">
      <jgpmDia></jgpmDia>
    </el-dialog>
    <el-dialog
      :visible.sync="xsjgShow"
      width="800px">
      <xsjgDia></xsjgDia>
    </el-dialog>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import jgpmDia from '@/components/yiliao2/zlDialog/jgpm'
import xsjgDia from '@/components/yiliao2/zlDialog/xsjg'
export default {
// import引入的组件需要注入到对象中才能使用
  components: { jgpmDia, xsjgDia },
  data () {
  // 这里存放数据
    return {
      xsjgShow: false,
      jgpmShow: false,
      allData: [
        {}, {}, {}, {}, {}, {}, {}
      ]
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    jgpmDia () {
      this.jgpmShow = true
    },
    xsjgDia () {
      this.xsjgShow = true
    },
    async initData () {
      const params = {
        jgbh: this.$store.state.loginInfo.jgbh,
        jbmc: this.$store.state.loginInfo.jgmc,
        ssjgbhmc: this.$store.state.loginInfo.label,
        usercode: this.$store.state.loginInfo.usercode,
        username: this.$store.state.loginInfo.username,
        jgjb: this.$store.state.loginInfo.jgjb,
        ssjgbh: this.$store.state.loginInfo.value,
        sjjgbh: this.$store.state.loginInfo.sjjgbh
      }
      // const params = {
      //   jgbh: '',
      //   jbmc: '',
      //   ssjgbhmc: '',
      //   usercode: '',
      //   username: '',
      //   jgjb: '',
      //   ssjgbh: ''
      // }
      // 暂时屏蔽
      // const { data: res } = await this.$http.post('api/jkct/app/zhdpyfrhsyzsj', params)
      // if (res.code !== 0) return this.$message.error(res.msg)
      // this.allData = res.data
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.initData()
  },
  beforeCreate () {}, // 生命周期 - 创建之前
  beforeMount () {}, // 生命周期 - 挂载之前
  beforeUpdate () {}, // 生命周期 - 更新之前
  updated () {}, // 生命周期 - 更新之后
  beforeDestroy () {}, // 生命周期 - 销毁之前
  destroyed () {}, // 生命周期 - 销毁完成
  activated () {} // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style>
  .zl .el-dialog {
    background-image: url('../../assets/img/spms/bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border: 1px solid #27F6FF;
  }
</style>
<style lang='less' scoped>
//@import url(); 引入公共css类
.zl {
  width: 100%;
  height: 100%;
  position: relative;
  padding-top: 1px;
  .top-btn {
    width: 100%;
    display: flex;
    color: #FFFFFF;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    font-size: 14px;
    .top-item {
      border: 1px solid #15EAF3;
      margin: 0 20px;
      padding: 5px;
      box-sizing: border-box;
      cursor: pointer;
    }
  }
  .center {
    height: 200px;
    width: 200px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -100px;
    margin-top: -100px;
    background-image: url('../../assets/img/spms/zlbg.png');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    z-index: 99;
    animation: roate 20s linear infinite;
  }
  @keyframes roate {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  .zl-items {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 25%;
    height: 16%;
    background-image: url('../../assets/img/spms/zl-com-bg.png');
    background-repeat: no-repeat;
    text-align: center;
    background-size: 100% 100%;
    background-position: center;
    .label {
        padding-top: 12px;
        font-size: 15px;
        font-family: SourceHanSansCN-Bold, SourceHanSansCN;
        font-weight: bold;
        color: #FFFFFF;
      }
      .value {
        padding-top: 5px;
        font-size: 32px;
        font-family: DINAlternate-Bold, DINAlternate;
        color: #15EAF3;
        span {
          font-size: 16px;
          font-weight: 400;
        }
      }
  }
  .zl-com {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    .zl-item {
      width: 25%;
      height: 10%;
      background-image: url('../../assets/img/spms/zl-com-bg.png');
      background-repeat: no-repeat;
      text-align: center;
      background-size:  100% 100%;
      background-position: center;

      .label {
        padding-top: 12px;
        font-size: 15px;
        font-family: SourceHanSansCN-Bold, SourceHanSansCN;
        font-weight: bold;
        color: #FFFFFF;
      }
      .value {
        padding-top: 5px;
        font-size: 32px;
        font-family: DINAlternate-Bold, DINAlternate;
        color: #15EAF3;
        span {
          font-size: 16px;
          font-weight: 400;
        }
      }
    }
  }

}
</style>
