<!-- 下属机构 -->
<template>
  <div class='promp'>
    <div class="header">下属机构</div>
    <div class="bot-table">
      <div class="tbStyle">
      <table>
        <thead class="table-header">
          <td style="width:200px;">机构名称</td>
          <td style="width:300px;">联系电话</td>
          <td style="width:200px;">负责人</td>
        </thead>
        <div style="height:30px"></div>
        <tbody >
            <tr v-for="(i,index) in allData" :key="index" :class="[index % 2 == 0?'color1':'color2']">
              <td style="width:200px;">{{i.label}}</td>
              <td style="width:300px;">{{i.xdh}}</td>
              <td style="width:200px;">{{i.zrys}}</td>
            </tr>
        </tbody>
      </table>
      </div>
    </div>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';

export default {
// import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
  // 这里存放数据
    return {
      allData: []
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {
  },
  // 方法集合
  methods: {
    async initData () {
      // const params = {
      //   jgbh: this.$store.state.loginInfo.jgbh,
      //   jbmc: this.$store.state.loginInfo.jgmc,
      //   ssjgbhmc: this.$store.state.loginInfo.label,
      //   usercode: this.$store.state.loginInfo.usercode,
      //   username: this.$store.state.loginInfo.username,
      //   jgjb: this.$store.state.loginInfo.jgjb,
      //   ssjgbh: this.$store.state.loginInfo.value
      // }
      // const { data: res } = await this.$http.post('api/jkct/app/zhdpyfrhsygztx', params)
      // if (res.code !== 0) return this.$message.error(res.msg)
      // this.allDatas = res.data
      this.allData = this.$store.state.loginInfo.children
    },
    startInterval () {
      if (this.timerId) {
        clearInterval(this.timerId)
      }
      this.timerId = setInterval(() => {
        if (this.topbtn < 3) {
          this.topbtn++
        } else if (this.topbtn === 3) {
          this.topbtn = 1
        }
      }, 15000)
    },
    startInterval1 () {
      if (this.timerId1) {
        clearInterval(this.timerId1)
      }
      this.timerId = setInterval(() => {
        if (this.botbtn < 5) {
          this.botbtn++
        } else if (this.botbtn === 5) {
          this.botbtn = 1
        }
      }, 3000)
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  async mounted () {
    await this.initData()
    this.startInterval()
    this.startInterval1()
  },
  beforeCreate () {}, // 生命周期 - 创建之前
  beforeMount () {}, // 生命周期 - 挂载之前
  beforeUpdate () {}, // 生命周期 - 更新之前
  updated () {}, // 生命周期 - 更新之后
  beforeDestroy () {}, // 生命周期 - 销毁之前
  destroyed () {
    clearInterval(this.timerId1)
    clearInterval(this.timerId)
  }, // 生命周期 - 销毁完成
  activated () {} // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.promp {
  width: 100%;
  height: 100%;
  background-image: url('../../../assets/img/spms/workwarn.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  .header {
    text-align: center;
    color: #ffffff;
    font-size: 14px;
    font-weight: bold;
  }
  .bot-table {
    padding: 0 15px;
    font-size: 14px;
    .tag {
      display: flex;
      justify-content: center;
      .tag-item {
        margin: 0 5px;
        border: 1px solid #FCD042;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        color: #FCD042;
        background-color: rgba(252, 208, 66, 0.2);
      }
      .tang {
        border: 1px solid #FF6652;
        background: rgba(255, 102, 82, 0.2);
        color: #FF6652;
      }
    }
    .suifang {
      border: 1px solid #27F6FF;
      color: #27F6FF;
      cursor: pointer;
    }
    .color1 {
      background-color:  rgba(7, 46, 68, 0.8);
    }
    .color2 {
      background-color:  rgba(10, 74, 110, 0.8);;
    }
    .tbStyle {
      display: block;
      width: 700px;
      height: 300px;
      overflow-x: hidden;
      overflow-y: scroll;
      scrollbar-width: none; /* firefox */
      -ms-overflow-style: none; /* IE 10+ */
    }
    .tbStyle::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
    table {
      border-collapse: collapse;
      width: 700px;
      text-align: center;
        td {
          padding: 5px 0;
        }
      .table-header {
        color: #27F6FF;
        background-color: #072E44;
        border-top: 1px solid #27F6FF;
        border-bottom: 1px solid #27F6FF;
        margin-bottom: 10px;
        position: absolute;
        width: 700px;
      }
      tbody {
        td {
          color: #ffffff;
        }
      }
    }
  }
}
</style>
