<!--  -->
<template>
  <div class='com-container com-container1'>
    <div class="com-title">
      <div class="com-title-left" style="color:#fff;">血压趋势图</div>
    </div>
    <div class="com-chart com-chart1" ref="ref_rank"></div>
  </div>
</template>
<script>
import { getExpectDate } from '@/utils/utils'
export default {
  components: {},
  props: ['allData'],
  data () {
    return {
      datas: null
    }
  },
  watch: {
    allData (v) {
      this.datas = v
      this.initChart()
      // getExpectDate('2022-12-14', 'day', -1)
    }
  },
  methods: {
    getresize () {
      setTimeout(() => {
        this.chartInstance.resize()
      }, 100)
    },
    async initChart () {
      this.chartInstance = this.$echarts.init(this.$refs.ref_rank, 'chalk')
      const initOption = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            lineStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0,
                  color: 'rgba(0, 255, 233,0)'
                }, {
                  offset: 0.5,
                  color: 'rgba(255, 255, 255,1)'
                }, {
                  offset: 1,
                  color: 'rgba(0, 255, 233,0)'
                }],
                global: false
              }
            }
          }
        },
        grid: {
          top: '12%',
          left: '5%',
          right: '5%',
          bottom: '13%'
        // containLabel: true
        },
        xAxis: [{
          type: 'category',
          axisLine: {
            show: true
          },
          splitArea: {
            // show: true,
            color: '#f00',
            lineStyle: {
              color: '#f00'
            }
          },
          axisLabel: {
            color: '#fff'
          },
          splitLine: {
            show: false
          },
          boundaryGap: false,
          data: [getExpectDate('2022-12-14', 'day', '-6'), getExpectDate('2022-12-14', 'day', '-5'), getExpectDate('2022-12-14', 'day', '-4'),
            getExpectDate('2022-12-14', 'day', '-3'), getExpectDate('2022-12-14', 'day', '-2'), getExpectDate('2022-12-14', 'day', '-1'), '2022-12-14']

        }],

        yAxis: [{
          type: 'value',
          min: 0,
          // max: 140,
          splitNumber: 4,
          splitLine: {
            show: true,
            lineStyle: {
              color: 'rgba(255,255,255,0.1)'
            }
          },
          axisLine: {
            show: true
          },
          axisLabel: {
            show: true,
            margin: 20,
            color: '#d1e6eb'
          },
          axisTick: {
            show: false
          }
        }],
        series: [{
          name: '高压(收缩压)',
          type: 'line',
          smooth: false, // 是否平滑
          showAllSymbol: true,
          // symbol: 'image://./static/images/guang-circle.png',
          symbol: 'circle',
          symbolSize: 15,
          lineStyle: {
            color: '#00b3f4',
            shadowColor: 'rgba(0, 0, 0, .3)',
            shadowBlur: 0,
            shadowOffsetY: 5,
            shadowOffsetX: 5
          },
          label: {
            show: true,
            position: 'top',
            color: '#00b3f4'
          },
          itemStyle: {
            color: '#00b3f4',
            borderColor: '#fff',
            borderWidth: 3,
            shadowColor: 'rgba(0, 0, 0, .3)',
            shadowBlur: 0,
            shadowOffsetY: 2,
            shadowOffsetX: 2
          },
          areaStyle: {
            color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
              offset: 0,
              color: 'rgba(0,179,244,0.3)'
            },
            {
              offset: 1,
              color: 'rgba(0,179,244,0)'
            }
            ], false),
            shadowColor: 'rgba(0,179,244, 0.9)',
            shadowBlur: 20
          },
          data: [110, 120, 115, 118, 131, 103, 105]
        },
        {
          name: '低压(舒张压)',
          type: 'line',
          smooth: false, // 是否平滑
          showAllSymbol: true,
          // symbol: 'image://./static/images/guang-circle.png',
          symbol: 'circle',
          symbolSize: 15,
          lineStyle: {
            color: '#00b3f4',
            shadowColor: 'rgba(0, 0, 0, .3)',
            shadowBlur: 0,
            shadowOffsetY: 5,
            shadowOffsetX: 5
          },
          label: {
            show: true,
            position: 'top',
            color: '#00b3f4'
          },
          itemStyle: {
            color: '#00b3f4',
            borderColor: '#fff',
            borderWidth: 3,
            shadowColor: 'rgba(0, 0, 0, .3)',
            shadowBlur: 0,
            shadowOffsetY: 2,
            shadowOffsetX: 2
          },
          areaStyle: {
            color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
              offset: 0,
              color: 'rgba(0,179,244,0.3)'
            },
            {
              offset: 1,
              color: 'rgba(0,179,244,0)'
            }
            ], false),
            shadowColor: 'rgba(0,179,244, 0.9)',
            shadowBlur: 20
          },
          data: [60, 71, 56, 83, 79, 66, 69]
        },
        {
          name: '心率',
          type: 'line',
          smooth: false, // 是否平滑
          showAllSymbol: true,
          // symbol: 'image://./static/images/guang-circle.png',
          symbol: 'circle',
          symbolSize: 15,
          lineStyle: {
            color: '#00b3f4',
            shadowColor: 'rgba(0, 0, 0, .3)',
            shadowBlur: 0,
            shadowOffsetY: 5,
            shadowOffsetX: 5
          },
          label: {
            show: true,
            position: 'top',
            color: '#00b3f4'
          },
          itemStyle: {
            color: '#00b3f4',
            borderColor: '#fff',
            borderWidth: 3,
            shadowColor: 'rgba(0, 0, 0, .3)',
            shadowBlur: 0,
            shadowOffsetY: 2,
            shadowOffsetX: 2
          },
          areaStyle: {
            color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
              offset: 0,
              color: 'rgba(0,179,244,0.3)'
            },
            {
              offset: 1,
              color: 'rgba(0,179,244,0)'
            }
            ], false),
            shadowColor: 'rgba(0,179,244, 0.9)',
            shadowBlur: 20
          },
          data: [50, 60, 45, 69, 65, 54, 60]
        }
        ]
      }
      this.chartInstance.setOption(initOption)
    }
  },
  mounted () {
    this.datas = this.allData
    this.initChart()

    window.addEventListener('resize', this.getresize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.getresize)
    if (this.chartInstance) {
      this.chartInstance.dispose()
    }
  }
}
</script>
<style lang='less' scoped>
.com-title-left {
  width: 530px !important;
  height: 40px;
}

.com-chart1 {
  height: calc(100% - 50px) !important;

  div {
    height: 100% !important;
  }
}
</style>
