<template>
  <div class="screen-container" ref="container">
    <div class="loginBox" v-if="getLoginStatus">
      <login @child-login='login'></login>
    </div>
    <!-- <div class="screen-body" v-if="!getLoginStatus && loginType === 1">
      <section class="screen-left">
        <div id="left-top">
          <fwjl></fwjl>
        </div>
        <div id="left-bottom">
          <sjhz></sjhz>
        </div>
      </section>
      <section class="screen-middle">
        <div id="middle-top">
          <zl1></zl1>
        </div>
        <div id="middle-bottom">
          <zl2></zl2>
        </div>
      </section>
      <section class="screen-right">
        <div id="right-top">
          <div class="">

          </div>
          <tjndfx></tjndfx>
        </div>
        <div id="right-center">
          <tjycjgfx></tjycjgfx>
        </div>
        <div id="right-bottom">
          <jcrs></jcrs>
        </div>
      </section>
    </div> -->
    <div class="footer">
      <span class="span"> </span>
      <span class="span"> </span>
    </div>
    <div class="popup" v-if="showPopup">
      <div class="popup-box">
        <div class="el-icon-close popup-close" @click="showPopup = false"></div>
        <div class="popup-item" @click="select(1)">
          <img src="../assets/img/login6.png" class="popup-img">
          <span>公卫体检</span>
        </div>
        <div class="popup-item" @click="select(2)">
          <img src="../assets/img/login4.png" class="popup-img">
          <span>医防融合</span>
        </div>
        <div class="popup-item" @click="select(3)">
          <img src="../assets/img/login5.png" class="popup-img">
          <span>儿童健康</span>
        </div>
      </div>
    </div>
    <ChildrenForm v-if="false"></ChildrenForm>
    <MedicalHealthServe v-if="false"></MedicalHealthServe>
    <NeonateForm v-if="false"></NeonateForm>
    <OneEightHealthForm v-if="false"></OneEightHealthForm>
    <TwelveThirty v-if="false"></TwelveThirty>
    <ThreeSix v-if="false"></ThreeSix>
    <ZeroThree v-if="false"></ZeroThree>
  </div>
</template>

<script>
// import tjndfx from '@/components/yiliao/tjndfx'
// import tjycjgfx from '@/components/yiliao/tjycjgfx'
// import sjhz from '@/components/yiliao/sjhz'
// import jcrs from '@/components/yiliao/jcrs'
// import fwjl from '@/components/yiliao/fwjl'
// import zl1 from '@/components/yiliao/zl1'
// import zl2 from '@/components/yiliao/zl2'
import login from '@/components/login'
import ChildrenForm from './children-form.vue'
import MedicalHealthServe from './medical-health-serve.vue'
import NeonateForm from './neonate-form.vue'
import OneEightHealthForm from './one-eight-health-form.vue'
import TwelveThirty from './twelve-thirty.vue'
import ThreeSix from './three-six.vue'
import ZeroThree from './zero-three.vue'
import { mapGetters, mapState } from 'vuex'
export default {
  components: {
    login,
    ChildrenForm,
    MedicalHealthServe,
    NeonateForm,
    OneEightHealthForm,
    TwelveThirty,
    ThreeSix,
    ZeroThree
    // tjndfx: tjndfx,
    // tjycjgfx: tjycjgfx,
    // sjhz: sjhz,
    // jcrs: jcrs,
    // fwjl: fwjl,
    // zl1: zl1,
    // zl2: zl2
  },
  data () {
    return {
      nowTime: '',
      showFull: false,
      showPopup: false
    }
  },
  beforeCreate () {
  },
  computed: {
    ...mapGetters([
      'getLoginStatus'
    ]),
    ...mapState(['loginType', 'loginStatus'])
  },
  filters: {
    test () {
      // // console.log(that)
    }
  },
  mounted () {
    setInterval(this.getTime, 1000)
    // if (this.loginStatus) {
    //   this.showPopup = true
    // }
  },
  methods: {
    loginOut () {
      this.$confirm('确定要退出登录吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.showLogin = true
        this.$store.dispatch('changeLogin', true)
        this.$store.dispatch('changeLogins', {})
        this.$message({
          type: 'success',
          message: '退出登录成功!'
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消退出登录'
        })
      })
    },
    login (v, n) {
      this.showPopup = true
      this.$store.commit('changeLoginStatus', true)
      this.v = v
    },
    select (index) {
      // this.getBjData()
      // this.initWebsocket()
      if (index === 1) {
        this.$router.push({
          path: '/run'
        })
      }
      if (index === 2) {
        this.$router.push({
          path: '/spms'
        })
      }
      if (index === 3) {
        this.$router.push({
          path: '/zhyl'
        })
      }
    },
    // 全屏
    full () {
      this.showFull = true
      if (this.$refs.container.requestFullscreen) {
        this.$refs.container.requestFullscreen()
      } else if (this.$refs.container.mozRequestFullScreen) {
        this.$refs.container.mozRequestFullScreen()
      } else if (this.$refs.container.webkitRequestFullscreen) {
        this.$refs.container.webkitRequestFullscreen()
      } else if (this.$refs.container.msRequestFullscreen) {
        this.$refs.container.msRequestFullscreen()
      }
    },
    // 退出全屏
    exitFullscreen () {
      this.showFull = false
      if (document.exitFullScreen) {
        document.exitFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen()
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      }
    },
    getTime () {
      var myDate = new Date()
      var myYear = myDate.getFullYear() // 获取完整的年份(4位,1970-????)
      var myMonth = myDate.getMonth() + 1 // 获取当前月份(0-11,0代表1月)
      var myToday = myDate.getDate() // 获取当前日(1-31)
      var myDay = myDate.getDay() // 获取当前星期X(0-6,0代表星期天)
      var myHour = myDate.getHours() // 获取当前小时数(0-23)
      var myMinute = myDate.getMinutes() // 获取当前分钟数(0-59)
      var mySecond = myDate.getSeconds() // 获取当前秒数(0-59)
      var week = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']

      this.nowTime = myYear + '-' + this.fillZero(myMonth) + '-' + this.fillZero(myToday) + '  ' + this.fillZero(myHour) + ':' +
        this.fillZero(myMinute) + ':' + this.fillZero(mySecond) + '  ' + week[myDay] + '  '
    },

    fillZero (str) {
      var realNum
      if (str < 10) {
        realNum = '0' + str
      } else {
        realNum = str
      }
      return realNum
    }

  }

}
</script>
<style lang="less" scoped>
.popup {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .76);

  .popup-box {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 70%;
    height: 305px;
    padding: 0 26px;
    background-color: rgba(13, 40, 87, .59);
    box-sizing: border-box;

    .popup-close {
      position: absolute;
      top: 10px;
      right: 18px;
      color: #fff;
      font-size: 30px;
      cursor: pointer;
    }

    .popup-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 33.3%;
      height: 225px;
      background-image: url('../assets/img/login2.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      letter-spacing: 4px;
      color: #fff;
      font-size: 30px;
      font-weight: bold;
      cursor: pointer;
    }
  }
}

.loginBox {
  z-index: 10;
  position: absolute;
  width: 40%;
  height: 500px;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  animation: toptobot .5s linear;
}

@keyframes toptobot {
  0% {
    top: 0%;
    width: 0%;
  }

  100% {
    top: 50%;
    width: 50%;
  }
}

.shade {
  z-index: 9999;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
}

.fullscreen {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  z-index: 100;
}

.screen-container {
  position: relative;
  width: 100%;
  height: 100%;
  // background-color: #081628;
  background-image: url('../assets/img/login.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  color: #fff;
  box-sizing: border-box;
}

.footer {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 4%;
  display: flex;
  justify-content: center;
  width: 100%;
  color: #fff;
  font-size: 20px;

  .span {
    margin: 0 20px;
  }
}

.screen-header {
  width: 100%;
  height: 80px;
  font-size: 30px;
  position: relative;

  >div {
    img {
      width: 100%;
      height: 70px;
    }
  }

  .title {
    position: absolute;
    left: 50%;
    top: 50%;
    font-size: 20px;
    color: #FFFFFF;
    letter-spacing: 4px;
    font-weight: bold;
    transform: translate(-50%, -50%);
  }

  .title-left {
    position: absolute;
    color: #BAE0FF;
    left: 150px;
    top: 50%;
    font-size: 20px;
    transform: translateY(-80%);
    display: flex;
    align-items: center;

    img {
      width: 60px;
      height: 60px;
    }
  }

  .title-left-jgmc {
    position: absolute;
    color: #BAE0FF;
    font-size: 20px;
    left: 1200px;
    top: 50%;
    transform: translateY(-50%);
  }

  .title-right {
    display: flex;
    align-items: center;
    position: absolute;
    right: 200px;
    color: #BAE0FF;
    font-size: 20px;
    top: 50%;
    transform: translateY(-80%);
  }

  .qiehuan {
    width: 140px;
    height: 105px;
    cursor: pointer;
  }

  .datetime {
    font-size: 18px;
  }
}

.screen-body {
  width: 100%;
  height: 100%;
  padding: 0 100px;
  display: flex;
  margin-top: 50px;
  box-sizing: border-box;

  .screen-left {
    height: 100%;
    width: 27.6%;

    #left-top {
      height: 40%;
      position: relative;
    }

    #left-bottom {
      height: 42%;
      margin-top: 20px;
      position: relative;
    }
  }

  .screen-middle {
    height: 100%;
    width: 41.5%;
    margin-left: 1.6%;
    margin-right: 1.6%;

    #middle-top {
      width: 100%;
      height: 40%;
      position: relative;
    }

    #middle-bottom {
      margin-top: 20px;
      width: 100%;
      height: 42%;
      position: relative;
    }
  }

  .screen-right {
    height: 100%;
    width: 27.6%;

    #right-top {
      height: 26%;
      position: relative;
    }

    #right-center {
      margin-top: 20px;
      height: 26%;
      position: relative;
    }

    #right-bottom {
      margin-top: 20px;
      height: calc(48% - 520px);
      position: relative;
    }
  }
}

.screen-size {
  position: absolute;
  top: 2%;
  right: 480px;
  color: white;
  cursor: pointer;
  z-index: 999;

  div {
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 1;
  }
}

.loginOut {
  position: absolute;
  top: 1.6%;
  right: 160px;
  z-index: 999;
  font-size: 26px;
  cursor: pointer;
}

.resize {
  position: absolute;
  right: 100px;
  top: 100px;
  cursor: pointer;
}
</style>
