<template>
  <div class="form">
    <div class="form-box">
      <div class="form-close">
        <span class="el-icon-close"></span>
      </div>
      <div class="form-title">
        <span>中医药健康管理服务</span>
      </div>
      <div class="form-head">
        <span>姓名：程霞</span>
        <span>编号：</span>
      </div>
      <div class="form-scroll">
        <table cellpadding="0" cellspacing="0" border class="form-table">
          <tr>
            <td colspan="4" class="form-td form-td-text">
              <span>六到十八月龄</span>
            </td>
          </tr>
          <tr>
            <td colspan="1" class="form-td form-td-left">
              <span>月龄</span>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <span>6月龄</span>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <span>12月龄</span>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <span>18月龄</span>
            </td>
          </tr>
          <tr>
            <td colspan="1" class="form-td form-td-left">
              <span>随访日期</span>
            </td>
            <td colspan="1" class="form-td form-td-text">
              <el-date-picker
                v-if="show"
                v-model="date"
                size="small"
                type="date"
                placeholder="选择日期">
              </el-date-picker>
            </td>
            <td colspan="1" class="form-td form-td-text">
              <el-date-picker
                v-if="show"
                v-model="date"
                size="small"
                type="date"
                placeholder="选择日期">
              </el-date-picker>
            </td>
            <td colspan="1" class="form-td form-td-text">
              <el-date-picker
                v-if="show"
                v-model="date"
                size="small"
                type="date"
                placeholder="选择日期">
              </el-date-picker>
            </td>
          </tr>
          <tr>
            <td colspan="1" class="form-td form-td-left">
              <span>是否失访</span>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <el-radio-group v-model="radio">
                <el-radio label="1">是</el-radio>
                <el-radio label="2">否</el-radio>
              </el-radio-group>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <el-radio-group v-model="radio">
                <el-radio label="1">是</el-radio>
                <el-radio label="2">否</el-radio>
              </el-radio-group>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <el-radio-group v-model="radio">
                <el-radio label="1">是</el-radio>
                <el-radio label="2">否</el-radio>
              </el-radio-group>
            </td>
          </tr>
          <tr>
            <td colspan="1" class="form-td form-td-left">
              <span>失访原因</span>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <el-input v-if="show" size="small" class="input" v-model="input" placeholder=""></el-input>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <el-input v-if="show" size="small" class="input" v-model="input" placeholder=""></el-input>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <el-input v-if="show" size="small" class="input" v-model="input" placeholder=""></el-input>
            </td>
          </tr>
          <tr>
            <td colspan="1" class="form-td form-td-left">
              <span>中医药健康管理服务</span>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <el-checkbox-group v-model="checkList">
                <el-checkbox label="1">中医饮食调养指导</el-checkbox>
                <el-checkbox label="2">中医起居调养指导</el-checkbox>
                <el-checkbox label="3">传授按摩四神聪穴大法</el-checkbox>
              </el-checkbox-group>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <el-checkbox-group v-model="checkList">
                <el-checkbox label="1">中医饮食调养指导</el-checkbox>
                <el-checkbox label="2">中医起居调养指导</el-checkbox>
                <el-checkbox label="3">传授按摩四神聪穴大法</el-checkbox>
              </el-checkbox-group>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <el-checkbox-group v-model="checkList">
                <el-checkbox label="1">中医饮食调养指导</el-checkbox>
                <el-checkbox label="2">中医起居调养指导</el-checkbox>
                <el-checkbox label="3">传授按摩四神聪穴大法</el-checkbox>
              </el-checkbox-group>
            </td>
          </tr>
          <tr>
            <td colspan="1" class="form-td form-td-left">
              <span>下次随访时间：</span>
            </td>
            <td colspan="1" class="form-td form-td-text">
              <el-date-picker
                v-if="show"
                v-model="date"
                size="small"
                type="date"
                placeholder="选择日期">
              </el-date-picker>
            </td>
            <td colspan="1" class="form-td form-td-text">
              <el-date-picker
                v-if="show"
                v-model="date"
                size="small"
                type="date"
                placeholder="选择日期">
              </el-date-picker>
            </td>
            <td colspan="1" class="form-td form-td-text">
              <el-date-picker
                v-if="show"
                v-model="date"
                size="small"
                type="date"
                placeholder="选择日期">
              </el-date-picker>
            </td>
          </tr>
          <tr>
            <td colspan="1" class="form-td form-td-left">
              <span>随访医生签名：</span>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <el-input v-if="show" size="small" class="input" v-model="input" placeholder=""></el-input>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <el-input v-if="show" size="small" class="input" v-model="input" placeholder=""></el-input>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <el-input v-if="show" size="small" class="input" v-model="input" placeholder=""></el-input>
            </td>
          </tr>
          <tr>
            <td colspan="1" class="form-td form-td-left">
              <span>录入人：</span>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <el-input v-if="show" size="small" class="input" v-model="input" placeholder=""></el-input>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <el-input v-if="show" size="small" class="input" v-model="input" placeholder=""></el-input>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <el-input v-if="show" size="small" class="input" v-model="input" placeholder=""></el-input>
            </td>
          </tr>
          <tr>
            <td colspan="1" class="form-td form-td-left">
              <span>录入时间：</span>
            </td>
            <td colspan="1" class="form-td form-td-text">
              <el-date-picker
                v-if="show"
                v-model="date"
                size="small"
                type="date"
                placeholder="选择日期">
              </el-date-picker>
            </td>
            <td colspan="1" class="form-td form-td-text">
              <el-date-picker
                v-if="show"
                v-model="date"
                size="small"
                type="date"
                placeholder="选择日期">
              </el-date-picker>
            </td>
            <td colspan="1" class="form-td form-td-text">
              <el-date-picker
                v-if="show"
                v-model="date"
                size="small"
                type="date"
                placeholder="选择日期">
              </el-date-picker>
            </td>
          </tr>
          <tr>
            <td colspan="1" class="form-td form-td-left">
              <span>录入机构：</span>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <el-input v-if="show" size="small" class="input" v-model="input" placeholder=""></el-input>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <el-input v-if="show" size="small" class="input" v-model="input" placeholder=""></el-input>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <el-input v-if="show" size="small" class="input" v-model="input" placeholder=""></el-input>
            </td>
          </tr>
          <tr>
            <td colspan="4" class="form-td form-td-text">
              <span>二十四到三十六月龄</span>
            </td>
          </tr>
          <tr>
            <td colspan="1" class="form-td form-td-left">
              <span>月龄</span>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <span>24月龄</span>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <span>30月龄</span>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <span>36月龄</span>
            </td>
          </tr>
          <tr>
            <td colspan="1" class="form-td form-td-left">
              <span>随访日期</span>
            </td>
            <td colspan="1" class="form-td form-td-text">
              <el-date-picker
                v-if="show"
                v-model="date"
                size="small"
                type="date"
                placeholder="选择日期">
              </el-date-picker>
            </td>
            <td colspan="1" class="form-td form-td-text">
              <el-date-picker
                v-if="show"
                v-model="date"
                size="small"
                type="date"
                placeholder="选择日期">
              </el-date-picker>
            </td>
            <td colspan="1" class="form-td form-td-text">
              <el-date-picker
                v-if="show"
                v-model="date"
                size="small"
                type="date"
                placeholder="选择日期">
              </el-date-picker>
            </td>
          </tr>
          <tr>
            <td colspan="1" class="form-td form-td-left">
              <span>是否失访</span>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <el-radio-group v-model="radio">
                <el-radio label="1">是</el-radio>
                <el-radio label="2">否</el-radio>
              </el-radio-group>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <el-radio-group v-model="radio">
                <el-radio label="1">是</el-radio>
                <el-radio label="2">否</el-radio>
              </el-radio-group>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <el-radio-group v-model="radio">
                <el-radio label="1">是</el-radio>
                <el-radio label="2">否</el-radio>
              </el-radio-group>
            </td>
          </tr>
          <tr>
            <td colspan="1" class="form-td form-td-left">
              <span>失访原因</span>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <el-input v-if="show" size="small" class="input" v-model="input" placeholder=""></el-input>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <el-input v-if="show" size="small" class="input" v-model="input" placeholder=""></el-input>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <el-input v-if="show" size="small" class="input" v-model="input" placeholder=""></el-input>
            </td>
          </tr>
          <tr>
            <td colspan="1" class="form-td form-td-left">
              <span>中医药健康管理服务</span>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <el-checkbox-group v-model="checkList">
                <el-checkbox label="1">中医饮食调养指导</el-checkbox>
                <el-checkbox label="2">中医起居调养指导</el-checkbox>
                <el-checkbox label="3">传授按摩四神聪穴大法</el-checkbox>
              </el-checkbox-group>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <el-checkbox-group v-model="checkList">
                <el-checkbox label="1">中医饮食调养指导</el-checkbox>
                <el-checkbox label="2">中医起居调养指导</el-checkbox>
                <el-checkbox label="3">传授按摩四神聪穴大法</el-checkbox>
              </el-checkbox-group>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <el-checkbox-group v-model="checkList">
                <el-checkbox label="1">中医饮食调养指导</el-checkbox>
                <el-checkbox label="2">中医起居调养指导</el-checkbox>
                <el-checkbox label="3">传授按摩四神聪穴大法</el-checkbox>
              </el-checkbox-group>
            </td>
          </tr>
          <tr>
            <td colspan="1" class="form-td form-td-left">
              <span>下次随访时间：</span>
            </td>
            <td colspan="1" class="form-td form-td-text">
              <el-date-picker
                v-if="show"
                v-model="date"
                size="small"
                type="date"
                placeholder="选择日期">
              </el-date-picker>
            </td>
            <td colspan="1" class="form-td form-td-text">
              <el-date-picker
                v-if="show"
                v-model="date"
                size="small"
                type="date"
                placeholder="选择日期">
              </el-date-picker>
            </td>
            <td colspan="1" class="form-td form-td-text">
              <el-date-picker
                v-if="show"
                v-model="date"
                size="small"
                type="date"
                placeholder="选择日期">
              </el-date-picker>
            </td>
          </tr>
          <tr>
            <td colspan="1" class="form-td form-td-left">
              <span>随访医生签名：</span>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <el-input v-if="show" size="small" class="input" v-model="input" placeholder=""></el-input>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <el-input v-if="show" size="small" class="input" v-model="input" placeholder=""></el-input>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <el-input v-if="show" size="small" class="input" v-model="input" placeholder=""></el-input>
            </td>
          </tr>
          <tr>
            <td colspan="1" class="form-td form-td-left">
              <span>录入人：</span>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <el-input v-if="show" size="small" class="input" v-model="input" placeholder=""></el-input>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <el-input v-if="show" size="small" class="input" v-model="input" placeholder=""></el-input>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <el-input v-if="show" size="small" class="input" v-model="input" placeholder=""></el-input>
            </td>
          </tr>
          <tr>
            <td colspan="1" class="form-td form-td-left">
              <span>录入时间：</span>
            </td>
            <td colspan="1" class="form-td form-td-text">
              <el-date-picker
                v-if="show"
                v-model="date"
                size="small"
                type="date"
                placeholder="选择日期">
              </el-date-picker>
            </td>
            <td colspan="1" class="form-td form-td-text">
              <el-date-picker
                v-if="show"
                v-model="date"
                size="small"
                type="date"
                placeholder="选择日期">
              </el-date-picker>
            </td>
            <td colspan="1" class="form-td form-td-text">
              <el-date-picker
                v-if="show"
                v-model="date"
                size="small"
                type="date"
                placeholder="选择日期">
              </el-date-picker>
            </td>
          </tr>
          <tr>
            <td colspan="1" class="form-td form-td-left">
              <span>录入机构：</span>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <el-input v-if="show" size="small" class="input" v-model="input" placeholder=""></el-input>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <el-input v-if="show" size="small" class="input" v-model="input" placeholder=""></el-input>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <el-input v-if="show" size="small" class="input" v-model="input" placeholder=""></el-input>
            </td>
          </tr>
        </table>
      </div>
      <div class="form-button">
        <el-button type="primary" size="medium">确认</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      radio: null,
      date: '',
      checkList: [],
      input: '',
      show: true
    }
  }
}
</script>

<style lang="less" scoped>
.form {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 888;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .9);

  .form-box {
    position: relative;
    width: 70%;
    height: 95%;
    padding: 0 73px;
    border: 4px solid #21D6E0;
    box-sizing: border-box;

    .form-close {
      position: absolute;
      right: 30px;
      height: 50px;
      line-height: 50px;
      color: #fff;
      font-size: 20px;
      cursor: pointer;
    }

    .form-title {
      width: 100%;
      height: 60px;
      line-height: 60px;
      text-align: center;
      color: #01EEFD;
      font-size: 22px;
    }

    .form-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 50px;
      color: #01EEFD;
      font-size: 22px;
    }

    .form-scroll {
      overflow-y: scroll;
      width: 100%;
      height: calc(100% - 180px);

      .form-table {
        width: 100%;
        border-color: #01EEFD;

        .form-td {
          padding: 12px 0;
          box-sizing: border-box;
          color: #01EEFD;
          font-size: 14px;
          min-width: 170px;
        }

        .form-td-left {
          padding-left: 11px;
          box-sizing: border-box;
        }

        .form-td-text {
          text-align: center;
        }

        .el-radio {
          color: #fff;
        }

        .input {
          width: 90%;
        }

        .input1 {
          width: 100px;
        }

        .el-checkbox {
          color: #fff;
        }
      }
    }

    .form-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 70px;
    }
  }
}
</style>
