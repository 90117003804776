<!-- 年度分析 -->
<template>
  <div class='com-container com-container1'>
    <div class="com-title">
      <div class="com-title-left">体检异常结果分析</div>
    </div>
    <div class="com-chart com-chart1" ref="ref_rank"></div>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';

export default {
// import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
  // 这里存放数据
    return {
      chartInstance: null, // echarts实例
      allData: null, // 图表数据
      startValue: 0, // 区域缩放起始值
      endValue: 9, // 区域缩放终点值
      timerId: null,
      nameArrs: []
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    async initChart () {
      this.chartInstance = this.$echarts.init(this.$refs.ref_rank, 'chalk')
      const initOption = {
        grid: {
          containLabel: true,
          left: '2%',
          top: '10%',
          right: '2%',
          bottom: '2%'
        },
        tooltip: {
          show: true
        },
        legend: {},
        xAxis: {
          type: 'category'
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            type: 'bar',
            label: {
              normal: {
                show: true,
                position: 'top',
                color: 'rgba(252,147,61,1)'
              }
            }
          }
        ]
      }
      this.chartInstance.setOption(initOption)
      // 鼠标移入移出操作
      // this.chartInstance.on('mouseover', () => {
      //   clearInterval(this.timerId)
      // })
      // this.chartInstance.on('mouseout', () => {
      //   this.startInterval()
      // })
    },
    async getData () {
      const params = {
        jgbh: this.$store.state.loginInfo.jgbh,
        jbmc: this.$store.state.loginInfo.jgmc,
        ssjgbhmc: this.$store.state.loginInfo.label,
        usercode: this.$store.state.loginInfo.usercode,
        username: this.$store.state.loginInfo.username,
        jgjb: this.$store.state.loginInfo.jgjb,
        ssjgbh: this.$store.state.loginInfo.value,
        sjjgbh: this.$store.state.loginInfo.sjjgbh
      }
      const { data: res } = await this.$http.post('api/jkct/app/zhdpgwfwtjycjgfx', params)
      if (res.code !== 0) this.$message.error('res.msg')
      this.allData = res.data
      res.data.map(item => {
        this.nameArrs.push(item.name)
      })
      // this.allData = [
      //   {
      //     name: '血压异常',
      //     value: 8163
      //   }, {
      //     name: '肾功能异常',
      //     value: 2627
      //   }, {
      //     name: '尿常规异常',
      //     value: 6512
      //   }, {
      //     name: '血糖异常',
      //     value: 5551
      //   }, {
      //     name: 'B超异常',
      //     value: 3701
      //   }, {
      //     name: '心电图异常',
      //     value: 8161
      //   }
      // ]
      this.updataChart()
    },
    updataChart () {
      // 绘制左侧面
      const CubeLeft = this.$echarts.graphic.extendShape({
        shape: {
          x: 0,
          y: 0
        },
        buildPath: function (ctx, shape) {
          // 会canvas的应该都能看得懂，shape是从custom传入的
          const xAxisPoint = shape.xAxisPoint
          const c0 = [shape.x + 7 * 2 / 5, shape.y]
          const c1 = [shape.x - 23 * 2 / 5, shape.y - 6 * 2 / 5]
          const c2 = [xAxisPoint[0] - 23 * 2 / 5, xAxisPoint[1] - 13 * 2 / 5]
          const c3 = [xAxisPoint[0] + 7 * 2 / 5, xAxisPoint[1]]
          ctx.moveTo(c0[0], c0[1]).lineTo(c1[0], c1[1]).lineTo(c2[0], c2[1]).lineTo(c3[0], c3[1]).closePath()
        }
      })
      // 绘制右侧面
      const CubeRight = this.$echarts.graphic.extendShape({
        shape: {
          x: 0,
          y: 0
        },
        buildPath: function (ctx, shape) {
          const xAxisPoint = shape.xAxisPoint
          const c1 = [shape.x + 7 * 2 / 5, shape.y]
          const c2 = [xAxisPoint[0] + 7 * 2 / 5, xAxisPoint[1]]
          const c3 = [xAxisPoint[0] + 25 * 2 / 5, xAxisPoint[1] - 15 * 2 / 5]
          const c4 = [shape.x + 25 * 2 / 5, shape.y - 15 * 2 / 5]
          ctx.moveTo(c1[0], c1[1]).lineTo(c2[0], c2[1]).lineTo(c3[0], c3[1]).lineTo(c4[0], c4[1]).closePath()
        }
      })
      // 绘制顶面
      const CubeTop = this.$echarts.graphic.extendShape({
        shape: {
          x: 0,
          y: 0
        },
        buildPath: function (ctx, shape) {
          const c1 = [shape.x + 7 * 2 / 5, shape.y]
          const c2 = [shape.x + 25 * 2 / 5, shape.y - 15 * 2 / 5] // 右点
          const c3 = [shape.x - 5 * 2 / 5, shape.y - 20 * 2 / 5]
          const c4 = [shape.x - 23 * 2 / 5, shape.y - 6 * 2 / 5]
          ctx.moveTo(c1[0], c1[1]).lineTo(c2[0], c2[1]).lineTo(c3[0], c3[1]).lineTo(c4[0], c4[1]).closePath()
        }
      })
      // 注册三个面图形
      this.$echarts.graphic.registerShape('CubeLeft', CubeLeft)
      this.$echarts.graphic.registerShape('CubeRight', CubeRight)
      this.$echarts.graphic.registerShape('CubeTop', CubeTop)
      // 颜色渐变数组
      // const colorArr = [
      //   ['rgba(252,147,61,1)', 'rgba(252,147,61,.1)']
      // ]
      // const nameArr = ['血压异常', '肾功能异常', '血糖异常', 'B超异常', '肝功能异常', '心电图异常']
      const nameArr = this.nameArrs
      const valueArr = this.allData.map(item => {
        return item.value
      })
      const dataOption = {
        xAxis: {
          data: nameArr,
          axisLabel: {
            inside: false,
            interval: 0,
            textStyle: {
              color: '#ffffff',
              fontSize: '12'
            }
          }
        },
        series: [
          {
            type: 'custom',
            renderItem: (params, api) => {
              let cubeLeftStyle = ''
              let cubeRightStyle = ''
              let cubeTopStyle = ''
              if (params.dataIndex === 0) {
                cubeLeftStyle = new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: 'rgba(255,156,125,1)'
                }, {
                  offset: 1,
                  color: 'rgba(254,116,114,1)'
                }])
                cubeRightStyle = new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: 'rgba(251,142,110,1)'
                }, {
                  offset: 1,
                  color: 'rgba(231,99,96,1)'
                }])
                cubeTopStyle = new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: 'rgba(212,128,110,1)'
                }, {
                  offset: 1,
                  color: 'rgba(249,116,113,1)'
                }])
              } else if (params.dataIndex === 1) {
                cubeLeftStyle = new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: 'rgba(191,145,246,1)'
                }, {
                  offset: 1,
                  color: 'rgba(108,75,246,1)'
                }])
                cubeRightStyle = new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: 'rgba(173,120,239,1)'
                }, {
                  offset: 1,
                  color: 'rgba(95,65,215,1)'
                }])
                cubeTopStyle = new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: 'rgba(191,145,246,1)'
                }, {
                  offset: 1,
                  color: 'rgba(108,75,246,1)'
                }])
              } else if (params.dataIndex === 2) {
                cubeLeftStyle = new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: 'rgba(253,68,68,1)'
                }, {
                  offset: 1,
                  color: 'rgba(160,1,0,1)'
                }])
                cubeRightStyle = new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: 'rgba(214,45,45,1)'
                }, {
                  offset: 1,
                  color: 'rgba(121,1,0,1)'
                }])
                cubeTopStyle = new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: 'rgba(253,68,68,1)'
                }, {
                  offset: 1,
                  color: 'rgba(160,1,0,1)'
                }])
              } else if (params.dataIndex === 3) {
                cubeLeftStyle = new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: 'rgba(3,226,240,1)'
                }, {
                  offset: 1,
                  color: 'rgba(6,164,171,1)'
                }])
                cubeRightStyle = new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: 'rgba(3,226,240,1)'
                }, {
                  offset: 1,
                  color: 'rgba(1,146,153,1)'
                }])
                cubeTopStyle = new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: 'rgba(3,226,240,1)'
                }, {
                  offset: 1,
                  color: 'rgba(6,164,171,1)'
                }])
              } else if (params.dataIndex === 4) {
                cubeLeftStyle = new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: 'rgba(36,129,250,1)'
                }, {
                  offset: 1,
                  color: 'rgba(9,61,196,1)'
                }])
                cubeRightStyle = new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: 'rgba(31,114,224,1)'
                }, {
                  offset: 1,
                  color: 'rgba(31,115,225,1)'
                }])
                cubeTopStyle = new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: 'rgba(36,129,250,1)'
                }, {
                  offset: 1,
                  color: 'rgba(9,61,196,1)'
                }])
              } else if (params.dataIndex === 5) {
                cubeLeftStyle = new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: 'rgba(255,176,35,1)'
                }, {
                  offset: 1,
                  color: 'rgba(255,96,56,1)'
                }])
                cubeRightStyle = new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: 'rgba(224,83,44,1)'
                }, {
                  offset: 1,
                  color: 'rgba(224,83,44,1)'
                }])
                cubeTopStyle = new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: 'rgba(255,176,35,1)'
                }, {
                  offset: 1,
                  color: 'rgba(255,96,56,1)'
                }])
              }
              const location = api.coord([api.value(0), api.value(1)])
              return {
                type: 'group',
                children: [{
                  type: 'CubeLeft',
                  shape: {
                    api,
                    xValue: api.value(0),
                    yValue: api.value(1),
                    x: location[0],
                    y: location[1],
                    xAxisPoint: api.coord([api.value(0), 0])
                  },
                  style: {
                    fill: cubeLeftStyle
                  }
                }, {
                  type: 'CubeRight',
                  shape: {
                    api,
                    xValue: api.value(0),
                    yValue: api.value(1),
                    x: location[0],
                    y: location[1],
                    xAxisPoint: api.coord([api.value(0), 0])
                  },
                  style: {
                    fill: cubeRightStyle
                  }
                },
                {
                  type: 'CubeTop',
                  shape: {
                    api,
                    xValue: api.value(0),
                    yValue: api.value(1),
                    x: location[0],
                    y: location[1],
                    xAxisPoint: api.coord([api.value(0), 0])
                  },
                  style: {
                    fill: cubeTopStyle
                  }
                }

                ]
              }
            },
            data: valueArr
          }, {
            type: 'bar',
            label: {
              normal: {
                show: true,
                position: 'top',
                offset: [2, -5],
                color: '#01EAF9'
              }
            },
            itemStyle: {
              color: 'transparent'
            },
            data: valueArr
          }
        ]
      }
      this.chartInstance.setOption(dataOption)
    },
    screenAdapter () {
      const titleFontSize = this.$refs.ref_rank.offsetWidth / 100 * 3.6
      const adapterOption = {
        series: [
          {
            barWidth: titleFontSize * 0.2
          }
        ]
      }
      this.chartInstance.setOption(adapterOption)
      this.chartInstance.resize()
    },
    startInterval () {
      if (this.timerId) {
        clearInterval(this.timerId)
      }
      this.timerId = setInterval(() => {
        this.startValue++
        this.endValue++
        if (this.endValue > this.allData.length - 1) {
          this.startValue = 0
          this.endValue = 9
        }
        this.updataChart()
      }, 2000)
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.initChart()
    this.getData()
    window.addEventListener('resize', this.screenAdapter)
    this.screenAdapter()
    // this.startInterval()
  },
  beforeCreate () {}, // 生命周期 - 创建之前
  beforeMount () {}, // 生命周期 - 挂载之前
  beforeUpdate () {}, // 生命周期 - 更新之前
  updated () {}, // 生命周期 - 更新之后
  beforeDestroy () {}, // 生命周期 - 销毁之前
  destroyed () {
    window.removeEventListener('resize', this.screenAdapter)
    clearInterval(this.timerId)
  }, // 生命周期 - 销毁完成
  activated () {} // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>

<style scoped lang="less">
/* // @import url(); 引入公共css类 */

</style>
