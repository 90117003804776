<!-- 智慧养老新 -->
<template>
  <div class='zhyl'
    :class="{ 'index-background': headIndex === 0 || headIndex === 3 || headIndex === 2 || headIndex === 4 || headIndex === 12 }">
    <div class="zhylHeader">
      <div class="zhylHeader-left"
        :class="{ 'head-left': headIndex === 0 || headIndex === 3 || headIndex === 2 || headIndex === 4 || headIndex === 12 }">
        <div class="zhyl-head">
          <div class="item" v-for="(item, index) in heads" :key="index" @click="headClick(item.index)">
            <img class="img" :src="item.index == headIndex ? item.active : item.url">
            <span class="text" :class="{
              active: item.index == headIndex
            }">{{ item.text }}</span>
          </div>
        </div>
      </div>
      <div class="zhylHeader-center"
        :class="{ 'head-center': headIndex === 0 || headIndex === 3 || headIndex === 2 || headIndex === 4 || headIndex === 12 }">
        <span>{{ titleName }}</span>
      </div>
      <div class="zhylHeader-right"
        :class="{ 'head-right': headIndex === 0 || headIndex === 3 || headIndex === 2 || headIndex === 4 || headIndex === 12 }">
        <div class="zhyl-head zhyl-head1" ref="scroll">
          <div class="zhyl-head-box">
            <div class="item" :id="'item' + item.index" v-for="(item, index) in heads1" :key="index" @click="headClick(item.index)">
              <img class="img" :src="item.index == headIndex ? item.active : item.url">
              <span class="text" :class="{
                active: item.index == headIndex
              }">{{ item.text }}</span>
            </div>
          </div>
        </div>
        <el-tooltip class="item" effect="dark" :content="this.SocketInfo === null ? '断开，可点击重连！' : '已连接！'"
          placement="bottom">
          <div class="zhylHeader-right-loginout" @click="loginSocket">
            <i class="el-icon-s-promotion blue-icon" :class="{ 'red-icon': this.SocketInfo === null }"></i>
          </div>
        </el-tooltip>

        <div class="zhylHeader-right-loginout" @click="openMonitor">
          <i class="el-icon-s-platform red-icon"></i>
        </div>
        <div class="zhylHeader-right-loginout" @click="openWarn">
          <el-badge value="">
            <i class="el-icon-message-solid badge-icon"></i>
          </el-badge>
        </div>
        <div class="zhylHeader-right-loginout">
          <i class="el-icon-switch-button" @click="loginOut"></i>
        </div>
      </div>
    </div>
    <div class="page-return" @click="back">
      <span class="el-icon-arrow-left"></span>
    </div>
    <div class="tab-content">
      <router-view></router-view>
      <div class="after-sales" @click="openAfterSales">
        <span>售后</span>
      </div>
    </div>
    <!-- 入住管理 -->
    <MoveInto v-if="showMoveInto" @close="closeMoveInto"></MoveInto>
    <!-- 上门服务 -->
    <DoorService v-if="showDoorService" @close="closeDoorService"></DoorService>
    <!-- 电子围栏 -->
    <ElectronicFence v-if="showElectronicFence" @close="closeElectronicFence"></ElectronicFence>
    <!-- 报警信息列表 -->
    <Warn v-if="showWarn" @close="closeWarn"></Warn>
    <!-- 监测记录 -->
    <Monitor v-if="showMonitor" @close="closeMonitor"></Monitor>
    <!-- 消息管理 -->
    <MessageRun v-if="false"></MessageRun>
    <demo v-if="false"></demo>
    <!-- 售后 -->
    <AfterSales v-if="showAfterSales" @close="closeAfterSales"></AfterSales>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import MoveInto from './popup/moveInto.vue'
import DoorService from './popup/doorService.vue'
import ElectronicFence from './popup/electronicFence.vue'
import AfterSales from './popup/afterSales.vue'
import Warn from './warnPopup.vue'
import Monitor from './monitorPopup.vue'
import MessageRun from './messageRun.vue'
import demo from './demo.vue'
export default {
  components: {
    MoveInto,
    DoorService,
    ElectronicFence,
    AfterSales,
    Warn,
    Monitor,
    MessageRun,
    demo
  },
  computed: {
    ...mapState(['SocketInfo', 'SocketCode', 'loginInfo', 'ShowHeadTab']),
    titleName () {
      const list = this.loginInfo.permissions.filter(item => item.parentId === 0 && item.id === 144)
      // console.log('标题：', list[0].name)
      if (list.length < 1) return '智慧慢病医防融合服务平台'
      return list[0].name
    }
  },
  data () {
    // 这里存放数据
    return {
      headIndex: 0,
      heads: [
        { url: require('../../assets/img/index/tab1.png'), active: require('../../assets/img/index/tab1-active.png'), text: '主页', index: 0 },
        { url: require('../../assets/img/index/tab2.png'), active: require('../../assets/img/index/tab2-active.png'), text: '智慧医疗', index: 1 },
        { url: require('../../assets/img/index/tab3.png'), active: require('../../assets/img/index/tab3-active.png'), text: '智慧公卫', index: 2 },
        { url: require('../../assets/img/index/tab12.png'), active: require('../../assets/img/index/tab12.png'), text: '智慧查体', index: 3 },
        { url: require('../../assets/img/index/tab4.png'), active: require('../../assets/img/index/tab4-active.png'), text: '智慧养老', index: 4 },
        { url: require('../../assets/img/index/tab5.png'), active: require('../../assets/img/index/tab5-active.png'), text: '智慧家签', index: 5 },
        { url: require('../../assets/img/index/tab6.png'), active: require('../../assets/img/index/tab6-acitve.png'), text: '智慧慢病', index: 6 }
      ],
      heads1: [
        { url: require('../../assets/img/index/tab9.png'), active: require('../../assets/img/index/tab9-active.png'), text: '预约服务', index: 7 },
        { url: require('../../assets/img/index/tab10.png'), active: require('../../assets/img/index/tab10-acitve.png'), text: '机构管理', index: 8 },
        { url: require('../../assets/img/index/tab10.png'), active: require('../../assets/img/index/tab10-acitve.png'), text: '智能病床', index: 12 },
        { url: require('../../assets/img/index/tab11.png'), active: require('../../assets/img/index/tab11-acitve.png'), text: '智能硬件', index: 9 },
        { url: require('../../assets/img/index/tab7.png'), active: require('../../assets/img/index/tab7-active.png'), text: '远程会诊', index: 10 },
        { url: require('../../assets/img/index/tab8.png'), active: require('../../assets/img/index/tab8-active.png'), text: '智慧大屏', index: 11 }
      ],
      value1: 1,
      value2: 1,
      wlFw: 0,
      headerMenuIndex: 0,
      menuIndex: 'glkb',
      showXt: false,
      showXy: false,
      showXya: false,
      showTw: false,
      showXl: false,
      showJgpm: false,
      showXjjg: false,
      showYyfw: false,
      showKfjh: false,
      showRzDia: false, // 入住登记
      showSmDia: false, // 上门服务
      showSmDitu: false, // 上门服务地图
      showWlDia: false, // 电子围栏
      isCircle: false, // 是否是圆形围栏
      pageParams: {
        start: 1,
        length: 16
      },
      searchVal: '',
      isCollapse: false,
      timerId: null,
      dataList: [],
      activePath: '',
      showMoveInto: false,
      showDoorService: false,
      showElectronicFence: false,
      showDialog: false,
      list: [],
      showWarn: false,
      showMonitor: false,
      socket: null,
      code: '',
      showAfterSales: false,
      showBox: false
    }
  },
  methods: {
    initRouter () {
      this.activePath = window.sessionStorage.getItem('activePath')
      this.headIndex = Number.parseInt(window.sessionStorage.getItem('headIndex')) || 0
      // console.log('-----', this.headIndex)
      const tab = this.loginInfo.permissions.filter(item => item.parentId === 144 && item.type === 1)
      // console.log('路由：', tab)
      // console.log('----------------')
      this.$store.commit('ChangeShowHeadTab', [...this.heads, ...this.heads1])
      if (tab.length < 1) return
      const heads = [
        { url: require('../../assets/img/index/tab1.png'), active: require('../../assets/img/index/tab1-active.png'), text: '主页', index: 0 },
        { url: require('../../assets/img/index/tab2.png'), active: require('../../assets/img/index/tab2-active.png'), text: '智慧医疗', index: 1 },
        { url: require('../../assets/img/index/tab3.png'), active: require('../../assets/img/index/tab3-active.png'), text: '智慧公卫', index: 2 },
        { url: require('../../assets/img/index/tab12.png'), active: require('../../assets/img/index/tab12.png'), text: '智慧查体', index: 3 },
        { url: require('../../assets/img/index/tab4.png'), active: require('../../assets/img/index/tab4-active.png'), text: '智慧养老', index: 4 },
        { url: require('../../assets/img/index/tab5.png'), active: require('../../assets/img/index/tab5-active.png'), text: '智慧家签', index: 5 },
        { url: require('../../assets/img/index/tab6.png'), active: require('../../assets/img/index/tab6-acitve.png'), text: '智慧慢病', index: 6 }
      ]
      const heads1 = [
        { url: require('../../assets/img/index/tab9.png'), active: require('../../assets/img/index/tab9-active.png'), text: '预约服务', index: 7 },
        { url: require('../../assets/img/index/tab10.png'), active: require('../../assets/img/index/tab10-acitve.png'), text: '机构管理', index: 8 },
        { url: require('../../assets/img/index/tab10.png'), active: require('../../assets/img/index/tab10-acitve.png'), text: '智能病区', index: 12 },
        { url: require('../../assets/img/index/tab11.png'), active: require('../../assets/img/index/tab11-acitve.png'), text: '智能硬件', index: 9 },
        { url: require('../../assets/img/index/tab7.png'), active: require('../../assets/img/index/tab7-active.png'), text: '远程会诊', index: 10 },
        { url: require('../../assets/img/index/tab8.png'), active: require('../../assets/img/index/tab8-active.png'), text: '智慧大屏', index: 11 }
      ]
      // this.heads = heads
      // this.heads1 = heads1

      let tabL = []
      let tabR = []
      for (let index = 0; index < tab.length; index++) {
        const url1 = heads.filter(item => item.text === tab[index].name)
        tabL = [...tabL, ...url1]
        const url2 = heads1.filter(item => item.text === tab[index].name)
        tabR = [...tabR, ...url2]
      }
      if (tabL.findIndex(item => item.text === '智慧慢病') > -1) {
        const arr1 = tabL.filter(item => item.text === '智慧慢病')
        const arr2 = tabL.filter(item => item.text !== '智慧慢病')
        tabL = [...arr1, ...arr2]
      }
      this.heads = tabL
      this.heads1 = tabR
      this.showBox = this.heads.findIndex(item => item.text === '智慧养老') === -1
      if (this.showBox) {
        const arr = [
          { url: require('../../assets/img/index/tab7.png'), active: require('../../assets/img/index/tab7-active.png'), text: '远程B超', index: 13 },
          { url: require('../../assets/img/index/tab7.png'), active: require('../../assets/img/index/tab7-active.png'), text: '远程心电', index: 14 },
          { url: require('../../assets/img/index/tab7.png'), active: require('../../assets/img/index/tab7-active.png'), text: '远程影像', index: 15 }
        ]
        this.heads1 = [...this.heads1, ...arr]
      }
      this.$store.commit('ChangeShowHeadTab', [...this.heads, ...this.heads1])
      if (Number.parseInt(window.sessionStorage.getItem('headIndex'))) {
        this.headClick(this.headIndex)
      } else {
        this.headClick(this.heads[0].index)
      }
    },
    close () {
      this.socket.close()
    },
    loginSocket () {
      try {
        if (this.SocketInfo !== null) return
        // 创建WebSocket连接
        if (!this.SocketCode) {
          const code = parseInt(Math.random() * 100000) + ''
          this.code = code.padStart(5, '0')
          this.$store.commit('ChangeSocketCode', this.code)
        }
        // 创建WebSocket连接
        this.socket = new WebSocket('ws://zhtj.zkwl.cc:63000/api/WebSocketwx/' + this.loginInfo.jgbh + this.SocketCode + '/')
        // console.log(socket)

        this.$store.commit('ChangeSocketInfo', this.socket)

        // 连接成功时的回调函数
        this.socket.onopen = function () {
          console.log('WebSocket连接已打开')
          // 发送消息
          // socket.send('Hello WebSocket')

          // setTimeout(() => {
          //   this.SocketInfo.close()
          // }, 4000)
        }

        // 监听接收到消息事件
        this.socket.onmessage = data => {
          console.log('WebSocket监听消息:', data)
          if (data.data && data.data.length > 5) {
            this.$store.commit('ChangeSocketData', JSON.parse(data.data))
          }
        }
        // 连接错误
        this.socket.onerror = error => {
          console.error('WebSocket连接错误:', error)
          this.$store.commit('ChangeSocketInfo', null)
        }

        // 关闭连接
        this.socket.onclose = () => {
          console.log('WebSocket连接已关闭')
          this.$store.commit('ChangeSocketInfo', null)
          const token = localStorage.getItem('token')
          if (token) {
            console.log('将在60秒后尝试重新连接...')
            setTimeout(this.loginSocket, 60000) // 等待一段时间后重新连接
          }
        }
      } catch (error) {
        console.log(error)
      }
    },
    headClick (index) {
      console.log(index)
      if (this.headIndex === index) return
      this.headIndex = index
      const list = ['runkanban', 'smarthealthcare', 'smartpublicguard', 'smartphysicalexamination', 'smartelderlycare', 'wisdomhomesignature', 'intelligentmonitoring', 'reservationservice', 'organrun', 'intelligenthardware', 'problemfeedback', 'zhdp', 'smartbeds', 'bultrasonics', 'ecgs', 'images']
      if (index !== 11) {
        window.sessionStorage.removeItem(list[index] + 'Path')
        window.sessionStorage.setItem('headIndex', index)
        this.$router.push('/' + list[index])
      } else {
        console.log(this.showBox)
        if (this.loginInfo.usercode === 'tj000') return this.$router.push('/zhdp/studenttest')
        if (this.loginInfo.usercode === 'xxsqlhj') return this.$router.push('/zhdp/generalcontrols')
        if (this.showBox) return this.$router.push('/zhdp/generalcontrols')
        this.$router.push('/zhdp/generalcontrol')
      }

      if (index > 6) {
        this.$refs.scroll.scrollLeft = 200
        document.getElementById('item' + this.headIndex).scrollIntoView({
          behavior: 'smooth', block: 'end', inline: 'nearest'
        })
      }
    },
    openMoveInto () {
      this.showMoveInto = true
    },
    closeMoveInto () {
      this.showMoveInto = false
    },
    openDoorService () {
      this.showDoorService = true
    },
    closeDoorService () {
      this.showDoorService = false
    },
    openElectronicFence () {
      this.showElectronicFence = true
    },
    closeElectronicFence () {
      this.showElectronicFence = false
    },
    saveNavState (path) {
      window.sessionStorage.setItem('activePath', path)
      this.activePath = path
    },

    handleOpen (key, keyPath) { },
    showSmDitus () {
      this.showSmDitu = true
    },
    wlCommit () {
      this.$message.success('提交成功！')
    },
    rzCommit () {
      this.$message.success('提交成功！')
      this.showRzDia = false
    },
    async getData () {
      const params = {
        jgbh: this.loginInfo.jgbh,
        ssjgbh: this.loginInfo.jgbh,
        start: this.pageParams.start,
        length: this.pageParams.length
      }
      const { data: res } = await this.$http.get('/ykdzhylfwlbs', { params: params })
      // // console.log('res', res)
      if (res.code === 0) {
        this.dataList = []
        this.dataList = res.data
      }
    },
    dataInterval () {
      if (this.timerId) {
        clearInterval(this.timerId)
      }
      this.timerId = setInterval(() => {
        if (this.pageParams.start < Math.ceil(34 / this.pageParams.length)) {
          this.pageParams.start++
          // // console.log(this.pageParams.start)
        } else {
          this.pageParams.start = 1
        }
        this.getData()
      }, 8000)
    },
    pre () {
      if (this.pageParams.start === 1) {
        this.$message.warning('已经是第一页了')
      } else {
        this.pageParams.start--
        this.getData()
      }
    },
    next () {
      this.pageParams.start++
      this.getData()
    },
    refresh () {
      this.pageParams.start = 1
      this.getData()
    },
    search () {
      this.$message.success('搜索')
    },
    tabClick (item, index) {
      this.headerMenuIndex = index
      if (item.value === 'index') {
        this.menuIndex = 'glkb'
      } else if (item.value === 'ruzhu') {
        this.openMoveInto()
      } else if (item.value === 'truck') {
        this.openDoorService()
      } else if (item.value === 'fence') {
        this.openElectronicFence()
      } else {
        this.$message.warning('功能暂未开放！')
      }
    },
    loginOut () {
      this.$confirm('确定要退出登录吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        callback: action => {
          if (action === 'cancel') {
            return this.$message({
              type: 'info',
              message: '已取消退出登录'
            })
          }
          if (action === 'confirm') {
            localStorage.clear()
            sessionStorage.clear()
            this.socket.close()
            this.$store.commit('ChangeSocketInfo', null)
            this.$store.commit('ChangeSocketCode', null)
            this.$router.push({ path: '/' })
            // this.showLogin = true
            this.$store.dispatch('changeLogin', true)
            this.$store.dispatch('changeLogins', {})
            this.$message({
              type: 'success',
              message: '退出登录成功!'
            })
          }
        }
      })
    },
    back () {
      // history.back()
      this.$router.go(-1)
    },
    openWarn () {
      this.showWarn = true
    },
    closeWarn () {
      this.showWarn = false
    },
    openMonitor () {
      this.showMonitor = true
    },
    closeMonitor () {
      this.showMonitor = false
    },
    onPageRefresh () {
      this.SocketInfo.close()
    },
    openAfterSales () {
      this.showAfterSales = true
    },
    closeAfterSales () {
      this.showAfterSales = false
    }
  },
  created () {
    this.initRouter()
  },
  mounted () {
    this.$store.commit('ChangeSocketInfo', null)
    this.loginSocket()
  },
  beforeDestroy () {
  }
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
@import url('./index.less');
</style>

<style lang="less">
.index-background {
  background-color: #F0F0F0 !important;
}

.zhyl-head {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 10px;
  box-sizing: border-box;

  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    margin: 0 6px;

    .img {
      width: 24px;
      height: 20px;
    }

    .text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #fff;
      font-size: 16px;
    }

    .active {
      color: #01EEFD;
    }
  }

}

.blue-icon {
  color: #01EEFD;
}

.red-icon {
  color: #FF0000;
}

.badge-icon {
  color: #f17272 !important;
}

.zhyl-head1 {
  overflow-x: scroll;
  display: block;
  width: calc(80%);
  height: 100%;
  padding: 0;

  .zhyl-head-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

.tab-content {
  position: relative;
  width: 100%;
  height: calc(100% - 60px);
}

.el-form--inline .el-form-item__label {
  color: #01EEFD;
}

.detail-right {
  position: absolute;
  bottom: 3px;
  right: 3px;
}

.page-return {
  position: absolute;
  top: 50px;
  right: 80px;
  z-index: 999;
  width: 50px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  background-color: #01EEFD;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
}

.update-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #fff;

  .update-head {
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
    background: linear-gradient(82deg, #3672F7 0%, #39B3ED 100%);

    .update-flex {
      color: #fff;
    }
  }

  .update-flex {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    text-align: center;
    color: #000;
    font-size: 16px;
  }

  .update-flex1 {
    justify-content: space-evenly;
  }

  .update-scroll {
    overflow-y: scroll;
    width: 100%;
    height: 400px;

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  .update-list {
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;

    &:nth-child(odd) {
      background-color: #ECF5FF;
    }

    .input {
      width: 90%;
      height: 40px;
      text-align: center;
      background-color: transparent;
      border: none;
    }

    .input-update {
      border: 1px solid #C5C5C5;
      border-radius: 4px;
    }

    .button {
      width: 70px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      background-color: #929292;
      border-radius: 6px;
      color: #fff;
      font-size: 16px;
      cursor: pointer;
    }

    .button-update {
      background: linear-gradient(90deg, #366FF8 0%, #3ABDEC 100%);
    }

  }

  .update-list-active {
    background-color: #7ae8f0;
  }

  .update-tip {
    width: 100%;
    height: 60px;
    line-height: 60px;
    text-align: center;
    color: #FF0000;
    font-size: 16px;
  }

  .el-button {
    margin: 0 auto;
    background: linear-gradient(90deg, #366FF8 0%, #3ABDEC 100%);
    color: #fff;
  }

}

.after-sales {
  position: absolute;
  bottom: 50%;
  right: 40px;
  z-index: 7;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  border-radius: 50%;
  background-color: #3FA1FF;
  color: #DCE4EC;
  font-size: 18px;
  cursor: pointer;
}
</style>
