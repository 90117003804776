<!-- 统计分析 -->
<template>
  <div class='tjfx'>
    <!-- <div style="position:absolute;right:50px;top:10px;z-index:9999">
      <el-button size="small" @click="goBack" type="">返回</el-button>
    </div> -->
    <div class="top">
      <div class="top_left"><jgpm></jgpm></div>
      <div class="top_right"><yhdtfb></yhdtfb></div>
    </div>
    <div class="middle">
      <div class="middle_left"><gzzt></gzzt></div>
      <div class="middle_center"><yhfl></yhfl></div>
      <div class="middle_right"><jgqk></jgqk></div>
    </div>
    <div class="bottom">
      <div class="bottom_left"><rwqk></rwqk></div>
      <div class="bottom_center"><yjcl></yjcl></div>
      <div class="bottom_right"><ysgzl></ysgzl></div>
    </div>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import jgpm from './chart/jgpm.vue'
import yhdtfb from './chart/yhdtfb.vue'
import gzzt from './chart/gzzt.vue'
import yhfl from './chart/yhfl.vue'
import jgqk from './chart/jgqk.vue'
import rwqk from './chart/rwqk.vue'
import yjcl from './chart/yjcl.vue'
import ysgzl from './chart/ysgzl.vue'
export default {
// import引入的组件需要注入到对象中才能使用
  components: { jgpm, yhdtfb, gzzt, yhfl, jgqk, rwqk, yjcl, ysgzl },
  data () {
  // 这里存放数据
    return {

    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    goBack () {
      this.$router.go(-1)
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {

  },
  beforeCreate () {}, // 生命周期 - 创建之前
  beforeMount () {}, // 生命周期 - 挂载之前
  beforeUpdate () {}, // 生命周期 - 更新之前
  updated () {}, // 生命周期 - 更新之后
  beforeDestroy () {}, // 生命周期 - 销毁之前
  destroyed () {}, // 生命周期 - 销毁完成
  activated () {} // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.tjfx {
  height: 100%;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  .top {
    height: 450px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    &_left {
      width: 36%;
      height: 100%;
      border: 1px solid #03757F;
      border-radius: 6px;
    }
    &_right {
      width: 60%;
      height: 100%;
      border: 1px solid #03757F;
      border-radius: 6px;
    }
  }
  .middle {
    height: 300px;
    width: 100%;
    margin-top: 2%;
    display: flex;
    justify-content: space-between;
    &_left {
      width: 32%;
      height: 100%;
      border: 1px solid #03757F;
      border-radius: 6px;
    }
    &_center {
      width: 32%;
      height: 100%;
      border: 1px solid #03757F;
      border-radius: 6px;
    }
    &_right {
      width: 32%;
      height: 100%;
      border: 1px solid #03757F;
      border-radius: 6px;
    }
  }
  .bottom {
    height: 300px;
    width: 100%;
    margin-top: 2%;
    display: flex;
    justify-content: space-between;
    &_left {
      width: 32%;
      height: 100%;
      border: 1px solid #03757F;
      border-radius: 6px;
    }
    &_center {
      width: 32%;
      height: 100%;
      border: 1px solid #03757F;
      border-radius: 6px;
    }
    &_right {
      width: 32%;
      height: 100%;
      border: 1px solid #03757F;
      border-radius: 6px;
    }
  }
}
</style>
