<template>
  <div class="form">
    <div class="form-box">
      <div class="form-close">
        <span class="el-icon-close"></span>
      </div>
      <div class="form-title">
        <span>儿童视觉健康档案表</span>
      </div>
      <div class="form-head">
        <span>姓名：程霞</span>
        <span>编号：</span>
      </div>
      <div class="form-scroll">
        <table cellpadding="0" cellspacing="0" border class="form-table">
          <tr>
            <td colspan="1" class="form-td form-td-left">
              <span>性别</span>
            </td>
            <td colspan="2" class="form-td form-td-left">
              <el-radio-group v-model="radio">
                <el-radio label="1">男</el-radio>
                <el-radio label="2">女</el-radio>
              </el-radio-group>
            </td>
            <td colspan="1" class="form-td form-td-text">
              <span>出生日期</span>
            </td>
            <td colspan="3" class="form-td form-td-text">
              <el-date-picker
                v-if="show"
                v-model="date"
                size="small"
                type="date"
                placeholder="选择日期">
              </el-date-picker>
            </td>
          </tr>
          <tr>
            <td colspan="1" class="form-td form-td-left">
              <span>身份证号</span>
            </td>
            <td colspan="2" class="form-td form-td-left">
              <span>体检日期</span>
              <el-date-picker
                v-if="show"
                v-model="date"
                size="small"
                type="date"
                placeholder="选择日期">
              </el-date-picker>
            </td>
            <td colspan="1" class="form-td form-td-text">
              <span>家庭住址</span>
            </td>
            <td colspan="3" class="form-td form-td-text">
              <el-input v-if="show" size="small" class="input" v-model="input" placeholder=""></el-input>
            </td>
          </tr>
          <tr>
            <td colspan="1" class="form-td form-td-left">
              <span>父亲</span>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <span>姓名：</span>
              <el-input v-if="show" size="small" class="input1" v-model="input" placeholder=""></el-input>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <span>职业：</span>
              <el-input v-if="show" size="small" class="input1" v-model="input" placeholder=""></el-input>
            </td>
            <td colspan="1" class="form-td form-td-text">
              <span>联系电话：</span>
            </td>
            <td colspan="1" class="form-td form-td-text">
              <el-input v-if="show" size="small" class="input" v-model="input" placeholder=""></el-input>
            </td>
            <td colspan="1" class="form-td form-td-text">
              <span>出生日期：</span>
            </td>
            <td colspan="1" class="form-td form-td-text">
              <el-date-picker
                v-if="show"
                v-model="date"
                size="small"
                type="date"
                placeholder="选择日期">
              </el-date-picker>
            </td>
          </tr>
          <tr>
            <td colspan="1" class="form-td form-td-left">
              <span>母亲</span>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <span>姓名：</span>
              <el-input v-if="show" size="small" class="input1" v-model="input" placeholder=""></el-input>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <span>职业：</span>
              <el-input v-if="show" size="small" class="input1" v-model="input" placeholder=""></el-input>
            </td>
            <td colspan="1" class="form-td form-td-text">
              <span>联系电话：</span>
            </td>
            <td colspan="3" class="form-td form-td-text">
              <el-input v-if="show" size="small" class="input" v-model="input" placeholder=""></el-input>
            </td>
          </tr>
          <tr>
            <td colspan="1" class="form-td form-td-left">
              <span>检查日期</span>
            </td>
            <td colspan="2" class="form-td form-td-text">
              <el-date-picker
                v-if="show"
                v-model="date"
                size="small"
                type="date"
                placeholder="选择日期">
              </el-date-picker>
            </td>
            <td colspan="1" class="form-td form-td-text">
              <span>检查年龄</span>
            </td>
            <td colspan="3" class="form-td form-td-text">
              <el-input v-if="show" size="small" class="input" v-model="input" placeholder=""></el-input>
            </td>
          </tr>
          <tr>
            <td colspan="1" class="form-td form-td-left">
              <span>家庭住址</span>
            </td>
            <td colspan="6" class="form-td form-td-left">
              <el-input v-if="show" size="small" class="input" v-model="input" placeholder=""></el-input>
            </td>
          </tr>
          <tr>
            <td colspan="1" class="form-td form-td-left">
              <span>检查方法：</span>
            </td>
            <td colspan="6" class="form-td form-td-left">
              <el-checkbox-group v-model="checkList">
                <el-checkbox label="1">眼外观</el-checkbox>
                <el-checkbox label="2">光照反应</el-checkbox>
                <el-checkbox label="3">瞬目反射</el-checkbox>
                <el-checkbox label="4">网球实验</el-checkbox>
                <el-checkbox label="5">眼位检查</el-checkbox>
                <el-checkbox label="6">眼球运动</el-checkbox>
                <el-checkbox label="7">视物行为观察</el-checkbox>
                <el-checkbox label="8">视力检查</el-checkbox>
                <el-checkbox label="9">眼底检查</el-checkbox>
                <el-checkbox label="10">眼屈光检查</el-checkbox>
              </el-checkbox-group>
            </td>
          </tr>
          <tr>
            <td colspan="1" class="form-td form-td-left">
              <span>左眼视力</span>
            </td>
            <td colspan="2" class="form-td form-td-text">
              <el-input v-if="show" size="small" class="input" v-model="input" placeholder=""></el-input>
            </td>
            <td colspan="1" class="form-td form-td-text">
              <span>右眼视力</span>
            </td>
            <td colspan="3" class="form-td form-td-text">
              <el-input v-if="show" size="small" class="input" v-model="input" placeholder=""></el-input>
            </td>
          </tr>
          <tr>
            <td colspan="1" class="form-td form-td-left">
              <span>检查结果</span>
            </td>
            <td colspan="6" class="form-td form-td-left">
              <el-input v-if="show" size="small" class="input" v-model="input" placeholder=""></el-input>
            </td>
          </tr>
          <tr>
            <td colspan="1" class="form-td form-td-left">
              <span>建议指导</span>
            </td>
            <td colspan="6" class="form-td form-td-left">
              <el-input v-if="show" size="small" class="input" v-model="input" placeholder=""></el-input>
            </td>
          </tr>
          <tr>
            <td colspan="1" class="form-td form-td-left">
              <span>检查医生：</span>
            </td>
            <td colspan="1" class="form-td form-td-text">
              <el-input v-if="show" size="small" class="input" v-model="input" placeholder=""></el-input>
            </td>
            <td colspan="1" class="form-td form-td-text">
              <span>录入人：</span>
            </td>
            <td colspan="1" class="form-td form-td-text">
              <el-input v-if="show" size="small" class="input" v-model="input" placeholder=""></el-input>
            </td>
            <td colspan="2" class="form-td form-td-text">
              <span>录入日期：</span>
            </td>
            <td colspan="1" class="form-td form-td-text">
              <el-date-picker
                v-if="show"
                v-model="date"
                size="small"
                type="date"
                placeholder="选择日期">
              </el-date-picker>
            </td>
          </tr>
          <tr>
            <td colspan="1" class="form-td form-td-left">
              <span>录入机构：</span>
            </td>
            <td colspan="6" class="form-td form-td-left">
              <el-input v-if="show" size="small" class="input" v-model="input" placeholder=""></el-input>
            </td>
          </tr>
        </table>
      </div>
      <div class="form-button">
        <el-button type="primary" size="medium">确认</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      radio: null,
      date: '',
      checkList: [],
      input: '',
      show: true
    }
  }
}
</script>

<style lang="less" scoped>
.form {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 888;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .9);

  .form-box {
    position: relative;
    width: 80%;
    height: 95%;
    padding: 0 73px;
    border: 4px solid #21D6E0;
    box-sizing: border-box;

    .form-close {
      position: absolute;
      right: 30px;
      height: 50px;
      line-height: 50px;
      color: #fff;
      font-size: 20px;
      cursor: pointer;
    }

    .form-title {
      width: 100%;
      height: 60px;
      line-height: 60px;
      text-align: center;
      color: #01EEFD;
      font-size: 22px;
    }

    .form-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 50px;
      color: #01EEFD;
      font-size: 22px;
    }

    .form-scroll {
      overflow-y: scroll;
      width: 100%;
      height: calc(100% - 180px);

      .form-table {
        width: 100%;
        border-color: #01EEFD;

        .form-td {
          padding: 12px 0;
          box-sizing: border-box;
          color: #01EEFD;
          font-size: 14px;
          min-width: 170px;
        }

        .form-td-left {
          padding-left: 11px;
          box-sizing: border-box;
        }

        .form-td-text {
          text-align: center;
        }

        .el-radio {
          color: #fff;
        }

        .input {
          width: 90%;
        }

        .input1 {
          width: 100px;
        }

        .el-checkbox {
          color: #fff;
        }
      }
    }

    .form-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 70px;
    }
  }
}
</style>
