<!-- 任务 -->
<template>
  <div class='task'>
    <div class="header">任务</div>
    <div class="task-tabs">
      <div class="tabs-item" :class="{select:selectbtn}" @click="selectbtn=!selectbtn">任务提醒</div>
      <div class="tabs-item" :class="{select:!selectbtn}" @click="selectbtn=!selectbtn">任务进站推送</div>
    </div>
    <div class="scrol-box">
      <div class="com-scroll"  @mouseover="onmouseover" @mouseout="onmouseout" ref="scroll" :style="{height:allData.length*190+'px'}">
        <div class="scroll-item"  v-for="(i,index) in allData" :key="index" >
          <div><span>任务类型</span>{{i.title}}</div>
          <div><span>任务内容</span>{{i.name}}</div>
          <div><span>任务时间</span>{{i.time}}</div>
          <div><span>任务责任人</span>{{i.glys}}</div>
          <div><span>处置方法</span>{{i.value}}</div>
          <div><span>操作</span>
            <span style="background-color:#15ACF3;" class="scroll-btn" @click="viewTask(i)">查看</span>
            <span style="background-color:#15CAD2;" class="scroll-btn" @click="handleTask(i)">处置</span>
            <span style="background-color:#FF6652;" class="scroll-btn" @click="delTask(i,index)">删除</span>
          </div>
      </div>
      </div>
    </div>
    <el-dialog :visible.sync="viewVisible" width="40%">
      <el-descriptions :column="1">
        <el-descriptions-item label="任务类型">{{viewData.title}}</el-descriptions-item>
        <el-descriptions-item label="任务内容">{{viewData.name}}</el-descriptions-item>
        <el-descriptions-item label="任务时间">{{viewData.time}}</el-descriptions-item>
        <el-descriptions-item label="任务负责人">{{viewData.glys}}</el-descriptions-item>
        <el-descriptions-item label="处置办法">{{viewData.value}}</el-descriptions-item>
    </el-descriptions>
    </el-dialog>
    <el-dialog :visible.sync="handleVisible" width="40%">
      <el-row>
        <el-col :push="2" :span="6">处理办法</el-col>
        <el-col :span="16">
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="办法1"></el-checkbox>
            <el-checkbox label="办法2"></el-checkbox>
            <el-checkbox label="办法3"></el-checkbox>
            <el-checkbox label="办法4"></el-checkbox>
            <el-checkbox label="办法5"></el-checkbox>
          </el-checkbox-group>
        </el-col>
      </el-row>
      <el-row>
        <el-col :push="2" :span="6">备注</el-col>
        <el-col :span="16">
          <el-input :rows="4" type="textarea"></el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col :push="18" :span="6">
          <el-button size="mini" type="primary">保存</el-button>
          <el-button size="mini" type="info">清空</el-button>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';

export default {
// import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
  // 这里存放数据
    return {
      viewData: {},
      viewVisible: false,
      handleVisible: false,
      selectbtn: true,
      allData: [],
      checkList: []
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {
    'selectbtn' () {
      this.initData()
    }
  },
  // 方法集合
  methods: {
    viewTask (i) {
      this.viewData = i
      this.viewVisible = true
    },
    handleTask (i) {
      this.viewData = i
      this.handleVisible = true
    },
    delTask (i, index) {
      this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    async initData () {
      const params = {
        jgbh: this.$store.state.loginInfo.jgbh,
        jbmc: this.$store.state.loginInfo.jgmc,
        ssjgbhmc: this.$store.state.loginInfo.label,
        usercode: this.$store.state.loginInfo.usercode,
        username: this.$store.state.loginInfo.username,
        jgjb: this.$store.state.loginInfo.jgjb,
        ssjgbh: this.$store.state.loginInfo.value,
        sjjgbh: this.$store.state.loginInfo.sjjgbh
      }
      // const params = {
      //   jgbh: '',
      //   jbmc: '',
      //   ssjgbhmc: '',
      //   usercode: '',
      //   username: '',
      //   jgjb: '',
      //   ssjgbh: ''
      // }
      // 暂时屏蔽
      // const url = this.selectbtn ? 'api/jkct/app/zhdpyfrhgztx' : 'api/jkct/app/zhdpyfrhrwts'
      // const { data: res } = await this.$http.post(url, params)
      // if (res.code !== 0) return this.$message.error(res.msg)
      // this.allData = res.data
    },
    onmouseover () {
      this.$refs.scroll.style.animationPlayState = 'paused'
    },
    onmouseout () {
      this.$refs.scroll.style.animationPlayState = 'running'
    },
    interval () {
      // // console.log()

      const styles = document.styleSheets
      const runkeyframes = `@keyframes rowup2 {
        0% {
            transform: translate3d(0, 0, 0);
        }
        100% {
            transform: translate3d(0, -${(this.allData.length - 7) * 190}px, 0);
            display: none;
        }
      }`
      styles[0].insertRule(runkeyframes)
      // Array.from(styles).map((item, index) => {
      //   item.insertRule(runkeyframes)
      // })
      this.$refs.scroll.style.animationName = 'rowup2'
      this.$refs.scroll.style.animationDuration = this.allData.length * 3 + 's'
      this.$refs.scroll.style.animationIterationCount = 'infinite'
      this.$refs.scroll.style.animationTimingFunction = 'linear'
      this.$refs.scroll.style.animationDelay = '0'
      if (this.timer) {
        clearInterval(this.timer)
      }
      this.timer = setInterval(() => {
        this.initData()
      }, 3 * 1000 * 60)
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  async mounted () {
    await this.initData()
    this.interval()
  },
  beforeCreate () {}, // 生命周期 - 创建之前
  beforeMount () {}, // 生命周期 - 挂载之前
  beforeUpdate () {}, // 生命周期 - 更新之前
  updated () {}, // 生命周期 - 更新之后
  beforeDestroy () {}, // 生命周期 - 销毁之前
  destroyed () {}, // 生命周期 - 销毁完成
  activated () {} // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style>
  .task .el-dialog {
    background-image: url('../../assets/img/spms/bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border: 1px solid #27F6FF;
  }
  .task .el-descriptions__body {
    color: #ffffff;
    background-color: rgba(0, 0, 0, 0);
  }
  .task .el-row {
    margin: 15px 0;
    color: #ffffff;
  }
</style>
<style lang='less' scoped>
//@import url(); 引入公共css类
.task {
  height: 100%;
  width: 100%;
  background-image: url('../../assets/img/spms/ssjk-bg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  overflow: hidden;
  .header {
    text-align: center;
    color: #ffffff;
    font-size: 14px;
    font-weight: bold;
  }
  .task-tabs {
    display: flex;
    justify-content: center;
    padding: 10px 0;
    .tabs-item {
      cursor: pointer;
      padding: 3px 15px;
      margin: 0 15px;
      font-size: 14px;
      color: #81AFD6;
      background-image: url('../../assets/img/spms/btn-default.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: center;
    }
    .select {
      background-image: url('../../assets/img/spms/btn-select.png');
      color: #27F6FF;
    }
  }
  .scrol-box {
    height: 100%;
    overflow: hidden;
    .scroll-item {
      width: 90%;
      height: 190px;
      overflow: hidden;
      position: relative;
      width: 90%;
      margin-left: 5%;
      margin-bottom: 10px;
      background-image: url('../../assets/img/spms/task.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: center;
      padding: 8px 14px;
      box-sizing: border-box;
      color: #ffffff;
      font-size: 14px;
      .scroll-btn {
        text-align: center;
        width:50px;
        color:#fff;
        padding: 3px 0;
        border-radius:15px;
        font-size: 14px;
        margin:0 6px;
        cursor: pointer;
      }
      span {
        color: #15EAF3;
        display: inline-block;
        width: 100px;
      }
      div {
        padding: 5px 0;
      }
    }
  }
}
</style>
